import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-client-widget',
  templateUrl: './client-widget.component.html',
  styleUrls: ['./client-widget.component.scss'],
})
export class ClientWidgetComponent {
  @HostBinding('class') string = 'o-widget';

  constructor() { }
}
