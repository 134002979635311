import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { Task } from '../task';
import { TaskEditModalEditData } from '../task-edit-modal/task-edit-modal-data';
import { TaskEditModalComponent } from '../task-edit-modal/task-edit-modal.component';
import { TaskState } from '../task-state.enum';
import { TaskService } from '../task.service';
import { IdObjectService } from '../../../core/id-object.service';
import { Role } from '../../account/role.enum';
import { ReminderType } from '../../reminder/reminder-type.enum';

@Component({
  selector: 'app-task-workspace-item',
  templateUrl: './task-workspace-item.component.html',
  styleUrls: ['./task-workspace-item.component.scss'],
})
export class TaskWorkspaceItemComponent {
  @Input() task!: Task;
  @Output() taskChange = new EventEmitter<Task>();
  @HostBinding('class.-dimmed') get isNotDone(): boolean {
    return this.task.progress === 100 || this.task.state === TaskState.DELETED;
  }

  @HostBinding('class.-flaggedFilterMatched') get isFlaggedFilterMatched(): boolean {
    return this.task.workspaceMatched === true;
  }

  @HostBinding('class.-flaggedDeleted') get isFlaggedDeleted(): boolean {
    return this.task.state === TaskState.DELETED;
  }

  @HostBinding('class.-flaggedIgnored') get isFlaggedIgnored(): boolean {
    return this.task.ignored;
  }

  public trackById = IdObjectService.trackById;

  get showBody(): boolean {
    return this.task.progress !== 100 && this.task.state !== TaskState.DELETED;
  }

  set isDone(isDone: boolean) {
    const progress = isDone ? 100 : 0;

    this.taskService.patch(this.task.id, { progress }).subscribe((success) => {
      if (success) {
        this.task.progress = progress;
        this.taskChange.emit(this.task);
      }
    });
  }

  get isDone(): boolean {
    return this.task.progress === 100;
  }

  get isDeleted(): boolean {
    return this.task.state === TaskState.DELETED;
  }

  constructor(private matDialog: MatDialog, private taskService: TaskService) {}

  public isResponsible(role: Role): boolean {
    return role === Role.RESPONSIBLE;
  }

  public isPrivate(reminderType: ReminderType): boolean {
    return reminderType === ReminderType.PRIVATE;
  }

  public get warnAboutEndDate(): boolean {
    if (this.isDone) {
      return false;
    }

    return new Date() >= new Date(this.task.endDate);
  }

  public openEditModal(event: MouseEvent): void {
    const data: TaskEditModalEditData = {
      taskId: this.task.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(TaskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe((task?: Task) => {
      if (task) {
        this.task = task;
        this.taskChange.emit(task);
      }
    });
  }

  public get cssClass(): string[] {
    const classes: string[] = [];

    if (this.task.workspaceMatched) {
      classes.push('filterMatched');
    }

    if (this.task.state === TaskState.DELETED) {
      classes.push('deleted');
    }
    if (this.task.ignored) {
      classes.push('ignored');
    }

    return classes;
  }
}
