<!-- Widget -->
<div *ngIf="!dashboardEvaluation">
  dashboardEvaluation missing
</div>
<div *ngIf="dashboardEvaluation">
  <header class="widget-header">
    <span class="icon"><fa-icon icon="umbrella"></fa-icon></span>
    <span class="text">Mandanten-Status (Unternehmen)</span>
  </header>
  <div class="content">
    <div class="item-type-intro status" [ngClass]="getProgressMeterClass(progressMeterType.MAIN, dashboardEvaluation.relatedCriticalCappedSum)">
      <span class="key">
        {{dashboardEvaluation.clientName}}
        <small>
          <fa-icon icon="money-bill" matTooltip="Kritische Größe des Mandanten"></fa-icon>&nbsp;<b matTooltip="Kritische Größe des Mandanten">€ {{dashboardEvaluation.criticalSizeTotal | number}}</b>
          &nbsp;|&nbsp;&nbsp;&nbsp;<fa-icon icon="gavel"></fa-icon>&nbsp;<b matTooltip="Notwendige Complance-Themen"> {{dashboardEvaluation.defaultComplianceCount}}</b>
        </small>
      </span>
      <span class="value statusText" matTooltip="Anteil Gesamtrisiko Konzern an kritischem Maximalwert Konzern"><!--{{getTotalRiskCompany() | number:'1.0-0' }}-->
        {{dashboardEvaluation.relatedCriticalCappedSum | number:'1.0-0'}}%*
                                     <!--            35%*-->
      </span>
    </div>
    <div class="item-type-graph status" *ngFor="let company of dashboardEvaluation.companies; trackBy: trackById" [ngClass]="getProgressMeterClass(progressMeterType.SUB, company.percentageSumCappedCritical)">
      <span class="key">
        <span matTooltip="Anteil Risiko eines Unternehmens an Gesamtrisiko Konzern">{{company.companyName}} <span style="font-size: 0.8em; font-weight: normal;"> // {{company.percentageSumCappedCritical | number:'1.0-1'}}%</span></span>
        <small>
          <fa-icon icon="money-bill" matTooltip="Kritische Größe des Unterehmens"></fa-icon>&nbsp;<b matTooltip="Kritische Größe des Unterehmens">€ {{company.criticalSize | number }}</b>
          &nbsp;|&nbsp;<fa-icon icon="bomb" matTooltip="Unternehmenskritische Risiken"></fa-icon>&nbsp;<b matTooltip="Unternehmenskritische Risiken">{{company.criticalRisks}}</b>
          &nbsp;|&nbsp;<fa-icon icon="balance-scale" matTooltip="Anteil Risiko eines Unternehmens an anteiligem kritischen Maximalwert pro Unternehmen"></fa-icon>&nbsp;<b>{{company.percentageCriticalSizeCompany | number:'1.0-1'}}%</b>
        </small>
      </span>
      <span class="value">{{company.sumCappedCritical | number:'1.0-0'}}</span>
      <span class="percentage statusBG" [ngStyle]="{width: company.percentageSumCappedCritical + '%'}"></span>
    </div>
  </div>
  <footer class="footer">
    <br>
    *Aktuelles Gesamtrisiko gemessen am Gesamtrisiko des Konzerns
    <br><br>
    Zeigt den aktuellen Stand des Gesamtrisikos (Eintrittswahrsch. * Schadenshöhe) unter Berücksichtigung der getroffenen Maßnahmen und der größe der Unternehmen eines Mandanten (bemessen an den jeweiligen kritischen Größen.
  </footer>
</div>
<!-- // Widget -->
