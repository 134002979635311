<div class="side-nav">
  <div class="side-nav-sticky">

    <!--
    <div class="nav-header">
      <span class="label">Auswertungen</span>
      <span class="icon-left"><fa-icon icon="chart-bar"></fa-icon></span>
    </div>
    <ul class="orga">
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.MY_TOPICS}" (click)="setFilterType(workspaceFilterType.MY_TOPICS)" class="active">
          <span class="label">Name der Auswertung</span>
        </a>
      </li>
    </ul>
    -->

    <div class="nav-header">
      <span class="label">Unternehmen</span>
      <span class="icon-left"><fa-icon icon="building"></fa-icon></span>
      <span class="text-right" *ngIf="this.focus.compliance || this.focus.area"
      ><a (click)="clearFocus()">Alle Unternehmen</a></span
      >
    </div>
    <ul class="orga">
      <li>
        <a [ngClass]="{'active': !evaluationFilter.companyId}" (click)="setCompanyFilter()" class="active">
          <span class="label">Alle Unternehmen</span>
          <!--<span class="icon-left"><fa-icon icon="chart-bar"></fa-icon></span>-->
        </a>
      </li>
      <li *ngFor="let company of companies">
        <a [ngClass]="{'active': evaluationFilter.companyId === company.id}" (click)="setCompanyFilter(company.id)" class="active">
          <span class="label">{{company.companyName}}</span>
          <!--<span class="icon-left"><fa-icon icon="chart-bar"></fa-icon></span>-->
        </a>
      </li>
    </ul>

    <div class="nav-header">
      <span class="label">Struktur</span>
      <span class="icon-left"><fa-icon icon="sitemap"></fa-icon></span>
      <span class="text-right" *ngIf="this.focus.compliance || this.focus.area"
      ><a (click)="clearFocus()">Alle Ebenen</a></span
      >
    </div>
    <ul class="structure">
      <li *ngFor="let compliance of compliances; trackBy: trackById">
        <a
          [ngClass]="{ active: evaluationFilter.complianceName === compliance.complianceName }"
          [ngStyle]="{ color: compliance.designColor }"
          title="{{ compliance.complianceName }}"
          (click)="selectCompliance(compliance)"
        >
          <span class="label">{{ compliance.complianceName }}</span>
          <!--          <span *ngIf="(compliance.calculation?.riskCountTotalHigh || 0) > 0" class="icon-left highlightMe" matTooltip="Enthält mindestens ein Risiko mit > 7.000 Schaden"><fa-icon icon="exclamation-triangle"></fa-icon></span>-->
        </a>
      </li>
    </ul>
  </div>
</div>
