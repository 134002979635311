<div mat-dialog-title>Complience bearbeiten</div>
<form
  novalidate
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Name des Compliance-Themas</mat-label>
      <input
        matInput
        type="text"
        placeholder="Name des Compliance-Themas"
        formControlName="displayName"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Farbe</mat-label>
      <input
        matInput
        type="color"
        placeholder="Farbe"
        formControlName="designColor"
        list="colorList"
      />
      <datalist id="colorList">
        <option value="#7A6FF0"></option>
        <option value="#008CE3"></option>
      </datalist>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Schlagwörter</mat-label>
      <mat-chip-list formControlName="tags" #tagList>
        <mat-chip
          [selectable]="true"
          [removable]="true"
          (removed)="removeTag(index)"
          *ngFor="let tag of editFormGroup.get('tags')?.value; index as index"
        >
          {{ tag?.displayName }}
          <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
        </mat-chip>
        <input
          placeholder="neues Schlagwort"
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="tagAutocomplete"
          [matChipInputFor]="tagList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addTag($event)"
        />
      </mat-chip-list>
      <mat-autocomplete
        #tagAutocomplete="matAutocomplete"
        (optionSelected)="selectTag($event)"
      >
        <mat-option *ngFor="let tag of tagOptions$ | async" [value]="tag">
          {{ tag?.displayName }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
  <footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
  </footer>
</form>
