<div class="card" [ngClass]="cssClass">
  <header class="header">
    <button
      type="button"
      class="headerCheckbox"
      [disabled]="isCompleted"
      (click)="setCompleted()"
      matTooltip="Maßnahme als abgeschlossen markieren"
    >
      <fa-icon icon="check" *ngIf="isCompleted"></fa-icon>
    </button>
    <span
      class="headerIndicator"
      *ngIf="probabilityValue || damageValue"
      (click)="openEditModal($event)"
    >
      <span
        class="headerIndicatorItem"
        *ngIf="probabilityValue"
        [class.-highlightMe]="damageValue && damageValue > 35"
        [class.-highlightMeNot]="damageValue && damageValue < 10"
      >
        <fa-icon icon="house-damage" class="headingIndicatorIcon"></fa-icon>
        - {{ damageValue }}%

        <span
          class="efficiency"
          matTooltip="Aktueller Wirkungsgrad - ohne dass die Maßnahme abgeschlossen wurde"
          *ngIf="
            measure &&
            measure.reachedEfficiencyDamageValue &&
            (!measure.calculatedTotalProgress ||
              measure.calculatedTotalProgress < 100)
          "
          >-{{ reachedDamageValue }}%</span
        >
      </span>

      <span
        class="headerIndicatorItem"
        matTooltip="Aktueller Wirkungsgrad - ohne dass die Maßnahme abgeschlossen wurde"
        *ngIf="damageValue"
        [class.-highlightMe]="probabilityValue && probabilityValue > 35"
        [class.-highlightMeNot]="probabilityValue && probabilityValue < 10"
      >
        <fa-icon icon="dice" class="headingIndicatorIcon"></fa-icon>
        - {{ probabilityValue }}%

        <span
          class="efficiency"
          *ngIf="
            measure &&
            measure.reachedEfficiencyProbabilityValue &&
            (!measure.calculatedTotalProgress ||
              measure.calculatedTotalProgress < 100)
          "
          >-{{ reachedProbabilityValue }}%</span
        >
      </span>
    </span>
    <h5 class="heading" (click)="openEditModal($event)">
      <span>{{ measure?.displayName }}</span>
    </h5>
    <div class="headerControls">
      <button mat-icon-button (click)="openCreateTaskModal($event)" matTooltip="Neue Aufgabe anlegen">
        <fa-icon icon="plus-square"></fa-icon>
      </button>
      <button mat-icon-button (click)="toggleExpand()" matTooltip="Untergeordnete Objekte anzeigen">
        <fa-icon [icon]="expanded ? 'angle-up' : 'angle-down'"></fa-icon>
      </button>
    </div>
  </header>
  <div class="body">
    <app-progress [progress]="measure.calculatedTotalProgress"></app-progress>
    <div class="tags">
      <span class="a-tag -maxWidth" *ngIf="hasOtherAssignedRisks">
        <fa-icon icon="code-branch" class="a-tag__icon"></fa-icon>
        {{ otherAssignedRisks | risksCommaSeparated }}
      </span>
      <span class="a-tag" *ngIf="measure.measureAccounts?.length">
        <fa-icon icon="users" class="a-tag__icon"></fa-icon>
        <ng-container
          *ngFor="
            let measureAccount of measure.measureAccounts;
            let last = last;
            trackBy: trackById
          "
        >
          <span
            class="a-tag__partial"
            [class.-underline]="isResponsible(measureAccount.role)"
            >{{ measureAccount.account?.firstName }}
            {{ measureAccount.account?.lastName }}</span
          ><ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
      <span class="a-tag" *ngIf="measure.startDate || measure.endDate">
        <fa-icon icon="calendar" class="a-tag__icon"></fa-icon>
        <span class="a-tag__partial" *ngIf="measure.startDate">
          Start: {{ measure.startDate | date: 'EE, dd.MM.yyyy' }} &nbsp;
        </span>
        <span
          class="a-tag__partial"
          [class.-warn]="warnAboutEndDate"
          *ngIf="measure.endDate"
        >
          Ende: {{ measure.endDate | date: 'EE, dd.MM.yyyy' }}
        </span>
      </span>
      <span class="a-tag" *ngIf="measure.measureReminders?.length">
        <fa-icon icon="bell" class="a-tag__icon"></fa-icon>
        <ng-container
          *ngFor="let reminder of measure.measureReminders; let last = last"
        >
          <span
            class="a-tag__partial"
            [class.-italic]="isPrivate(reminder.reminderType)"
            >{{ reminder.date | date: 'E, dd.MM.y  HH:mm' }}</span
          ><ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
    </div>
  </div>
</div>
<div class="tasks" *ngIf="expanded">
  <app-task-workspace-item
    [task]="task"
    (taskChange)="updateTasks(true)"
    *ngFor="let task of tasks; trackBy: trackById"
  ></app-task-workspace-item>
  <button
    type="button"
    *ngIf="!tasks || tasks.length === 0"
    class="addTaskButton"
    (click)="openCreateTaskModal($event)"
  >
    Erste Aufgabe hinzufügen
  </button>
</div>
