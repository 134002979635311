import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { Category } from '../../category/category';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CategoryService } from '../../category/category.service';
import { StateService } from '@uirouter/core';
import { CategoryModalData } from './category-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../../area/area-state.enum';
import { CategoryState } from '../category-state.enum';
import {Role} from "../../account/role.enum";
import {Attribute} from "../../attribute/attribute";
import {Template} from "../../template/template";
import {startWith} from "rxjs/operators";
import {AttributeState} from "../../attribute/attribute-state.enum";
import {TemplateService} from "../../template/template.service";
import {TemplateState} from "../../template/template-state.enum";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss'],
})
export class CategoryModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  category: Category | null = null;
  pending: boolean = false;

  @ViewChild('templateInput') templateInput!: ElementRef<HTMLInputElement>;
  @ViewChild('parentCategoryInput') parentCategoryInput!: ElementRef<HTMLInputElement>;

  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: CategoryState.ACTIVE},
    {displayName: 'inaktiv', value: CategoryState.INACTIVE},
    {displayName: 'gelöscht ', value: CategoryState.DELETED},
  ];

  public editFormGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', []],
    state: [CategoryState.ACTIVE, [Validators.required]],
    templates: [[], []],
    parentCategory: [null, []]
  });

  readonly templateControl = new FormControl();
  searchTemplates: Template[] = [];

  readonly parentCategoryControl = new FormControl();
  searchParentCategories: Category[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CategoryModalData,
    public matDialogRef: MatDialogRef<CategoryModalComponent>,
    private categoryService: CategoryService,
    private templateService: TemplateService,
    private stateService: StateService,
  ) {
    this.templateControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.templateService.search({searchText, state: TemplateState.ACTIVE}).then((pagesItems) => {
            let currentNames = (this.editFormGroup.get('templates')?.value as Template[]).map(x => x.id);
            this.searchTemplates = pagesItems.items.filter(x => !currentNames.includes(x.id));
          });
        } else {
          this.searchTemplates = [];
        }
      });

    this.parentCategoryControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.categoryService.search({searchText, flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
            let currentId = this.editFormGroup.get('parentCategory')?.value ? this.editFormGroup.get('parentCategory')?.value.id : null;
            this.searchParentCategories = pagesItems.items.filter(x => (!currentId || currentId !== x.id) && this.category!.id !== x.id);
          });
        } else {
          this.searchParentCategories = [];
        }
      });
  }

  public compareByValue = ValueObjectService.compareByValue;

  public ngOnInit() {
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (this.data.categoryId) {
          this.categoryService.getSingle(this.data.categoryId).subscribe((category) => {
            this.category = category;
            this.editFormGroup.patchValue(category);
          });
        }
        break;
      case EditMode.CREATE:
        if (this.data.parentCategory) {
          this.editFormGroup.get('parentCategory')?.setValue(this.data.parentCategory);
        }
    }
  }

  selectTemplate(event: MatAutocompleteSelectedEvent) {
    const template = event.option.value as Template;

    this.templateInput.nativeElement.value = '';
    this.editFormGroup.get('templates')?.value.push(template);
  }

  removeTemplate(index: number) {
    this.editFormGroup.get('templates')?.value.splice(index, 1);
  }

  selectParentCategory(event: MatAutocompleteSelectedEvent) {
    const parentCategory = event.option.value as Category;

    this.parentCategoryInput.nativeElement.value = '';
    this.editFormGroup.get('parentCategory')?.setValue(parentCategory);
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  listAllSearchItems(event: Event, trigger: MatAutocompleteTrigger) {
    this.searchTemplates = [];
    this.templateService.search({searchText: '', state: TemplateState.ACTIVE}).then((pagesItems) => {
      let currentNames = (this.editFormGroup.get('templates')?.value as Template[]).map(x => x.id);
      this.searchTemplates = pagesItems.items.filter(x => !currentNames.includes(x.id));
    });

    event.stopPropagation();
    trigger.openPanel();
  }

  removeParentCategory() {
    console.log("sdfgfdgfd")
    this.editFormGroup.get('parentCategory')?.setValue(null);
  }

  listAllParentCategorySearchItems(event: Event, trigger: MatAutocompleteTrigger) {
    this.searchParentCategories = [];
    this.categoryService.search({searchText: '', flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
      let currentId = this.editFormGroup.get('parentCategory')?.value ? this.editFormGroup.get('parentCategory')?.value.id : null;
      this.searchParentCategories = pagesItems.items.filter(x => (!currentId || currentId !== x.id) && this.category!.id !== x.id);
    });

    event.stopPropagation();
    trigger.openPanel();
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (!this.category) {
          return;
        }
        const {id: categoryId} = this.category;
        this.categoryService.patch(categoryId, editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;

      case EditMode.CREATE:
        this.categoryService.create(editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;
    }
  }
}
