<!--<div class="navigation">
  <a uiSref="dashboard" uiSrefActive="active">Dashboard</a>
  <a uiSref="workspace" uiSrefActive="active">Workspace</a>
  <a uiSref="admin.compliance" uiSrefActive="active">Compliance</a>
  <a uiSref="admin.tags" uiSrefActive="active">Schlagwörter</a>
  <a uiSref="admin.areas" uiSrefActive="active">Bereiche</a>
  <a uiSref="admin.clients" uiSrefActive="active">Mandanten</a>
  <a uiSref="user.profile" uiSrefActive="active">Profil</a>
</div>-->
<!--<div class="outstanding-links">-->
<!--  <a href="/deposit/create">-->
<!--    <span class="mainNav-icon">-->
<!--      <fa-icon [icon]="['fas', 'hand-holding-heart']"></fa-icon>-->
<!--    </span>-->
<!--  </a>-->
<!--  <a uiSref="app.deposit.return">-->
<!--    <span class="mainNav-icon">-->
<!--      <fa-icon icon="inbox"></fa-icon>-->
<!--    </span>-->
<!--  </a>-->
<!--</div>-->


<nav id="mainNavNew" role="navigation">
  <ul>

    <!-- DASHBOARD NICHT AKTIV
    <li class="mainPage" uiSrefActive="active">
      <a uiSref="app.dashboard">
        <span class="mainNav-icon">
          <fa-icon icon="tachometer-alt"></fa-icon>
        </span>
        <span class="mainNav-title ng-binding">Dashboard</span>
        <span class="mainNav-subtitle ng-binding">Alles auf einen Blick</span>
      </a>
    </li>
    -->
    <li class="mainPage mobileLeft" uiSrefActive="active">
      <a uiSref="app.avv">
        <span class="mainNav-icon">
          <fa-icon icon="exchange-alt"></fa-icon>
        </span>
        <span class="mainNav-title ng-binding">AVVs</span>
      </a>
    </li>

    <li class="outstanding-spacer"></li>

    <li class="mainPage mobileRight" uiSrefActive="active">
      <a uiSref="app.menu">
        <span class="mainNav-icon">
          <fa-icon icon="ellipsis-h"></fa-icon>
        </span>
        <span class="mainNav-title ng-binding">Mehr</span>
      </a>
    </li>

<!--    <li class="mainPage" uiSrefActive="active">-->
<!--      <a uiSref="app.settings">-->
<!--        <span class="mainNav-icon">-->
<!--          <fa-icon icon="building"></fa-icon>-->
<!--        </span>-->
<!--        <span class="mainNav-title ng-binding">Einstellungen</span>-->
<!--      </a>-->
<!--    </li>-->
<!--    <li class="mainPage" uiSrefActive="active">-->
<!--      <a uiSref="app.client">-->
<!--        <span class="mainNav-icon">-->
<!--          <fa-icon icon="building"></fa-icon>-->
<!--        </span>-->
<!--        <span class="mainNav-title ng-binding">Agentur</span>-->
<!--      </a>-->
<!--    </li>-->


    <li class="space"></li>

    <li class="changeAccount">
      <a (click)="openChangeRoleModal()">
        <span class="showFullMenue-icon">
          <fa-icon icon="theater-masks"></fa-icon>
        </span>
        <span class="showFullMenue-title ng-binding">Rolle wechseln</span>
        <span class="showFullMenue-subtitle"></span>
      </a>
    </li>

    <li class="userDetails">
      <a uiSref="app.profile" class="user-edit">
        <span class="user-info">
          <span class="user-title">
            {{ authInfo?.account?.firstName }}
            {{ authInfo?.account?.lastName }}
          </span>
          <span class="user-subtitle">
            {{ authInfo?.client?.displayName }}
          </span>
        </span>
        <span class="user-image">
          <img src="/img/defaults/no-image-guest-400.jpg" alt=""
          /></span>
      </a>
      <a href="/logout" class="user-logout" target="_self">
        <span class="user-logout-icon"
        ><fa-icon icon="power-off"></fa-icon
        ></span>
        <span class="user-logout-title">{{ 'WORD_LOGOUT' | translate }}</span>
      </a>
    </li>
  </ul>
</nav>
