import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { EditMode } from '../../../core/edit-mode.enum';
import { Area } from '../../area/area';
import { Deposit } from '../deposit';
import { DepositService } from '../deposit.service';
import { StateService } from '@uirouter/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaEditModalData } from '../../area/area-edit-modal/area-edit-modal-data';
import { AreaState } from '../../area/area-state.enum';
import { DepositState } from '../deposit-state.enum';
import {BarcodeFormat} from "@zxing/library";
import {DepositCreateComponent} from "../deposit-create/deposit-create.component";
import {ComplianceEditModalData} from "../../compliance/compliance-edit-modal/compliance-edit-modal-data";
import {DepositEditModalEditData} from "../deposit-edit-modal/deposit-edit-modal-data";

@Component({
  selector: 'app-deposit-qr-scan-modal',
  templateUrl: './deposit-qr-scan-modal.component.html',
  styleUrls: ['./deposit-qr-scan-modal.component.scss'],
})
export class DepositQrScanModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  allowedQrFormats = [ BarcodeFormat.QR_CODE ];

  constructor(
    private matDialogRef: MatDialogRef<DepositQrScanModalComponent>,
    private ngZone: NgZone
  ) {
  }

  public ngOnInit() {
  }

  scanSuccessHandler(result: string) {
    this.ngZone.run(() => {
      this.matDialogRef.close(result)
    });

  }
}
