import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Paging, PagingSort } from './paging';

@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit {
  @Input() public paging!: Paging;
  @Input() public pagingSorts!: PagingSort[];
  @Output() pagingChanged = new EventEmitter<Paging>();

  currentSorting: PagingSort | undefined = undefined;

  constructor() {
  }

  ngOnInit(): void {
    if (this.paging.sort && this.pagingSorts) {
      this.currentSorting = this.pagingSorts.find(s => s.key === this.paging.sort && s.direction === this.paging.sortDirection);
    }
  }

  gotoPrevPage() {
    if (this.paging.currentPage > 0) {
      this.paging.currentPage--;
      this.updatePaging();
    }
  }

  gotoNextPage() {
    if (this.paging.currentPage < this.paging.totalPages) {
      this.paging.currentPage++;
      this.updatePaging();
    }
  }

  updateSorting() {
    if (this.currentSorting) {
      this.paging.sort = this.currentSorting.key;
      this.paging.sortDirection = this.currentSorting.direction;

      this.updatePaging();
    }
  }

  updatePaging() {
    console.log('** pagingChanged');
    this.pagingChanged.emit(this.paging);
  }

  get hasPrevPage(): boolean {
    return this.paging.currentPage > 0;
  }

  get hasNextPage(): boolean {
    return (this.paging.currentPage < (this.paging.totalPages - 1));
  }
}
