import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPANY_STATES } from './company.states';
import { UIRouterModule } from '@uirouter/angular';
import { CompanyGeneralComponent } from './company-general/company-general.component';
import { CompanyDesignComponent } from './company-design/company-design.component';
import { CompanyUserComponent } from './company-user/company-user.component';
import { CompanyNavComponent } from './company-nav/company-nav.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { UploadModule } from '../../core/upload/upload.module';

@NgModule({
  declarations: [
    CompanyGeneralComponent,
    CompanyDesignComponent,
    CompanyUserComponent,
    CompanyNavComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: COMPANY_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    UploadModule,
  ],
})
export class CompanyModule {}
