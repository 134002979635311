import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import {ClientService} from "../client/client.service";
import {resolveCurrentClient} from "../client/client.resolver";
import {ClientGeneralComponent} from "../client/client-general/client-general.component";
import {ClientDesignComponent} from "../client/client-design/client-design.component";
import {ClientUserComponent} from "../client/client-user/client-user.component";
import {AttributeOverviewComponent} from "../attribute/attribute-overview/attribute-overview.component";

export const SETTINGS_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.settings',
    redirectTo: 'app.settings.attribute.overview',
    url: '/settings',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'currentClient',
        deps: [Transition, ClientService],
        resolveFn: resolveCurrentClient,
      },
    ],
  },
  // {
  //   name: 'app.settings.attributes',
  //   views: {
  //     '$default@app': {
  //       component: AttributeOverviewComponent,
  //     },
  //   },
  //   url: '/attributes',
  // },
];
