import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { EvaluationFilter } from '../../../evaluation/evaluation.filter';
import { AuthInfo } from '../../../../core/auth-info/auth-info';
import { DashboardEvaluation, DashboardEvaluationRisk } from '../../dashboardEvaluation';
import { ReminderType } from '../../../reminder/reminder-type.enum';
import { Risk } from '../../dashboard';
import { RiskEditModalEditData } from '../../../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../../../risk/risk-edit-modal/risk-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';
import { StateService } from '@uirouter/angular';
import { AuthInfoService } from '../../../../core/auth-info/auth-info.service';
import { ProgressMeterMilestone } from '../../../../core/progress-meter/progress-meter-milestone';
import { ProgressMeterStates } from '../../../../core/calculation/ProgressMeterStates';
import { ProgressMeterType } from '../../../../core/progress-meter/progress-meter-type.enum';

@Component({
  selector: 'app-dashboard-widget-risks',
  templateUrl: './dashboard-widget-risks.component.html',
  styleUrls: ['./dashboard-widget-risks.component.scss'],
})
export class DashboardWidgetRisksComponent implements OnInit {
  @Input() evaluationFilter!: EvaluationFilter;
  @Input() authInfo?: AuthInfo | null = null;
  @Input() dashboardEvaluation?: DashboardEvaluation | null = null;
  @Output() filterChanged = new EventEmitter<EvaluationFilter>();
  @Output() dashboardReload = new EventEmitter<void>();

  risks: DashboardEvaluationRisk[] = [];
  public progressMeterType = ProgressMeterType;

  constructor(
    private matDialog: MatDialog,
    private authInfoService: AuthInfoService,
  ) {
  }

  ngOnInit(): void {
    this.updateRisks();
  }

  public isPrivate(reminderType: ReminderType): boolean {
    return reminderType === ReminderType.PRIVATE;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.evaluationFilter && changes.evaluationFilter.currentValue) {
      this.updateRisks();
    }
  }

  updateRisks() {
    console.log('**UpdateRisks for filter', this.evaluationFilter);
    const newRisks = [];
    if (this.dashboardEvaluation) {
      for (const company of this.dashboardEvaluation.companies) {
        if (this.evaluationFilter.companyId && this.evaluationFilter.companyId !== company.id) {
          continue;
        }

        for (const compliance of company.compliances) {
          if (this.evaluationFilter.complianceName && this.evaluationFilter.complianceName !== compliance.complianceName) {
            continue;
          }

          compliance.risks.forEach(r => {
            r.$$complianceName = compliance.complianceName;
            r.$$complianceColor = compliance.designColor;
            r.$$areaName = compliance.areaName;
            r.$$companyName = company.companyName;
          });
          newRisks.push(...compliance.risks);
        }
      }
    }

    this.risks = newRisks;
  }

  changeAccountIfNecessary(companyId: string) {
    if (!this.authInfo) {
      return;
    }

    // if (companyId !== this.authInfo.company.id) {
    //   this.authInfoService.setCurrentAccount({
    //     clientId: this.authInfo.client.id,
    //   });
    // }
  }

  editRisk(risk: DashboardEvaluationRisk) {
    this.changeAccountIfNecessary(risk.company.id);

    const data: RiskEditModalEditData = {
      riskId: risk.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.dashboardReload.emit();
      }
    });
  }

  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  getProgressMeterClass(type: ProgressMeterType, value: number) {
    const classNames: string[] = [];
    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }

    return classNames;
  }
}
