<!-- Widget -->
<div *ngIf="selectedCompany">
  <header class="widget-header">
    <span class="icon"><fa-icon icon="exclamation-triangle"></fa-icon></span>
    <span class="text">Risiken mit höchstem Risiko</span>
  </header>
  <div class="content" style="padding-bottom: 10px">
    <!-- Settings -->
    <div class="widget-settings">

      <div class="content-selection">
        <div class="intro">Zeige:</div>
        <select [(ngModel)]="selectedCompliance" (change)="complianceChanged()" name="food">
          <option [ngValue]="undefined">Alle Compliance-Themen</option>
          <option *ngFor="let compliance of selectedCompany.compliances; trackBy: trackById" [value]="compliance.complianceName">
            {{compliance.complianceName}}
          </option>
        </select>
        <!--
        <ul class="options">
          <li [ngClass]="{active: !dashboard.risksHighRisk.$$companyFilter}"
            (click)="setRisksHighRiskFilter()">
            <a>Alle Unternehmen<span class="icon"><fa-icon icon="sort-down"></fa-icon></span></a>
          </li>
          <li [ngClass]="{active: dashboard.risksHighRisk.$$companyFilter === company.id}"
            (click)="setRisksHighRiskFilter(company)" *ngFor="let company of dashboard.risksHighRisk.$$companies">
            <a>{{company.companyName}}<span class="icon"><fa-icon icon="sort-down"></fa-icon></span></a>
          </li>
        </ul>-->
      </div>

    </div>
    <!-- // Settings -->
    <!-- TagNav alt <div class="item-type-tagNav">
      <a class="tag" [ngClass]="{active: !dashboard.risksHighRisk.$$companyFilter}"
        (click)="setRisksHighRiskFilter()">
        Alle
      </a>
      <a class="tag" [ngClass]="{active: dashboard.risksHighRisk.$$companyFilter === company.id}"
        (click)="setRisksHighRiskFilter(company)" *ngFor="let company of dashboard.risksHighRisk.$$companies">
        {{company.companyName}}
      </a>
    </div>-->

    <div (click)="editRisk(risk)" class="item-type-moreDetails status clickable hasPercantage" *ngFor="let risk of selectedCompany.$risks; trackBy: trackById"
      [ngClass]="getProgressMeterClass(progressMeterType.MAIN, risk.calculatedRiskDamage)">
      <div class="key">
        <span matTooltip="Anteil Einzelrisiko an Risiko Compliance-Thema">{{risk.riskName}} <span style="font-size: 0.8em">// {{risk.percentageProportion | number:'1.0-1'}}%</span></span>
        <div class="itemDetails">
          <span class="detail"><fa-icon icon="house-damage"></fa-icon>
            {{risk.calculatedDamage}}</span>
          <span class="detail"><fa-icon icon="dice"></fa-icon>
            {{risk.$$prohabilityValue}}</span>
          <!--<span class="detail"><fa-icon icon="building"></fa-icon>
            {{risk.company?.companyName}}</span>-->
          <span class="detail"><fa-icon icon="gavel"></fa-icon>
            {{risk.$$complianceName}}</span>
        </div>
      </div>
      <span class="value statusText">{{risk.calculatedRiskDamage | number:'1.0-0'}}</span>
      <span class="percentage" [ngStyle]="{'width': risk.percentageProportion + '%'}" style="background: #a8b7be"></span>
    </div>
  </div>
</div>
<!-- // Widget -->
