<div mat-dialog-title>
  <div *ngIf="riskRevision">
    {{riskRevision.displayName}}<br />
    Revision vom {{riskRevision.revisionDate | date:'dd.MM.yyyy, HH:mm'}}<br />
    <small>Erstellt von {{riskRevision.revisionUser.firstName}}&nbsp;{{riskRevision.revisionUser.lastName}}</small>
  </div>
</div>
<form *ngIf="riskRevision">
  <mat-tab-group animationDuration="0ms" dynamicHeight="false">
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="user-check"></fa-icon>
        &nbsp;&nbsp;Revision
      </ng-template>
      <div mat-dialog-content>
        <h3 class="subHeading">Details</h3>
        <div>
          <label>Dokumentation der Prüfungshandlung</label><br />
          <span>{{riskRevision.auditPlotDescription}}</span>
        </div>

        <div>
          <label>Beschreibung des Ergebnisses</label><br />
          <span>{{riskRevision.auditResultDescription}}</span>
        </div>

        <h3 class="subHeading">Zusammenfassung</h3>

        <div>
          <label>Ergebnis der Prüfung</label><br />
          <span>{{'AUDIT_RESULT.' + riskRevision.auditResult | translate}}</span>
        </div>

        <div>
          <label>Nächste Prüfung</label><br />
          <span>{{'AUDIT_NEXT_DATE_TYPE.' + riskRevision.auditNextDateType | translate}}</span>
        </div>

        <div>
          <label>Nächste Prüfung</label><br />
          <span>{{riskRevision.auditNextDate | date:'dd.MM.yyyy'}}</span>
        </div>

        <div>
          <h3 class="subHeading">Prüfung</h3>
          Durchgeführt am {{riskRevision.auditLastDate | date:'dd.MM.yyyy'}} von {{riskRevision.auditLastUser?.firstName}}&nbsp;{{riskRevision.auditLastUser?.lastName}}
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="info-circle"></fa-icon>
        &nbsp;&nbsp;Allgemeines
      </ng-template>
      <div mat-dialog-content>

        <!-- STATUS -->
        <div class="conclusionContainer" style="padding-top: 20px">
          <!--<h2>Aktueller Status</h2>-->
          <fa-icon class="icon" icon="exclamation-triangle"></fa-icon>
          <app-progress-meter
            class="headerProgressMeter"
            [start]="riskRevision.damageValue * riskRevision.probabilityValue"
            [end]="riskRevision.calculatedRiskDamageMax"
            [value]="riskRevision.calculatedRiskDamage"
            [type]="progressMeterType.MAIN"
          ></app-progress-meter>

          <div class="progressMeters">
            <div class="progressMeterWithIcon" matTooltip="Risiko">
              <fa-icon class="icon" icon="house-damage"></fa-icon>
              <app-progress-meter
                class="progressMeter"
                [start]="riskRevision.damageValue"
                [end]="riskRevision.calculatedDamageMax"
                [value]="riskRevision.calculatedDamage"
                [type]="progressMeterType.SUB"
              ></app-progress-meter>
            </div>
            <div class="progressMeterWithIcon" matTooltip="Eintrittswahrscheinlichkeit">
              <fa-icon class="icon" icon="dice"></fa-icon>
              <app-progress-meter
                class="progressMeter"
                [start]="riskRevision.probabilityValue"
                [end]="riskRevision.calculatedProbabilityMax"
                [value]="riskRevision.calculatedProbability"
                [type]="progressMeterType.SUB"
              ></app-progress-meter>
            </div>
          </div>

         </div>
        <!-- // STATUS -->

        <div>
          <label>Risiko/Frage</label><br />
          <span>{{riskRevision.displayName}}</span>
        </div>

        <div>
          <label>Beschreibung</label><br />
          <span>{{riskRevision.description}}</span>
        </div>

        <h3 class="subHeading">Schaden</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <div>
              <label>Schaden</label><br />
              <span>{{riskRevision.damageValueEuro}}</span>
            </div>

            <small class="legende" matTooltip="Aktualisierung erfolgt nach dem Speichern // Die Berechnung der Punkte erfolgt auf Basis von 80% der kritischen Unternehmensgröße. // Der Maximale Wert beträgt 100 Punkte.">
              <b>Punkte: {{riskRevision.damageValue}}&nbsp;<fa-icon icon="info-circle"></fa-icon></b>
            </small>
          </div>
          <div class="gridItem giw-50">
            <div>
              <label>Eintrittswahrscheinlichkeit</label><br />
              <span>{{probabilityValue}}</span>
            </div>
            <small class="legende"><b>Punkte: {{ riskRevision.probabilityValue }}</b></small>
          </div>
        </div>

        <div>
          <label>Schadensarten</label><br />
          <span>{{riskRevision.damageTypes}}</span>
        </div>

        <mat-checkbox [checked]="riskRevision.companyRisk===true">
          Unternehmenskritisch
          <br><small>Auch wenn die Eintrittswahrscheinlichkeit sehr gering ist</small>
        </mat-checkbox>

        <h3 class="subHeading">Risiko Status</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <div class="checkboxes">
              <mat-checkbox [checked]="riskRevision.secured===true">Dieses Risiko ist sichergestellt</mat-checkbox>
            </div>
          </div>
          <div class="gridItem giw-50">
            <div class="checkboxes">
              <mat-checkbox [checked]="riskRevision.ignored===true">Dieses Risiko hat keine Relevanz</mat-checkbox>
            </div>
          </div>
        </div>

        <div>
          <label>Kommentar zum Status</label><br />
          <span>{{riskRevision.stateNote}}</span>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="sliders-h"></fa-icon>
        &nbsp;&nbsp;Details
      </ng-template>
      <div mat-dialog-content>
        <div>
          <label>Region</label><br />
          <span>{{riskRevision.$$translatedLegalAreas?.join(", ")}}</span>
        </div>
        <div>
          <label>Rechtsgrundlagen</label><br />
          <span>{{riskRevision.legalBases?.join(", ")}}</span>
        </div>

        <div>
          <label>Standards</label><br />
          <span>{{riskRevision.norms?.join(", ")}}</span>
        </div>

        <h3 class="subHeading">Organisatorischer</h3>

        <div>
          <label>Schlagwörter</label><br />
          <span>{{riskRevision.tags?.join(", ")}}</span>
        </div>

        <div>
          <label>Status</label><br />
          <span>{{'RISK_STATE.' + riskRevision.state | translate}}</span>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <footer mat-dialog-actions>
    <button type="button" (click)="closeModal()" mat-raised-button mat-dialog-close>Schließen</button>
  </footer>
</form>
