<div mat-dialog-title>Bereich bearbeiten</div>
<form
  novalidate
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
  <div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Name des Bereichs</mat-label>
      <input matInput type="text" formControlName="displayName" />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Compliance</mat-label>
      <mat-select [compareWith]="compareWithId" formControlName="compliance">
        <mat-option
          [value]="compliance"
          *ngFor="let compliance of compliances$ | async"
        >{{ compliance?.displayName }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <app-cp-tag-field [editFormGroup]="editFormGroup">

    </app-cp-tag-field>

    <mat-form-field appearance="fill">
      <mat-label>Interner Status</mat-label>
      <mat-select
        [compareWith]="compareByValue"
        formControlName="state">
        <mat-option
          [value]="state?.value"
          *ngFor="let state of stateOptions">
          {{ state?.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
  </footer>
</form>
