import { AccountTarget } from '../../../common/account/accountTarget.enum';
import { IdObject } from '../../id-object';
import {AuthInfo} from "../../auth-info/auth-info";

export interface CreateUserModalData {
  id?: string,
  userId?: string,
  amountClientAccounts?: number,
  target: AccountTarget;
  mode: CreateUserModalMode;
}

export enum CreateUserModalMode {
  CREATE,
  EDIT,
}
