import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressMeterComponent } from './progress-meter.component';
import { ProgressMeterMilestoneInRangePipe } from './progress-meter-milestone-in-range.pipe';

@NgModule({
  declarations: [ProgressMeterComponent, ProgressMeterMilestoneInRangePipe],
  imports: [CommonModule],
  exports: [ProgressMeterComponent],
})
export class ProgressMeterModule {}
