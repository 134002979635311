import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { UserService } from '../../../core/user/user.service';
import { AccountService } from '../../account/account.service';
import { Account } from '../../account/account';

@Component({
  selector: 'app-profile-infos',
  templateUrl: './profile-infos.component.html',
  styleUrls: ['./profile-infos.component.scss'],
})
export class ProfileInfosComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  public editFormGroup: FormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
  });

  public pending: boolean = false;

  account: Account | null = null;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private accountService: AccountService,
  ) {
  }

  public ngOnInit() {
    this.accountService.getSingle(this.authInfo.account.id).subscribe((account) => {
      this.account = account;
      this.editFormGroup.patchValue(account);
    });
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    const {value: editData} = editFormGroup;
    if (!this.account) {
      return;
    }

    this.pending = true;
    const {id} = this.account;

    this.accountService.patch(id, editData).subscribe(() => {
      this.pending = false;
      this.stateService.go('.', undefined, {reload: true});
    });
  }
}
