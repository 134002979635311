import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AttributeDeleteModalData} from './attribute-delete-modal';

@Component({
  selector: 'app-cp-confirm-modal',
  templateUrl: './attribute-delete-modal.component.html',
  styleUrls: ['./attribute-delete-modal.component.scss'],
})
export class AttributeDeleteModalComponent implements OnInit {
  pending: boolean = false;
  retypeDeleteKeyword: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    check: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AttributeDeleteModalData,
    public matDialogRef: MatDialogRef<AttributeDeleteModalComponent>,
  ) {

  }

  ngOnInit(): void {

  }

  cancel() {
    if (this.data && this.data.cancelAction) {
      const promise: Promise<any> = this.data.cancelAction();
      promise.then((result) => {
        this.matDialogRef.close(false);
      });
    }
  }

  proceed() {
      if (this.data && this.data.proceedAction) {

        const promise: Promise<any> = this.data.proceedAction();
        promise.then((result) => {
          this.matDialogRef.close(true);
        });
      }
  }

  proceedExtended() {
    if (this.data && this.data.proceedExtendedAction) {

      const promise: Promise<any> = this.data.proceedExtendedAction();
      promise.then((result) => {
        this.matDialogRef.close(true);
      });
    }
  }
}
