<div class="signUp" xmlns="http://www.w3.org/1999/html">
  <div class="canvas">
    <canvas #signCanvas style="width:100%; height: 100%"
      tabindex="0"></canvas>
  </div>
  <a (click)="clearSignature()" class="signupClear" *ngIf="hasSignature"><fa-icon icon="trash-alt"></fa-icon></a>
  <div class="label">
    <ng-content></ng-content>
  </div>
</div>
