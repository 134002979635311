<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Kategorie anlegen
  </ng-template>
  <ng-template #editModeText>
    Template bearbeiten
  </ng-template>
</div>
<form
  novalidate
  (ngSubmit)=" onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="gear"></fa-icon>
        &nbsp;&nbsp;Stammdaten
      </ng-template>
      <div style="padding: 20px 10px;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Bezeichnung</mat-label>
          <input matInput type="text" formControlName="name"/>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Beschreibung</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Interner Status</mat-label>
          <mat-select
            [compareWith]="compareByValue"
            formControlName="state">
            <mat-option
              [value]="state?.value"
              *ngFor="let state of stateOptions">
              {{ state?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="text-height"></fa-icon>&nbsp;&nbsp;Dynamische Attribute
      </ng-template>
      <div mat-dialog-content>
        <section class="subView-steps">
          <!-- Liste Add / Remove -->
          <div class="cp-gui-item">
            <div class="cp-gui-list-v01" style="margin-top: -20px">
              <div class="add-items-header">
                <div class="search">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Attribut zuweisen</mat-label>
                    <input type="text"
                           placeholder="Bezeichnung"
                           aria-label="Number"
                           matInput
                           #attributeInput
                           #trigger="matAutocompleteTrigger"
                           [formControl]="attributesControl"
                           [matAutocomplete]="attributeAutocomplete">
                    <mat-autocomplete
                      #attributeAutocomplete="matAutocomplete"
                      (optionSelected)="selectAttribute($event)"
                    >
                      <mat-option *ngFor="let attribute of searchAttributes" [value]="attribute">
                        <span>
                          <span *ngIf="attribute?.type === 'TEXT_ONE_LINE'" matTooltip="Einzeiliger Text"><fa-icon icon="font"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'TEXT_MULTI_LINE'" matTooltip="Mehrzeiliger Text"><fa-icon icon="align-left"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'NUMBER'" matTooltip="Nummer/Zahl">123</span>
                        <span *ngIf="attribute?.type === 'PRICE'" matTooltip="Preis"><fa-icon icon="euro-sign"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'DATE'" matTooltip="Datum"><fa-icon icon="calendar-day"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'FILE'" matTooltip="Datei"><fa-icon icon="upload"></fa-icon></span>
                        &nbsp;| {{attribute.key}}<small *ngIf="attribute.description"> / {{attribute.description}}</small></span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <a class="scan" (click)="listAllSearchItems($event, trigger)" matTooltip="Zeige alle Attribute">
                  <fa-icon icon="list-ul"></fa-icon>
                </a>
              </div>
              <!-- Item List -->
              <div class="item-list">
                <div class="itemList__entry" *ngFor="let item of editFormGroup.get('attributes')?.value; index as index">
                  <div class="item">
                    <div class="options-selection">
                      <a>
                        <span class="options-icon" (click)="removeAttribute(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                      </a>
                    </div>
                    <div class="icon">
                      <span *ngIf="item?.type === 'TEXT_ONE_LINE'" matTooltip="Einzeiliger Text"><fa-icon icon="font"></fa-icon></span>
                      <span *ngIf="item?.type === 'TEXT_MULTI_LINE'" matTooltip="Mehrzeiliger Text"><fa-icon icon="align-left"></fa-icon></span>
                      <span *ngIf="item?.type === 'NUMBER'" matTooltip="Nummer/Zahl">123</span>
                      <span *ngIf="item?.type === 'PRICE'" matTooltip="Preis"><fa-icon icon="euro-sign"></fa-icon></span>
                      <span *ngIf="item?.type === 'DATE'" matTooltip="Datum"><fa-icon icon="calendar-day"></fa-icon></span>
                      <span *ngIf="item?.type === 'FILE'" matTooltip="Datei"><fa-icon icon="upload"></fa-icon></span>
                    </div>
                    <div class="details" *ngIf="!item?.description">

                        <span class="primary">
                          <strong>{{ item?.key }}</strong>
                        </span>

                    </div>

                    <div class="details" *ngIf="item?.description">

                        <span class="primary">
                          <strong>{{ item?.key }}</strong>
                        </span>
                      <span class="secondary">
                          <span class="subItem">{{ item?.description }}</span>
                        </span>

                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      </div>
    </mat-tab>
  </mat-tab-group>
<footer mat-dialog-actions>
  <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
  <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
</footer>
</form>
