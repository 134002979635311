import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnassignedMeasuresWorkspaceItemComponent } from './unassigned-measures-workspace-item.component';
import { MeasureWorkspaceItemModule } from '../measure-workspace-item/measure-workspace-item.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [UnassignedMeasuresWorkspaceItemComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MeasureWorkspaceItemModule,
  ],
  exports: [UnassignedMeasuresWorkspaceItemComponent],
})
export class UnassignedMeasuresWorkspaceItemModule {}
