import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Tag } from '../../tag/tag';
import { TagService } from '../../tag/tag.service';
import { Compliance } from '../compliance';
import { ComplianceService } from '../compliance.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../../area/area-state.enum';
import { ComplianceState } from '../compliance-state.enum';
import { ValueObjectService } from '../../../core/value-object.service';

@Component({
  selector: 'app-compliance-edit-general',
  templateUrl: './compliance-edit-general.component.html',
  styleUrls: ['./compliance-edit-general.component.scss'],
})
export class ComplianceEditGeneralComponent implements OnInit {
  @Input() compliance: Compliance | null = null;
  @Input() editMode!: EditMode;
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly tagCtrl = new FormControl();

  tagOptions$: Observable<Tag[]> | null = null;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    designColor: ['#c7c7c7', [Validators.required]],
    tags: [[], []],
    state: [ComplianceState.ACTIVE, [Validators.required]],
  });

  public stateOptions: ValueObject[] = [
    { displayName: 'aktiv', value: ComplianceState.ACTIVE },
    { displayName: 'gelöscht ', value: ComplianceState.DELETED },
  ];

  public compareByValue = ValueObjectService.compareByValue;

  public pending: boolean = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public navigationService: NavigationService,
    private complianceService: ComplianceService,
    private tagService: TagService,
    private fb: FormBuilder,
    private stateService: StateService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-cancel.svg',
      ),
    );
    this.tagCtrl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.tagOptions$ = this.tagService.search({ searchText });
        } else {
          this.tagOptions$ = null;
        }
      });
  }

  public ngOnInit() {
    this.editFormGroup.patchValue(this.compliance as Compliance);
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.editFormGroup.get('tags')?.value.push({ displayName: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(index: number) {
    this.editFormGroup.get('tags')?.value.splice(index, 1);
  }

  selectTag(event: MatAutocompleteSelectedEvent) {
    const tag = event.option.value as Tag;

    this.tagInput.nativeElement.value = '';
    this.editFormGroup.get('tags')?.value.push(tag);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const { value: editData } = editFormGroup;
    switch (this.editMode) {
      case EditMode.CREATE:
        this.complianceService.create(editData).subscribe(() => {
          this.pending = false;
          this.stateService.go('app.compliance', undefined, { reload: true });
        });

        break;
      case EditMode.EDIT:
        if (!this.compliance) {
          return;
        }

        const { id: areaId } = this.compliance;

        this.complianceService.patch(areaId, editData).subscribe(() => {
          this.pending = false;
          this.stateService.go('app.compliance', undefined, { reload: true });
        });

        break;
    }
  }
}
