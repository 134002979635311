import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsOverviewComponent } from './tag-overview/tags-overview.component';
import { UIRouterModule } from '@uirouter/angular';
import { TAG_STATES } from './tag.states';

@NgModule({
  declarations: [TagsOverviewComponent],
  imports: [CommonModule, UIRouterModule.forChild({ states: TAG_STATES })],
})
export class TagModule {}
