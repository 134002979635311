export enum WorkspaceFilterType {
  MY_REMINDER = 'MY_REMINDER',
  MY_TOPICS = 'MY_TOPICS',
  DUE_TOPICS = 'DUE_TOPICS',
  RISK_HIGH_TOTAL_RISK = 'RISK_HIGH_TOTAL_RISK',
  RISK_HIGH_DAMAGE_VALUE = 'RISK_HIGH_DAMAGE_VALUE',
  RISK_HIGH_PROBABILITY_VALUE = 'RISK_HIGH_PROBABILITY_VALUE',
  RISK_WITHOUT_MEASURE = 'RISK_WITHOUT_MEASURE',
  RISK_COMPANY_RISK = 'RISK_COMPANY_RISK',
  MEASURE_SECURED = 'MEASURE_SECURED',
  IGNORED_OBJECTS = 'IGNORED_OBJECTS',
  DELETED_OBJECTS = 'DELETED_OBJECTS',
}
