<div class="paging-container" style="display: flex;" *ngIf="paging.totalItems > 0">
  <ul class="pages">
    <li class="prev-next" [ngClass]="{disabled: !hasPrevPage}"><a (click)="gotoPrevPage()"><span><fa-icon icon="angle-left"></fa-icon></span></a></li>
    <li class="select choose-page">
      <mat-form-field appearance="fill">
        <mat-label>Seite</mat-label>
        <mat-select [(ngModel)]="paging.currentPage" (selectionChange)="updatePaging()">
          <mat-option [value]="page" *ngFor="let page of paging.pages">{{ page+1 }}</mat-option>
        </mat-select>
      </mat-form-field>
      <span>Total: {{paging.totalItems}}</span>
    </li>
    <li class="prev-next" [ngClass]="{disabled: !hasNextPage}"><a (click)="gotoNextPage()"><span><fa-icon icon="angle-right"></fa-icon></span></a></li>
  </ul>

  <ul class="options">
    <li class="select choose-itemCount">
      <mat-form-field appearance="fill">
        <mat-label>Pro Seite</mat-label>
        <mat-select [(ngModel)]="paging.perPage" (selectionChange)="updatePaging()">
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="20">20</mat-option>
          <mat-option [value]="50">50</mat-option>
        </mat-select>
      </mat-form-field>
    </li>
    <li class="select choose-sort">
      <mat-form-field appearance="fill">
        <mat-label>Sortierung</mat-label>
        <mat-select [(ngModel)]="currentSorting" (selectionChange)="updateSorting()">
          <mat-option [value]="sort" *ngFor="let sort of pagingSorts">{{ sort.displayName }}</mat-option>
        </mat-select>
      </mat-form-field>
    </li>
  </ul>
</div>
