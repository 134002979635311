import { HostBinding } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-measures-widget',
  templateUrl: './measures-widget.component.html',
  styleUrls: ['./measures-widget.component.scss'],
})
export class MeasuresWidgetComponent {
  @HostBinding('class') string = 'o-widget';

  constructor() { }
  }
