<div mat-dialog-title>Berechtigungsprüfung</div>
<div mat-dialog-content>
  <p>Bitte aktuelles Passwort zum Bestätigen eingeben</p>
  <form novalidate (ngSubmit)="submit(formGroup)" [formGroup]="formGroup">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Aktuelles Passwort*</mat-label>
        <input matInput type="password" formControlName="currentPassword" />
        <mat-error *ngIf="formGroup.hasError('required', 'currentPassword')">
          Bitte aktuelles Passwort angeben.
        </mat-error>
        <mat-error *ngIf="formGroup.hasError('pattern', 'currentPassword')">
          Das eingegebene Passwort stimmt nicht überein.
        </mat-error>
      </mat-form-field>
    </div>
    <footer mat-dialog-actions>
      <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
      <button type="submit" mat-raised-button color="primary" [disabled]="!formGroup.get('currentPassword')?.value || pending || formGroup.invalid">Sichern</button>
    </footer>
  </form>
</div>
