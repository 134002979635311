import { ProgressMeterMilestone } from '../progress-meter/progress-meter-milestone';
import { ProgressMeterState } from '../progress-meter/progress-meter-state.enum';

export const ProgressMeterStates: ProgressMeterMilestone[] = [
  {
    state: ProgressMeterState.VERY_HIGH,
    valueMain: 7000,
    valueSub: 80,
    className: '-veryHigh',
  },
  {
    state: ProgressMeterState.HIGH,
    valueMain: 5000,
    valueSub: 60,
    className: '-high',
  },
  {
    state: ProgressMeterState.MEDIUM,
    valueMain: 2000,
    valueSub: 30,
    className: '-medium',
  },
  {
    state: ProgressMeterState.LOW,
    valueMain: 1000,
    valueSub: 9,
    className: '-low',
  },
  {
    state: ProgressMeterState.VERY_LOW,
    valueMain: 0,
    valueSub: 0,
    className: '-veryLow',
  },
];
