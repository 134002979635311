<nav id="subNav" class="hasIcons">
	<ul>
		<li uiSrefActive="active">
      <a uiSref="^.risk">
        <fa-icon icon="font"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.REVISOR.MENU.RISK' | translate}}</small>
		  </a>
    </li>
		<li uiSrefActive="active">
      <a uiSref="^.measure">
        <fa-icon icon="font"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.REVISOR.MENU.MEASURE' | translate}}</small>
		  </a>
    </li>
	</ul>
</nav>
