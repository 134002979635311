import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ItemOverviewComponent } from './item-overview/item-overview.component';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';

export const ITEM_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.item',
    redirectTo: 'app.item.overview',
    url: '/item',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
  {
    name: 'app.item.overview',
    views: {
      '$default@app': {
        component: ItemOverviewComponent,
      },
    },
    url: '/',
  },
];
