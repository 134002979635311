import { Ng2StateDeclaration } from '@uirouter/angular';
import { AppComponent } from './app.component';
import { resolveAuthInfo } from './core/auth-info/auth-info.resolver';
import { AuthInfoService } from './core/auth-info/auth-info.service';
import {ErrorPageOverviewComponent} from "./common/error-page/error-page-overview/error-page-overview.component";

export const MAIN_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app',
    component: AppComponent,
    url: '?',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
  },
];
