import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { Tag } from '../../../common/tag/tag';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';
import { TagService } from '../../../common/tag/tag.service';

@Component({
  selector: 'app-cp-tag-field',
  templateUrl: './cp-tag-field.component.html',
  styleUrls: ['./cp-tag-field.component.scss'],
})
export class CpTagFieldComponent implements OnInit {
  @Input() editFormGroup!: FormGroup;
  @Input() fxFlex!: string;
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly tagCtrl = new FormControl();

  constructor(private tagService: TagService) {
    this.tagCtrl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.tagOptions$ = this.tagService.search({searchText});
        } else {
          this.tagOptions$ = null;
        }
      });
  }

  ngOnInit(): void {
  }

  tagOptions$: Observable<Tag[]> | null = null;

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.editFormGroup.get('tags')?.value.push({displayName: value.trim()});
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(index: number) {
    this.editFormGroup.get('tags')?.value.splice(index, 1);
  }

  selectTag(event: MatAutocompleteSelectedEvent) {
    const tag = event.option.value as Tag;

    this.tagInput.nativeElement.value = '';
    this.editFormGroup.get('tags')?.value.push(tag);
  }
}
