<header id="header" role="banner">
  <h1>
    Auswertung
    <small>Risiken</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>
<app-evaluation-nav></app-evaluation-nav>

<main role="main" id="content" class="evaluation dashboard-widgets">
  <!-- Widget-Container -->
  <div class="widget-container" *ngIf="dashboardEvaluation && evaluationFilter.companyId === undefined">
    <div class="rowContainer">
      <app-dashboard-widget-client-overview class="widget width-1-2 showWidgetDetails" [dashboardEvaluation]="dashboardEvaluation"></app-dashboard-widget-client-overview>

      <app-dashboard-widget-client-compliances
        class="widget width-1-2 showWidgetDetails"
        [ngClass]="{'showWidgetDetails': evaluationFilter.showTotalRiskDetails}"
        [authInfo]="authInfo"
        [evaluationFilter]="evaluationFilter"
        (filterChanged)="updateFilter()"
        [dashboardEvaluation]="dashboardEvaluation">
      </app-dashboard-widget-client-compliances>
    </div>
  </div>

  <div class="widget-container" *ngIf="dashboardEvaluation && evaluationFilter.companyId !== undefined">
    <div class="rowContainer">
      <app-dashboard-widget-company-overview
        class="widget width-1-2 showWidgetDetails"
        [dashboardEvaluation]="dashboardEvaluation"
        [evaluationFilter]="evaluationFilter">
      </app-dashboard-widget-company-overview>

      <app-dashboard-widget-company-risks
        class="widget width-1-2 showWidgetDetails"
        [dashboardEvaluation]="dashboardEvaluation"
        (dashboardReload)="reloadEvaluation()"
        (filterChanged)="updateFilter()"
        [evaluationFilter]="evaluationFilter">
      </app-dashboard-widget-company-risks>
    </div>
  </div>
  <!-- // Widget-Container -->

  <app-dashboard-widget-risks
    class="widget width-1-2 showWidgetDetails"
    [dashboardEvaluation]="dashboardEvaluation"
    (dashboardReload)="reloadEvaluation()"
    (filterChanged)="updateFilter()"
    [evaluationFilter]="evaluationFilter">
  </app-dashboard-widget-risks>
</main>

<app-evaluation-sidenav
  [companies]="dashboardEvaluation?.companies"
  [dashboardEvaluation]="dashboardEvaluation"
  [evaluationFilter]="evaluationFilter"
  (evaluationFilterChanged)="updateFilter()">
</app-evaluation-sidenav>
