import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvvOverviewComponent } from './avv-overview/avv-overview.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DEPOSIT_STATES } from './avv.states';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import { SignatureModule } from '../../core/signature/signature.module';
import { PagingModule } from '../../core/paging/paging.module';
import {AvvConfirmationModalComponent} from "./avv-confirmation-modal/avv-confirmation-modal.component";

@NgModule({
  declarations: [
    AvvOverviewComponent,
      AvvConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: DEPOSIT_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    SignatureModule,
    ZXingScannerModule,
    PagingModule,
  ],
})
export class AvvModule {
}
