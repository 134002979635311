import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation/navigation.component';
import { UIRouterModule } from '@uirouter/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MenuComponent } from './menu/menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { UI_STATES } from './ui.states';
import { CpTagFieldComponent } from './formFields/cp-tag-field/cp-tag-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    NavigationComponent,
    MenuComponent,
    CpTagFieldComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: UI_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MatFormFieldModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    FormsModule,
    FlexModule,
    MatIconModule,
  ],
  exports: [
    NavigationComponent,
    CpTagFieldComponent,
  ],
})
export class UiModule {
}
