import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, throwError } from 'rxjs';
import {
  TfaAuthorizedRequestData,
  Tfa,
  TfaLinkData,
  TfaCreateData,
  TfaForLinkData,
} from './tfa';

@Injectable({
  providedIn: 'root',
})
export class TfaService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}user/tfa`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}user/tfa/${id}`;
  private static forLinkUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}user/tfa/${id}/forLink`;
  private static linkUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}user/tfa/${id}/link`;
  private static enableUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}user/tfa/${id}/enable`;
  private static disableUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}user/tfa/${id}/disable`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Tfa[]> {
    const {apiUrl} = environment;
    const url = TfaService.listUrl(apiUrl);

    return this.httpClient
      .get<Tfa[]>(url)
      .pipe(catchError(this.handleError<Tfa[]>('getList', [])));
  }

  public getForLinkData(id: string): Observable<TfaForLinkData> {
    const {apiUrl} = environment;
    const url = TfaService.forLinkUrl(apiUrl, id);

    return this.httpClient
      .get<TfaForLinkData>(url)
      .pipe(catchError(this.handleError<TfaForLinkData>('getList')));
  }

  public create(tfaCreate: TfaCreateData): Observable<TfaForLinkData> {
    const {apiUrl} = environment;
    const url = TfaService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<TfaForLinkData>(url, tfaCreate, {headers})
      .pipe(catchError(this.handleError<TfaForLinkData>('create')));
  }

  public confirm(id: string, tfaConfirm: TfaLinkData): Observable<boolean> {
    const {apiUrl} = environment;
    const url = TfaService.linkUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<any>(url, tfaConfirm, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<false>('confirm')),
    );
  }

  public enable(
    id: string,
    authorizedRequest: TfaAuthorizedRequestData,
  ): Observable<boolean> {
    const {apiUrl} = environment;
    const url = TfaService.enableUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<any>(url, authorizedRequest, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<false>('enable')),
    );
  }

  public disable(
    id: string,
    authorizedRequest: TfaAuthorizedRequestData,
  ): Observable<boolean> {
    const {apiUrl} = environment;
    const url = TfaService.disableUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<any>(url, authorizedRequest, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<false>('disable')),
    );
  }

  public delete(
    id: string,
    authorizedRequest: TfaAuthorizedRequestData,
  ): Observable<boolean> {
    const {apiUrl} = environment;
    const url = TfaService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .delete<void>(url, {body: authorizedRequest, headers})
      .pipe(
        map(() => true),
        catchError(this.handleError('remove', false)),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${TfaService.name}->${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return throwError(error.error);
      // return of(result as T);
    };
  }
}
