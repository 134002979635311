<header id="header" role="banner">
  <h1>
    Workspace
    <small>Workspace</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content" class="workspace">
  <div class="list-header">
    <div class="bigsearch-container">
      <input
        [(ngModel)]="workspaceFilter.searchText"
        (keyup.enter)="filterChanged()"
        class="bigsearch"
        placeholder="Compliance, Bereich, Risiko, Aufgabe, …"
      />
      <button class="bigsearch-button" (click)="filterChanged()">
        <fa-icon icon="search"></fa-icon>
      </button>
    </div>
    <button class="refresh-button" *ngIf="workspaceFilter.searchText" (click)="resetFilter(['searchText'])">
      <fa-icon icon="broom"></fa-icon>
    </button>
  </div>

  <div class="compliances">
    <app-compliance-workspace-item
      [compliance]="compliance"
      [workspaceFilter]="workspaceFilter"
      [focus]="focus"
      (complianceChange)="updateCompliances()"
      *ngFor="let compliance of compliances; trackBy: trackById"
    ></app-compliance-workspace-item>
    <app-unassigned-measures-workspace-item></app-unassigned-measures-workspace-item>
  </div>
</main>

<div class="side-nav">
  <div class="side-nav-sticky">
    <div class="nav-header">
      <span class="label">Filter</span>
      <span class="icon-left"><fa-icon icon="filter"></fa-icon></span>
      <span class="text-right" *ngIf="workspaceFilter.filterType" (click)="resetFilter(['filterType'])"><a>Filter zurücksetzen</a></span>
    </div>

    <ul class="orga">
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.MY_TOPICS}" (click)="setFilterType(workspaceFilterType.MY_TOPICS)">
          <span class="label">Nur meine Themen</span>
          <span class="icon-left"><fa-icon icon="user"></fa-icon></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.MY_REMINDER}" (click)="setFilterType(workspaceFilterType.MY_REMINDER)">
          <span class="label">Meine Reminder</span>
          <span class="icon-left"><fa-icon icon="bell"></fa-icon></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.DUE_TOPICS}" (click)="setFilterType(workspaceFilterType.DUE_TOPICS)">
          <span class="label">Fällige Themen</span>
          <span class="icon-left"><fa-icon icon="calendar-alt"></fa-icon></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.RISK_HIGH_TOTAL_RISK}" (click)="setFilterType(workspaceFilterType.RISK_HIGH_TOTAL_RISK)">
          <span class="label">Risiken: Gesamtrisiko > 7.000</span>
          <span class="icon-left"
            ><fa-icon icon="exclamation-triangle"></fa-icon
          ></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.RISK_COMPANY_RISK}" (click)="setFilterType(workspaceFilterType.RISK_COMPANY_RISK)">
          <span class="label">Unternehmenskritisch</span>
          <span class="icon-left"
            ><fa-icon icon="bomb"></fa-icon
          ></span>
        </a>
      </li>
			<li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.RISK_HIGH_DAMAGE_VALUE}" (click)="setFilterType(workspaceFilterType.RISK_HIGH_DAMAGE_VALUE)">
          <span class="label">Risiken: Schaden > 80</span>
          <span class="icon-left"
            ><fa-icon icon="house-damage"></fa-icon
          ></span>
        </a>
      </li>
			<li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.RISK_HIGH_PROBABILITY_VALUE}" (click)="setFilterType(workspaceFilterType.RISK_HIGH_PROBABILITY_VALUE)">
          <span class="label">Risiken: Eintrittswahrsch. > 80</span>
          <span class="icon-left"
            ><fa-icon icon="dice"></fa-icon
          ></span>
        </a>
      </li>
			<li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.RISK_WITHOUT_MEASURE}" (click)="setFilterType(workspaceFilterType.RISK_WITHOUT_MEASURE)">
          <span class="label">Risiken ohne Maßnahme</span>
          <span class="icon-left"
            ><fa-icon icon="ban"></fa-icon
          ></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.MEASURE_SECURED}" (click)="setFilterType(workspaceFilterType.MEASURE_SECURED)">
          <span class="label">Sichergestellte Maßnahmen</span>
          <span class="icon-left"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.IGNORED_OBJECTS}" (click)="setFilterType(workspaceFilterType.IGNORED_OBJECTS)">
          <span class="label">Ignorierte Objekte</span>
          <span class="icon-left"><fa-icon icon="eye-slash"></fa-icon></span>
        </a>
      </li>
      <li>
        <a [ngClass]="{'active': workspaceFilter.filterType === workspaceFilterType.DELETED_OBJECTS}" (click)="setFilterType(workspaceFilterType.DELETED_OBJECTS)">
          <span class="label">Gelöschte Objekte</span>
          <span class="icon-left"><fa-icon icon="trash-alt"></fa-icon></span>
        </a>
      </li>
    </ul>

    <div class="nav-header">
      <span class="label">Struktur</span>
      <span class="icon-left"><fa-icon icon="sitemap"></fa-icon></span>
      <span class="text-right" *ngIf="this.focus.compliance || this.focus.area"
        ><a (click)="clearFocus()">Alle Ebenen</a></span
      >
    </div>
    <ul class="structure">
      <li *ngFor="let compliance of compliances; trackBy: trackById">
        <a
          [ngClass]="{ active: focus.compliance?.id === compliance.id }"
          [ngStyle]="{ color: compliance.designColor }"
          title="{{ compliance.displayName }}"
          (click)="selectCompliance(compliance)"
        >
          <span class="label">{{ compliance.displayName }}</span>
          <span *ngIf="(compliance.calculation?.riskCountTotalHigh || 0) > 0" class="icon-left highlightMe" matTooltip="Enthält mindestens ein Risiko mit > 7.000 Schaden"><fa-icon icon="exclamation-triangle"></fa-icon></span>

          <span
            class="icon-right"
            *ngIf="compliance.areas.length > 0"
            (click)="toggleExpanded(compliance)"
          >
            <fa-icon
              [icon]="
                expandedCompliance === compliance.id ? 'angle-up' : 'angle-down'
              "
            ></fa-icon>
          </span>
        </a>

        <ul
          class="structure-sub"
          *ngIf="
            expandedCompliance === compliance.id && compliance.areas.length > 0
          "
        >
          <li *ngFor="let area of compliance.areas; trackBy: trackById">
            <a
              [ngClass]="{ active: focus.area?.id === area.id }"
              [ngStyle]="{ color: compliance.designColor }"
              title="{{ area.displayName }}"
              (click)="selectArea(compliance, area)"
            >
              <span class="label">{{ area.displayName }}</span>
              <span *ngIf="(area.calculation?.riskCountTotalHigh || 0) > 0"class="icon-left highlightMe" matTooltip="Enthält mindestens ein Risiko mit > 7.000 Schaden" ><fa-icon icon="exclamation-triangle"></fa-icon></span>
            </a>
            <a (click)="openCreateRiskModal(area, $event)">
              <span class="icon-right" matTooltip="Risiko in diesem Bereich angelegen"><fa-icon icon="plus"></fa-icon></span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
