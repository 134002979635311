import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientWidgetComponent } from './client-widget.component';



@NgModule({
  declarations: [
    ClientWidgetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ClientWidgetComponent,
  ],
})
export class ClientWidgetModule { }
