<header id="header" role="banner">
  <h1>
    Bereiche
    <small>General</small>
  </h1>
  <a
    (click)="navigationService.goBack('app.area')"
    rel="back"
    class="header-icon header-icon-left"
    ng-if="!vm.event.parentEvent"
  >
    <fa-icon icon="chevron-left"></fa-icon>
  </a>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>
<main role="main" id="content" class="areas">
  <app-area-edit-nav></app-area-edit-nav>
  <form
    class="o-areaEdit"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup"
  >

    <h3 class="subHeading">Allgemeine Informationen</h3>

    <div fxLayout="row">
      <mat-form-field fxFlex="100%" appearance="fill">
        <mat-label>Name des Bereichs</mat-label>
        <input matInput type="text" formControlName="displayName" />
      </mat-form-field>
    </div>

    <div fxLayout="row">
      <app-cp-tag-field fxFlex="100%" [editFormGroup]="editFormGroup">

      </app-cp-tag-field>
    </div>

    <div fxLayout="row">
      <mat-form-field fxFlex="100%" appearance="fill">
        <mat-label>Interner Status</mat-label>
        <mat-select
          [compareWith]="compareByValue"
          formControlName="state">
          <mat-option
            [value]="state?.value"
            *ngFor="let state of stateOptions">
            {{ state?.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <footer class="o-areaEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>
