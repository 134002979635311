import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Compliance, ComplianceAccount, ComplianceAccountDraft, ComplianceCalculation,
  ComplianceDraft,
  ComplianceSearchData,
} from './compliance';
import { IdObject } from 'src/app/core/id-object';
import { NGXLogger } from 'ngx-logger';
import { RiskCalculations } from '../risk/risk';

@Injectable({
  providedIn: 'root',
})
export class ComplianceService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}compliance`;
  private static singleUrl = (apiUrl: string, id: string): string => `${apiUrl}compliance/${id}`;
  // private static singleAccountsUrl = (apiUrl: string, complianceId: string, id: string): string => `${apiUrl}compliance/${complianceId}/accounts/${id}`;

  private static accountsUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}compliance/${id}/accounts`;

  private static singleAccountUrl = (
    apiUrl: string,
    complianceId: string,
    id: string,
  ): string => `${apiUrl}compliance/${complianceId}/accounts/${id}`;

  private static singleCalculationsUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}compliance/${id}/calculations`;

  private static searchUrl = (apiUrl: string): string => `${apiUrl}compliance/search`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Compliance[]> {
    const {apiUrl} = environment;
    const url = ComplianceService.listUrl(apiUrl);

    return this.httpClient
      .get<Compliance[]>(url)
      .pipe(
        catchError(
          this.handleError<Compliance[]>('ComplianceService->getList', []),
        ),
      );
  }

  public getSingle(id: string): Observable<Compliance> {
    const {apiUrl} = environment;
    const url = ComplianceService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Compliance>(url)
      .pipe(
        catchError(
          this.handleError<Compliance>('ComplianceService->getSingle'),
        ),
      );
  }

  public getSingleAccounts(id: string): Observable<ComplianceAccount[]> {
    const {apiUrl} = environment;
    const url = ComplianceService.accountsUrl(apiUrl, id);

    return this.httpClient
      .get<ComplianceAccount[]>(url)
      .pipe(catchError(this.handleError<ComplianceAccount[]>('ComplianceService->getSingleAccounts')));
  }

  public createOrUpdateAccount(
    complianceId: string,
    accountDraft: ComplianceAccount | ComplianceAccountDraft,
  ): Observable<boolean> {
    const {apiUrl} = environment;
    const url = ComplianceService.accountsUrl(apiUrl, complianceId);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<void>(url, accountDraft, {headers}).pipe(
        map(() => true),
        catchError(this.handleError('ComplianceService->createAccount', false)),
      );
  }

  public deleteAccount(complianceId: string, account: ComplianceAccount): Observable<boolean> {
    const {apiUrl} = environment;
    const url = ComplianceService.singleAccountUrl(apiUrl, complianceId, account.id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('ComplianceService->deleteAccount', false)),
    );
  }

  public getSingleCalculation(id: string): Observable<ComplianceCalculation> {
    const {apiUrl} = environment;
    const url = ComplianceService.singleCalculationsUrl(apiUrl, id);

    return this.httpClient
      .get<ComplianceCalculation>(url)
      .pipe(catchError(this.handleError<ComplianceCalculation>(
        'ComplianceService->getSingleCalculation')));
  }

  public create(complianceDraft: ComplianceDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = ComplianceService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<IdObject>(url, complianceDraft, {headers})
      .pipe(
        map((idObject: IdObject) => idObject.id),
        catchError(this.handleError<string>('ComplianceService->create')),
      );
  }

  public patch(
    id: string,
    complianceDraft: ComplianceDraft,
  ): Observable<boolean> {
    const {apiUrl} = environment;
    const url = ComplianceService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<void>(url, complianceDraft, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('ComplianceService->patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = ComplianceService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('ComplianceService->remove', false)),
    );
  }

  public search(
    complianceSearchDatac: ComplianceSearchData,
  ): Observable<Compliance[]> {
    const {apiUrl} = environment;
    const url = ComplianceService.searchUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<Compliance[]>(url, complianceSearchDatac, {headers})
      .pipe(
        catchError(
          this.handleError<Compliance[]>('ComplianceService->search', []),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
