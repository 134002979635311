<header id="header" role="banner">
  <h1>
    Revisor
    <small>Risiken</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>
<app-revisor-nav></app-revisor-nav>

<main role="main" id="content" class="revisor dashboard-widgets">
<!-- Risk-Container -->
  <div class="risk-container">
    <!-- Risk -->
    <!--<div class="risk-item-withoutCollapsable clickable status">
      <div class="risk-shortInfo">
        <div class="checkbox">
          <fa-icon [icon]="['far', 'circle']"></fa-icon>
          &lt;!&ndash;<fa-icon [icon]="['far', 'check-circle']"></fa-icon>&ndash;&gt;
        </div>
      </div>

      <div class="risk-mainInfo">
        <span class="risk-titel">Name des Risikos/der Maßnahme</span>
        <div class="metaData">

          <div class="meta-item statusColor">
            <span class="icon"><fa-icon icon="gavel"></fa-icon></span>
            <span class="value">Compliance Name</span>
          </div>
          <div class="meta-item">
            <span class="icon"><fa-icon icon="building"></fa-icon></span>
            <span class="value">Unternehmen Name</span>
          </div>
          <div class="meta-item statusColor" style="background: #94c100" matTooltip="Wirksam ohne Beanstandung">
            <span class="icon" style="border-color: #94c100; color: #94c100;"><fa-icon icon="user-check"></fa-icon></span>
            <span class="value">Letzte Prüfung: 21.02.2022, Wirksam ohne Beanstandung</span>
          </div>
          <div class="meta-item" matTooltip="Eingestellte Reminder">
            <span class="icon"><fa-icon icon="bell"></fa-icon></span>
            <span class="value">
              <ng-container>
                <span>Nächste Prüfung: 25.02.2021</span>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>-->

    <div class="risk-item-withoutCollapsable clickable status" (click)="editRisk(risk)" *ngFor="let risk of filteredRisks">
      <div class="risk-shortInfo">
        <div class="checkbox">
          <fa-icon *ngIf="!risk.auditConfirmed" [icon]="['far', 'circle']"></fa-icon>
          <fa-icon *ngIf="risk.auditConfirmed" [icon]="['far', 'check-circle']"></fa-icon>
        </div>
      </div>

      <div class="risk-mainInfo">
        <span class="risk-titel">{{risk.riskName}}</span>
        <div class="metaData">

          <div class="meta-item statusColor" [ngStyle]="{'background': risk.compliance.designColor}">
            <span class="icon"><fa-icon icon="gavel"></fa-icon></span>
            <span class="value">{{risk.compliance.complianceName}}</span>
          </div>
          <div class="meta-item">
            <span class="icon"><fa-icon icon="building"></fa-icon></span>
            <span class="value">{{risk.company.companyName}}</span>
          </div>
          <div class="meta-item statusColor" style="background: #94c100" matTooltip="{{'AUDIT_RESULT.' + risk.auditResult | translate}}" *ngIf="risk.auditResult">
            <span class="icon" style="border-color: #94c100; color: #94c100;"><fa-icon icon="user-check"></fa-icon></span>
            <span class="value">Letzte Prüfung: {{risk.auditLastDate | date: 'dd.MM.yyyy'}}, {{'AUDIT_RESULT.' + risk.auditResult | translate}}</span>
          </div>
          <div class="meta-item" *ngIf="risk.auditNextDate">
            <span class="icon"><fa-icon icon="bell"></fa-icon></span>
            <span class="value">
              <ng-container>
                <span>Nächste Prüfung: {{risk.auditNextDate | date: 'dd.MM.yyyy'}}</span>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-revisor-sidenav
  [companies]="dashboardRevisor?.companies"
  [dashboardRevisor]="dashboardRevisor"
  [revisorFilter]="revisorFilter"
  (revisorFilterChanged)="updateFilter($event)">
</app-revisor-sidenav>
