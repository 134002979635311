import { Component, Input } from '@angular/core';
import { ProgressMeterMilestone } from './progress-meter-milestone';
import { ProgressMeterState } from './progress-meter-state.enum';
import { ProgressMeterType } from './progress-meter-type.enum';
import { ProgressMeterStates } from '../calculation/ProgressMeterStates';

@Component({
  selector: 'app-progress-meter',
  templateUrl: './progress-meter.component.html',
  styleUrls: ['./progress-meter.component.scss'],
})
export class ProgressMeterComponent {
  @Input() start!: number;
  @Input() end!: number;
  @Input() value!: number;
  @Input() type!: ProgressMeterType;

  public get progressPercentage(): number {
    const {value} = this;

    return this.getPercentage(value);
  }

  public get progressClassName(): string | string[] {
    const classNames: string[] = [];

    // if (type === ProgressMeterType.MAIN) {
    const {value, type} = this;

    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }


    // const reachedMilestione = this.milestones
    //   .sort((milestoneA, milestoneB) => {
    //     switch (type) {
    //       case ProgressMeterType.MAIN:
    //         return milestoneA.valueMain - milestoneB.valueMain;
    //       case ProgressMeterType.SUB:
    //         return milestoneA.valueSub - milestoneB.valueSub;
    //     }
    //   })
    //   .find((milestone) => {
    //     switch (type) {
    //       case ProgressMeterType.MAIN:
    //         return milestone.valueMain >= value;
    //       case ProgressMeterType.SUB:
    //         return milestone.valueSub >= value;
    //     }
    //   });
    //
    // if (reachedMilestione) {
    //   classNames.push(reachedMilestione.className);
    // }
    // }

    return classNames;
  }

  public getPercentage(value: number) {
    const {start, end} = this;

    if (start === null || end === null || value === null) {
      return 0;
    }

    const percentage = ((value - start) / (end - start)) * 100;

    return Math.min(Math.max(percentage, 0), 100);
  }

  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  public getMilestonePercentage(milestone: ProgressMeterMilestone): number {
    const {type} = this;

    switch (type) {
      case ProgressMeterType.MAIN:
        const {valueMain} = milestone;

        return this.getPercentage(valueMain);
      case ProgressMeterType.SUB:
        const {valueSub} = milestone;

        return this.getPercentage(valueSub);
    }
  }

  public getMilestoneValue(milestone: ProgressMeterMilestone): number {
    const {type} = this;

    switch (type) {
      case ProgressMeterType.MAIN:
        return milestone.valueMain;
      case ProgressMeterType.SUB:
        return milestone.valueSub;
    }
  }

  constructor() {
  }
}
