import { Transition } from '@uirouter/angular';
import { Client } from './client';
import { ClientService } from './client.service';

export function resolveCurrentClient(
  transition: Transition,
  clientService: ClientService,
): Promise<Client> {
  return new Promise((resolve, reject) => {
    transition
      .injector()
      .getAsync('authInfo')
      .then((authInfo) => {
        if (!authInfo?.client?.id) {
          reject('current client not found in AuthInfo');
        }

        const { id: clientId } = authInfo.client;

        clientService.getSingle(clientId).subscribe(resolve);
      });
  });
}
