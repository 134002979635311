import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ProfileInfosComponent } from './profile-infos/profile-infos.component';
import { ProfileSecurityComponent } from './profile-security/profile-security.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import { TfaService } from 'src/app/core/tfa/tfa.service';
import { resolveTfaList } from 'src/app/core/tfa/tfa.resolver';

export const PROFILE_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.profile',
    redirectTo: 'app.profile.infos',
    url: '/profile',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
  {
    name: 'app.profile.infos',
    views: {
      '$default@app': {
        component: ProfileInfosComponent,
      },
    },
    url: '/infos',
  },
  {
    name: 'app.profile.settings',
    views: {
      '$default@app': {
        component: ProfileSettingsComponent,
      },
    },
    url: '/settings',
  },
  {
    name: 'app.profile.security',
    views: {
      '$default@app': {
        component: ProfileSecurityComponent,
      },
    },
    url: '/security',
    resolve: [
      {
        token: 'tfas',
        deps: [TfaService],
        resolveFn: resolveTfaList,
      },
    ],
  },
];
