import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import {SETTINGS_STATES} from './settings.states';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import {PagingModule} from "../../core/paging/paging.module";
import {SettingsNavComponent} from "./settings-nav/settings-nav.component";

@NgModule({
  declarations: [
    SettingsNavComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: SETTINGS_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    PagingModule,
  ],
  exports: [
    SettingsNavComponent
  ]
})
export class SettingsModule {
}
