import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Norm, NormDraft } from './norm';
import { IdObject } from 'src/app/core/id-object';

@Injectable({
  providedIn: 'root',
})
export class NormService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}norm`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}norm/${id}`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}norm/search`;

  constructor(private httpClient: HttpClient) {}

  public getList(): Observable<Norm[]> {
    const { apiUrl } = environment;
    const url = NormService.listUrl(apiUrl);

    return this.httpClient
      .get<Norm[]>(url)
      .pipe(catchError(this.handleError<Norm[]>('NormService->getList', [])));
  }

  public getSingle(id: string): Observable<Norm> {
    const { apiUrl } = environment;
    const url = NormService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Norm>(url)
      .pipe(catchError(this.handleError<Norm>('NormService->getSingle')));
  }

  public create(normDraft: NormDraft): Observable<string> {
    const { apiUrl } = environment;
    const url = NormService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post<IdObject>(url, normDraft, { headers }).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('NormService->create')),
    );
  }

  public patch(id: string, normDraft: NormDraft): Observable<boolean> {
    const { apiUrl } = environment;
    const url = NormService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .patch<boolean>(url, normDraft, { headers })
      .pipe(catchError(this.handleError<boolean>('NormService->patch', false)));
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = NormService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('NormService->remove', false)),
    );
  }

  public search(searchData: any): Observable<Norm[]> {
    const { apiUrl } = environment;
    const url = NormService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<Norm[]>(url, searchData, { headers })
      .pipe(catchError(this.handleError<Norm[]>('NormService->search', [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
