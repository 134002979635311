import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PasswordConfirmModalComponent } from './password-confirm-modal.component';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [PasswordConfirmModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  exports: [],
})
export class PasswordConfirmModalModule {
}
