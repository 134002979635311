import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { WorkspaceFilter } from '../../../common/workspace/workspace.filter';
import { PasswordValidation } from './password-validation';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss'],
})
export class PasswordValidationComponent implements OnInit {
  @Input() password!: AbstractControl | null;
  // @Input() form!: FormGroup;
  // @Input() formField!: string;

  public specialChars: String = ',.-_!@#$%^&+=§/(){}[]?="´`^';

  public validation: PasswordValidation = {
    length: false,
    lower: false,
    upper: false,
    number: false,
    special: false,
  };

  private validationPattern = {
    length: {name: 'length', pattern: /^.{8,}$/, valid: false},
    lower: {name: 'lower', pattern: /^(?=.*[a-z]).*$/, valid: false},
    upper: {name: 'upper', pattern: /^(?=.*[A-Z]).*$/, valid: false},
    number: {name: 'number', pattern: /^(?=.*[0-9]).*$/, valid: false},
    special: {
      name: 'special',
      pattern: /^(?=.*[,\.\-_!@#$%^&+=\§\/\(\)\{\}\[\]\?\"\´\`]).*$/,
      valid: false,
    },
  };

  constructor() {
  }

  ngOnInit(): void {
    this.password?.valueChanges.subscribe(() => {
      this.update();
    });
  }

  update() {
    const value = this.password?.value;
    this.validation.length = this.validationPattern.length.pattern.test(value);
    this.validation.lower = this.validationPattern.lower.pattern.test(value);
    this.validation.upper = this.validationPattern.upper.pattern.test(value);
    this.validation.number = this.validationPattern.number.pattern.test(value);
    this.validation.special = this.validationPattern.special.pattern.test(value);
  }
}
