import { Component, Input, OnInit } from '@angular/core';
import { Area } from '../area/area';
import {
  Dashboard,
  DashboardCompany, DashboardSumming,
  Risk,
  RiskCompliance, RiskComplianceByCompany,
  RiskComplianceCompliance,
} from './dashboard';
import { IdObjectService } from '../../core/id-object.service';
import { ProhabilityValues } from '../../core/calculation/ProhabilityValue';
import { NumericValueObject, ValueObject } from '../../core/value-object';
import { IdObject } from '../../core/id-object';
import { DashboardFilter } from './dashboard.filter';
import { ProgressMeterType } from '../../core/progress-meter/progress-meter-type.enum';
import { ProgressMeterStates } from '../../core/calculation/ProgressMeterStates';
import { ProgressMeterMilestone } from '../../core/progress-meter/progress-meter-milestone';
import { StateService } from '@uirouter/angular';
import { AuthInfo, CurrentAccount } from '../../core/auth-info/auth-info';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { RiskEditModalEditData } from '../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../risk/risk-edit-modal/risk-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from './dashboard.service';
import {
  DashboardEvaluation,
  DashboardEvaluationCompany,
  DashboardEvaluationComplianceByClient, DashboardEvaluationRisk,
} from './dashboardEvaluation';
import { EvaluationFilter } from '../evaluation/evaluation.filter';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @Input() dashboardEvaluation?: DashboardEvaluation | null = null;
  @Input() authInfo?: AuthInfo | null = null;

  public trackById = IdObjectService.trackById;
  public progressMeterType = ProgressMeterType;

  private prohabilityValues: NumericValueObject[] = ProhabilityValues;
  evaluationDashboardFilter: EvaluationFilter;

  constructor(
    private matDialog: MatDialog,
    private dashboardService: DashboardService,
  ) {
    const evaluationDashboardFilterStr = localStorage.getItem('evaluationDashboardFilter');
    if (evaluationDashboardFilterStr) {
      this.evaluationDashboardFilter = JSON.parse(evaluationDashboardFilterStr);
    } else {
      this.evaluationDashboardFilter = {};
    }
  }

  getProhabilityValues(value: number): string {
    const values = this.prohabilityValues.sort((a, b) => a.value - b.value);
    let lastValue: NumericValueObject | null = null;
    for (let entry of values) {
      if (value >= entry.value) {
        lastValue = entry;
      } else {
        break;
      }
    }

    return lastValue ? lastValue.displayName : '';
  }

  reloadDashboard() {
    this.dashboardService.getEvaluationData().subscribe((dashboard) => {
      this.dashboardEvaluation = dashboard;
      this.ngOnInit();
    });
  }

  ngOnInit(): void {
    if (this.dashboardEvaluation) {
      if (!this.evaluationDashboardFilter.companyId && this.dashboardEvaluation.companies.length > 0) {
        this.evaluationDashboardFilter.companyId = this.dashboardEvaluation.companies[0].id;
        this.companySelectionChanged();
      }
    }
  }

  updateFilter() {
    localStorage.setItem('evaluationDashboardFilter', JSON.stringify(this.evaluationDashboardFilter));
    this.evaluationDashboardFilter = {...this.evaluationDashboardFilter};
  }

  companySelectionChanged() {
    this.updateFilter();
  }
}
