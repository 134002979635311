import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Client } from '../client';
import { ClientService } from '../client.service';

@Component({
  selector: 'app-client-general',
  templateUrl: './client-general.component.html',
  styleUrls: ['./client-general.component.scss'],
})
export class ClientGeneralComponent implements OnInit {
  @Input() currentClient!: Client;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    letterOfAgreement: ['', []],
    letterOfAgreementShort: ['', []],
    legalTextImprint: ['', []],
    legalTextPrivacy: ['', []],
    deleteAfterReturnDays: ['', []],
    deleteAfterBorrowedDays: [null, []],
  });


  public pending: boolean = false;

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private clientService: ClientService,
  ) {}

  public ngOnInit() {
    this.editFormGroup.patchValue(this.currentClient);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    console.log("client" , this.currentClient)

    const { value: editData } = editFormGroup;
    if (!this.currentClient) {
      return;
    }

    this.pending = true;
    const { id: clientId } = this.currentClient;

    this.clientService.patch(clientId, editData).subscribe(() => {
      this.pending = false;
      this.stateService.go('.', undefined, { reload: true });
    });
  }
}
