import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MeasureType, MeasureTypeDraft } from './measure-type';
import { IdObject } from 'src/app/core/id-object';

@Injectable({
  providedIn: 'root',
})
export class MeasureTypeService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}measureType`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}measureType/${id}`;
  private static searchUrl = (apiUrl: string): string =>
    `${apiUrl}measureType/search`;

  constructor(private httpClient: HttpClient) {}

  public getList(): Observable<MeasureType[]> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.listUrl(apiUrl);

    return this.httpClient
      .get<MeasureType[]>(url)
      .pipe(
        catchError(
          this.handleError<MeasureType[]>('MeasureTypeService->getList', []),
        ),
      );
  }

  public getSingle(id: string): Observable<MeasureType> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<MeasureType>(url)
      .pipe(
        catchError(
          this.handleError<MeasureType>('MeasureTypeService->getSingle'),
        ),
      );
  }

  public create(measureTypeDraft: MeasureTypeDraft): Observable<string> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<IdObject>(url, measureTypeDraft, { headers })
      .pipe(
        map((idObject: IdObject) => idObject.id),
        catchError(this.handleError<string>('MeasureTypeService->create')),
      );
  }

  public patch(
    id: string,
    measureTypeDraft: MeasureTypeDraft,
  ): Observable<boolean> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.patch<void>(url, measureTypeDraft, { headers }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('MeasureTypeService->patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('MeasureTypeService->remove', false)),
    );
  }

  public search(searchData: any): Observable<MeasureType[]> {
    const { apiUrl } = environment;
    const url = MeasureTypeService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<MeasureType[]>(url, searchData, { headers })
      .pipe(
        catchError(
          this.handleError<MeasureType[]>('MeasureTypeService->search', []),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
