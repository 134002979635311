import { Pipe, PipeTransform } from '@angular/core';
import { ProgressMeterMilestone } from './progress-meter-milestone';
import { ProgressMeterType } from './progress-meter-type.enum';

@Pipe({
  name: 'progressMeterMilestoneInRange',
})
export class ProgressMeterMilestoneInRangePipe implements PipeTransform {
  transform(
    milestones: ProgressMeterMilestone[],
    start: number,
    end: number,
    type: ProgressMeterType,
  ): ProgressMeterMilestone[] {
    return milestones.filter((milestone) => {
      if (start === null || end === null || type === null) {
        return false;
      }

      switch (type) {
        case ProgressMeterType.MAIN:
          return (
            (milestone.valueMain > start && milestone.valueMain < end) ||
            (milestone.valueMain < start && milestone.valueMain > end)
          );
        case ProgressMeterType.SUB:
          return (
            (milestone.valueSub > start && milestone.valueSub < end) ||
            (milestone.valueSub < start && milestone.valueSub > end)
          );
      }
    });
  }
}
