<div mat-dialog-title>
  <div *ngIf="measureRevision">
    {{measureRevision.displayName}}<br />
    Revision vom {{measureRevision.revisionDate | date:'dd.MM.yyyy, HH:mm'}}<br />
    <small>Erstellt von {{measureRevision.revisionUser.firstName}}&nbsp;{{measureRevision.revisionUser.lastName}}</small>
  </div>
</div>
<form novalidate *ngIf="measureRevision">
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="user-check"></fa-icon>
        &nbsp;&nbsp;Revision
      </ng-template>

      <div mat-dialog-content>
        <h3 class="subHeading">Details</h3>
        <div>
          <label>Dokumentation der Prüfung</label><br />
          <span>{{measureRevision.auditResultDescription}}</span>
        </div>

        <div>
          <label>Stichprobengröße</label><br />
          <span>{{measureRevision.auditSampleSize}}</span>
        </div>

        <h3 class="subHeading">Zusammenfassung</h3>

        <div>
          <label>Art der Prüfung</label><br />
          <span>{{'AUDIT_TYPE.' + measureRevision.auditType | translate}}</span>
        </div>

        <div>
          <label>Ergebnis der Prüfung</label><br />
          <span>{{'AUDIT_RESULT.' + measureRevision.auditResult | translate}}</span>
        </div>

        <div>
          <label>Nächste Prüfung</label><br />
          <span>{{'AUDIT_NEXT_DATE_TYPE.' + measureRevision.auditNextDateType | translate}}</span>
        </div>

        <div>
          <label>Nächste Prüfung</label><br />
          <span>{{measureRevision.auditNextDate | date:'dd.MM.yyyy'}}</span>
        </div>

        <div>
          <h3 class="subHeading">Prüfung</h3>
          Durchgeführt am {{measureRevision.auditLastDate | date:'dd.MM.yyyy'}} von {{measureRevision.auditLastUser?.firstName}}&nbsp;{{measureRevision.auditLastUser?.lastName}}
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="info-circle"></fa-icon>
        &nbsp;&nbsp;Allgemeines
      </ng-template>

      <div mat-dialog-content>
        <div>
          <label>Bezeichnung</label><br />
          <span>{{measureRevision.displayName}}</span>
        </div>

        <div>
          <label>Beschreibung</label><br />
          <span>{{measureRevision.description}}</span>
        </div>

        <div>
          <label>Häufigkeit der Maßnahme</label><br />
          <span>{{measureRevision.frequency}}</span>
        </div>

        <h3 class="subHeading">Timing</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <div>
              <label>Start</label><br />
              <span>{{measureRevision.startDate | date:'dd.MM.yyyy HH:mm'}}</span>
            </div>
          </div>

          <div class="gridItem giw-50">
            <div>
              <label>Start</label><br />
              <span>{{measureRevision.endDate | date:'dd.MM.yyyy HH:mm'}}</span>
            </div>
          </div>
        </div>

        <h3 class="subHeading">Einordnung</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <div>
              <label>Art der Maßnahme 1</label><br />
              <span>{{measureRevision.firstType?.displayName}}</span>
            </div>
          </div>

          <div class="gridItem giw-50">
            <div>
              <label>Art der Maßnahme 2</label><br />
              <span>{{measureRevision.secondType?.displayName}}</span>
            </div>
          </div>
        </div>

        <h3 class="subHeading">Status der Maßnahme</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <div>
              <label>Reduzierung der Schadenshöhe<sup>1</sup></label><br />
              <span>{{measureRevision.reachedEfficiencyDamageValue}}</span>
            </div>
          </div>

          <div class="gridItem giw-50">
            <div>
              <label>Reduzierung der Eintrittswahrscheinlichkeit<sup>1</sup></label><br />
              <span>{{measureRevision.reachedEfficiencyProbabilityValue}}</span>
            </div>
          </div>
        </div>
        <p class="infoText">
          <sup>1</sup> Der Status der Maßnahme berechnet automatisch
          entsprechend dem Status der zugeordneten Aufgaben. Hier können Sie
          aber einen individuellen bereits erreichten Wirkungsgrad hinterlegen.
        </p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="sliders-h"></fa-icon>
        &nbsp;&nbsp;Details
      </ng-template>

      <div class="mat-dialog-content" mat-dialog-content>
        <h3 class="subHeading">Organisatorisches</h3>

        <div>
          <label>Schlagwörter</label><br />
          <span>{{measureRevision.tags?.join(", ")}}</span>
        </div>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <div>
              <label>Interner Status</label><br />
              <span>{{'MEASURE_STATE.' + measureRevision.state | translate}}</span>
            </div>
          </div>

          <div class="gridItem giw-50">
            <div class="checkboxes" style="padding-top: 8px">
              <mat-checkbox [checked]="measureRevision.ignored===true" disabled="true">Diese Maßnahme ignorieren</mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <footer mat-dialog-actions>
    <button type="button" (click)="closeModal()" mat-raised-button mat-dialog-close>Schließen</button>
  </footer>
</form>
