import {Component, Input, OnInit} from '@angular/core';
import {ItemModalEditData} from '../item-modal/item-modal-data';
import {EditMode} from '../../../core/edit-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {ItemService} from '../item.service';
import {ItemModalComponent} from '../item-modal/item-modal.component';
import {Item, ItemSearchData} from '../item';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {ItemState} from "../item-state.enum";
import {Paging, PagingHeader, PagingSort} from "../../../core/paging/paging";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {DepositQrScanModalComponent} from "../../deposit/deposit-qr-scan-modal/deposit-qr-scan-modal.component";
import {environment} from "../../../../environments/environment";
import {DepositFilterState} from "../../deposit/deposit-filter-state.enum";
import {CategoryService} from "../../category/category.service";
import {Category, CategorySearchData} from "../../category/category";
import {CategoryState} from "../../category/category-state.enum";

@Component({
  selector: 'app-item-overview',
  templateUrl: './item-overview.component.html',
  styleUrls: ['./item-overview.component.scss'],
})
export class ItemOverviewComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  categories: Category[] = [];

  items: Item[] = [];
  paging: Paging = {
    currentPage: 0,
    pages: [],
    perPage: 20,
    sort: 'displayName',
    sortDirection: 'desc',
    totalItems: 0,
    totalPages: 0,
  };

  pagingSorts: PagingSort[] = [
    {key: 'displayName', direction: 'asc', displayName: 'Bezeichnung (absteigend)'},
    {key: 'displayName', direction: 'desc', displayName: 'Bezeichnung (aufsteigend)'},
  ];

  modelChanged = new Subject<string>();

  public filter: ItemSearchData = {
    state: ItemState.ACTIVE,
  };

  constructor(
    private itemService: ItemService,
    private categoryService: CategoryService,
    private matDialog: MatDialog,
  ) {
    const itemFilterStr = sessionStorage.getItem('itemFilter');
    if (itemFilterStr) {
      this.filter = JSON.parse(itemFilterStr);
    } else {
      this.filter = {
        state: ItemState.ACTIVE,
      };
    }
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.search();
      });

    let filter: CategorySearchData = {
      state: CategoryState.ACTIVE,
      flat: true
    };

    let paging: Paging = {
      currentPage: 0,
      pages: [],
      perPage: -1,
      sort: 'name',
      sortDirection: 'desc',
      totalItems: 0,
      totalPages: 0,
    };

    this.categoryService.search(filter, paging).then((pagesCategories) => {
      this.categories = pagesCategories.items;
    });
  }

  public ngOnInit() {
    this.search();
  }

  removeCategory() {
    this.filter.category = null;
    this.search();
  }

  selectCategory(category: Category) {
    this.filter.category = category;
    this.search();
  }

  searchChanged() {
    this.modelChanged.next();
  }

  search() {
    this.saveFilter();

    this.itemService.search(this.filter, this.paging).then((pagesItems) => {
      this.updatePaging(pagesItems.pagingHeader);
      this.items = pagesItems.items;
    });
  }

  openCreateModal(event: MouseEvent) {
    const data: ItemModalEditData = {
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(ItemModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((item?: Item) => {
      this.search();
    });
  }

  toggleCategoryFilter() {
    this.filter.categoryOpen = !this.filter.categoryOpen
    this.saveFilter();
  }

  saveFilter() {
    sessionStorage.setItem('itemFilter', JSON.stringify(this.filter));
  }

  changeFilterState(filterState: string | null) {
    if (!filterState) {
      this.filter.state = null
    } else {
      this.filter.state = ItemState[filterState as keyof typeof ItemState];
    }

    this.search();
  }
  resetFilter() {
    this.filter.searchText = undefined;
    this.search();
  }
  openEditModal(item: Item, event: MouseEvent) {
    const data: ItemModalEditData = {
      editMode: EditMode.EDIT,
      itemId: item.id,
    };

    const dialogRef = this.matDialog.open(ItemModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((item?: Item) => {
      this.search();
    });
  }

  getStandardImageAssetUrl(item?: Item) {
    if (item?.standardImage) {
      return `${environment.apiUrl}/asset/${item?.standardImage.id}`;
    }
    return null;
  }

  openQrScanModal(event: MouseEvent) {

    const dialogRef = this.matDialog.open(DepositQrScanModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
    });
    dialogRef.afterClosed().subscribe((result?: string) => {
      if (result) {
        this.filter.searchText = result;
        this.search()
      }
    });
  }

  private updatePaging(pagingHeader: PagingHeader | undefined) {
    if (pagingHeader) {
      this.paging.perPage = pagingHeader.perPage;
      this.paging.totalItems = pagingHeader.totalItems;
      this.paging.currentPage = pagingHeader.currentPage;
      this.paging.totalPages = Math.ceil(pagingHeader.totalItems / pagingHeader.perPage);

      console.log('paging updated', this.paging);
      const pages: number[] = [];
      for (let i = 0; i < this.paging.totalPages; i++) {
        pages.push(i);
      }
      this.paging.pages = pages;
    }
  }
}
