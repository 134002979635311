import { Component } from '@angular/core';

@Component({
  selector: 'app-compliance-edit-nav',
  templateUrl: './compliance-edit-nav.component.html',
  styleUrls: ['./compliance-edit-nav.component.scss'],
})
export class ComplianceEditNavComponent {
  constructor() {}
}
