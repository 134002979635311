import { Component, Input } from '@angular/core';
import { IdObjectService } from 'src/app/core/id-object.service';
import { Compliance } from '../compliance/compliance';
import { ComplianceService } from '../compliance/compliance.service';
import { WorkspaceFocus } from './workspace.focus';
import { Area } from '../area/area';
import { WorkspaceFilter } from './workspace.filter';
import { WorkspaceFilterType } from './workspace.filter-type.enum';
import { WorkspaceService } from './workspace.service';
import { RiskEditModalCreateData } from '../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../risk/risk-edit-modal/risk-edit-modal.component';
import { Risk } from '../risk/risk';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss'],
})
export class WorkspaceComponent {
  // @Input() compliances!: Compliance[];
  @Input() complianceId: string | null = null;
  @Input() areaId: string | null = null;
  compliances: Compliance[] | null = null;
  expandedCompliance: string = '';
  focus: WorkspaceFocus = {compliance: undefined, area: undefined};
  workspaceFilter: WorkspaceFilter = {};
  workspaceFilterType = WorkspaceFilterType;

  constructor(
    private complianceService: ComplianceService,
    private matDialog: MatDialog,
  ) {
  }

  public trackById = IdObjectService.trackById;

  public updateCompliances() {
    this.complianceService.search({workspaceFilter: this.workspaceFilter})
      .subscribe((compliances) => {
        this.compliances = compliances;
      });
  }

  clearFocus() {
    this.focus = {compliance: undefined, area: undefined};
  }

  selectCompliance(compliance: Compliance) {
    const newFocus = Object.assign({}, this.focus);
    if (newFocus.compliance && newFocus.compliance.id === compliance.id) {
      newFocus.compliance = undefined;

    } else {
      newFocus.compliance = compliance;
      this.expandedCompliance = compliance.id;
    }

    this.focus = newFocus;
  }

  selectArea(compliance: Compliance, area: Area) {
    const newFocus = Object.assign({}, this.focus);
    newFocus.compliance = compliance;
    if (newFocus.area && newFocus.area.id === area.id) {
      newFocus.area = undefined;

    } else {
      newFocus.area = area;
    }

    this.focus = newFocus;
  }

  toggleExpanded(compliance: Compliance) {
    if (compliance.areas.length === 0) {
      return;
    }

    if (this.expandedCompliance === compliance.id) {
      this.expandedCompliance = '';

    } else {
      this.expandedCompliance = compliance.id;
    }
  }

  ngOnInit() {
    if (this.complianceId) {
      this.focus.compliance = {id: this.complianceId};
    }

    const workspaceFilterStr = localStorage.getItem('workspaceFilter');
    if (workspaceFilterStr) {
      this.workspaceFilter = JSON.parse(workspaceFilterStr);
    }

    this.updateCompliances();
  }

  setFilterType(filterType: WorkspaceFilterType) {
    if (this.workspaceFilter.filterType === filterType) {
      this.workspaceFilter.filterType = undefined;

    } else {
      this.workspaceFilter.filterType = filterType;
    }
    this.filterChanged();
  }

  openCreateRiskModal(area: Area, event: MouseEvent) {
    const data: RiskEditModalCreateData = {
      areaId: area.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.updateCompliances();
      }
    });
  }

  filterChanged() {
    localStorage.setItem('workspaceFilter', JSON.stringify(this.workspaceFilter));
    this.updateCompliances();
  }

  resetFilter(types?: string[]) {
    if (!types) {
      this.workspaceFilter = {};
    } else if (types.indexOf('searchText') !== -1) {
      this.workspaceFilter.searchText = undefined;

    } else if (types.indexOf('filterType') !== -1) {
      this.workspaceFilter.filterType = undefined;
    }
    this.filterChanged();
  }
}
