import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  DashboardEvaluation,
  DashboardEvaluationCompany,
  DashboardEvaluationRisk,
} from '../../dashboardEvaluation';
import { IdObjectService } from '../../../../core/id-object.service';
import { ProgressMeterType } from '../../../../core/progress-meter/progress-meter-type.enum';
import { ProgressMeterMilestone } from '../../../../core/progress-meter/progress-meter-milestone';
import { ProgressMeterStates } from '../../../../core/calculation/ProgressMeterStates';
import { NumericValueObject } from '../../../../core/value-object';
import { ProhabilityValues } from '../../../../core/calculation/ProhabilityValue';
import { EvaluationFilter } from '../../../evaluation/evaluation.filter';

@Component({
  selector: 'app-dashboard-widget-company-overview',
  templateUrl: './dashboard-widget-company-overview.component.html',
  styleUrls: ['./dashboard-widget-company-overview.component.scss'],
})
export class DashboardWidgetCompanyOverviewComponent implements OnInit {
  @Input() dashboardEvaluation!: DashboardEvaluation;
  @Input() evaluationFilter!: EvaluationFilter;

  selectedCompany?: DashboardEvaluationCompany;
  private prohabilityValues: NumericValueObject[] = ProhabilityValues;

  public trackById = IdObjectService.trackById;
  public progressMeterType = ProgressMeterType;

  constructor() {
  }

  ngOnInit(): void {
    this.updateSelectedCompanyRisks();
  }

  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  getProgressMeterClass(type: ProgressMeterType, value: number) {
    const classNames: string[] = [];
    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }

    return classNames;
  }

  getProhabilityValues(value: number): string {
    const values = this.prohabilityValues.sort((a, b) => a.value - b.value);
    let lastValue: NumericValueObject | null = null;
    for (let entry of values) {
      if (value >= entry.value) {
        lastValue = entry;
      } else {
        break;
      }
    }

    return lastValue ? lastValue.displayName : '';
  }

  updateSelectedCompanyRisks() {
    console.log('updateSelectedCompanyRisks for company', this.evaluationFilter.companyId);

    if (this.dashboardEvaluation) {
      if (this.evaluationFilter.companyId) {
        this.selectedCompany = this.dashboardEvaluation.companies.find(c => c.id === this.evaluationFilter.companyId);
      } else if (this.dashboardEvaluation.companies.length > 0) {
        this.selectedCompany = this.dashboardEvaluation.companies[0];
      }

      if (this.selectedCompany) {
        const filterComplianceId = this.selectedCompany.$$complianceFilter;
        const risks: DashboardEvaluationRisk[] = [];
        this.selectedCompany.compliances
          .filter(c => !filterComplianceId || filterComplianceId === c.id)
          .forEach(c => {
            c.risks.forEach(r => {
              r.$$complianceName = c.complianceName;
              r.$$complianceColor = c.designColor;
              r.$$prohabilityValue = this.getProhabilityValues(r.calculatedProbability);
              risks.push(r);
            });
          });
        this.selectedCompany.$risks = risks.sort((
          a,
          b,
        ) => b.calculatedRiskDamage - a.calculatedRiskDamage);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.evaluationFilter && changes.evaluationFilter.currentValue) {
      console.log('changes.evaluationFilter', changes.evaluationFilter);
      this.updateSelectedCompanyRisks();
    }
  }
}
