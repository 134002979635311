import { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { Company } from '../company';
import { CompanyService } from '../company.service';

@Component({
  selector: 'app-company-general',
  templateUrl: './company-general.component.html',
  styleUrls: ['./company-general.component.scss'],
})
export class CompanyGeneralComponent implements OnInit {
  @Input() currentCompany!: Company;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    legalTextAgb: ['', []],
    legalTextImprint: ['', []],
    legalTextPrivacy: ['', []],
    criticalSize: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private companyService: CompanyService,
  ) {}

  public pending: boolean = false;

  public ngOnInit() {
    this.editFormGroup.patchValue(this.currentCompany);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    const { value: editData } = editFormGroup;
    if (!this.currentCompany) {
      return;
    }

    this.pending = true;
    const { id: companyId } = this.currentCompany;

    this.companyService.patch(companyId, editData).subscribe(() => {
      this.pending = false;
      this.stateService.go('.', undefined, { reload: true });
    });
  }
}
