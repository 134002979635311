<div mat-dialog-title>Wirklich löschen?</div>
<div mat-dialog-content>
  <div>
    <ng-content></ng-content>
  </div>
  <form [formGroup]="editFormGroup">
    <div>
      <p><b>ACHTUNG: Der Lösch-Vorgang kann nicht rückgängig gemacht werden!!</b><br>
         Bitte gib zur Bestätigung 'DELETE' ein.</p>
       <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>DELETE hier eingeben</mat-label>
        <input matInput type="text" formControlName="check" value="" />
       </mat-form-field>
    </div>
  </form>
  <div *ngIf="retypeDeleteKeyword">
    Bitte nochmal versuchen!
  </div>
</div>
<footer mat-dialog-actions>
    <button type="button" (click)="cancel()" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="button" (click)="proceed()" mat-raised-button color="primary" [disabled]="pending">Unwiederbringlich löschen</button>
  </footer>
