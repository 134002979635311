<ng-container [formGroup]="editFormGroup">
  <section class="section">
    <header class="header">
      <h3 class="heading">Allgemeine Reminder</h3>
      <p class="infoText">
        Allgemeine Reminder gelten für alle zugeordneten Benutzer.
      </p>
    </header>

    <table
      mat-table
      [dataSource]="globalRemindersDataSource"
      formArrayName="globalReminders"
    >
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; let index = index; columns: columns"
      ></tr>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell [formGroupName]="index" *matCellDef="let index = index">
          <mat-form-field appearance="standard">
            <input
              matInput
              type="datetime"
              placeholder="Datum"
              [ngxMatDatetimePicker]="datePicker"
              formControlName="date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(datePicker)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #datePicker
              [showSpinners]="false"
            ></ngx-mat-datetime-picker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Bezeichnung</th>
        <td mat-cell [formGroupName]="index" *matCellDef="let index = index">
          <mat-form-field appearance="standard">
            <input
              type="text"
              placeholder="Bezeichnung"
              matInput
              formControlName="displayName"
              cdkTextareaAutosize
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="controls" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let index = index">
          <button
            mat-icon-button
            (click)="removeReminderFormGroup(reminderType.GLOBAL, index)"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>
    </table>

    <footer class="footer">
      <button
        type="button"
        class="addReminderButton"
        (click)="addReminderFormGroup(reminderType.GLOBAL)"
      >
        <fa-icon icon="bell"></fa-icon>
        Allgemeinen Reminder anlegen
      </button>
    </footer>
  </section>

  <section class="section">
    <header class="header">
      <h3 class="heading">Persönliche Reminder</h3>
      <p class="infoText">
        Persönliche Reminder gelten nur für Sie. Andere Benutzer sehen diese
        nicht und bekommen auch keine Erinnerung.
      </p>
    </header>

    <table
      mat-table
      [dataSource]="privateRemindersDataSource"
      formArrayName="privateReminders"
    >
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr
        mat-row
        *matRowDef="let row; let index = index; columns: columns"
      ></tr>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Datum</th>
        <td mat-cell [formGroupName]="index" *matCellDef="let index = index">
          <mat-form-field appearance="standard">
            <input
              matInput
              type="datetime"
              placeholder="Datum"
              [ngxMatDatetimePicker]="datePicker"
              formControlName="date"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(datePicker)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #datePicker
              [showSpinners]="false"
            ></ngx-mat-datetime-picker>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Bezeichnung</th>
        <td mat-cell [formGroupName]="index" *matCellDef="let index = index">
          <mat-form-field appearance="standard">
            <input
              type="text"
              placeholder="Bezeichnung"
              matInput
              formControlName="displayName"
              cdkTextareaAutosize
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container matColumnDef="controls" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let index = index">
          <button
            mat-icon-button
            (click)="removeReminderFormGroup(reminderType.PRIVATE, index)"
          >
            <fa-icon icon="trash"></fa-icon>
          </button>
        </td>
      </ng-container>
    </table>
    <footer class="footer">
      <button
        type="button"
        class="addReminderButton"
        (click)="addReminderFormGroup(reminderType.PRIVATE)"
      >
        <fa-icon icon="bell"></fa-icon>
        Persönlichen Reminder anlegen
      </button>
    </footer>
  </section>
</ng-container>
