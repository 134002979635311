import { Component } from '@angular/core';

@Component({
  selector: 'app-area-edit-nav',
  templateUrl: './area-edit-nav.component.html',
  styleUrls: ['./area-edit-nav.component.scss']
})
export class AreaEditNavComponent {

  constructor() { }
}
