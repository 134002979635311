<header id="header" role="banner">
  <h1>
    Einstellungen
    <small>Kategorien</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-settings-nav></app-settings-nav>

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a (click)="openCreateModal($event)" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Kategorie hinzufügen
        </a>
      </div>
      <div class="header-item searchItem size-2-3">
        <input class="overviewHeader-search" type="text" [(ngModel)]="filter.searchText" (ngModelChange)="searchChanged()" placeholder="Bezeichnung"/>
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
        <a class="icon-deleteSearch" *ngIf="filter.searchText" (click)="resetFilter()">
          <fa-icon icon="broom"></fa-icon>
        </a>
      </div>
     </div>
   </header>

  <!-- ### FILTER ### -->
  <div class="filterBar-v01">
		<div class="filter">
			<ul>
				<li [ngClass]="{'active': !filter.state}" (click)="changeFilterState(null)"><small>Alle</small></li>
        <li [ngClass]="{'active': filter.state === 'ACTIVE'}" (click)="changeFilterState('ACTIVE')"><small>Aktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'INACTIVE'}" (click)="changeFilterState('INACTIVE')"><small>Inaktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'DELETED'}" (click)="changeFilterState('DELETED')"><small>Gelöscht</small></li>
			</ul>
		</div>
		<div class="filter-icon"><a class="header-item refresh size-square"  (click)="search()"><fa-icon icon="sync-alt"></fa-icon></a></div>
	</div>
  <!-- ### FILTER ### -->

  <!-- ATTRIBUTE LISTE -->
  <div class="container-box-style-v3">
    <div *ngFor="let category of categories">
      <app-category-item [category]="category" [searchAction]="searchAction()"></app-category-item>
		</div>
	</div>
  <!-- // ATTRIBUTE LISTE -->
</main>
