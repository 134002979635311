import { Ng2StateDeclaration } from '@uirouter/angular';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { resolveDashboard, resolveDashboardEvaluation } from './dashboard.resolver';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';

export const DASHBOARD_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.dashboard',
    component: DashboardComponent,
    url: '/dashboard',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'dashboardEvaluation',
        deps: [DashboardService],
        resolveFn: resolveDashboardEvaluation,
      },
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
];
