<header id="header" role="banner">
  <h1>
    Profil
    <small>Infos</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-profile-nav></app-profile-nav>
  <form
    class="o-profileInfos"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup">

    <h3 class="subHeading">Allgemeine Informationen</h3>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="50%" appearance="fill">
        <mat-label>Vorname</mat-label>
        <input matInput type="text" formControlName="firstName" />
      </mat-form-field>

      <mat-form-field fxFlex="50%" appearance="fill">
        <mat-label>Nachname</mat-label>
        <input matInput type="text" formControlName="lastName" />
      </mat-form-field>
    </div>
    <footer class="o-complianceEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>
