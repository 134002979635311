import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { Template } from '../../template/template';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from '../../template/template.service';
import { StateService } from '@uirouter/core';
import { TemplateModalData } from './template-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../../area/area-state.enum';
import { TemplateState } from '../template-state.enum';
import {Role} from "../../account/role.enum";
import {Attribute} from "../../attribute/attribute";
import {startWith} from "rxjs/operators";
import {AttributeState} from "../../attribute/attribute-state.enum";
import {AttributeService} from "../../attribute/attribute.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CategoryState} from "../../category/category-state.enum";

@Component({
  selector: 'app-template-modal',
  templateUrl: './template-modal.component.html',
  styleUrls: ['./template-modal.component.scss'],
})
export class TemplateModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  template: Template | null = null;
  pending: boolean = false;

  @ViewChild('attributeInput') attributeInput!: ElementRef<HTMLInputElement>;

  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: TemplateState.ACTIVE},
    {displayName: 'inaktiv', value: TemplateState.INACTIVE},
    {displayName: 'gelöscht ', value: TemplateState.DELETED},
  ];

  public editFormGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', []],
    state: [TemplateState.ACTIVE, [Validators.required]],
    attributes: [[], []],
  });

  readonly attributesControl = new FormControl();
  searchAttributes: Attribute[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: TemplateModalData,
    public matDialogRef: MatDialogRef<TemplateModalComponent>,
    private attributeService: AttributeService,
    private templateService: TemplateService,
    private stateService: StateService,
  ) {
    this.attributesControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.attributeService.search({searchText, state: AttributeState.ACTIVE}).then((pagesItems) => {
            let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
            this.searchAttributes = pagesItems.items.filter(x => !currentKeys.includes(x.id));
          });
        } else {
          this.searchAttributes = [];
        }
      });
  }

  public compareByValue = ValueObjectService.compareByValue;

  public ngOnInit() {
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (this.data.templateId) {
          this.templateService.getSingle(this.data.templateId).subscribe((template) => {
            this.template = template;
            this.editFormGroup.patchValue(template);
          });
        }
        break;
      case EditMode.CREATE:
      //TODO
    }
  }

  selectAttribute(event: MatAutocompleteSelectedEvent) {
    const attribute = event.option.value as Attribute;

    this.attributeInput.nativeElement.value = '';
    this.editFormGroup.get('attributes')?.value.push(attribute);
  }

  removeAttribute(index: number) {
    this.editFormGroup.get('attributes')?.value.splice(index, 1);
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  listAllSearchItems(event: Event, trigger: MatAutocompleteTrigger) {
    this.searchAttributes = [];
    this.attributeService.search({searchText: '', state: AttributeState.ACTIVE}).then((pagesItems) => {
      let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
      this.searchAttributes = pagesItems.items.filter(x => !currentKeys.includes(x.id));
    });

    event.stopPropagation();
    trigger.openPanel();
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (!this.template) {
          return;
        }
        const {id: templateId} = this.template;
        this.templateService.patch(templateId, editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;

      case EditMode.CREATE:
        this.templateService.create(editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;
    }
  }
}
