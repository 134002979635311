<div mat-dialog-title *ngIf="!data.isRevokeMode">Daten gespeichert</div>
<div mat-dialog-title *ngIf="data.isRevokeMode">Wirklich Einstellung/AVV widerrufen?</div>

<mat-dialog-content class="mat-typography">
  <div *ngIf="data.isRevokeMode">
    <div class="options-container">
      <a class="option notImportant" (click)="revokeAvv()">Ja</a>
      <a class="option notImportant" (click)="closeDialog()">Nein</a>
    </div>
  </div>
  <div *ngIf="!data.isRevokeMode">
    <div class="options-container">
      <a class="option notImportant" (click)="backToApp()" *ngIf="!data.showSaveOnly">Zurück zu {{ appName }}</a>
      <a class="option notImportant" (click)="closeDialog()" *ngIf="data.showSaveOnly">Schließen</a>
      <a class="option download" *ngIf="state === 'AVV_USAGE'" [href]="getAvvDownloadUrl()" target="_blank" download>AVV herunterladen</a>
    </div>
  </div>

</mat-dialog-content>

