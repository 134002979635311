import { HostBinding } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss'],
})
export class TasksWidgetComponent {
  @HostBinding('class') string = 'o-widget';

  constructor() { }
}
