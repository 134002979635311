import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ClientGeneralComponent } from './client-general/client-general.component';
import { ClientDesignComponent } from './client-design/client-design.component';
import { ClientUserComponent } from './client-user/client-user.component';
import { ClientService } from './client.service';
import { resolveCurrentClient } from './client.resolver';
import {AuthInfoService} from "../../core/auth-info/auth-info.service";
import {resolveAuthInfo} from "../../core/auth-info/auth-info.resolver";

export const CLIENT_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.client',
    redirectTo: 'app.client.general',
    url: '/client',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'currentClient',
        deps: [Transition, ClientService],
        resolveFn: resolveCurrentClient,
      },
    ],
  },
  {
    name: 'app.client.general',
    views: {
      '$default@app': {
        component: ClientGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.client.design',
    views: {
      '$default@app': {
        component: ClientDesignComponent,
      },
    },
    url: '/design',
  },
  {
    name: 'app.client.user',
    views: {
      '$default@app': {
        component: ClientUserComponent,
      },
    },
    url: '/user',
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
];
