import { AuthAccount } from 'src/app/core/auth-info/auth-info';
import { Flag } from './flag.enum';
import { Role } from './role.enum';
import { AccountTarget } from './accountTarget.enum';

export interface Account extends AuthAccount {
  flags: Flag;
  role: Role;
  state: AccountState;
}

export interface AccountDraft extends Partial<Account> {
  email: string;
  target: AccountTarget;
}

export interface AccountSearchData {
  searchText?: string;
}

export interface AccountPasswordUpdate extends AccountDraft {
  newPassword: string;
  newPasswordRepeat: string;
  currentPassword: string;
}

export interface SimpleAccount {
  firstName: string;
  lastName: string;
  id: string;
}

export enum AccountState {
  OK = 'OK',
  NOT_ACTIVATED = 'NOT_ACTIVATED',
  INACTIVE = 'INACTIVE',
}
