import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceWorkspaceItemComponent as ComplianceWorkspaceItemComponent } from './compliance-workspace-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { AreaWorkspaceItemModule } from '../../area/area-workspace-item/area-workspace-item.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ComplianceWorkspaceItemComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    AreaWorkspaceItemModule,
    MatTooltipModule,
  ],
  exports: [ComplianceWorkspaceItemComponent],
})
export class ComplianceWorkspaceItemModule {}
