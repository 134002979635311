<header id="header" role="banner">
  <h1>
    Compliance
    <small>General</small>
  </h1>
  <a (click)="navigationService.goBack('app.compliance')" rel="back" class="header-icon header-icon-left" ng-if="!vm.event.parentEvent">
    <fa-icon icon="chevron-left"></fa-icon>
  </a>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="guestoo - Gästemanagement einfach!" />
  </a>
</header>
<main role="main" id="content">
  <app-compliance-edit-nav></app-compliance-edit-nav>
  <form
    class="o-complianceEdit"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup"
  >

    <h3 class="subHeading">Allgemein Informationen</h3>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Name des Compliance-Themas</mat-label>
        <input
          matInput
          type="text"
          placeholder="Name des Compliance-Themas"
          formControlName="displayName"
        />
      </mat-form-field>

      <mat-form-field fxFlex="100%" appearance="fill">
        <mat-label>Interner Status</mat-label>
        <mat-select
          [compareWith]="compareByValue"
          formControlName="state">
          <mat-option
            [value]="state?.value"
            *ngFor="let state of stateOptions">
            {{ state?.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <h3 class="subHeading">Design</h3>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Farbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Farbe"
          formControlName="designColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>
    </div>


    <h3 class="subHeading">Organisatorisches</h3>
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start stretch">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Schlagwörter</mat-label>
        <mat-chip-list formControlName="tags" #tagList>
          <mat-chip
            [selectable]="true"
            [removable]="true"
            (removed)="removeTag(index)"
            *ngFor="let tag of editFormGroup.get('tags')?.value; index as index"
          >
            {{ tag?.displayName }}
            <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
          </mat-chip>
          <input
            placeholder="neues Schlagwort"
            #tagInput
            [formControl]="tagCtrl"
            [matAutocomplete]="tagAutocomplete"
            [matChipInputFor]="tagList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addTag($event)"
          />
        </mat-chip-list>
        <mat-autocomplete
          #tagAutocomplete="matAutocomplete"
          (optionSelected)="selectTag($event)"
        >
          <mat-option *ngFor="let tag of tagOptions$ | async" [value]="tag">
            {{ tag?.displayName }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <footer class="o-complianceEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>
