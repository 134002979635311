import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import {ErrorPageOverviewComponent} from "./error-page-overview/error-page-overview.component";

export const ERROR_PAGE_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.error-page',
    redirectTo: 'app.error-page.overview',
    url: '/error-page',
    params: {
      security: {
        enabled: false,
        roles: [],
      },
      style: {
        feedback: false,
        hasMainMenu: false,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
  },
  {
    name: 'app.error-page.overview',
    views: {
      '$default@app': {
        component: ErrorPageOverviewComponent,
      },
    },
    url: '/',
  },
];
