<header id="header" role="banner">
  <h1>
    Company
    <small>General</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-company-nav></app-company-nav>
  <form
    class="o-companyGeneral"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup">

    <h3 class="subHeading">Allgemeine Informationen</h3>

      <mat-form-field fxFlexFill appearance="fill">
        <mat-label>Company name</mat-label>
        <input matInput type="text" formControlName="displayName" />
      </mat-form-field>

      <mat-form-field fxFlexFill appearance="fill">
        <mat-label>Kritische Größe (Zahl / €)</mat-label>
        <input matInput type="number" formControlName="criticalSize" />
      </mat-form-field>
      <small class="legende">
        <b>Empfehlung</b>: Bitte geben Sie hier die Liquidität 2. Grades ein.<br>
        <b>Hinweis:</b> Für die Berechnung der Schadenspunkte am Risiko werden 80% dieser Zahl ({{editFormGroup.get('criticalSize')?.value*0.8}}) genutzt.<br>
        <b>Hinweis:</b> Wenn Sie hier den Wert anpassen, dann wird an jedem Risiko der eingegebene €-Wert für den Schaden beibehalten und die zugeordneten Punkte neu berechnet.
      </small>

    <h3 class="subHeading">Rechtstexte</h3>

    <div style="display: block;">

      <mat-form-field appearance="fill" style="display: block; margin-bottom: 20px;">
        <mat-label>Allgemeine Geschäftsbedingungen</mat-label>
        <textarea matInput formControlName="legalTextAgb"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="display: block; margin-bottom: 20px;">
        <mat-label>Impressum</mat-label>
        <textarea matInput formControlName="legalTextImprint"></textarea>
      </mat-form-field>

      <mat-form-field appearance="fill" style="display: block; margin-bottom: 20px;">
        <mat-label>Datenschutz</mat-label>
        <textarea matInput formControlName="legalTextPrivacy"></textarea>
      </mat-form-field>

    </div>
    <footer class="o-complianceEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>
