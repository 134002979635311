<header id="header" role="banner">
  <h1>
    Compliance
    <small>Übersicht</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>
<main role="main" id="content" class="areas">
  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem width-100pz">
        <a uiSref=".create.general" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Neues Compliance Thema
        </a>
      </div>
      <!--
      <div class="header-item searchItem">
        <input class="overviewHeader-search" type="text" />
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
      </div>
      <div class="header-item filterItem">
        <div>
          <a class="overviewHeader-filter">
            <span class="bigIcon">
              <fa-icon icon="filter"></fa-icon>
            </span>
            <span class="placeholder">Liste filtern</span>
          </a>
        </div>
      </div>
      -->
    </div>
  </header>
  <ul
    class="o-complianceList"
    cdkDropList
    (cdkDropListDropped)="onComplianceDropped($event)"
  >
    <li
      class="o-compliaeceList__item"
      cdkDrag
      *ngFor="let compliance of compliances"
    >
      <div
        class="o-complianceList__itemBlock"
        [style.backgroundColor]="compliance?.designColor"
        [class.-semiTransparent]="compliance?.tags !== 'default'"
        [class.-deleted]="compliance?.state === 'DELETED'"
      >
        <span class="o-complianceList__itemIcon">
          <fa-icon icon="sort"></fa-icon>
        </span>
        <span class="o-complianceList__itemLabel">
          <span
            class="o-complianceList__itemBadge"
            *ngIf="compliance?.tags !== 'default'"
            >{{ compliance?.tags }}</span
          >
          {{ compliance?.displayName }}
        </span>
        <a
          uiSref=".details.general"
          [uiParams]="{ complianceId: compliance?.id }"
        >
          <span class="o-complianceList__itemControls">
            <fa-icon icon="cog"></fa-icon>
          </span>
        </a>
      </div>
    </li>
    <!--<li class="o-complianceList__item">
      <a
        uiSref=".create.general"
        class="o-complianceList__itemBlock -placeholder"
      >
        <span class="o-complianceList__itemIcon">
          <fa-icon icon="plus"></fa-icon>
        </span>
        <span class="o-complianceList__itemLabel">
          Neues Compliance Thema
        </span>
      </a>
    </li>-->
  </ul>
</main>
