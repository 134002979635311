<!-- Widget -->
<div *ngIf="!dashboardEvaluation">
  dashboardEvaluation missing
</div>
<div *ngIf="dashboardEvaluation">
  <header class="widget-header">
    <span class="icon"><fa-icon icon="exclamation-triangle"></fa-icon></span>
    <span class="text">Mandanten-Status (nach Compliance-Themen)</span>
  </header>

  <div class="content">
    <div class="item-type-subList" [ngClass]="{'showSubDetails': totalRiskDetailsComplianceIsVisible(compliance)}" *ngFor="let compliance of compliances; trackBy: trackById">
        <div class="item-header status" [ngClass]="getProgressMeterClass(progressMeterType.SUB, compliance.percentageClientCriticalCappedSum)">
          <span class="key">
            <span matTooltip="Anteil Risiko eines gesamten Compliance-Themas an Gesamtrisiko Konzern">{{compliance.complianceName}} <span style="font-size: 0.8em;font-weight: normal"> // {{compliance.percentageClientCriticalCappedSum | number:'1.0-1'}}%</span></span>
            <small style="padding-top: 5px">
              <fa-icon icon="arrow-alt-circle-down" matTooltip="Niedrigster Wert"></fa-icon>&nbsp;<b matTooltip="Niedrigster Wert">{{compliance.minValue}}</b>
              &nbsp;|&nbsp;<fa-icon icon="arrow-alt-circle-up" matTooltip="Höchster Wert"></fa-icon>&nbsp;<b matTooltip="Höchster Wert">{{compliance.maxValue}}</b>
              &nbsp;|&nbsp;<fa-icon icon="bomb" matTooltip="Unternehmenskritische Risiken"></fa-icon>&nbsp;<b matTooltip="Unternehmenskritische Risiken">{{compliance.criticalRiskCount}}</b>
            &nbsp;|&nbsp;<fa-icon icon="balance-scale" matTooltip="Anteil Gesamtrisiko eines Compliance-Themas des Konzerns an anteiligem kritischen Maximalwert pro Compliance-Thema"></fa-icon>&nbsp;
               <b
                 matTooltip="Anteil Gesamtrisiko eines Compliance-Themas des Konzerns an anteiligem kritischen Maximalwert pro Compliance-Thema">{{compliance.percentageCriticalCappedSum | number:'1.0-1' }}%</b></small>
          </span>
          <!--              <span class="value statusText" matTooltip="Punkte gesamt: {{compliance.total}} // Risiken: {{compliance.count}}">{{compliance.total / compliance.count | number:'1.0-0'}}</span>-->
          <span class="value statusText">{{compliance.criticalCappedSum | number:'1.0-0'}}</span>
          <span class="percentage" [ngStyle]="{width: compliance.percentageClientCriticalCappedSum + '%'}"></span>
         </div>

        <div class="subItems">
          <div (click)="gotoCompliance(compliance, complianceCompany)" [ngClass]="getProgressMeterClass(progressMeterType.SUB, complianceCompany.percentageCriticalCapped)"
            class="subItem status hasPercantage" *ngFor="let complianceCompany of compliance.complianceCompanies; trackBy: trackById">
            <span class="percentage" [ngStyle]="{width: complianceCompany.percentageCriticalCapped+'%'}" matTooltip="{{complianceCompany.percentageCriticalCapped | number:'1.0-1' }}% - Anteil Risiko eines Compliance-Themas eines Unternehmens an Gesamtrisiko eines Compliance-Themas des Konzerns"></span>
            <span class="key">
              <span matTooltip="Anteil Risiko eines Compliance-Themas eines Unternehmens an Gesamtrisiko eines Compliance-Themas des Konzerns">{{complianceCompany.companyName}}<span style="font-size: 0.8em"> // {{complianceCompany.percentageCriticalCapped | number:'1.0-1' }}%</span></span>
              <small style="padding-top: 5px">
                <fa-icon icon="arrow-alt-circle-down" matTooltip="Niedrigster Wert"></fa-icon>&nbsp;<b matTooltip="Niedrigster Wert">{{complianceCompany.minValue}}</b>
                &nbsp;|&nbsp;<fa-icon icon="arrow-alt-circle-up" matTooltip="Höchster Wert"></fa-icon>&nbsp;<b matTooltip="Höchster Wert">{{complianceCompany.maxValue}}</b>
                &nbsp;|&nbsp;<fa-icon icon="bomb" matTooltip="Unternehmenskritische Risiken"></fa-icon>&nbsp;<b matTooltip="Unternehmenskritische Risiken">{{complianceCompany.criticalRiskCount}}</b>
                </small>
            </span>
            <!--                <span class="value statusText" matTooltip="Punkte gesamt: {{company.total}} // Risiken: {{company.count}}">{{company.total / company.count | number:'1.0-0'}}</span>-->
            <span class="value statusText">{{complianceCompany.criticalCapped | number:'1.0-0'}}</span>
          </div>
        </div>
        <div class="collapableIcon">
          <a (click)="toggleTotalRiskDetailsCompliance(compliance)">
            <span class="icon-hideDetails"><fa-icon icon="chevron-up" matTooltip="Verstecke Details"></fa-icon></span>
            <span class="icon-showDetails"><fa-icon icon="chevron-down" matTooltip="Zeige Details"></fa-icon></span>
          </a>
        </div>
      </div>
  </div>
  <div class="collapableIcon">
    <a (click)="toggleTotalRiskDetails()">
      <span class="icon-hideDetails"><fa-icon icon="chevron-up" matTooltip="Verstecke Details"></fa-icon></span>
      <span class="icon-showDetails"><fa-icon icon="chevron-down" matTooltip="Zeige Details"></fa-icon></span>
    </a>
  </div>
</div>
<!-- // Widget -->
