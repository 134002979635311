import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksWidgetComponent } from './tasks-widget.component';



@NgModule({
  declarations: [
    TasksWidgetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    TasksWidgetComponent,
  ],
})
export class TasksWidgetModule { }
