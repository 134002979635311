import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-revisor-nav',
  templateUrl: './revisor-nav.component.html',
  styleUrls: ['./revisor-nav.component.scss']
})
export class RevisorNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
