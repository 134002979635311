import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { ComplianceOverviewComponent } from './compliance-overview/compliance-overview.component';
import { ComplianceEditGeneralComponent } from './compliance-edit-general/compliance-edit-general.component';
import { ComplianceEditUserComponent } from './compliance-edit-user/compliance-edit-user.component';
import { ComplianceService } from './compliance.service';
import {
  resolveComplianceList,
  resolveComplianceSingle,
} from './compliance.resolver';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { resolveEditMode } from 'src/app/core/edit-mode.resolver';

export const COMPLIANCE_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.compliance',
    url: '/compliance',
    views: {
      '$default@app': {
        component: ComplianceOverviewComponent,
      },
    },
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'compliances',
        deps: [Transition, ComplianceService],
        resolveFn: resolveComplianceList,
      },
    ],
  },
  {
    name: 'app.compliance.details',
    abstract: true,
    url: '/{complianceId}',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
      editMode: EditMode.EDIT,
    },
    resolve: [
      {
        token: 'compliance',
        deps: [Transition, ComplianceService],
        resolveFn: resolveComplianceSingle,
      },
      {
        token: 'editMode',
        deps: [Transition],
        resolveFn: resolveEditMode,
      },
    ],
  },
  {
    name: 'app.compliance.details.general',
    views: {
      '$default@app': {
        component: ComplianceEditGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.compliance.details.user',
    views: {
      '$default@app': {
        component: ComplianceEditUserComponent,
      },
    },
    url: '/user',
  },
  {
    name: 'app.compliance.create',
    abstract: true,
    url: '/create',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
      editMode: EditMode.CREATE,
    },
    resolve: [
      {
        token: 'editMode',
        deps: [Transition],
        resolveFn: resolveEditMode,
      },
    ],
  },
  {
    name: 'app.compliance.create.general',
    views: {
      '$default@app': {
        component: ComplianceEditGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.compliance.create.user',
    views: {
      '$default@app': {
        component: ComplianceEditUserComponent,
      },
    },
    url: '/user',
  },
];
