import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Risk, RiskCalculations, RiskDraft, RiskRevision, RiskSearchData } from './risk';
import { IdObject } from 'src/app/core/id-object';
import { NGXLogger } from 'ngx-logger';
import { ResponseError } from '../../core/validation/response-error';
import { PatchResponse } from '../../core/validation/patch-response';

@Injectable({
  providedIn: 'root',
})
export class RiskService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}risk`;
  private static listRevisionsUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}risk/${id}/revisions`;
  private static singleUrl = (apiUrl: string, id: string): string => `${apiUrl}risk/${id}`;
  private static singleRevisionUrl = (apiUrl: string, id: string, revisionId: string): string => `${apiUrl}risk/${id}/revisions/${revisionId}`;
  private static singleCalculationsUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}risk/${id}/calculations`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}risk/search`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Risk[]> {
    const {apiUrl} = environment;
    const url = RiskService.listUrl(apiUrl);

    return this.httpClient
      .get<Risk[]>(url)
      .pipe(catchError(this.handleError<Risk[]>('RiskService->getList', [])));
  }

  public getRevisions(id: string): Observable<RiskRevision[]> {
    const {apiUrl} = environment;
    const url = RiskService.listRevisionsUrl(apiUrl, id);

    return this.httpClient
      .post<RiskRevision[]>(url, {})
      .pipe(catchError(this.handleError<RiskRevision[]>('RiskService->getRevisions', [])));
  }

  public getSingle(id: string): Observable<Risk> {
    const {apiUrl} = environment;
    const url = RiskService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Risk>(url)
      .pipe(catchError(this.handleError<Risk>('RiskService->getSingle')));
  }

  public getSingleRevision(id: string, revisionId: string): Observable<RiskRevision> {
    const {apiUrl} = environment;
    const url = RiskService.singleRevisionUrl(apiUrl, id, revisionId);

    return this.httpClient
      .get<RiskRevision>(url)
      .pipe(catchError(this.handleError<RiskRevision>('RiskService->getSingleRevision')));
  }

  public getSingleCalculations(id: string): Observable<RiskCalculations> {
    const {apiUrl} = environment;
    const url = RiskService.singleCalculationsUrl(apiUrl, id);

    return this.httpClient
      .get<RiskCalculations>(url)
      .pipe(catchError(this.handleError<RiskCalculations>('RiskService->getSingleCalculations')));
  }

  public create(riskDraft: RiskDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = RiskService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<IdObject>(url, riskDraft, {headers}).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('RiskService->create')),
    );
  }


  public patch(id: string, riskDraft: RiskDraft): Observable<ResponseError> {
    const {apiUrl} = environment;
    const url = RiskService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<PatchResponse>(url, riskDraft, {headers})
      .pipe(catchError(this.errorHandler));
    // .pipe(
    //   catchError((err: HttpErrorResponse) => {
    //       if (err.error instanceof Error) {
    //         console.error('An error occurred:', err.error.message);
    //       } else {
    //         console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
    //       }
    //       return err;
    //     },
    //   ));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error);
  }

  public remove(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = RiskService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('RiskService->remove', false)),
    );
  }

  public search(riskSearchData: RiskSearchData): Observable<Risk[]> {
    const {apiUrl} = environment;
    const url = RiskService.searchUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<Risk[]>(url, riskSearchData, {headers})
      .pipe(catchError(this.handleError<Risk[]>('RiskService->search', [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
