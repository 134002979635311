import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgxFileUploadUiProgressbarModule,
  NgxFileUploadUiCommonModule,
  NgxFileUploadUiToolbarModule,
} from '@ngx-file-upload/ui';
import { NgxFileDropModule } from 'ngx-file-drop';
import { CpUploadComponent } from './cp-upload/cp-upload.component';

@NgModule({
  declarations: [CpUploadComponent],
  imports: [
    CommonModule,
    NgxFileUploadUiToolbarModule,
    NgxFileUploadUiProgressbarModule,
    NgxFileUploadUiCommonModule,
    NgxFileDropModule,
  ],
  exports: [
    CpUploadComponent,
  ],
})
export class UploadModule {
}
