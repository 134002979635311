<div *ngIf="isVisible()" [ngClass]="cssClass">
  <header class="header">
    <!--<span class="headerIcon" (click)="expanded = !expanded">
      <fa-icon icon="exclamation-triangle"></fa-icon>
    </span>-->
    <h2 class="heading" (click)="toggleExpand()">
			<span class="warning-dialog-container">
				<span class="warning-item" [matTooltip]="getTooltipTotalHigh()">
          <fa-icon icon="exclamation-triangle"></fa-icon>
          {{compliance.calculation?.riskCountTotalHigh}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalDamageValue()">
          <fa-icon icon="house-damage"></fa-icon>
          {{compliance.calculation?.riskCountHighDamageValue}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalProbabilityValue()">
          <fa-icon icon="dice"></fa-icon>
          {{compliance.calculation?.riskCountHighProbabilityValue}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalNoMeasure()">
          <fa-icon icon="ban"></fa-icon>
          {{compliance.calculation?.riskCountNoMeasure}}
        </span>
				<span class="warning-item"> / {{compliance.calculation?.riskCount}}</span>
			</span>
      {{ compliance?.displayName }}
		</h2>
    <div class="headerControls">
      <!-- <div class="m-progressSlider -state1">
        <fa-icon icon="asterisk" class="m-progressSlider__icon"></fa-icon>
        <div class="m-progressSlider__track">
          <div class="m-progressSlider__progress" style="width: 33.3%">
            <span class="m-progressSlider__thumb"></span>
          </div>
        </div>
        <div class="m-progressSlider__state"></div>
      </div>-->
      <button mat-icon-button (click)="toggleExpand()" matTooltip="Untergeordnete Objekte anzeigen">
        <fa-icon [icon]="expanded ? 'angle-up' : 'angle-down'"></fa-icon>
      </button>
      <!--<button mat-icon-button (click)="openEditModal($event)">
        <fa-icon icon="cog"></fa-icon>
      </button>-->
    </div>
  </header>
  <div class="areas" *ngIf="expanded">
    <app-area-workspace-item
      [area]="area"
      [focus]="focus"
      [workspaceFilter]="workspaceFilter"
      (areaChange)="updateAreas()"
      (updateParentCalculation)="updateCalculation()"
      *ngFor="let area of areas; trackBy: trackById"
    ></app-area-workspace-item>
  </div>
</div>
