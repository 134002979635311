<div mat-dialog-title>
  TFA anlegen
  <button class="closeDialog" aria-label="Abbrechen" mat-dialog-close>
    <fa-icon icon="times"></fa-icon>
  </button>
</div>
<div mat-dialog-content>
  <mat-vertical-stepper linear #stepper>
    <mat-step [editable]="false" [stepControl]="createFormGroup">
      <ng-template matStepLabel>TFA anlegen</ng-template>
      <ng-template matStepContent *ngIf="isCreate">
        <form
          novalidate
          (ngSubmit)="onCreateFormSubmit(createFormGroup)"
          [formGroup]="createFormGroup"
        >
          <p>
            Bitte geben Sie den Namen des Gerätes an, auf dem Sie die
            Zwei-Faktor-App installiert haben. Dies dient nur zu Ihrer besseren
            Orientierung und hat keine weiteren Auswirkungen.
          </p>
          <mat-form-field appearance="fill">
            <mat-label>Gerätename</mat-label>
            <input matInput formControlName="displayName" required />
            <mat-error *ngIf="createFormGroup.hasError('required', 'displayName')">
              Bitte einen Namen vergeben.
            </mat-error>
          </mat-form-field>
          <p>
            Aus Sicherheitsgründen geben Sie bitte das Passwort ein, mit dem Sie
            sich angemeldet haben.
          </p>
          <mat-form-field appearance="fill">
            <mat-label>Account-Passwort</mat-label>
            <input
              type="password"
              autocomplete="current-password"
              matInput
              formControlName="accountPassword"
              required
            />
            <mat-error *ngIf="createFormGroup.hasError('required', 'accountPassword')">
              Bitte aktuelles Passwort angeben.
            </mat-error>
            <mat-error *ngIf="createFormGroup.hasError('pattern', 'accountPassword')">
              Das eingegebene Passwort stimmt nicht überein.
            </mat-error>
          </mat-form-field>

          <footer mat-dialog-actions>
            <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
            <button type="submit" mat-raised-button color="primary" [disabled]="pending || createFormGroup.invalid">Sichern</button>
          </footer>
        </form>
      </ng-template>
    </mat-step>
    <mat-step [editable]="false" [stepControl]="confirmFormGroup">
      <ng-template matStepLabel>TFA freigeben</ng-template>
      <ng-template matStepContent>
        <form
          novalidate
          (ngSubmit)="onConfirmFormSubmit(confirmFormGroup)"
          [formGroup]="confirmFormGroup"
        >
          <p>
            Bitte öffnen Sie Ihre Authentifizierungs-App. Auf dem
            Smartphone/Tablet können Sie den den QR-Code scannen, um die App mit
            Ihrem Konto zu verbinden. In Desktop-Apps oder wenn es sonst nicht
            möglich ist, den QR-Code zu scannen, geben Sie bitte die folgende
            Zeichenfolge manuell in Ihre App ein.
          </p>
          <img
            [src]="tfaForLinkData?.qrCode?.defaultImagePath"
            [alt]="tfaForLinkData?.secret"
          />
          <pre>{{ tfaForLinkData?.secret }}</pre>
          <mat-form-field appearance="fill">
            <mat-label>Neu generiertes OTP</mat-label>
            <input
              autocomplete="one-time-code"
              matInput
              formControlName="token"
              required
            />
          </mat-form-field>
          <p>
            Then enter the code you see in the OTP app here and click "Finish".
          </p>
          <footer mat-dialog-actions>
            <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
            <button type="submit" mat-raised-button color="primary" [disabled]="pending || createFormGroup.invalid">Sichern</button>
          </footer>
        </form>
      </ng-template>
    </mat-step>
  </mat-vertical-stepper>
</div>
