import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { ValueObject } from 'src/app/core/value-object';
import { ValueObjectService } from 'src/app/core/value-object.service';
import { Account } from '../../account/account';
import { AccountService } from '../../account/account.service';
import { Role } from '../../account/role.enum';
import { MeasureCategory } from '../../measure-category/measure-category';
import { MeasureCategoryService } from '../../measure-category/measure-category.service';
import { MeasureType } from '../../measure-type/measure-type';
import { MeasureTypeKind } from '../../measure-type/measure-type-kind.enum';
import { MeasureTypeService } from '../../measure-type/measure-type.service';
import { RiskMeasure, RiskMeasureDraft } from '../../risk-measure/risk-measure';
import { Risk } from '../../risk/risk';
import { RiskService } from '../../risk/risk.service';
import { Measure, MeasureAccount, MeasureAccountDraft, MeasureRevision } from '../measure';
import { MeasureState } from '../measure-state.enum';
import { MeasureService } from '../measure.service';
import { MeasureEditModalData } from './measure-edit-modal-data';
import { AuditNextDateType, AuditResult, AuditType } from '../../revisor/revisor-audit.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MeasureRevisionModalData } from '../measure-revision-modal/measure-revision-modal-data';
import { MeasureRevisionModalComponent } from '../measure-revision-modal/measure-revision-modal.component';

@Component({
  selector: 'app-measure-edit-modal',
  templateUrl: './measure-edit-modal.component.html',
  styleUrls: ['./measure-edit-modal.component.scss'],
})
export class MeasureEditModalComponent implements OnInit {
  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: MeasureState.ACTIVE},
    {displayName: 'gelöscht', value: MeasureState.DELETED},
    {displayName: 'abgeschlossen ', value: MeasureState.FINISHED},
  ];
  public roleOptions: ValueObject[] = [
    {displayName: 'informativer Benutzer', value: Role.INFORMATIVE},
    {displayName: 'Sachbearbeiter', value: Role.OFFICIAL},
    {displayName: 'Verantwortlicher', value: Role.RESPONSIBLE},
    {displayName: 'Administrator', value: Role.ADMIN},
    {displayName: 'Revisor', value: Role.REVISOR},
  ];
  public measureCategoryOptions$!: Observable<MeasureCategory[]>;
  public measureTypeOptions$!: Observable<MeasureType[]>;
  public riskOptions$!: Observable<Risk[]>;
  public filteredRiskOptions$ = new BehaviorSubject<Risk[]>([]);
  public openedRiskSelectForRiskMeasure = new SelectionModel<number>(false);
  public accountOptions$!: Observable<Account[]>;
  public filteredAccountOptions$ = new BehaviorSubject<Account[]>([]);
  public openedAccountSelectForMeasureAccount = new SelectionModel<number>(
    false,
  );

  public SECURITY_TYPE = MeasureTypeKind.SECURITY_TYPE;
  public CONTROL_TYPE = MeasureTypeKind.CONTROL_TYPE;

  public pending: boolean = false;
  private submitType: string = 'submit';
  private isRestartAudit: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    description: ['', []],
    startDate: [null, []],
    endDate: [null, []],
    ignored: [false, []],
    reachedEfficiencyProbabilityValue: [
      null,
      [Validators.min(0), Validators.max(100)],
    ],
    reachedEfficiencyDamageValue: [
      null,
      [Validators.min(0), Validators.max(100)],
    ],
    category: [null, []],
    firstType: [null, [Validators.required]],
    secondType: [null, [Validators.required]],
    state: [MeasureState.ACTIVE, [Validators.required]],
    riskMeasures: this.fb.array([]),
    measureAccounts: this.fb.array([]),
    measureReminders: [[], []],
    tags: [[], []],
    frequency: ['', []],
    auditPlotDescription: ['', []],
    auditResultDescription: ['', []],
    auditResult: [null, []],
    auditNextDate: [null, []],
    auditNextDateType: [null, []],
    auditSampleSize: ['', []],
    auditType: [null, []],
  });

  public riskSearchCtrl = new FormControl();
  public accountSearchCtrl = new FormControl();

  public riskMeasuresDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public riskMeasureColumns = [
    'risk',
    'damageValue',
    'probabilityValue',
    'controls',
  ];

  public measureAccountsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public measureAccountColumns = ['account', 'role', 'controls'];

  public measure: Measure | null = null;

  measureRevisionsLoaded: boolean = false;
  public measureRevisions: MeasureRevision[] | null = null;

  public auditTypeOptions: ValueObject[] = [
    {displayName: 'Aufbauprüfung', value: AuditType.StructureTest},
    {displayName: 'Funktionsprüfung', value: AuditType.FunctionTest},
    {displayName: 'Sonstige', value: AuditType.Other},
  ];

  public auditResultOptions: ValueObject[] = [
    {displayName: 'Wirksam mit Beanstandung', value: AuditResult.EffectiveWithComplaint},
    {displayName: 'Wirksam ohne Beanstandung', value: AuditResult.EffectiveWithoutComplaint},
    {displayName: 'Unwirksam', value: AuditResult.Ineffective},
  ];

  public auditNextDateOptions: ValueObject[] = [
    {displayName: 'in 1 Jahr', value: AuditNextDateType.ONE_YEAR},
    {displayName: 'in 2 Jahren', value: AuditNextDateType.TWO_YEARS},
    {displayName: 'in 3 Jahren', value: AuditNextDateType.THREE_YEARS},
    {displayName: 'Freie Angabe', value: AuditNextDateType.CUSTOM},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MeasureEditModalData,
    private matDialog: MatDialog,
    public matDialogRef: MatDialogRef<MeasureEditModalComponent>,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private measureService: MeasureService,
    private measureCategoryService: MeasureCategoryService,
    private measureTypeService: MeasureTypeService,
    private riskService: RiskService,
    private accountService: AccountService,
  ) {
    this.measureCategoryOptions$ = this.measureCategoryService.getList();
    this.measureTypeOptions$ = this.measureTypeService.getList();
    this.riskOptions$ = this.riskService.getList();
    this.accountOptions$ = this.accountService.getList();

    this.riskSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredRiskOptions(searchText);
      });

    this.accountSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredAccountOptions(searchText);
      });
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    switch (this.data.editMode) {
      case EditMode.CREATE:
        const risk: IdObject = {id: this.data.riskId};
        const riskMeasure: RiskMeasureDraft = {risk};
        this.addRiskMeasureFormGroup(riskMeasure);
        break;
      case EditMode.EDIT:
        this.measureService
          .getSingle(this.data.measureId)
          .subscribe((measure) => {
            this.measure = measure;
            this.editFormGroup.patchValue(measure);

            measure.riskMeasures?.forEach((riskMeasure) => {
              this.addRiskMeasureFormGroup(riskMeasure);
            });

            measure.measureAccounts?.forEach((measureAccount) => {
              this.addMeasureAccountFormGroup(measureAccount);
            });
          });

        break;
    }
    this.updateRiskMeasureView();
    this.getFilteredRiskOptions();
    this.getFilteredAccountOptions();
  }

  public getFilteredRiskOptions(searchText?: string) {
    this.riskService.search({searchText}).subscribe((riskOptions) => {
      this.filteredRiskOptions$.next(riskOptions);
    });
  }

  public getFilteredAccountOptions(searchText?: string) {
    this.accountService.search({searchText}).subscribe((accountOptions) => {
      this.filteredAccountOptions$.next(accountOptions);
    });
  }

  public addRiskMeasureFormGroup(
    riskMeasure?: RiskMeasure | RiskMeasureDraft,
  ): void {
    const riskMeasureFormGroup: FormGroup = this.fb.group({
      id: [riskMeasure?.id || undefined, []],
      risk: [
        {value: riskMeasure?.risk || null, disabled: !!riskMeasure?.id},
        [Validators.required],
      ],
      damageValue: [riskMeasure?.damageValue || null, []],
      probabilityValue: [riskMeasure?.probabilityValue || null, []],
    });

    (this.editFormGroup.get('riskMeasures') as FormArray).push(
      riskMeasureFormGroup,
    );

    this.updateRiskMeasureView();
  }

  public removeRiskMeasureFormGroup(index: number) {
    (this.editFormGroup.get('riskMeasures') as FormArray).removeAt(index);
    this.updateRiskMeasureView();
  }

  public setRiskSelectForRiskMeasure(open: boolean, index: number): void {
    if (open) {
      this.openedRiskSelectForRiskMeasure.select(index);
    } else {
      this.openedRiskSelectForRiskMeasure.deselect(index);
    }
  }

  public isRiskSelectForRiskMeasureOpen(index: number): boolean {
    return this.openedRiskSelectForRiskMeasure.isSelected(index);
  }

  public updateRiskMeasureView(): void {
    this.riskMeasuresDataSource.next(
      (this.editFormGroup.get('riskMeasures') as FormArray).controls,
    );
  }

  public addMeasureAccountFormGroup(
    measureAccount?: MeasureAccount | MeasureAccountDraft,
  ): void {
    const riskAccountFormGroup: FormGroup = this.fb.group({
      id: [measureAccount?.id || undefined, []],
      account: [measureAccount?.account, [Validators.required]],
      role: [measureAccount?.role || null, []],
    });

    (this.editFormGroup.get('measureAccounts') as FormArray).push(
      riskAccountFormGroup,
    );

    this.updateMeasureAccountView();
  }

  public removeMeasureAccountFormGroup(index: number) {
    (this.editFormGroup.get('measureAccounts') as FormArray).removeAt(index);
    this.updateMeasureAccountView();
  }

  public setAccountSelectForMeasureAccount(open: boolean, index: number): void {
    if (open) {
      this.openedAccountSelectForMeasureAccount.select(index);
    } else {
      this.openedAccountSelectForMeasureAccount.deselect(index);
    }
  }

  public isAccountSelectForMeasureAccountOpen(index: number): boolean {
    return this.openedAccountSelectForMeasureAccount.isSelected(index);
  }

  public updateMeasureAccountView(): void {
    this.measureAccountsDataSource.next(
      (this.editFormGroup.get('measureAccounts') as FormArray).controls,
    );
  }

  public onSubmit(editFormGroup: FormGroup): void {
    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      this.logger.log(editFormGroup.value, editFormGroup.errors);
      return;
    }

    this.pending = true;
    const confirmAudit = this.submitType === 'submitAudit';
    const restartAudit = this.isRestartAudit;

    const {value: editData} = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.CREATE:
        const risk: IdObject = {id: this.data.riskId};

        this.measureService
          .create({risk, ...editData})
          .subscribe((measureId) => {
            let response: Measure | null = null;

            if (!!measureId) {
              response = {
                id: measureId,
                ...editData,
              };
            }

            this.pending = false;
            this.matDialogRef.close(response);
          });

        break;
      case EditMode.EDIT:
        const {measureId} = this.data;

        this.measureService.patch(measureId, {restartAudit, confirmAudit, ...editData})
          .subscribe(() => {
            let response: Measure | null = {
              id: measureId,
              ...editData,
            };

            this.pending = false;
            this.matDialogRef.close(response);
          }, (err) => {
            this.pending = false;
            console.debug('Error', err);
            alert('Das hat leider nicht geklappt.\n' + (err.errorMessage
              ? err.errorMessage
              : 'Die' +
              ' Daten konnten nicht gespeichert werden, bitte überprüfen Sie alle Felder.'));
          });

        break;
    }
  }

  tabClick(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (this.data && this.data.editMode === EditMode.EDIT) {
      if (tab === 'Revisionen') {
        const {measureId} = this.data;
        this.measureRevisionsLoaded = true;
        this.measureService.getRevisions(measureId).subscribe((revisions) => {
          this.measureRevisions = revisions;
        });
      }
    }
  }

  updateSubmitType(type: string) {
    this.submitType = type;
  }

  restartAudit() {
    this.isRestartAudit = true;
    if (this.measure) {
      this.measure.auditConfirmed = false;
    }
  }

  openRevisionModal(revision: MeasureRevision) {
    if (!this.measure || !revision.id) {
      return;
    }

    const data: MeasureRevisionModalData = {
      measureId: this.measure.id,
      revisionId: revision.id,
    };

    const dialogRef = this.matDialog.open(MeasureRevisionModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    // dialogRef.afterClosed().subscribe((measure?: Measure) => {
    // });
  }
}
