import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DepositOverviewComponent } from './deposit-overview/deposit-overview.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DEPOSIT_STATES } from './deposit.states';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import {DepositEditModalComponent} from "./deposit-edit-modal/deposit-edit-modal.component";
import { DepositCreateComponent } from './deposit-create/deposit-create.component';
import { DepositReturnComponent } from './deposit-return/deposit-return.component';
import {ZXingScannerModule} from "@zxing/ngx-scanner";
import {DepositQrScanModalComponent} from "./deposit-qr-scan-modal/deposit-qr-scan-modal.component";
import { SignatureModule } from '../../core/signature/signature.module';
import {DepositConfirmationModalComponent} from "./deposit-confirmation-modal/deposit-confirmation-modal.component";
import {DepositAgreementModalComponent} from "./deposit-agreement-modal/deposit-agreement-modal.component";
import {DepositSelectionModalComponent} from "./deposit-selection-modal/deposit-selection-modal.component";
import {DepositDeleteModalComponent} from "./deposit-delete-modal/deposit-delete-modal.component";
import { PagingModule } from '../../core/paging/paging.module';

@NgModule({
  declarations: [
    DepositOverviewComponent,
    DepositEditModalComponent,
    DepositCreateComponent,
    DepositReturnComponent,
    DepositQrScanModalComponent,
    DepositConfirmationModalComponent,
    DepositAgreementModalComponent,
    DepositSelectionModalComponent,
    DepositDeleteModalComponent
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: DEPOSIT_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    SignatureModule,
    ZXingScannerModule,
    PagingModule,
  ],
})
export class DepositModule {
}
