<header id="header" role="banner">
	<h1>Dashboard
    <small>Dashboard</small>
	</h1>
  <!--<a href="" class="header-logo">
    <img src="img/logos/logo-100x100-inverted.png" alt="guestoo - Gästemanagement einfach!" />
  </a>-->
</header>

<main role="main" id="content" *ngIf="dashboardEvaluation" class="dashboard dashboard-widgets" style="height: 100vh;">
 <!-- <app-client-widget class="o-dashboard__widget -halfWidth"></app-client-widget>
  <app-company-widget class="o-dashboard__widget -halfWidth" [company]="company" *ngFor="let company of companies"></app-company-widget>
  <app-tasks-widget class="o-dashboard__widget -oneThirdWidth"></app-tasks-widget>
  <app-measures-widget class="o-dashboard__widget -oneThirdWidth"></app-measures-widget>
  <app-reminders-widget class="o-dashboard__widget -oneThirdWidth"></app-reminders-widget> -->


 <!-- ROW MANDANT -->
  <div class="rowContainer" *ngIf="dashboardEvaluation">
    <app-dashboard-widget-client-overview class="widget width-1-2 showWidgetDetails" [dashboardEvaluation]="dashboardEvaluation"></app-dashboard-widget-client-overview>

    <app-dashboard-widget-client-compliances
      class="widget width-1-2 showWidgetDetails"
      [ngClass]="{'showWidgetDetails': evaluationDashboardFilter.showTotalRiskDetails}"
      [authInfo]="authInfo"
      [evaluationFilter]="evaluationDashboardFilter"
      (filterChanged)="updateFilter()"
      [dashboardEvaluation]="dashboardEvaluation"></app-dashboard-widget-client-compliances>
  </div>
 <!-- // ROW -->

 <!-- ROW Company -->
  <div class="rowContainer">
    <div class="row-company-switcher">
      <select [(ngModel)]="evaluationDashboardFilter.companyId" (change)="companySelectionChanged()">
        <option [ngValue]="company.id" *ngFor="let company of dashboardEvaluation?.companies">Details: {{company.companyName}}</option>
      </select>
    </div>

    <app-dashboard-widget-company-overview
      class="widget width-1-2 showWidgetDetails"
      (filterChanged)="updateFilter()"
      [dashboardEvaluation]="dashboardEvaluation"
      [evaluationFilter]="evaluationDashboardFilter">
    </app-dashboard-widget-company-overview>

    <app-dashboard-widget-company-risks
      class="widget width-1-2 showWidgetDetails"
      (dashboardReload)="reloadDashboard()"
      (filterChanged)="updateFilter()"
      [dashboardEvaluation]="dashboardEvaluation"
      [evaluationFilter]="evaluationDashboardFilter">
    </app-dashboard-widget-company-risks>
  </div>
 <!-- // ROW  Company -->

 <!-- ROW Footer -->
  <div class="rowContainer" style="margin-top: 30px">
    <!-- Widget -->
    <div class="">
      <ul class="colorLegende">
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="umbrella"></fa-icon></span> Mandant</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="building"></fa-icon></span> Unternehmen</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="gavel"></fa-icon></span> Compliance-Thema</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="folder-open"></fa-icon></span> Bereich im Comoliance Thema</li>
        <li style="width: 40px"></li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="bolt"></fa-icon></span> Risiko</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="tasks"></fa-icon></span> Maßnahme</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="check-square"></fa-icon></span> Aufgabe</li>
      </ul>
      <ul class="colorLegende">
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="exclamation-triangle"></fa-icon></span> Gesamtrisiko (Eintrittswahrscheilichkeit * Schaden)</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="house-damage"></fa-icon></span> Schaden (0-100 Punkte)</li>
        <li class="colorLegende-item"><span class="icon"><fa-icon icon="dice"></fa-icon></span> Eintrittswahrscheinlichkeit (0-100 Punkte)</li>
      </ul>
      <ul class="colorLegende" style="padding-bottom: 60px">
        <li class="colorLegende-item"><span class="colorExample veryLow"></span> Sehr niedrig (bis 1.000 | 9)</li>
        <li class="colorLegende-item"><span class="colorExample low"></span> Niedrig (bis 2.000 | 30)</li>
        <li class="colorLegende-item"><span class="colorExample medium"></span> Mittel (bis 5.000 | 60)</li>
        <li class="colorLegende-item"><span class="colorExample high"></span> Hoch (bis 7.000 | 80)</li>
        <li class="colorLegende-item"><span class="colorExample veryHigh"></span> Sehr hoch (ab 7.000 | 80)</li>
      </ul>
    </div>
  </div>
 <!-- // ROW Footer -->
</main>
<span class="bg-container"></span>
