import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { EditMode } from '../../../core/edit-mode.enum';
import { Area } from '../../area/area';
import {Deposit, DepositSearchData} from '../deposit';
import { DepositService } from '../deposit.service';
import { StateService } from '@uirouter/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaEditModalData } from '../../area/area-edit-modal/area-edit-modal-data';
import { AreaState } from '../../area/area-state.enum';
import { DepositState } from '../deposit-state.enum';
import {BarcodeFormat} from "@zxing/library";
import {DepositCreateComponent} from "../deposit-create/deposit-create.component";
import {ComplianceEditModalData} from "../../compliance/compliance-edit-modal/compliance-edit-modal-data";
import {DepositEditModalEditData} from "../deposit-edit-modal/deposit-edit-modal-data";
import {DepositConfirmationModalData} from "../deposit-confirmation-modal/deposit-confirmation-modal-data";
import {DepositSelectionModalData} from "./deposit-selection-modal-data";

@Component({
  selector: 'app-deposit-selection-modal',
  templateUrl: './deposit-selection-modal.component.html',
  styleUrls: ['./deposit-selection-modal.component.scss'],
})
export class DepositSelectionModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  deposits: Deposit[] = [];

  constructor(
    private matDialogRef: MatDialogRef<DepositSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DepositSelectionModalData
  ) {
  }

  public selectDeposit(deposit: Deposit) {
    this.matDialogRef.close(deposit)
  }

  public closeDialog() {
    this.matDialogRef.close(null)
  }

  public ngOnInit() {
    this.deposits = this.data.deposits
  }

}
