<header id="header" role="banner">
  <h1>
    Compliance
    <small>Benutzer</small>
  </h1>
</header>

<main role="main" id="content" class="companies">
  <app-compliance-edit-nav></app-compliance-edit-nav>

  <div class="o-complianceUser" novalidate>
    <div class="userList">
      <table>
        <tr>
          <th class="name">Name</th>
          <th class="role">Rolle</th>
          <th class="options">Optionen</th>
        </tr>
        <tr>
          <td class="name">
            <mat-form-field appearance="standard">
              <mat-select
                placeholder="Account"
                [compareWith]="compareById"
                [(ngModel)]="complianceAccountDraft.account">
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="accountSearchCtrl"
                    placeholderLabel="Accounts suchen …"
                    noEntriesFoundLabel="keine Accounts gefunden"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  [value]="account"
                  *ngFor="let account of (filteredAccountOptions$ | async)">
                  {{ account?.firstName }}
                  {{ account?.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
          <td class="role">
            <mat-select placeholder="Rolle" [(ngModel)]="complianceAccountDraft.role">
              <mat-option
                [value]="role.value"
                *ngFor="let role of roleOptions"
              >
                {{ role.displayName }}
              </mat-option>
            </mat-select>
          </td>
          <td class="options">
            <button mat-icon-button (click)="addAccount()"><fa-icon icon="plus"></fa-icon>Hinzufügen</button>
          </td>
        </tr>
        <tr class="userList__entry" *ngFor="let complianceAccount of this.complianceAccounts">
          <td class="name">{{complianceAccount.account.firstName}}&nbsp;{{complianceAccount.account.lastName}}</td>
          <td class="role">
            <mat-select placeholder="Rolle" [(ngModel)]="complianceAccount.role" (selectionChange)="roleChanged(complianceAccount)">
              <mat-option
                [value]="role.value"
                *ngFor="let role of roleOptions"
              >
                {{ role.displayName }}
              </mat-option>
            </mat-select>
          </td>
          <td class="options">
            <app-cp-confirm [proceedAction]="deleteAccount(complianceAccount)" linkText="Löschen">Löschen</app-cp-confirm>
            <!--            <button mat-icon-button (click)="deleteAccount(complianceAccount)"><fa-icon icon="trash"></fa-icon></button>-->
          </td>
        </tr>
      </table>
    </div>
  </div>
</main>
