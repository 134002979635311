import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Measure, MeasureDraft, MeasureRevision, MeasureSearchData } from './measure';
import { IdObject } from 'src/app/core/id-object';
import { NGXLogger } from 'ngx-logger';
import { RiskDraft, RiskRevision } from '../risk/risk';
import { ResponseError } from '../../core/validation/response-error';
import { PatchResponse } from '../../core/validation/patch-response';

@Injectable({
  providedIn: 'root',
})
export class MeasureService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}measure`;
  private static listRevisionsUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}measure/${id}/revisions`;

  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}measure/${id}`;

  private static singleRevisionUrl = (apiUrl: string, id: string, revisionId: string): string =>
    `${apiUrl}measure/${id}/revisions/${revisionId}`;

  private static searchUrl = (apiUrl: string): string =>
    `${apiUrl}measure/search`;

  private static setCompleteUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}measure/${id}/setCompleted`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Measure[]> {
    const {apiUrl} = environment;
    const url = MeasureService.listUrl(apiUrl);

    return this.httpClient
      .get<Measure[]>(url)
      .pipe(
        catchError(this.handleError<Measure[]>('MeasureService->getList', [])),
      );
  }

  public getRevisions(id: string): Observable<MeasureRevision[]> {
    const {apiUrl} = environment;
    const url = MeasureService.listRevisionsUrl(apiUrl, id);

    return this.httpClient
      .post<MeasureRevision[]>(url, {})
      .pipe(catchError(this.handleError<MeasureRevision[]>('MeasureService->getRevisions', [])));
  }

  public getSingle(id: string): Observable<Measure> {
    const {apiUrl} = environment;
    const url = MeasureService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Measure>(url)
      .pipe(catchError(this.handleError<Measure>('MeasureService->getSingle')));
  }

  public getSingleRevision(id: string, revisionId: string): Observable<MeasureRevision> {
    const {apiUrl} = environment;
    const url = MeasureService.singleRevisionUrl(apiUrl, id, revisionId);

    return this.httpClient
      .get<MeasureRevision>(url)
      .pipe(catchError(this.handleError<MeasureRevision>('MeasureService->getSingleRevision')));
  }

  public create(measureDraft: MeasureDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = MeasureService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<IdObject>(url, measureDraft, {headers}).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('MeasureService->create')),
    );
  }


  public patch(id: string, measureDraft: MeasureDraft): Observable<ResponseError> {
    const {apiUrl} = environment;
    const url = MeasureService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<PatchResponse>(url, measureDraft, {headers})
      .pipe(catchError(this.errorHandler));
    // .pipe(
    //   catchError((err: HttpErrorResponse) => {
    //       if (err.error instanceof Error) {
    //         console.error('An error occurred:', err.error.message);
    //       } else {
    //         console.error(`Backend returned code ${err.status}, body was: ${err.error}`);
    //       }
    //       return err;
    //     },
    //   ));
  }

  public remove(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = MeasureService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('MeasureService->remove', false)),
    );
  }

  public search(measureSearchData: MeasureSearchData): Observable<Measure[]> {
    const {apiUrl} = environment;
    const url = MeasureService.searchUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<Measure[]>(url, measureSearchData, {headers})
      .pipe(
        catchError(this.handleError<Measure[]>('MeasureService->search', [])),
      );
  }

  public setComplete(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = MeasureService.setCompleteUrl(apiUrl, id);

    return this.httpClient.post<void>(url, null).pipe(
      map(() => true),
      catchError(this.handleError('MeasureService->setCompleteUrl', false)),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
