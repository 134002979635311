import { DashboardService } from './dashboard.service';
import { Dashboard } from './dashboard';
import { DashboardEvaluation } from './dashboardEvaluation';
import { DashboardRevisor, DashboardRevisorFilter } from '../revisor/dashboardRevisor';
import { RevisorFilterType } from '../revisor/revisor.filter-type.enum';

export function resolveDashboard(dashboardService: DashboardService): Promise<Dashboard> {
  return new Promise((resolve) => {
    dashboardService.getSingle().subscribe(resolve);
  });
}

export function resolveDashboardEvaluation(dashboardService: DashboardService): Promise<DashboardEvaluation> {
  return new Promise((resolve) => {
    dashboardService.getEvaluationData().subscribe(resolve);
  });
}

export function resolveDashboardRevisor(dashboardService: DashboardService): Promise<DashboardRevisor> {
  return new Promise((resolve) => {

    const filter: DashboardRevisorFilter = {filterType: RevisorFilterType.ALL_ENTRIES};
    const revisorFilterStr = localStorage.getItem('revisorFilter');
    if (revisorFilterStr) {
      const revisorFilter = JSON.parse(revisorFilterStr);
      if (revisorFilter && revisorFilter.filterType) {
        filter.filterType = revisorFilter.filterType;
      }
    }

    dashboardService.getRevisorData(filter).subscribe(resolve);
  });
}
