<nav id="subNav" class="hasIcons">
	<ul>
		<li uiSrefActive="active">
      <a uiSref="^.infos">
        <fa-icon icon="font"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.PROFILE.MENU.INFOS' | translate}}</small>
		  </a>
    </li>
		<!--<li uiSrefActive="active">
      <a uiSref="^.settings">
        <fa-icon icon="paint-brush"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.PROFILE.MENU.SETTINGS' | translate}}</small>
		  </a>
    </li>-->
		<li uiSrefActive="active">
      <a uiSref="^.security">
        <fa-icon icon="user-tie"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.PROFILE.MENU.SECURITY' | translate}}</small>
		  </a>
    </li>
	</ul>
</nav>
