import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttributeModalComponent } from './attribute-modal/attribute-modal.component';
import { UIRouterModule } from '@uirouter/angular';
import {ATTRIBUTE_STATES} from './attribute.states';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import { AttributeOverviewComponent } from './attribute-overview/attribute-overview.component';
import {PagingModule} from "../../core/paging/paging.module";
import {SettingsModule} from "../settings/settings.module";
import {AttributeDeleteModalComponent} from "./attribute-delete-modal/attribute-delete-modal.component";

@NgModule({
  declarations: [
    AttributeOverviewComponent,
    AttributeModalComponent,
    AttributeDeleteModalComponent,
  ],
    imports: [
        CommonModule,
        UIRouterModule.forChild({states: ATTRIBUTE_STATES}),
        TranslateModule.forChild({}),
        FontAwesomeModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ValidationModule,
        PagingModule,
        SettingsModule,
    ],
})
export class AttributeModule {
}
