import { HostBinding, Input } from '@angular/core';
import { Component } from '@angular/core';
import { Compliance, ComplianceDraft } from '../compliance';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComplianceService } from '../compliance.service';

@Component({
  selector: 'app-compliance-overview',
  templateUrl: './compliance-overview.component.html',
  styleUrls: ['./compliance-overview.component.scss'],
})
export class ComplianceOverviewComponent {
  @Input() compliances: Compliance[] = [];

  constructor(private complianceService: ComplianceService) {}

  onComplianceDropped(event: CdkDragDrop<string[]>) {
    const { previousIndex, currentIndex } = event;
    const { id } = this.compliances[previousIndex];
    const complianceDraft: ComplianceDraft = { sortOrder: currentIndex };

    moveItemInArray(this.compliances, previousIndex, currentIndex);
    this.complianceService.patch(id, complianceDraft).subscribe();
  }
}
