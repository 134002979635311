import {Component, NgZone, OnInit} from '@angular/core';
import {BarcodeFormat} from "@zxing/library";
import {DepositService} from "../deposit.service";
import {Deposit, DepositSearchData} from "../deposit";
import {MatDialog} from "@angular/material/dialog";
import {DepositSelectionModalComponent} from "../deposit-selection-modal/deposit-selection-modal.component";
import {DepositSelectionModalData} from "../deposit-selection-modal/deposit-selection-modal-data";
import {DepositState} from "../deposit-state.enum";
import {StateService} from "@uirouter/core";
import {DepositFilterState} from "../deposit-filter-state.enum";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-deposit-return',
  templateUrl: './deposit-return.component.html',
  styleUrls: ['./deposit-return.component.scss']
})
export class DepositReturnComponent implements OnInit {

  constructor(private ngZone: NgZone,
              private depositService: DepositService,
              private matDialog: MatDialog,
              private stateService: StateService,) { }

  allowedQrFormats = [ BarcodeFormat.QR_CODE ];
  deposit: Deposit | null = null
  searchingDeposit: boolean = false
  depositSuccessfullySaved: boolean = false
  pending: boolean = false;

  ngOnInit(): void {
  }

  saveDeposit(isOk: boolean) {
    if (this.deposit) {
      this.deposit.state = isOk ? DepositState.RETURNED : DepositState.ON_HOLD
    }
  }

  scanSuccessHandler(result: string) {
    this.ngZone.run(() => {
      if (this.searchingDeposit) {
        return;
      }

      this.searchingDeposit = true;
      let searchFilter: DepositSearchData = {
        searchText: result,
        state: DepositFilterState.BORROWED
      }
      this.depositService.search(searchFilter).then((pagesResponse) => {
        const depositsFound: Deposit[] = pagesResponse.items;
        if (depositsFound && depositsFound.length > 0) {
          if (depositsFound.length > 1) {
            const data: DepositSelectionModalData = {
              deposits: depositsFound
            };

            const dialogRef = this.matDialog.open(DepositSelectionModalComponent, {
              width: '750px',
              maxWidth: '100%',
              minHeight: 'calc(100vh - 0)',
              // position: {top: '0', right: '0'},
              data
            });
            dialogRef.afterClosed().subscribe((depositSelected?: Deposit) => {
              if (depositSelected) {
                this.deposit = depositSelected
              } else {
                this.searchingDeposit = false;
              }
            });
          } else {
            this.depositService.getSingle(depositsFound[0].id).subscribe((deposit) => {
              this.deposit = deposit;
            });
          }

        } else {
          this.searchingDeposit = false;
        }
      });
    });

  }

  public onSubmit() {
    if (!this.deposit) {
      return;
    }

    if (this.pending) {
      return;
    }

    this.pending = true;
    const {id: depositId} = this.deposit;

    const patchData = {...this.deposit};
    this.depositService.patch(depositId, patchData).subscribe(() => {
      this.pending = false;
      this.stateService.go('app.deposit', undefined, {reload: true});
    });
  }

}
