import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdObject } from 'src/app/core/id-object';
import { environment } from 'src/environments/environment';
import { Area, AreaCalculation, AreaDraft, AreaSearchData } from './area';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}area`;
  private static singleUrl = (apiUrl: string, id: string): string => `${apiUrl}area/${id}`;
  private static singleCalculationsUrl = (apiUrl: string, id: string): string => `${apiUrl}area/${id}/calculations`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}area/search`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {}

  public getList(): Observable<Area[]> {
    const { apiUrl } = environment;
    const url = AreaService.listUrl(apiUrl);

    return this.httpClient
      .get<Area[]>(url)
      .pipe(catchError(this.handleError<Area[]>('AreaService->getList', [])));
  }

  public getSingle(id: string): Observable<Area> {
    const { apiUrl } = environment;
    const url = AreaService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Area>(url)
      .pipe(catchError(this.handleError<Area>('AreaService->getSingle')));
  }

  public getSingleCalculation(id: string): Observable<AreaCalculation> {
    const { apiUrl } = environment;
    const url = AreaService.singleCalculationsUrl(apiUrl, id);

    return this.httpClient
      .get<AreaCalculation>(url)
      .pipe(catchError(this.handleError<AreaCalculation>('AreaService->getSingleCalculation')));
  }

  public create(areaDraft: AreaDraft): Observable<string> {
    const { apiUrl } = environment;
    const url = AreaService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post<IdObject>(url, areaDraft, { headers }).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('AreaService->create')),
    );
  }

  public patch(id: string, areaDraft: AreaDraft): Observable<boolean> {
    const { apiUrl } = environment;
    const url = AreaService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.patch<void>(url, areaDraft, { headers }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('AreaService->patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = AreaService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('AreaService->remove', false)),
    );
  }

  public search(areaSearchData: AreaSearchData): Observable<Area[]> {
    const { apiUrl } = environment;
    const url = AreaService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<Area[]>(url, areaSearchData, { headers })
      .pipe(catchError(this.handleError<Area[]>('AreaService->search', [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
