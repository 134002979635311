import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CPConfirmModalData } from '../../../core/validation/cp-confirm-modal/cp-confirm-modal';
import { AccountService } from '../account.service';
import { AuthInfo, AuthInfoReduced } from '../../../core/auth-info/auth-info';
import { AuthInfoService } from '../../../core/auth-info/auth-info.service';
import { Role } from '../role.enum';
import { StateService } from '@uirouter/angular';
import { ChangeRoleModalData } from './change-role-modal.data';

@Component({
  selector: 'app-change-role-modal',
  templateUrl: './change-role-modal.component.html',
  styleUrls: ['./change-role-modal.component.scss'],
})
export class ChangeRoleModalComponent implements OnInit {
  pending: boolean = false;
  accounts: AuthInfoReduced[] = [];
  authInfo?: AuthInfo;
  Role = Role;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ChangeRoleModalData,
    public matDialogRef: MatDialogRef<ChangeRoleModalComponent>,
    private stateService: StateService,
    private authInfoService: AuthInfoService,
  ) {
    this.authInfo = data.authInfo;
  }

  ngOnInit(): void {
    this.authInfoService.getAccounts().subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  cancel() {
    this.matDialogRef.close();
  }

  useAccount(account: AuthInfoReduced) {
    if (account.clientId === this.authInfo?.client.id) {
      return;
    }
    this.authInfoService.setCurrentAccount(account);
    this.authInfoService.getAuthInfo(true).then((authInfo) => {
      this.stateService.go('.', undefined, {reload: true});
      this.matDialogRef.close();
      // window.location.reload();
    });
  }
}
