import { Component, Input, OnInit } from '@angular/core';
import { DashboardEvaluation } from '../../dashboardEvaluation';
import { ProgressMeterMilestone } from '../../../../core/progress-meter/progress-meter-milestone';
import { ProgressMeterStates } from '../../../../core/calculation/ProgressMeterStates';
import { ProgressMeterType } from '../../../../core/progress-meter/progress-meter-type.enum';
import { IdObjectService } from '../../../../core/id-object.service';

@Component({
  selector: 'app-dashboard-widget-client-overview',
  templateUrl: './dashboard-widget-client-overview.component.html',
  styleUrls: ['./dashboard-widget-client-overview.component.scss'],
})
export class DashboardWidgetClientOverviewComponent implements OnInit {
  @Input() dashboardEvaluation?: DashboardEvaluation | null = null;

  public trackById = IdObjectService.trackById;
  public progressMeterType = ProgressMeterType;

  constructor() {
  }

  ngOnInit(): void {
  }


  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  getProgressMeterClass(type: ProgressMeterType, value: number) {
    const classNames: string[] = [];
    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }

    return classNames;
  }
}
