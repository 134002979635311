import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Tag } from '../../tag/tag';
import { TagService } from '../../tag/tag.service';
import { Compliance } from '../compliance';
import { ComplianceService } from '../compliance.service';
import { ComplianceEditModalData } from './compliance-edit-modal-data';

@Component({
  selector: 'app-compliance-edit-modal',
  templateUrl: './compliance-edit-modal.component.html',
  styleUrls: ['./compliance-edit-modal.component.scss'],
})
export class ComplianceEditModalComponent implements OnInit {
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  readonly tagCtrl = new FormControl();

  tagOptions$: Observable<Tag[]> | null = null;
  public pending: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    designColor: ['', [Validators.required]],
    tags: [[], []],
  });

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public navigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: ComplianceEditModalData,
    public matDialogRef: MatDialogRef<ComplianceEditModalComponent>,
    private complianceService: ComplianceService,
    private tagService: TagService,
    private fb: FormBuilder,
  ) {
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-cancel.svg',
      ),
    );
    this.tagCtrl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.tagOptions$ = this.tagService.search({ searchText });
        } else {
          this.tagOptions$ = null;
        }
      });
  }

  public ngOnInit() {
    if (this.data.editMode === EditMode.EDIT) {
      this.complianceService
        .getSingle(this.data.complianceId)
        .subscribe((complience) => {
          this.editFormGroup.patchValue(complience);
        });
    }
  }

  addTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.editFormGroup.get('tags')?.value.push({ displayName: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  removeTag(index: number) {
    this.editFormGroup.get('tags')?.value.splice(index, 1);
  }

  selectTag(event: MatAutocompleteSelectedEvent) {
    const tag = event.option.value as Tag;

    this.tagInput.nativeElement.value = '';
    this.editFormGroup.get('tags')?.value.push(tag);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      return;
    }

    this.pending = true;
    const { value: editData } = editFormGroup;

    switch (this.data.editMode) {
      case EditMode.EDIT:
        const { complianceId } = this.data;

        this.complianceService
          .patch(complianceId, editData)
          .subscribe((success) => {
            let response: Compliance | null = null;

            if (success) {
              response = {
                id: complianceId,
                ...editData,
              };
            }

            this.pending = false;
            this.matDialogRef.close(response);
          });

        break;
    }
  }
}
