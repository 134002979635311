import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { ValueObject } from 'src/app/core/value-object';
import { ValueObjectService } from 'src/app/core/value-object.service';
import { Account } from '../../account/account';
import { AccountService } from '../../account/account.service';
import { Role } from '../../account/role.enum';
import { MeasureCategory } from '../../measure-category/measure-category';
import { MeasureCategoryService } from '../../measure-category/measure-category.service';
import { MeasureType } from '../../measure-type/measure-type';
import { MeasureTypeKind } from '../../measure-type/measure-type-kind.enum';
import { MeasureTypeService } from '../../measure-type/measure-type.service';
import { RiskMeasure, RiskMeasureDraft } from '../../risk-measure/risk-measure';
import { Risk } from '../../risk/risk';
import { RiskService } from '../../risk/risk.service';
import { Measure, MeasureAccount, MeasureAccountDraft, MeasureRevision } from '../measure';
import { MeasureState } from '../measure-state.enum';
import { MeasureService } from '../measure.service';
import { MeasureRevisionModalData } from './measure-revision-modal-data';
import { AuditNextDateType, AuditResult, AuditType } from '../../revisor/revisor-audit.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MeasureEditModalEditData } from '../measure-edit-modal/measure-edit-modal-data';
import { MeasureEditModalComponent } from '../measure-edit-modal/measure-edit-modal.component';

@Component({
  selector: 'app-measure-revision-modal',
  templateUrl: './measure-revision-modal.component.html',
  styleUrls: ['./measure-revision-modal.component.scss'],
})
export class MeasureRevisionModalComponent implements OnInit {
  public measureRevision: MeasureRevision | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MeasureRevisionModalData,
    public matDialogRef: MatDialogRef<MeasureRevisionModalComponent>,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private measureService: MeasureService,
  ) {
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    this.measureService
      .getSingleRevision(this.data.measureId, this.data.revisionId)
      .subscribe((measureRevision) => {
        this.measureRevision = measureRevision;
      });
  }

  public closeModal(): void {
    this.matDialogRef.close({});
  }
}
