import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskWorkspaceItemComponent } from './risk-workspace-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { MeasureWorkspaceItemModule } from '../../measure/measure-workspace-item/measure-workspace-item.module';
import { DamageTypeModule } from '../../damage-type/damage-type.module';
import { ProgressMeterModule } from 'src/app/core/progress-meter/progress-meter.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [RiskWorkspaceItemComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    MeasureWorkspaceItemModule,
    DamageTypeModule,
    ProgressMeterModule,
    MatTooltipModule,
  ],
  exports: [RiskWorkspaceItemComponent],
})
export class RiskWorkspaceItemModule {}
