<!--<div mat-dialog-title>Vollständige Ausleihbedingungen</div>-->
<mat-dialog-content class="mat-typography">
<div *ngIf="data.letterOfAgreement" style="white-space: pre-wrap;overflow: auto">{{ data.letterOfAgreement }}</div>
<div *ngIf="!data.letterOfAgreement" style="overflow: auto">
  Es wurden noch keine Ausleihbedingungen hinterlegt.
</div>
<div style="margin-top: 20px">
  <a class="footer-action-inside" (click)="closeDialog()">Schließen</a>
</div>
</mat-dialog-content>
