<div *ngIf="isVisible()" [ngClass]="cssClass">
  <header class="header">
    <!--<span class="headerIcon" (click)="expanded = !expanded">
      <fa-icon icon="exclamation-triangle"></fa-icon>
    </span>-->
    <h2 class="heading" (click)="toggleExpand()">
			<span class="warning-dialog-container">
				<span class="warning-item" [matTooltip]="getTooltipTotalHigh()">
          <fa-icon icon="exclamation-triangle"></fa-icon>
          {{area.calculation?.riskCountTotalHigh}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalDamageValue()">
          <fa-icon icon="house-damage"></fa-icon>
          {{area.calculation?.riskCountHighDamageValue}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalProbabilityValue()">
          <fa-icon icon="dice"></fa-icon>
          {{area.calculation?.riskCountHighProbabilityValue}}
        </span>
				<span class="warning-item" [matTooltip]="getTooltipTotalNoMeasure()">
          <fa-icon icon="ban"></fa-icon>
          {{area.calculation?.riskCountNoMeasure}}
        </span>
				<span class="warning-item"> / {{area.calculation?.riskCount}}</span>
			</span>
      {{ area?.displayName }}
		</h2>
    <div class="headerControls">
      <button mat-icon-button (click)="openCreateRiskModal($event)" matTooltip="Neues Risiko anlegen">
        <fa-icon icon="plus-square"></fa-icon>
      </button>
      <button mat-icon-button (click)="toggleExpand()"  matTooltip="Untergeordnete Objekte anzeigen">
        <fa-icon [icon]="expanded ? 'angle-up' : 'angle-down'"></fa-icon>
      </button>
    </div>
  </header>
  <ng-container *ngIf="expanded">
    <div class="risks">
      <app-risk-workspace-item
        [risk]="risk"
        [workspaceFilter]="workspaceFilter"
        (riskChange)="updateRisks()"
        (updateParentCalculation)="updateCalculation()"
        *ngFor="let risk of risks; trackBy: trackById"
      ></app-risk-workspace-item>
      <button
        type="button"
        *ngIf="!risks || risks.length === 0"
        class="addRiskButton"
        (click)="openCreateRiskModal($event)"
      >
        Erstes Risiko anlegen
      </button>
    </div>
    <div class="areas">
      <app-area-workspace-item
        [area]="subArea"
        [focus]="focus"
        [workspaceFilter]="workspaceFilter"
        (areaChange)="this.areaChange.emit($event)"
        *ngFor="let subArea of area?.children"
      ></app-area-workspace-item>
    </div>
  </ng-container>
</div>
