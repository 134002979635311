<header id="header" role="banner">
  <h1>
    Verleih-Vorgänge
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>

<main role="main" id="content" class="areas">

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a uiSref="app.deposit.create" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Verleihvorgang anlegen
        </a>
      </div>
      <div class="header-item searchItem size-2-3">
        <input class="overviewHeader-search" type="text" [(ngModel)]="filter.searchText" (ngModelChange)="searchChanged()" placeholder="Nachname, Datum (21.01.2022), Object-Code" />
        <span class="icon-search" (click)="search()">
          <fa-icon icon="search"></fa-icon>
        </span>
        <a class="icon-deleteSearch" *ngIf="filter.searchText" (click)="resetFilter()">
          <fa-icon icon="broom"></fa-icon>
        </a>
        <a class="icon-deleteSearch" *ngIf="!filter.searchText" (click)="openQrScanModal($event)">
          <fa-icon icon="qrcode"></fa-icon>
        </a>
      </div>
      <!--<div class="header-item filterItem">
         <div>
           <a class="overviewHeader-filter">
             <span class="bigIcon">
               <fa-icon icon="filter"></fa-icon>
             </span>
             <span class="placeholder">Liste filtern</span>
           </a>
         </div>
       </div>-->
     </div>
   </header>
  <!-- ### FILTER ### -->
  <div class="filterBar-v01">
		<div class="filter">
			<ul>
				<li [ngClass]="{'active': filter.state === 'ALL'}" (click)="changeFilterState('ALL')">
          <small>Alle</small>
        </li>
				<li [ngClass]="{'active': filter.state === 'BORROWED'}" (click)="changeFilterState('BORROWED')">
          <small><fa-icon [icon]="['fas', 'hand-holding-heart']"></fa-icon> Verliehen</small>
        </li>
        <li [ngClass]="{'active': filter.state === 'RETURNED'}" (click)="changeFilterState('RETURNED')">
          <small><fa-icon icon="inbox"></fa-icon> Zurück</small>
        </li>
        <li [ngClass]="{'active': filter.state === 'ON_HOLD'}" (click)="changeFilterState('ON_HOLD')">
          <small><fa-icon icon="thumbs-down"></fa-icon> Fehlerhaft</small>
        </li>
        <!--<li [ngClass]="{'active': filter.state === 'NO_DELETE'}" (click)="changeFilterState('NO_DELETE')">
          <small><fa-icon icon="thumbs-down"></fa-icon> Nicht löschen</small>
        </li>-->
			</ul>
		</div>
		<div class="filter-icon"><a class="header-item refresh size-square" (click)="search()"><fa-icon icon="sync-alt"></fa-icon></a></div>
	</div>
  <!-- ### FILTER ### -->

  <!-- DEPOSITE LISTE -->
  <div class="container-box-style-v3">

    <div class="item" *ngFor="let deposit of deposits">

			<div class="icon"  (click)="openEditModal(deposit, $event)">
				<span class="graphic">
					<span class="status singleStatus">
						<fa-icon [icon]="['fas', 'hand-holding-heart']" *ngIf="deposit.state === 'BORROWED'"></fa-icon>
            <fa-icon icon="inbox" *ngIf="deposit.state === 'RETURNED'"></fa-icon>
            <fa-icon icon="thumbs-down" *ngIf="deposit.state === 'ON_HOLD'"></fa-icon>
					</span>
				</span>
        <!--<span class="description"></span>-->
			</div>

			<div class="options-selection">
				<a (click)="openEditModal(deposit, $event)">
					<span class="options-icon"><fa-icon icon="pencil-alt"></fa-icon></span>
				</a>
			</div>

			<div class="details"  (click)="openEditModal(deposit, $event)">
				<span class="primary">
					<strong>{{ deposit.firstName }} {{ deposit.lastName }}</strong>
          <!--<strong ng-if="manager.accountStatus === 'PENDING_ACTIVATION' && !manager.emailInfoErrorText" class="color-system-warning-important"><i class="far fa-pause-circle"></i>&nbsp;{{manager.user.email}}</strong>
          <strong ng-if="manager.emailInfoErrorText" class="error"><i class="fas fa-exclamation-triangle"></i>&nbsp;{{manager.user.email}}</strong>-->
				</span>

				<span class="secondary">
          <span class="subItem">{{ deposit.amountItems }}</span>
          <span class="subItem" *ngIf="deposit.borrowedDate && deposit.state === 'BORROWED'"><fa-icon [icon]="['fas', 'hand-holding-heart']"></fa-icon>&nbsp;{{ deposit.borrowedDate ? (deposit.borrowedDate | date:'dd.MM.yyyy, HH:mm') + ' Uhr' : '-'}}</span>
          <span class="subItem" *ngIf="deposit.returnedDate"><fa-icon icon="inbox"></fa-icon>&nbsp;{{ deposit.returnedDate ? (deposit.returnedDate | date:'dd.MM.yyyy, HH:mm') + ' Uhr' : '-'}}</span>
          <span class="subItem" *ngIf="deposit.onHoldDate"><fa-icon icon="inbox"></fa-icon>&nbsp;{{ deposit.onHoldDate ? (deposit.onHoldDate | date:'dd.MM.yyyy, HH:mm') + ' Uhr' : '-'}}</span>
          <!--<span class="subItem">{{ getStateName(deposit.state) }}</span>-->
          <!--<span class="subItem">{{deposit.id}}</span>-->
				</span>

			</div>

		</div>

    <app-paging [paging]="paging" [pagingSorts]="pagingSorts" (pagingChanged)="search()"></app-paging>
	</div>
  <!-- // DEPOSITE LISTE -->
</main>
