import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Item , ItemSearchData} from '../../item/item';
import { startWith } from 'rxjs/operators';
import { ItemService } from '../../item/item.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ItemState } from '../../item/item-state.enum';
import { DepositService } from '../deposit.service';
import { NGXLogger } from 'ngx-logger';
import {BarcodeFormat} from "@zxing/library";
import {DepositEditModalEditData} from "../deposit-edit-modal/deposit-edit-modal-data";
import {DepositEditModalComponent} from "../deposit-edit-modal/deposit-edit-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {DepositQrScanModalComponent} from "../deposit-qr-scan-modal/deposit-qr-scan-modal.component";
import {Deposit} from "../deposit";
import {DepositConfirmationModalComponent} from "../deposit-confirmation-modal/deposit-confirmation-modal.component";
import {AccountTarget} from "../../account/accountTarget.enum";
import {CreateUserModalMode} from "../../../core/user/create-edit-user/create-edit-user";
import {
  DepositConfirmationModalConfirmationData,
  DepositConfirmationModalData
} from "../deposit-confirmation-modal/deposit-confirmation-modal-data";
import {ClientService} from "../../client/client.service";
import {Client} from "../../client/client";
import {DepositAgreementModalComponent} from "../deposit-agreement-modal/deposit-agreement-modal.component";
import {DepositAgreementModalData} from "../deposit-agreement-modal/deposit-agreement-modal-data";
import {StateService} from "@uirouter/core";

@Component({
  selector: 'app-deposit-create',
  templateUrl: './deposit-create.component.html',
  styleUrls: ['./deposit-create.component.scss'],
})
export class DepositCreateComponent implements OnInit {

  @Input() currentClient!: Client;

  public editFormGroup: FormGroup = this._formBuilder.group({
    firstName: ['', []],
    lastName: ['', []],
    birthday: [null, []],
    idNumber: ['', []],
    street: ['', []],
    plz: ['', []],
    location: ['', []],
    country: ['', []],
    items: [[], []],
    signatureData: [null, []],
    isIdCardFromNetherlands: [false, []],
  });

  public uploadStatePage1: number = 0;
  public uploadStatePage2: number = 0;
  public pending: boolean = false;
  public currentStep: number = 0;
  public isIdCardFromNetherlands: boolean = false;
  public today: Date = new Date();

  depositId?: string;

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;
  readonly itemsControl = new FormControl();
  searchItems: Item[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private itemService: ItemService,
    private depositService: DepositService,
    private stateService: StateService,
    private clientService: ClientService,
    private matDialog: MatDialog,
              private logger: NGXLogger,
  ) {
    this.itemsControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.itemService.search({searchText, state: ItemState.ACTIVE}).then((pagesItems) => {
            let currentCodes = (this.editFormGroup.get('items')?.value as Item[]).map(x => x.code);
            this.searchItems = pagesItems.items.filter(x => !currentCodes.includes(x.code));
          });
        } else {
          this.searchItems = [];
        }
      });

  }

  ngOnInit() {

  }

  public onStepChange(event: any): void {
    if (event.selectedIndex === 4) {
      this.stepper.selectedIndex = 3;
      this.currentStep = 3;
      return;
    }
    this.currentStep = event.selectedIndex;
    this.checkIfCompleted();
    this.scrollToTop();
  }

  checkIfCompleted() {
    this.stepper.steps.get(2)!.completed = this.editFormGroup.get('items')?.value.length > 0;
  }

  scrollToTop() {
    window.scroll(0, 0);
  }

  goToStep(index: number) {
    this.stepper.selectedIndex = index;
    this.currentStep = index;
    if (this.stepper.selected && !this.stepper.selected.completed) this.stepper.selected.completed = false;
    this.scrollToTop();
  }

  editDeposit() {
    this.stateService.go('app.deposit', undefined, {reload: true});
    const data: DepositEditModalEditData = {
      depositId: this.depositId!,
    };

    this.matDialog.open(DepositEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
  }

  resetAll() {
    this.currentStep = 1;
    this.goToStep(0);
    this.editFormGroup = this._formBuilder.group({
      firstName: ['', []],
      lastName: ['', []],
      birthday: [null, []],
      idNumber: ['', []],
      street: ['', []],
      plz: ['', []],
      location: ['', []],
      country: ['', []],
      items: [[], []],
      signatureData: [null, []],
      isIdCardFromNetherlands: [false, []],
    });
    this.stepper.reset();
    this.itemInput.nativeElement.value = '';
    this.uploadStatePage1 = 0;
    this.uploadStatePage2 = 0;
  }

  selectItem(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value as Item;

    this.itemInput.nativeElement.value = '';
    this.editFormGroup.get('items')?.value.push(item);
  }

  removeItem(index: number) {
    this.editFormGroup.get('items')?.value.splice(index, 1);
  }

  openQrScanModal(event: MouseEvent) {

    const dialogRef = this.matDialog.open(DepositQrScanModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
    });
    dialogRef.afterClosed().subscribe((result?: string) => {
      if (result) {
        let searchFilter: ItemSearchData = {
          searchText: result
        }
        this.itemService.search(searchFilter).then((pagesItems) => {
          if (pagesItems.items) {
            let currentCodes = (this.editFormGroup.get('items')?.value as Item[]).map(x => x.code)
            let searchItems = pagesItems.items.filter(x => !currentCodes.includes(x.code))
            if (searchItems.length > 0) {
              this.editFormGroup.get('items')?.value.push(searchItems[0])
            }
          }
        });
      }

    });
  }

  public onSubmit(editFormGroup: FormGroup): void {
    if (this.pending) {
      return;
    }

    let identityDataIncomplete = false;
    let itemsIncomplete = false;
    let signatureIncomplete = false;
    if (!this.editFormGroup.get('firstName')?.value ||
      !this.editFormGroup.get('lastName')?.value ||
      !this.editFormGroup.get('idNumber')?.value ||
      !this.editFormGroup.get('street')?.value ||
      !this.editFormGroup.get('plz')?.value ||
      !this.editFormGroup.get('location')?.value ||
      !this.editFormGroup.get('country')?.value ||
      !this.editFormGroup.get('birthday')?.value) {
      identityDataIncomplete = true
    }

    if (!this.editFormGroup.get('items')?.value || this.editFormGroup.get('items')?.value.length === 0 ) {
      itemsIncomplete = true
    }

    if (!this.editFormGroup.get('signatureData')?.value) {
      signatureIncomplete = true
    }

    if (identityDataIncomplete || itemsIncomplete || signatureIncomplete) {

      const data: DepositConfirmationModalData = {
        identityDataIncomplete,
        itemsIncomplete,
        signatureIncomplete
      };

      const dialogRef = this.matDialog.open(DepositConfirmationModalComponent, {
        width: '750px',
        maxWidth: '100%',
        minHeight: 'calc(100vh - 0)',
        // position: {top: '0', right: '0'},
        data
      });
      dialogRef.afterClosed().subscribe((saveData?: boolean) => {
        if (saveData) {
          this.saveData()
        }
      });

      return
    }

    if (editFormGroup.invalid) {
      this.logger.log(editFormGroup.value, editFormGroup.errors);
      return;
    }

    this.saveData()
  }

  saveData() {
    this.pending = true;

    const {value: editData} = this.editFormGroup;

    this.depositService
      .create(editData)
      .subscribe((depositId) => {
        this.currentStep = 4;
        this.pending = false;
        this.depositId = depositId
      });
  }

  updateSignature(data: any) {
    this.editFormGroup.get('signatureData')?.setValue(data);
  }

  showAgreementModal() {

    const data: DepositAgreementModalData = {
      letterOfAgreement: this.currentClient.letterOfAgreement
    };

    this.matDialog.open(DepositAgreementModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data
    });
  }

  uploadPage(page: number, event: Event) {

    if (page === 1) {
      this.uploadStatePage1 = 1
      if (this.uploadStatePage2 === 1 || this.uploadStatePage2 === 2) {
        this.goToStep(1)
      }
    } else {
      this.uploadStatePage2 = 1
      if (this.uploadStatePage1 === 1 || this.uploadStatePage1 === 2) {
        this.goToStep(1)
      }
    }

    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;

    if (!fileList || !fileList.item(0)) {
      return
    }

    this.depositService.scanImage(fileList, this.editFormGroup.get('isIdCardFromNetherlands')?.value).then((deposit) => {
      if (deposit) {
        if (deposit.firstName) this.editFormGroup.get('firstName')?.setValue(deposit.firstName)
        if (deposit.lastName) this.editFormGroup.get('lastName')?.setValue(deposit.lastName)
        if (deposit.idNumber) this.editFormGroup.get('idNumber')?.setValue(deposit.idNumber)
        if (deposit.street) this.editFormGroup.get('street')?.setValue(deposit.street)
        if (deposit.plz) this.editFormGroup.get('plz')?.setValue(deposit.plz)
        if (deposit.location) this.editFormGroup.get('location')?.setValue(deposit.location)
        if (deposit.country) deposit.country ? this.editFormGroup.get('country')?.setValue(deposit.country) : 'DEUTSCH'
        if (deposit.birthday) this.editFormGroup.get('birthday')?.setValue(deposit.birthday)

        if (page === 1) {
          this.uploadStatePage1 = 2
        } else {
          this.uploadStatePage2 = 2
        }
      } else {
        if (page === 1) {
          this.uploadStatePage1 = 3
        } else {
          this.uploadStatePage2 = 3
        }
      }
    })
  }
}
