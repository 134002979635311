import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UIRouterModule, UIView } from '@uirouter/angular';
import { Location, registerLocaleData } from '@angular/common';
import { MaterialModule } from './material-module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { AppComponent } from './app.component';
import { UiModule } from './layout/ui.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AreaModule } from './common/area/area.module';
import { ClientModule } from './common/client/client.module';
import { CompanyModule } from './common/company/company.module';
import { TagModule } from './common/tag/tag.module';
import { ComplianceModule } from './common/compliance/compliance.module';
import { DashboardModule } from './common/dashboard/dashboard.module';
import { WorkspaceModule } from './common/workspace/workspace.module';
import { MAIN_STATES } from './app.states';
import { ProfileModule } from './common/profile/profile.module';
import { DepositModule } from './common/deposit/deposit.module';
import { AreaEditModalComponent } from './common/area/area-edit-modal/area-edit-modal.component';
import { ComplianceEditModalComponent } from './common/compliance/compliance-edit-modal/compliance-edit-modal.component';
import { RiskEditModalComponent } from './common/risk/risk-edit-modal/risk-edit-modal.component';
import { MeasureEditModalComponent } from './common/measure/measure-edit-modal/measure-edit-modal.component';
import { MeasureTypeModule } from './common/measure-type/measure-type.module';
import { TaskEditModalComponent } from './common/task/task-edit-modal/task-edit-modal.component';
import { environment } from 'src/environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReminderFormGroupModule } from './common/reminder/reminder-form-group/reminder-form-group.module';
import { ValidationModule } from './core/validation/validation.module';
import { PasswordConfirmModalModule } from './core/validation/password-confirm-modal/password-confirm-modal.module';
import { TfaCreateModalComponent } from './core/tfa/tfa-create-modal/tfa-create-modal.component';
import { TfaAuthorizedRequestModalComponent } from './core/tfa/tfa-authorized-request-modal/tfa-authorized-request-modal.component';
import { UploadModule } from './core/upload/upload.module';
import { CreateEditUserComponent } from './core/user/create-edit-user/create-edit-user.component';
import { ChangeRoleModalComponent } from './common/account/change-role-modal/change-role-modal.component';
import { ProgressMeterModule } from './core/progress-meter/progress-meter.module';
import { EvaluationModule } from './common/evaluation/evaluation.module';
import { RevisorModule } from './common/revisor/revisor.module';
import { MeasureRevisionModalComponent } from './common/measure/measure-revision-modal/measure-revision-modal.component';
import { RiskRevisionModalComponent } from './common/risk/risk-revision-modal/risk-revision-modal.component';
import { HistoryModalComponent } from './common/history/history-modal/history-modal.component';
import { CpHistoryComponent } from './common/history/cp-history/cp-history.component';
import { ItemModule } from './common/item/item.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner'
import {SettingsModule} from "./common/settings/settings.module";
import {AttributeModule} from "./common/attribute/attribute.module";
import {CategoryModule} from "./common/category/category.module";
import {TemplateModule} from "./common/template/template.module";
import {AvvModule} from "./common/avv/avv.module";
import {ErrorPageOverviewComponent} from "./common/error-page/error-page-overview/error-page-overview.component";
import {ErrorPageModule} from "./common/error-page/error-page.module";
import {RouterModule} from "@angular/router";

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    AreaEditModalComponent,
    ComplianceEditModalComponent,
    RiskEditModalComponent,
    RiskRevisionModalComponent,
    MeasureEditModalComponent,
    MeasureRevisionModalComponent,
    TaskEditModalComponent,
    TfaCreateModalComponent,
    TfaAuthorizedRequestModalComponent,
    CreateEditUserComponent,
    ChangeRoleModalComponent,
    HistoryModalComponent,
    CpHistoryComponent,
  ],
  imports: [
    AreaModule,
    ClientModule,
    SettingsModule,
    CompanyModule,
    ComplianceModule,
    TagModule,
    DashboardModule,
    WorkspaceModule,
    RevisorModule,
    ProfileModule,
    EvaluationModule,
    UploadModule,
    ValidationModule,
    PasswordConfirmModalModule,
    UiModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MeasureTypeModule,
    DepositModule,
    AvvModule,
    ItemModule,
    AttributeModule,
    CategoryModule,
    TemplateModule,
    ErrorPageModule,
    ZXingScannerModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      disableConsoleLogging: environment.production,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),

    UIRouterModule.forRoot({otherwise: '/avv', states: MAIN_STATES}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'de',
    }),
    FontAwesomeModule,
    HttpClientModule,
    FlexLayoutModule,
    ReminderFormGroupModule,
    ProgressMeterModule,
  ],
  providers: [
    Location,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  // bootstrap: [AppComponent],
  bootstrap: [UIView],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far);
  }
}
