import { Injectable } from '@angular/core';
import { StateService } from '@uirouter/angular';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private stateService: StateService,
  ) {
  }

  goBack(fallbackState: string): void {
    this.stateService.go(fallbackState, {}, {reload: true});
  }
}
