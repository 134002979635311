import {Component, Input, OnInit} from '@angular/core';
import {CategoryModalEditData} from '../category-modal/category-modal-data';
import {EditMode} from '../../../core/edit-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {CategoryService} from '../category.service';
import {CategoryModalComponent} from '../category-modal/category-modal.component';
import {Category, CategorySearchData} from '../category';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {CategoryState} from "../category-state.enum";
import {Paging, PagingHeader, PagingSort} from "../../../core/paging/paging";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";
import {Delegate} from "../../../core/validation/cp-confirm/cp-confirm";

@Component({
  selector: 'app-category-overview',
  templateUrl: './category-overview.component.html',
  styleUrls: ['./category-overview.component.scss'],
})
export class CategoryOverviewComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  categories: Category[] = [];
  paging: Paging = {
    currentPage: 0,
    pages: [],
    perPage: 20,
    sort: 'name',
    sortDirection: 'desc',
    totalItems: 0,
    totalPages: 0,
  };

  pagingSorts: PagingSort[] = [
    {key: 'name', direction: 'asc', displayName: 'Name (absteigend)'},
    {key: 'name', direction: 'desc', displayName: 'Name (aufsteigend)'},
  ];

  modelChanged = new Subject<string>();

  public filter: CategorySearchData = {
    state: CategoryState.ACTIVE
  };

  constructor(
    private categoryService: CategoryService,
    private matDialog: MatDialog,
  ) {
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.search();
      });
  }

  public ngOnInit() {
    this.search();
  }

  searchChanged() {
    this.modelChanged.next();
  }

  search() {
    this.categoryService.search(this.filter, this.paging).then((pagesCategories) => {
      this.categories = pagesCategories.items;
    });
  }

  openCreateModal(event: MouseEvent) {
    const data: CategoryModalEditData = {
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(CategoryModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((category?: Category) => {
      this.search();
    });
  }

  changeFilterState(filterState: string | null) {
    if (!filterState) {
      this.filter.state = null
    } else {
      this.filter.state = CategoryState[filterState as keyof typeof CategoryState];
    }

    this.search();
  }
  resetFilter() {
    this.filter.searchText = undefined;
    this.search();
  }

  searchAction(): Delegate<Promise<boolean>> {
    return () => new Promise<boolean>((resolve, reject) => {
      this.search();
      resolve(true);
    })
  }
}
