import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {

  constructor() {
  }

  isExpanded(id: string): boolean {
    const openWorkspace = JSON.parse(localStorage.getItem('openWorkspace') || '[]');
    return openWorkspace.indexOf(id) !== -1;
  }

  setExpanded(id: string, expanded: boolean) {
    const openWorkspace = JSON.parse(localStorage.getItem('openWorkspace') || '[]');
    const index = openWorkspace.indexOf(id);

    if (expanded) {
      if (index === -1) {
        openWorkspace.push(id);
      }
    } else {
      if (index !== -1) {
        openWorkspace.splice(index, 1);
      }
    }

    localStorage.setItem('openWorkspace', JSON.stringify(openWorkspace));
  }
}
