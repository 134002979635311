<div [ngClass]="cssClass">
  <header class="header">
    <button type="button" class="headerCheckbox" (click)="toggleSecured()" matTooltip="Risiko als sichergestellt markieren">
      <fa-icon icon="check" *ngIf="risk.secured"></fa-icon>
    </button>
    <div class="main">
      <h4 class="heading" (click)="openEditModal($event)">
       <span *ngIf="risk.companyRisk" class="companyRisk"><fa-icon [icon]="['fas', 'bomb']"></fa-icon></span><span>{{ risk?.displayName }}</span>
      </h4>
    </div>
    <div class="headerControls">
      <button mat-icon-button (click)="openCreateMeasureModal($event)" matTooltip="Maßnahme erstellen">
        <fa-icon icon="plus-square"></fa-icon>
      </button>
      <button mat-icon-button (click)="toggleExpand()" matTooltip="Untergeordnete Objekte anzeigen">
        <fa-icon [icon]="expanded ? 'angle-up' : 'angle-down'"></fa-icon>
      </button>
    </div>
    <app-progress-meter
      class="headerProgressMeter"
      [start]="risk.damageValue * risk.probabilityValue"
      [end]="risk.calculatedRiskDamageMax"
      [value]="risk.calculatedRiskDamage"
      [type]="progressMeterType.MAIN"
    ></app-progress-meter>
  </header>
  <div class="body">
    <div class="progressMeters">
      <div class="progressMeterWithIcon" matTooltip="Risiko">
        <fa-icon class="icon" icon="house-damage"></fa-icon>
        <app-progress-meter
          class="progressMeter"
          [start]="risk.damageValue"
          [end]="risk.calculatedDamageMax"
          [value]="risk.calculatedDamage"
          [type]="progressMeterType.SUB"
        ></app-progress-meter>
      </div>
      <div class="progressMeterWithIcon" matTooltip="Eintrittswahrscheinlichkeit">
        <fa-icon class="icon" icon="dice"></fa-icon>
        <app-progress-meter
          class="progressMeter"
          [start]="risk.probabilityValue"
          [end]="risk.calculatedProbabilityMax"
          [value]="risk.calculatedProbability"
          [type]="progressMeterType.SUB"
        ></app-progress-meter>
      </div>
    </div>

    <div class="tags">
      <span class="a-tag" *ngIf="risk.riskAccounts?.length">
        <fa-icon icon="users" class="a-tag__icon"></fa-icon>
        <ng-container
          *ngFor="
            let riskAccount of risk.riskAccounts;
            let last = last;
            trackBy: trackById
          "
        >
          <span
            class="a-tag__partial"
            [class.-underline]="isResponsible(riskAccount.role)"
            >{{ riskAccount.account?.firstName }}
            {{ riskAccount.account?.lastName }}</span
          ><ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
      <span class="a-tag" *ngIf="risk.riskReminders?.length">
        <fa-icon icon="bell" class="a-tag__icon"></fa-icon>
        <ng-container
          *ngFor="let reminder of risk.riskReminders; let last = last"
        >
          <span
            class="a-tag__partial"
            [class.-italic]="isPrivate(reminder.reminderType)"
          >
            {{ reminder.date | date: 'E, dd.MM.y HH:mm' }}</span
          ><ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
      <span class="a-tag" *ngIf="risk.damageTypes?.length">
        <fa-icon icon="bolt" class="a-tag__icon"></fa-icon>
        {{ risk.damageTypes | damageTypesCommaSeparated }}
      </span>
    </div>
    <div class="measures" *ngIf="expanded">
      <app-measure-workspace-item
        [measure]="measure"
        [riskId]="risk.id"
        [workspaceFilter]="workspaceFilter"
        (measureChange)="updateMeasures($event)"
        *ngFor="let measure of measures; trackBy: trackById"
      ></app-measure-workspace-item>
      <button
        type="button"
        *ngIf="!measures || measures.length === 0"
        class="addMeasureButton"
        (click)="openCreateMeasureModal($event)"
      >
        Erste Maßnahme anlegen
      </button>
    </div>
  </div>
</div>
