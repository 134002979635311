import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Compliance } from '../../compliance/compliance';
import { WorkspaceFocus } from '../../workspace/workspace.focus';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { WorkspaceFilterType } from '../../workspace/workspace.filter-type.enum';
import { IdObjectService } from '../../../core/id-object.service';
import { Area } from '../../area/area';
import { RiskEditModalCreateData } from '../../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../../risk/risk-edit-modal/risk-edit-modal.component';
import { Risk } from '../../risk/risk';
import { ComplianceService } from '../../compliance/compliance.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { EvaluationFilter } from '../evaluation.filter';
import { DashboardFilter } from '../../dashboard/dashboard.filter';
import {
  DashboardEvaluation,
  DashboardEvaluationCompany, DashboardEvaluationCompliance,
  DashboardEvaluationComplianceByClient,
} from '../../dashboard/dashboardEvaluation';

@Component({
  selector: 'app-evaluation-sidenav',
  templateUrl: './evaluation-sidenav.component.html',
  styleUrls: ['./evaluation-sidenav.component.scss'],
})
export class EvaluationSidenavComponent implements OnInit {
  @Input() evaluationFilter!: EvaluationFilter;
  @Input() dashboardEvaluation!: DashboardEvaluation;
  @Input() companies?: DashboardEvaluationCompany[] | null = null;
  @Output() evaluationFilterChanged = new EventEmitter<EvaluationFilter>();

  expandedCompliance: string = '';
  focus: WorkspaceFocus = {compliance: undefined, area: undefined};
  workspaceFilter: WorkspaceFilter = {};
  workspaceFilterType = WorkspaceFilterType;
  compliances: DashboardEvaluationCompliance[] | undefined = [];

  public trackById = IdObjectService.trackById;

  constructor(
    private complianceService: ComplianceService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.updateCompliances();
  }

  updateCompliances() {
    const complianceNames = [];
    const newCompliances = [];

    console.log('** updateCompliances by filter:', this.evaluationFilter);

    for (const company of this.dashboardEvaluation.companies) {
      for (const compliance of company.compliances) {

        if (complianceNames.indexOf(compliance.complianceName) === -1) {
          complianceNames.push(compliance.complianceName);
          newCompliances.push(compliance);
        }
      }
    }

    this.compliances = newCompliances;
    //
    // if (this.evaluationFilter.companyId) {
    //   this.compliances = this.dashboardEvaluation.companies.find(c => c.id === this.evaluationFilter.companyId)?.compliances;
    //
    // } else {
    //   this.compliances = [];
    // }
  }

  setCompanyFilter(companyId?: string) {
    this.evaluationFilter.companyId = companyId;
    this.evaluationFilterChanged.emit();
    // this.updateCompliances();
  }

  selectCompliance(compliance: DashboardEvaluationCompliance) {
    if (compliance) {
      if (this.evaluationFilter.complianceName === compliance.complianceName) {
        this.evaluationFilter.complianceName = undefined;
      } else {
        this.evaluationFilter.complianceName = compliance.complianceName;
      }

    } else {
      this.evaluationFilter.complianceName = undefined;
    }

    this.evaluationFilterChanged.emit();
  }

  setFilterType(filterType: WorkspaceFilterType) {
    if (this.workspaceFilter.filterType === filterType) {
      this.workspaceFilter.filterType = undefined;

    } else {
      this.workspaceFilter.filterType = filterType;
    }
    this.filterChanged();
  }

  openCreateRiskModal(area: Area, event: MouseEvent) {
    const data: RiskEditModalCreateData = {
      areaId: area.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        //this.updateCompliances();
      }
    });
  }

  clearFocus() {
    this.focus = {compliance: undefined, area: undefined};
  }

  filterChanged() {
    localStorage.setItem('workspaceFilter', JSON.stringify(this.workspaceFilter));
    //this.updateCompliances();
  }

  resetFilter(types?: string[]) {
    if (!types) {
      this.workspaceFilter = {};
    } else if (types.indexOf('searchText') !== -1) {
      this.workspaceFilter.searchText = undefined;

    } else if (types.indexOf('filterType') !== -1) {
      this.workspaceFilter.filterType = undefined;
    }
    this.filterChanged();
  }
}
