<header id="header" role="banner">
	<h1>Menü
    <small class="hideInsideApp">Alle Optionen</small>
	</h1>
	<a (click)="closeMenuPage()" class="header-icon header-icon-right menueCloseIcon" title="Show/Hide Menue">
    <fa-icon icon="times"></fa-icon>
  </a>
</header>

<main role="main" id="content">

  <ul class="mainNavNew-sortable">
		<li class="mainPage mobileLeft" uiSrefActive="active">
      <a uiSref="app.avv">
        <span class="mainNav-icon">
          <fa-icon icon="exchange-alt"></fa-icon>
        </span>
        <span class="mainNav-title ng-binding">AVVs</span>
        <span class="mainNav-subtitle">Erstellen & bearbeiten</span>
      </a>
    </li>
<!--    <li class="mainPage" uiSrefActive="active">-->
<!--      <a uiSref="app.client">-->
<!--        <span class="mainNav-icon">-->
<!--          <fa-icon icon="building"></fa-icon>-->
<!--        </span>-->
<!--        <span class="mainNav-title ng-binding">Agentur</span>-->
<!--        <span class="mainNav-subtitle">Grundeinstellungen</span>-->
<!--      </a>-->
<!--    </li>-->
	</ul>


	<div class="userDetails-modal">
		<a class="user-edit" uiSref="app.profile">
            <span class="user-image"><img src="/img/defaults/noImage-default.jpg" alt="" /></span>
			<span class="user-info">
				<span class="user-title">{{ authInfo?.account?.firstName }} {{ authInfo?.account?.lastName }}</span>
				<span class="user-subtitle">{{ authInfo?.company?.displayName }}</span>
				<span class="user-edit"><i class="fa fa-pencil-alt"></i> Bearbeiten</span>
			</span>
		</a>

		<div class="changeRole-Logout">
			<a class="user-changeRole" (click)="openChangeRoleModal()">
				<span class="user-changeRole-icon"><i class="fas fa-theater-masks"></i></span>
				<span class="user-changeRole-title">Rolle wechseln</span>
			</a>
			<a href="/logout" target="_self" class="user-logout" target="_self"
        style="margin-left: 0px !important;">
				<span class="user-logout-icon"><i class="fa fa-power-off"></i></span>
				<span class="user-logout-title">Ausloggen</span>
			</a>
		</div>
	</div>
</main>
<!-- ng-click="vm.menuClicked()" -->
