import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeasureRevisionModalData } from '../../measure/measure-revision-modal/measure-revision-modal-data';
import { NGXLogger } from 'ngx-logger';
import { FormBuilder } from '@angular/forms';
import { MeasureService } from '../../measure/measure.service';
import { HistoryModalData } from './history-modal-data';
import { HistoryService } from '../history.service';
import { HistoryFilter } from '../history-filter';
import { History } from '../history';

@Component({
  selector: 'app-history-modal',
  templateUrl: './history-modal.component.html',
  styleUrls: ['./history-modal.component.scss'],
})
export class HistoryModalComponent implements OnInit {

  private historyFilter: HistoryFilter;
  public entries: History[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: HistoryModalData,
    public matDialogRef: MatDialogRef<HistoryModalComponent>,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private historyService: HistoryService,
  ) {
    this.historyFilter = {
      module: data.module,
      entityId: data.entityId,
      field: data.field,
      page: 0,
    };
  }

  ngOnInit(): void {
    this.historyService.listUrl(this.historyFilter).subscribe((entries) => {
      this.entries = entries;
    });
  }

  public closeModal(): void {
    this.matDialogRef.close({});
  }
}
