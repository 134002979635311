import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { MatDialog } from '@angular/material/dialog';
import { Deposit, DepositSearchData } from '../deposit';
import { DepositEditModalEditData } from '../deposit-edit-modal/deposit-edit-modal-data';
import { DepositEditModalComponent } from '../deposit-edit-modal/deposit-edit-modal.component';
import { DepositService } from '../deposit.service';
import { DepositFilterState } from '../deposit-filter-state.enum';
import { Paging, PagingHeader, PagingSort } from '../../../core/paging/paging';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {DepositQrScanModalComponent} from "../deposit-qr-scan-modal/deposit-qr-scan-modal.component";
import {Item, ItemSearchData} from "../../item/item";

@Component({
  selector: 'app-deposit-overview',
  templateUrl: './deposit-overview.component.html',
  styleUrls: ['./deposit-overview.component.scss'],
})
export class DepositOverviewComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  deposits: Deposit[] = [];
  paging: Paging = {
    currentPage: 0,
    pages: [],
    perPage: 20,
    sort: 'borrowedDate',
    sortDirection: 'desc',
    totalItems: 0,
    totalPages: 0,
  };

  pagingSorts: PagingSort[] = [
    {key: 'borrowedDate', direction: 'asc', displayName: 'Ausleihdatum (älteste zuerst)'},
    {key: 'borrowedDate', direction: 'desc', displayName: 'Ausleihdatum (neuste zuerst)'},
    {key: 'returnedDate', direction: 'asc', displayName: 'Rückgabedatum (älteste zuerst'},
    {key: 'returnedDate', direction: 'desc', displayName: 'Rückgabedatum (neuste zuerst)'},
  ];

  public filter: DepositSearchData = {
    state: DepositFilterState.ALL,
  };

  modelChanged = new Subject<string>();

  constructor(
    private fb: FormBuilder,
    private depositService: DepositService,
    private matDialog: MatDialog,
  ) {
    const depositFilterStr = sessionStorage.getItem('depositFilter');
    if (depositFilterStr) {
      this.filter = JSON.parse(depositFilterStr);
    } else {
      this.filter = {
        state: DepositFilterState.ALL,
      };
    }
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.search();
      });
  }

  public ngOnInit() {
    this.search();
  }

  searchChanged() {
    this.modelChanged.next();
  }

  search() {
    sessionStorage.setItem('depositFilter', JSON.stringify(this.filter));
    console.log('**runsearch', this.paging);
    this.depositService.search(this.filter, this.paging).then((pagesDeposits) => {
      this.updatePaging(pagesDeposits.pagingHeader);
      this.deposits = pagesDeposits.items;
    });
  }

  changeFilterState(filterState: string) {
    this.filter.state = DepositFilterState[filterState as keyof typeof DepositFilterState];
    this.search();
  }

  getStateName(filterState: string): string {
    if (filterState === 'RETURNED') {
      return 'Zurückgegeben';
    } else if (filterState === 'ON_HOLD') {
      return 'Fehlerhaft';
    }
    return 'Verliehen';
  }

  openEditModal(deposit: Deposit, event: MouseEvent) {
    const data: DepositEditModalEditData = {
      depositId: deposit.id,
    };

    const dialogRef = this.matDialog.open(DepositEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.search();
    });
  }

  resetFilter() {
    this.filter.searchText = undefined;
    this.search();
  }

  openQrScanModal(event: MouseEvent) {

    const dialogRef = this.matDialog.open(DepositQrScanModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
    });
    dialogRef.afterClosed().subscribe((result?: string) => {
      if (result) {
        this.filter.searchText = result;
        this.search()
      }
    });
  }

  private updatePaging(pagingHeader: PagingHeader | undefined) {
    if (pagingHeader) {
      this.paging.perPage = pagingHeader.perPage;
      this.paging.totalItems = pagingHeader.totalItems;
      this.paging.currentPage = pagingHeader.currentPage;
      this.paging.totalPages = Math.ceil(pagingHeader.totalItems / pagingHeader.perPage);

      console.log('paging updated', this.paging);
      const pages: number[] = [];
      for (let i = 0; i < this.paging.totalPages; i++) {
        pages.push(i);
      }
      this.paging.pages = pages;
    }
  }
}
