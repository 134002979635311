import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileInfosComponent } from './profile-infos/profile-infos.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { ProfileSecurityComponent } from './profile-security/profile-security.component';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PROFILE_STATES } from './profile.states';
import { COMPLIANCE_STATES } from '../compliance/compliance.states';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppModule } from '../../app.module';
import { ValidationModule } from '../../core/validation/validation.module';

@NgModule({
  declarations: [
    ProfileInfosComponent,
    ProfileSettingsComponent,
    ProfileSecurityComponent,
    ProfileNavComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: PROFILE_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
  ],
})
export class ProfileModule {
}
