import { Pipe, PipeTransform } from '@angular/core';
import { MeasureType } from './measure-type';
import { MeasureTypeKind } from './measure-type-kind.enum';

@Pipe({
  name: 'measureType',
})
export class MeasureTypePipe implements PipeTransform {
  transform(
    measureTypes: MeasureType[] | null,
    kind: MeasureTypeKind,
  ): MeasureType[] | null {
    if (!measureTypes) {
      return null;
    }
    return measureTypes.filter((measureType) => measureType.kind === kind);
  }
}
