import { Pipe, PipeTransform } from '@angular/core';
import { IdObject } from 'src/app/core/id-object';
import { DamageType } from './damage-type';

@Pipe({
  name: 'damageTypesCommaSeparated',
})
export class DamageTypesCommaSeparatedPipe implements PipeTransform {
  transform(damageTypes: DamageType[] | IdObject[]): string | null {
    if (!damageTypes) {
      return null;
    }

    return (
      damageTypes.filter((damageType) =>
        damageType.hasOwnProperty('displayName'),
      ) as DamageType[]
    )
      .map((damageType) => damageType.displayName)
      .join(', ');
  }
}
