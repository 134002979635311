import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
} from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-cp-signature',
  templateUrl: './cp-signature.component.html',
  styleUrls: ['./cp-signature.component.scss'],
})
export class CpSignatureComponent implements OnInit, AfterContentInit, AfterViewInit {
  @Output()
  public signatureChanged: EventEmitter<any> = new EventEmitter<any[]>();

  @Input()
  public signature: string | null = null;

  @ViewChild('signCanvas')
  private canvasElement: ElementRef = {} as ElementRef;

  protected canvas: HTMLCanvasElement | null = null;
  protected canvasContainer: HTMLElement | null = null;

  protected signaturePad: SignaturePad | null = null;

  public hasSignature: boolean = false;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initSignature();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['signature'] && !this.signature) {
      this.clearSignature()
    }
  }

  signatureDone = (value: any) => {
    if (this.signaturePad) {
      console.log('**signature done', value);
      const data = this.signaturePad.toDataURL(); // save image as JPEG
      this.signatureChanged.emit(data);
      this.hasSignature = true;
    }
  };

  resizeCanvas() {
    console.log('* resize. Canvas:', this.canvas);
    if (this.canvas && this.signaturePad && this.canvasContainer) {

      const {width, height} = this.canvas.getBoundingClientRect();
      console.log('canvas boundings height', height, 'width', width);


      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      this.canvas.width = this.canvas.offsetWidth * ratio;
      this.canvas.height = this.canvas.offsetHeight * ratio;

      console.log('Canvas height', this.canvas.offsetHeight, 'width', this.canvas.offsetWidth);
      console.log(
        'canvasContainer height',
        this.canvasContainer.offsetHeight,
        'width',
        this.canvasContainer.offsetWidth,
      );

      this.canvas.getContext('2d')?.scale(ratio, ratio);
      this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }
  }

  protected initSignature() {
    if (this.canvasElement) {
      this.canvas = this.canvasElement.nativeElement;
      if (!this.canvas) {
        return;
      }
      this.canvasContainer = this.canvas.parentElement;
      this.signaturePad = new SignaturePad(this.canvas);
      this.signaturePad.addEventListener('endStroke', this.signatureDone, {once: false});
      this.resizeCanvas();

      if (this.signature) {
        this.signaturePad.fromDataURL(this.signature);
      }
    }
  }

  ngAfterContentInit(): void {
    console.log('** after content init');
  }

  clearSignature() {
    this.signaturePad?.clear();
    this.hasSignature = false;
    this.signatureChanged.emit(null);
  }
}
