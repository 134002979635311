import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemModalComponent } from './item-modal/item-modal.component';
import { UIRouterModule } from '@uirouter/angular';
import { ITEM_STATES } from './item.states';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import { ItemOverviewComponent } from './item-overview/item-overview.component';
import {PagingModule} from "../../core/paging/paging.module";
import {UploadModule} from "../../core/upload/upload.module";
import {
  CategoryAddAttributesModalComponent
} from "./category-add-attributes-modal/category-add-attributes-modal.component";

@NgModule({
  declarations: [
    ItemOverviewComponent,
    ItemModalComponent,
    CategoryAddAttributesModalComponent
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: ITEM_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    PagingModule,
    UploadModule,
  ],
})
export class ItemModule {
}
