import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DepositDeleteModalData } from './deposit-delete-modal';

@Component({
  selector: 'app-cp-confirm-modal',
  templateUrl: './deposit-delete-modal.component.html',
  styleUrls: ['./deposit-delete-modal.component.scss'],
})
export class DepositDeleteModalComponent implements OnInit {
  pending: boolean = false;
  retypeDeleteKeyword: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    check: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DepositDeleteModalData,
    public matDialogRef: MatDialogRef<DepositDeleteModalComponent>,
  ) {

  }

  ngOnInit(): void {

  }

  cancel() {
    if (this.data && this.data.cancelAction) {
      this.data.cancelAction.apply(false);
      this.matDialogRef.close(false);
    }
  }

  proceed() {
    const deleteKeyword: string = 'DELETE'
    if (this.editFormGroup.get('check')?.value !== deleteKeyword) {
      this.retypeDeleteKeyword = true
    } else {
      if (this.data && this.data.proceedAction) {

        const promise: Promise<any> = this.data.proceedAction();
        console.log('** ACTION promise:', promise);

        promise.then((result) => {
          console.log('** ACTION result:', result);
          this.matDialogRef.close(true);

        }, (err) => {
          console.log('** ACTION error:', err);
        });

        // this.data.proceedAction.apply(true).subscribe((result: boolean) => {
        //   console.log('** ACTION result:', result);
        // }, (err: any) => {
        //   console.log('** ACTION error:', err);
        // });
      }
    }

  }
}
