<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Risiko anlegen
  </ng-template>
  <ng-template #editModeText>Risiko bearbeiten</ng-template>
</div>
<form
  novalidate
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup">
  <mat-tab-group animationDuration="0ms" dynamicHeight="false" (selectedTabChange)="tabClick($event)">
    <mat-tab *ngIf="data.revisor">
      <ng-template mat-tab-label>
        <fa-icon icon="user-check"></fa-icon>
        &nbsp;&nbsp;Revision
      </ng-template>

      <div mat-dialog-content *ngIf="risk">
        <h3 class="subHeading">Details</h3>
        <mat-form-field appearance="fill">
          <mat-label>Beschreibung der Prüfungshandlung*</mat-label>
          <textarea
            [readonly]="risk.auditConfirmed"
            matInput
            formControlName="auditPlotDescription"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Beschreibung des Ergebnisses*</mat-label>
          <textarea
            [readonly]="risk.auditConfirmed"
            matInput
            formControlName="auditResultDescription"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <h3 class="subHeading">Zusammenfassung</h3>

        <mat-form-field appearance="fill">
          <mat-label>Ergebnis der Prüfung*</mat-label>
          <mat-select
            [disabled]="risk.auditConfirmed"
            [compareWith]="compareByValue"
            formControlName="auditResult">
            <mat-option
              [value]="auditResult.value"
              *ngFor="let auditResult of auditResultOptions">
              {{ auditResult.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Nächste Prüfung*</mat-label>
          <mat-select
            [disabled]="risk.auditConfirmed"
            [compareWith]="compareByValue"
            formControlName="auditNextDateType">
            <mat-option
              [value]="dateType.value"
              *ngFor="let dateType of auditNextDateOptions">
              {{ dateType.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="editFormGroup.get('auditNextDateType')?.value === 'CUSTOM' && !risk.auditConfirmed">
          <mat-label>Nächste Prüfung*</mat-label>
          <input
            matInput
            placeholder="Nächste Prüfung"
            [matDatepicker]="datePicker"
            formControlName="auditNextDate" />
          <mat-datepicker-toggle matSuffix [for]="$any(datePicker)"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <button
          *ngIf="!risk.auditConfirmed"
          type="submit"
          (click)="updateSubmitType('submitAudit')"
          mat-raised-button
          color="primary"
          class="cta-button"
          [disabled]="pending">
          Prüfung abschließen
        </button>


        <div *ngIf="risk.auditConfirmed">
          <div>
            <label>Nächste Prüfung*</label><br />
            <span>{{risk.auditNextDate | date:'dd.MM.yyyy'}}</span>
          </div>

          <div>
            <h3 class="subHeading">Prüfung</h3>
            Durchgeführt am {{risk.auditLastDate | date:'dd.MM.yyyy'}} von {{risk.auditLastUser.firstName}}&nbsp;{{risk.auditLastUser.lastName}}
          </div>
        </div>
        <button
          *ngIf="risk.auditConfirmed"
          type="button"
          (click)="restartAudit()"
          mat-raised-button
          color="primary"
          class="cta-button"
          [disabled]="pending">
          Neue Revision starten
        </button>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="info-circle"></fa-icon>
        &nbsp;&nbsp;Allgemeines
      </ng-template>

      <div mat-dialog-content>

        <!-- STATUS -->
        <div class="conclusionContainer" style="padding-top: 20px">
          <!--<h2>Aktueller Status</h2>-->
          <fa-icon class="icon" icon="exclamation-triangle"></fa-icon>
          <app-progress-meter
            *ngIf="risk"
            class="headerProgressMeter"
            [start]="risk.damageValue * risk.probabilityValue"
            [end]="risk.calculatedRiskDamageMax"
            [value]="risk.calculatedRiskDamage"
            [type]="progressMeterType.MAIN"
          ></app-progress-meter>

          <div class="progressMeters">
            <div class="progressMeterWithIcon" matTooltip="Risiko">
              <fa-icon class="icon" icon="house-damage"></fa-icon>
              <app-progress-meter
                *ngIf="risk"
                class="progressMeter"
                [start]="risk.damageValue"
                [end]="risk.calculatedDamageMax"
                [value]="risk.calculatedDamage"
                [type]="progressMeterType.SUB"
              ></app-progress-meter>
            </div>
            <div class="progressMeterWithIcon" matTooltip="Eintrittswahrscheinlichkeit">
              <fa-icon class="icon" icon="dice"></fa-icon>
              <app-progress-meter
                *ngIf="risk"
                class="progressMeter"
                [start]="risk.probabilityValue"
                [end]="risk.calculatedProbabilityMax"
                [value]="risk.calculatedProbability"
                [type]="progressMeterType.SUB"
              ></app-progress-meter>
            </div>
          </div>

         </div>
        <!-- // STATUS -->

        <mat-form-field appearance="fill">
          <mat-label>Risiko/Frage*</mat-label>
          <textarea
            matInput
            formControlName="displayName"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Beschreibung</mat-label>
          <textarea
            matInput
            formControlName="description"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <h3 class="subHeading">Schaden</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Schaden</mat-label>
              <input
                matInput
                type="number"
                step="0.01"
                formControlName="damageValueEuro"
              />
              <span matSuffix>EUR</span>
            </mat-form-field>
            <small class="legende" matTooltip="Aktualisierung erfolgt nach dem Speichern // Die Berechnung der Punkte erfolgt auf Basis von 80% der kritischen Unternehmensgröße. // Der Maximale Wert beträgt 100 Punkte.">
              <b>Punkte: {{risk?.damageValue}}&nbsp;<fa-icon icon="info-circle"></fa-icon></b>
            </small>
          </div>
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Eintrittswahrscheinlichkeit</mat-label>
              <mat-select
                [compareWith]="compareByValue"
                formControlName="probabilityValue"
              >
                <mat-option
                  [value]="probabilityValue.value"
                  *ngFor="let probabilityValue of probabilityValueOptions"
                >
                  {{ probabilityValue?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <small class="legende"><b>Punkte: {{ editFormGroup.get('probabilityValue')?.value }}</b></small>
          </div>
        </div>

        <mat-form-field appearance="fill">
          <mat-label>Schadensarten</mat-label>
          <mat-select
            [multiple]="true"
            [compareWith]="compareById"
            formControlName="damageTypes"
          >
            <mat-option
              [value]="damageType"
              *ngFor="let damageType of damageTypeOptions$ | async"
            >
              {{ damageType?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox formControlName="companyRisk">
          Unternehmenskritisch
          <br><small>Auch wenn die Eintrittswahrscheinlichkeit sehr gering ist</small>
        </mat-checkbox>


        <h3 class="subHeading">Risiko Status</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <div class="checkboxes">
              <mat-checkbox formControlName="secured"
              >Dieses Risiko ist sichergestellt</mat-checkbox
              >
            </div>
          </div>
          <div class="gridItem giw-50">
            <div class="checkboxes">
              <mat-checkbox formControlName="ignored"
              >Dieses Risiko hat keine Relevanz</mat-checkbox
              >
            </div>
          </div>
        </div>

        <mat-form-field appearance="fill">
          <mat-label>Kommentar zum Status</mat-label>
          <textarea
            matInput
            formControlName="stateNote"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="sliders-h"></fa-icon>
        &nbsp;&nbsp;Details
      </ng-template>

      <div mat-dialog-content>
        <mat-form-field appearance="fill">
          <mat-label>Region</mat-label>
          <mat-select
            [multiple]="true"
            [compareWith]="compareByValue" Region
            formControlName="legalAreas"
          >
            <mat-option
              [value]="legalArea?.value"
              *ngFor="let legalArea of legalAreaOptions"
            >
              {{ legalArea?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Rechtsgrundlagen</mat-label>
          <mat-select
            [multiple]="true"
            [compareWith]="compareById"
            formControlName="legalBases"
          >
            <mat-option
              [value]="legalBasis"
              *ngFor="let legalBasis of legalBasisOptions$ | async"
            >
              {{ legalBasis?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Standards</mat-label>
          <mat-select
            [multiple]="true"
            [compareWith]="compareById"
            formControlName="norms"
          >
            <mat-option
              [value]="norm"
              *ngFor="let norm of normOptions$ | async"
            >
              {{ norm?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <h3 class="subHeading">Organisatorischer</h3>

        <app-cp-tag-field [editFormGroup]="editFormGroup">

        </app-cp-tag-field>

        <mat-form-field appearance="fill">
          <label>Status</label>
          <mat-select [compareWith]="compareByValue" formControlName="state">
            <mat-option
              [value]="state?.value"
              *ngFor="let state of stateOptions"
            >
              {{ state?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="tasks"></fa-icon>
        &nbsp;&nbsp;Maßnahmen
      </ng-template>

      <div class="infobox">Offene Maßnahmen zu diesem Risiko</div>
      <ul *ngFor="let measure of activeMeasures" class="measureList">
        <li><a (click)="openMeasureDialog(measure)"><span class="icon"><fa-icon [icon]="['far', 'circle']"></fa-icon></span> {{measure.displayName}}</a></li>
      </ul><!--{{measure.state}}-->
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="bell"></fa-icon>
        &nbsp;&nbsp;Reminder
      </ng-template>

      <app-reminder-form-group
        class="-containsTable"
        mat-dialog-content
        formControlName="riskReminders"
      ></app-reminder-form-group>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="users"></fa-icon>
        &nbsp;&nbsp;Benutzer
      </ng-template>

      <div class="-containsTable" mat-dialog-content>
        <table
          mat-table
          [dataSource]="riskAccountsDataSource"
          formArrayName="riskAccounts"
        >
          <tr mat-header-row *matHeaderRowDef="riskAccountColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; let index = index; columns: riskAccountColumns"
          ></tr>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select
                  placeholder="Account"
                  [compareWith]="compareById"
                  (openedChange)="setAccountSelectForRiskAccount($event, index)"
                  formControlName="account"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="accountSearchCtrl"
                      placeholderLabel="Accounts suchen …"
                      noEntriesFoundLabel="'keine Accounts gefunden'"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    [value]="account"
                    *ngFor="
                      let account of isAccountSelectForRiskAccountOpen(index)
                        ? (filteredAccountOptions$ | async)
                        : (accountOptions$ | async)
                    "
                  >
                    {{ account?.firstName }}
                    {{ account?.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Rolle</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select placeholder="Rolle" formControlName="role">
                  <mat-option
                    [value]="role.value"
                    *ngFor="let role of roleOptions"
                  >
                    {{ role.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="controls" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let index = index">
              <a (click)="removeRiskAccountFormGroup(index)">
                <fa-icon icon="trash"></fa-icon>
              </a>
            </td>
          </ng-container>
        </table>

        <a (click)="addRiskAccountFormGroup()" class="addItem-button">
          <fa-icon icon="user-plus"></fa-icon>
          Benutzer zuordnen
        </a>
      </div>
    </mat-tab>
    <mat-tab label="Revisionen" *ngIf="!isCreate">
      <ng-template mat-tab-label>
        <fa-icon icon="user-clock"></fa-icon>
      &nbsp;&nbsp;Revisionen
      </ng-template>

      <div class="infobox" *ngIf="!riskRevisionsLoaded">Lädt...</div>
      <div class="infobox" *ngIf="riskRevisionsLoaded && riskRevisions?.length === 0">Keine Revisionen gefunden</div>
      <ul class="measureList" >
        <li *ngFor="let revision of riskRevisions"><a (click)="openRevisionModal(revision)">
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'EffectiveWithoutComplaint'"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'EffectiveWithComplaint'" ><fa-icon [icon]="['far', 'check-circle']" style="color: orange !important;"></fa-icon></span>
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'Ineffective'"><fa-icon [icon]="['fas', 'ban']" style="color: red !important;"></fa-icon></span>
          Revision vom {{revision.revisionDate | date:'dd.MM.yyyy, HH:mm'}} erstellt durch {{revision.revisionUser.firstName}}&nbsp;{{revision.revisionUser.lastName}}
        </a></li>
      </ul>
    </mat-tab>
  </mat-tab-group>
  <footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button
      type="submit"
      (click)="updateSubmitType('submit')"
      mat-raised-button
      color="primary"
      [disabled]="pending">
      Sichern
    </button>
  </footer>
</form>
