import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdObject } from 'src/app/core/id-object';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { Account, AccountDraft, AccountSearchData } from './account';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}account`;
  private static singleUrl = (apiUrl: string, id: string): string => `${apiUrl}account/${id}`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}account/search`;
  private static resendConfirmationUrl = (
    apiUrl: string,
    id: string,
  ): string => `${apiUrl}account/${id}/actions/resendConfirmation`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Account[]> {
    const {apiUrl} = environment;
    const url = AccountService.listUrl(apiUrl);

    return this.httpClient
      .get<Account[]>(url)
      .pipe(catchError(this.handleError<Account[]>('getList', [])));
  }

  public getSingle(id: string): Observable<Account> {
    const {apiUrl} = environment;
    const url = AccountService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Account>(url)
      .pipe(catchError(this.handleError<Account>('getSingle')));
  }

  public create(userDraft: AccountDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = AccountService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<IdObject>(url, userDraft, {headers}).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('create')),
    );
  }

  public resendConfirmationUrl(id: string, data: any): Observable<string> {
    const {apiUrl} = environment;
    const url = AccountService.resendConfirmationUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    console.log('** POST TO ', url);

    return this.httpClient.post<any>(url, data, {headers})
      .pipe(
        map((a) => a),
        catchError(this.handleError<string>('resendConfirmationUrl')),
      );
  }

  public patch(id: string, userDraft: AccountDraft): Observable<boolean> {
    const {apiUrl} = environment;
    const url = AccountService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<void>(url, userDraft, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = AccountService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('remove', false)),
    );
  }

  public search(userSearchData: AccountSearchData): Observable<Account[]> {
    const {apiUrl} = environment;
    const url = AccountService.searchUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient
      .post<Account[]>(url, userSearchData, {headers})
      .pipe(catchError(this.handleError<Account[]>('search', [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${AccountService.name}->${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
