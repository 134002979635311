import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskWorkspaceItemComponent } from './task-workspace-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { ProgressModule } from 'src/app/core/progress/progress.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [TaskWorkspaceItemComponent],
  imports: [CommonModule, FontAwesomeModule, MatButtonModule, ProgressModule, MatTooltipModule,],
  exports: [TaskWorkspaceItemComponent],
})
export class TaskWorkspaceItemModule {}
