import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-company-widget',
  templateUrl: './company-widget.component.html',
  styleUrls: ['./company-widget.component.scss'],
})
export class CompanyWidgetComponent {
  @HostBinding('class') string = 'o-widget';

  @Input() company: any;

  constructor() { }
}
