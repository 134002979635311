<div mat-dialog-title>Bitte wähle einen Verleihvorgang</div>

<div mat-dialog-content>
  <!-- Item List -->
  <div class="cp-gui-list-v01">
    <div class="item-list">
    <div class="itemList__entry" *ngFor="let deposit of deposits" (click)="selectDeposit(deposit)">
      <div class="item noIcon">
        <div class="options-selection">
          <a>
            <span class="options-icon" (click)="selectDeposit(deposit)"><fa-icon icon="chevron-right"></fa-icon></span>
          </a>
        </div>

        <div class="details">
      <span class="primary">
        <strong>{{ deposit.firstName }} {{ deposit.lastName }}</strong>
      </span>
          <span class="secondary">
        <span class="subItem">{{ deposit.plz }}</span>
      </span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close (click)="closeDialog()">Abbrechen</button>
</footer>
