import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';
import { HistoryFilter } from './history-filter';
import { History } from './history';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  private static listUrl = (apiUrl: string): string =>
    `${apiUrl}history?perPage=10`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public listUrl(historyFilter: HistoryFilter): Observable<History[]> {
    const {apiUrl} = environment;
    const url = HistoryService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<History[]>(url, historyFilter, {headers})
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
