<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Objekt anlegen
  </ng-template>
  <ng-template #editModeText>
    <span *ngIf="item?.displayName">{{item?.displayName}}</span>
    <span *ngIf="!item?.displayName">{{item?.code}}</span>
  </ng-template>
</div>
<form
  novalidate
  (ngSubmit)=" onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>

  <mat-tab-group animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="gear"></fa-icon>
          &nbsp;&nbsp;Stammdaten
        </ng-template>

        <div mat-dialog-content>
<div style="padding: 20px 10px">
          <mat-form-field appearance="fill" *ngIf="item" style="width: 100%;">
            <mat-label>Code - Autom. erstellt</mat-label>
            <input matInput type="text" [value]="item.code" readonly />
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Bezeichnung (optional)</mat-label>
            <input matInput type="text" formControlName="displayName" />
          </mat-form-field>

          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Beschreibung</mat-label>
            <textarea matInput formControlName="description"></textarea>
          </mat-form-field>

          <h3 class="subHeading">Bild</h3>
          <div>
            <cp-upload (uploadFinished)="standardImageAssetUploadFinished($event)" [multiple]="false" [type]="'DYNAMIC_IMAGE'" *ngIf="item">
              <div class="imageGallery-v01">

                <div *ngIf="item.standardImage" class="galleryItem">
                  <div class="options">
                    <a (click)="removeStandardImage()" class="delete"><fa-icon icon="trash"></fa-icon></a>
                    <a target="_blank" [href]="getOriginalStandardImageAssetUrl()" class="download" download><fa-icon icon="download"></fa-icon></a>
                  </div>
                  <div class="preview"><img [src]="getStandardImageAssetUrl()"/></div>
                </div>

              </div>

              <!--<div *ngIf="!item.standardImage">
                Bitte Datei hochladen
              </div>-->
            </cp-upload>

          </div>




</div>
        </div>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="gear"></fa-icon>
          &nbsp;&nbsp;Dynamische Attribute
        </ng-template>

        <div mat-dialog-content>

          <section class="subView-steps">
            <!-- Liste Add / Remove -->
            <div class="cp-gui-item">
              <div class="cp-gui-list-v01" style="margin-top: -20px">
                <div class="add-items-header">
                  <div class="search" style="padding-right: 140px">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Attribut hinzufügen</mat-label>
                      <input type="text"
                        placeholder="Code, Bezeichnung"
                        aria-label="Number"
                        matInput
                        #attributeInput
                        #trigger="matAutocompleteTrigger"
                        [formControl]="attributesControl"
                        [matAutocomplete]="attributeAutocomplete">
                      <mat-autocomplete
                        #attributeAutocomplete="matAutocomplete"
                        (optionSelected)="selectAttribute($event)"
                      >
                        <mat-option *ngFor="let attribute of searchAttributes" [value]="attribute">
                          <span>
                            <span *ngIf="attribute?.type === 'TEXT_ONE_LINE'" matTooltip="Einzeiliger Text"><fa-icon icon="font"></fa-icon></span>
                            <span *ngIf="attribute?.type === 'TEXT_MULTI_LINE'" matTooltip="Mehrzeiliger Text"><fa-icon icon="align-left"></fa-icon></span>
                            <span *ngIf="attribute?.type === 'NUMBER'" matTooltip="Nummer/Zahl">123</span>
                            <span *ngIf="attribute?.type === 'PRICE'" matTooltip="Preis"><fa-icon icon="euro-sign"></fa-icon></span>
                            <span *ngIf="attribute?.type === 'DATE'" matTooltip="Datum"><fa-icon icon="calendar-day"></fa-icon></span>
                            <span *ngIf="attribute?.type === 'FILE'" matTooltip="Datei"><fa-icon icon="upload"></fa-icon></span>
                            &nbsp;| {{attribute.key}}<small *ngIf="attribute.description"> / {{attribute.description}}</small></span>
                          <!-- <span><fa-icon icon="file-alt"></fa-icon> | {{templates.name}}<small *ngIf="template.description"> / {{template.description}}</small></span> -->
                          <!-- <span><fa-icon icon="sitemap"></fa-icon> | {{category.name}}<small *ngIf="category.description"> / {{category.description}}</small></span> -->
                        </mat-option>
                        <mat-option *ngFor="let category of searchAttributeCategories" [value]="category">
                          <span><fa-icon icon="sitemap"></fa-icon> | {{category.name}}<small *ngIf="category.description"> / {{category.description}}</small></span>
                          <!-- <span><fa-icon icon="file-alt"></fa-icon> | {{templates.name}}<small *ngIf="template.description"> / {{template.description}}</small></span> -->
                          <!-- <span><fa-icon icon="sitemap"></fa-icon> | {{category.name}}<small *ngIf="category.description"> / {{category.description}}</small></span> -->
                        </mat-option>
                        <mat-option *ngFor="let template of searchTemplates" [value]="template">
                          <span><fa-icon icon="file-alt"></fa-icon> | {{template.name}}<small *ngIf="template.description"> / {{template.description}}</small></span>
                          <!-- <span><fa-icon icon="file-alt"></fa-icon> | {{templates.name}}<small *ngIf="template.description"> / {{template.description}}</small></span> -->
                          <!-- <span><fa-icon icon="sitemap"></fa-icon> | {{category.name}}<small *ngIf="category.description"> / {{category.description}}</small></span> -->
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <a class="scan" (click)="listAllSearchItems($event, trigger)" matTooltip="Zeige alle Attribute/Kategorien/Templates" style="right: 70px;">
                    <fa-icon icon="list-ul"></fa-icon>
                  </a>
                  <a (click)="openCreateModal($event)" class="scan"><small>Neu</small></a>
                </div>
                <!-- Item List -->
                <div class="item-list-v2">
                  <div class="itemList__entry" *ngFor="let attribute of editFormGroup.get('attributes')?.value; index as index">

                    <div class="item">
                      <div class="options-selection">
                        <a>
                          <span class="options-icon" (click)="removeAttribute(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                        </a>
                      </div>
                      <div class="icon">
                        <span *ngIf="attribute?.type === 'TEXT_ONE_LINE'" matTooltip="Einzeiliger Text"><fa-icon icon="font"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'TEXT_MULTI_LINE'" matTooltip="Mehrzeiliger Text"><fa-icon icon="align-left"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'NUMBER'" matTooltip="Nummer/Zahl">123</span>
                        <span *ngIf="attribute?.type === 'PRICE'" matTooltip="Preis"><fa-icon icon="euro-sign"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'DATE'" matTooltip="Datum"><fa-icon icon="calendar-day"></fa-icon></span>
                        <span *ngIf="attribute?.type === 'FILE'" matTooltip="Datei"><fa-icon icon="upload"></fa-icon></span>
                      </div>

                      <div class="details">

                        <span class="primary">
                          <strong>{{ attribute?.key }}</strong>
                        </span>
                        <span class="secondary"  *ngIf="attribute?.description">
                          <span class="subItem">{{ attribute?.description }}</span>
                        </span>

                      </div>

                      <div class="details">
                        <div *ngIf="attribute?.type === 'TEXT_ONE_LINE'">
                          <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                            <!--<mat-label>Wert (einzeilig)</mat-label>-->
                            <input matInput type="text" [(ngModel)]="attribute.oneLineValue" [ngModelOptions]="{standalone: true}"/>
                          </mat-form-field>
                        </div>
                        <div *ngIf="attribute?.type === 'TEXT_MULTI_LINE'">
                          <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                            <!--<mat-label>Wert (einzeilig)</mat-label>-->
                            <textarea matInput [(ngModel)]="attribute.multiLineValue" [ngModelOptions]="{standalone: true}"></textarea>
                          </mat-form-field>
                        </div>
                        <div *ngIf="attribute?.type === 'NUMBER'">
                          <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                            <!--<mat-label>Wert (Zahl)</mat-label>-->
                            <input matInput type="number" [(ngModel)]="attribute.numberValue" [ngModelOptions]="{standalone: true}"/>
                          </mat-form-field>
                        </div>
                        <div *ngIf="attribute?.type === 'PRICE'">
                          <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                            <!--<mat-label>Wert (Zahl)</mat-label>-->
                            <input matInput type="number" [(ngModel)]="attribute.priceValue" [ngModelOptions]="{standalone: true}"/>
                          </mat-form-field>
                        </div>
                        <div *ngIf="attribute?.type === 'DATE'">
                          <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                            <!--<mat-label>Wert (Datum)</mat-label>-->
                            <input matInput [matDatepicker]="dateValuePicker" [(ngModel)]="attribute.dateValue" [ngModelOptions]="{standalone: true}">
                            <mat-datepicker-toggle matSuffix [for]="$any(dateValuePicker)"></mat-datepicker-toggle>
                            <mat-datepicker #dateValuePicker></mat-datepicker>
                          </mat-form-field>
                        </div>
                        <div *ngIf="attribute?.type === 'FILE'">
                          <cp-upload (uploadFinished)="assetUploadFinished(attribute, $event)" [multiple]="false" [type]="'DYNAMIC_FILE'">
                            <div *ngIf="attribute.fileValue">
                              <a [href]="getAssetUrl(attribute)" target="_blank" class="download-item">{{attribute.fileValue.originalFileName }} <small class="download-icon"><fa-icon icon="download"></fa-icon></small></a>
                            </div>
                            <div *ngIf="!attribute.fileValue">
                              Bitte Datei hochladen
                            </div>
                          </cp-upload>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="gear"></fa-icon>
          &nbsp;&nbsp;Zugeordnete Kategorien
        </ng-template>

        <div mat-dialog-content>

          <section class="subView-steps">
            <!-- Liste Add / Remove -->
            <div class="cp-gui-item">
              <div class="cp-gui-list-v01" style="margin-top: -20px">
                <div class="add-items-header">
                  <div class="search">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Kategorie suchen</mat-label>
                      <input type="text"
                             placeholder="Name, Beschreibung"
                             aria-label="Number"
                             matInput
                             #categoryInput
                             #categoryTrigger="matAutocompleteTrigger"
                             [formControl]="categoryControl"
                             [matAutocomplete]="categoryAutocomplete">
                      <mat-autocomplete
                        #categoryAutocomplete="matAutocomplete"
                        (optionSelected)="selectCategory($event)"
                      >
                        <mat-option *ngFor="let category of searchCategories" [value]="category">
                          <span *ngIf="category.name">{{category.name}}<small > / {{category.description}}</small></span>
                          <span *ngIf="!category.description">{{category.name}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <a class="scan" (click)="listAllSearchCategoryItems($event, categoryTrigger)" matTooltip="Zeige alle Kategorien">
                    <fa-icon icon="list-ul"></fa-icon>
                  </a>
                </div>
                <!-- Item List -->
                <div class="item-list">
                  <div class="itemList__entry" *ngFor="let item of editFormGroup.get('categories')?.value; index as index">
                    <div class="item noIcon">
                      <div class="options-selection">
                        <a>
                          <span class="options-icon" (click)="removeCategory(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                        </a>
                      </div>

                      <div class="details" *ngIf="!item?.description">

                            <span class="primary">
                              <strong>{{ item?.name }}</strong>
                            </span>

                      </div>

                      <div class="details" *ngIf="item?.description">

                            <span class="primary">
                              <strong>{{ item?.name }}</strong>
                            </span>
                        <span class="secondary">
                              <span class="subItem">{{ item?.description }}</span>
                            </span>

                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>

        </div>

      </mat-tab>



      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="gear"></fa-icon>
          &nbsp;&nbsp;Einstellungen
        </ng-template>

        <div mat-dialog-content>
          <div style="padding: 20px 10px">
          <mat-form-field appearance="fill" style="width: 100%;box-sizing: border-box;">
            <mat-label>Interner Status</mat-label>
            <mat-select
              [compareWith]="compareByValue"
              formControlName="state">
              <mat-option
                [value]="state?.value"
                *ngFor="let state of stateOptions">
                {{ state?.displayName }}
              </mat-option>
            </mat-select>
          </mat-form-field>


        <h3 class="subHeading">Sonstiges</h3>
        <div class="gridContainer">

          <div class="gridItem giw-50">
            <mat-checkbox formControlName="defect">
              Defekt
            </mat-checkbox>
          </div>
          <div class="gridItem giw-50">
            <mat-checkbox formControlName="lost">
              Verloren
            </mat-checkbox>
          </div>
          <div class="gridItem giw-50">
            <mat-checkbox formControlName="sold">
              Verkauft
            </mat-checkbox>
          </div>

        </div>
        </div>
          </div>
      </mat-tab>



  </mat-tab-group>






  <!--<mat-form-field appearance="fill" *ngIf="item">
    <mat-label>Code</mat-label>
    <input matInput type="text" [value]="item.code" readonly />
  </mat-form-field>-->

<footer mat-dialog-actions>
  <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
  <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
</footer>
</form>
