import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DamageType, DamageTypeDraft } from './damage-type';
import { IdObject } from 'src/app/core/id-object';

@Injectable({
  providedIn: 'root',
})
export class DamageTypeService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}damageType`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}damageType/${id}`;
  private static searchUrl = (apiUrl: string): string =>
    `${apiUrl}damageType/search`;

  constructor(private httpClient: HttpClient) {}

  public getList(): Observable<DamageType[]> {
    const { apiUrl } = environment;
    const url = DamageTypeService.listUrl(apiUrl);

    return this.httpClient
      .get<DamageType[]>(url)
      .pipe(
        catchError(
          this.handleError<DamageType[]>('DamageTypeService->getList', []),
        ),
      );
  }

  public getSingle(id: string): Observable<DamageType> {
    const { apiUrl } = environment;
    const url = DamageTypeService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<DamageType>(url)
      .pipe(
        catchError(
          this.handleError<DamageType>('DamageTypeService->getSingle'),
        ),
      );
  }

  public create(damageTypeDraft: DamageTypeDraft): Observable<string> {
    const { apiUrl } = environment;
    const url = DamageTypeService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<IdObject>(url, damageTypeDraft, { headers })
      .pipe(
        map((idObject: IdObject) => idObject.id),
        catchError(this.handleError<string>('DamageTypeService->create')),
      );
  }

  public patch(
    id: string,
    damageTypeDraft: DamageTypeDraft,
  ): Observable<boolean> {
    const { apiUrl } = environment;
    const url = DamageTypeService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .patch<boolean>(url, damageTypeDraft, { headers })
      .pipe(
        catchError(
          this.handleError<boolean>('DamageTypeService->patch', false),
        ),
      );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = DamageTypeService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('DamageTypeService->remove', false)),
    );
  }

  public search(searchData: any): Observable<DamageType[]> {
    const { apiUrl } = environment;
    const url = DamageTypeService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<DamageType[]>(url, searchData, { headers })
      .pipe(
        catchError(
          this.handleError<DamageType[]>('DamageTypeService->search', []),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
