import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Company, CompanyDraft } from './company';
import { IdObject } from 'src/app/core/id-object';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}company`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}company/${id}`;

  constructor(private httpClient: HttpClient) {}

  public getList(): Observable<Company[]> {
    const { apiUrl } = environment;
    const url = CompanyService.listUrl(apiUrl);

    return this.httpClient
      .get<Company[]>(url)
      .pipe(
        catchError(this.handleError<Company[]>('CompanyService->getList', [])),
      );
  }

  public getSingle(id: string): Observable<Company> {
    const { apiUrl } = environment;
    const url = CompanyService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Company>(url)
      .pipe(
        catchError(
          this.handleError<Company>('CompanyService->getSingle', undefined),
        ),
      );
  }

  public create(companyDraft: CompanyDraft): Observable<IdObject> {
    const { apiUrl } = environment;
    const url = CompanyService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<IdObject>(url, companyDraft, { headers })
      .pipe(
        catchError(
          this.handleError<IdObject>('CompanyService->create', undefined),
        ),
      );
  }

  public patch(id: string, companyDraft: CompanyDraft): Observable<boolean> {
    const { apiUrl } = environment;
    const url = CompanyService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .patch<boolean>(url, companyDraft, { headers })
      .pipe(
        catchError(this.handleError<boolean>('CompanyService->patch', false)),
      );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = CompanyService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('CompanyService->remove', false)),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
