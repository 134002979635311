import { Account } from '../../account/account';
import { AccountService } from '../../account/account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { DamageType } from '../../damage-type/damage-type';
import { DamageTypeService } from '../../damage-type/damage-type.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { LegalArea } from '../../legal-area/legal-area.enum';
import { LegalBasis } from '../../legal-basis/legal-basis';
import { LegalBasisService } from '../../legal-basis/legal-basis.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Norm } from '../../norm/norm';
import { NormService } from '../../norm/norm.service';
import { Risk, RiskAccount, RiskAccountDraft, RiskRevision } from '../risk';
import { RiskEditModalData } from './risk-edit-modal-data';
import { RiskService } from '../risk.service';
import { RiskState } from '../risk-state.enum';
import { Role } from '../../account/role.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { ValueObject } from 'src/app/core/value-object';
import { ValueObjectService } from 'src/app/core/value-object.service';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProgressMeterType } from '../../../core/progress-meter/progress-meter-type.enum';
import { MeasureState } from '../../measure/measure-state.enum';
import { Measure, MeasureRevision } from '../../measure/measure';
import { MeasureEditModalEditData } from '../../measure/measure-edit-modal/measure-edit-modal-data';
import { MeasureEditModalComponent } from '../../measure/measure-edit-modal/measure-edit-modal.component';
import { AuditNextDateType, AuditResult } from '../../revisor/revisor-audit.enum';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ResponseError } from '../../../core/validation/response-error';
import { MeasureRevisionModalData } from '../../measure/measure-revision-modal/measure-revision-modal-data';
import { MeasureRevisionModalComponent } from '../../measure/measure-revision-modal/measure-revision-modal.component';
import { RiskRevisionModalData } from '../risk-revision-modal/risk-revision-modal-data';
import { RiskRevisionModalComponent } from '../risk-revision-modal/risk-revision-modal.component';

@Component({
  selector: 'app-risk-edit-modal',
  templateUrl: './risk-edit-modal.component.html',
  styleUrls: ['./risk-edit-modal.component.scss'],
})
export class RiskEditModalComponent implements OnInit {
  public legalAreaOptions: ValueObject[] = [
    {displayName: 'Deutschland', value: LegalArea.DE},
    {displayName: 'Europäische Union', value: LegalArea.EU},
    {displayName: 'andere', value: LegalArea.OTHER},
  ];
  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: RiskState.ACTIVE},
    {displayName: 'gelöscht', value: RiskState.DELETED},
  ];
  public roleOptions: ValueObject[] = [
    {displayName: 'informativer Benutzer', value: Role.INFORMATIVE},
    {displayName: 'Sachbearbeiter', value: Role.OFFICIAL},
    {displayName: 'Verantwortlicher', value: Role.RESPONSIBLE},
    {displayName: 'Administrator', value: Role.ADMIN},
    {displayName: 'Revisor', value: Role.REVISOR},
  ];
  public probabilityValueOptions: ValueObject[] = [
    {displayName: '100%', value: 100},
    {displayName: 'mehrfach täglich und häufiger', value: 95},
    {displayName: 'täglich', value: 85},
    {displayName: 'mehrfach pro Woche', value: 75},
    {displayName: 'wöchentlich', value: 65},
    {displayName: 'mehrfach pro Monat', value: 55},
    {displayName: 'monatlich', value: 45},
    {displayName: 'mehrfach pro Quartal', value: 35},
    {displayName: 'quartalweise', value: 25},
    {displayName: 'jährlich', value: 15},
    {displayName: 'weniger als jährlich', value: 5},
    {displayName: 'gar nicht', value: 0},
  ];
  public damageTypeOptions$!: Observable<DamageType[]>;
  public legalBasisOptions$!: Observable<LegalBasis[]>;
  public normOptions$!: Observable<Norm[]>;
  public accountOptions$!: Observable<Account[]>;
  public filteredAccountOptions$ = new BehaviorSubject<Account[]>([]);
  public openedAccountSelectForRiskAccount = new SelectionModel<number>(false);
  public progressMeterType = ProgressMeterType;

  public pending: boolean = false;
  private submitType: string = 'submit';
  private isRestartAudit: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    description: ['', []],
    secured: [false, []],
    stateNote: ['', []],
    damageValueEuro: [undefined, [Validators.min(0)]],
    probabilityValue: [undefined, [Validators.min(0), Validators.max(100)]],
    legalAreas: [[], []],
    ignored: [false, []],
    legalBases: [[], []],
    norms: [[], []],
    damageTypes: [[], []],
    state: [RiskState.ACTIVE, []],
    companyRisk: [false, []],
    riskAccounts: this.fb.array([]),
    riskReminders: [[], []],
    tags: [[], []],

    auditPlotDescription: ['', []],
    auditResultDescription: ['', []],
    auditResult: [null, []],
    auditNextDate: [null, []],
    auditNextDateType: [null, []],
  });

  public accountSearchCtrl = new FormControl();

  public riskAccountsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public riskAccountColumns = ['account', 'role', 'controls'];

  public risk: Risk | null = null;
  activeMeasures: Measure[] = [];

  riskRevisionsLoaded: boolean = false;
  public riskRevisions: RiskRevision[] | null = null;

  public auditResultOptions: ValueObject[] = [
    {displayName: 'Wirksam mit Beanstandung', value: AuditResult.EffectiveWithComplaint},
    {displayName: 'Wirksam ohne Beanstandung', value: AuditResult.EffectiveWithoutComplaint},
    {displayName: 'Unwirksam', value: AuditResult.Ineffective},
  ];

  public auditNextDateOptions: ValueObject[] = [
    {displayName: 'in 1 Jahr', value: AuditNextDateType.ONE_YEAR},
    {displayName: 'in 2 Jahren', value: AuditNextDateType.TWO_YEARS},
    {displayName: 'in 3 Jahren', value: AuditNextDateType.THREE_YEARS},
    {displayName: 'Freie Angabe', value: AuditNextDateType.CUSTOM},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RiskEditModalData,
    public matDialogRef: MatDialogRef<RiskEditModalComponent>,
    private fb: FormBuilder,
    private riskService: RiskService,
    private damageTypeService: DamageTypeService,
    private legalBasisService: LegalBasisService,
    private normService: NormService,
    private accountService: AccountService,
    private matDialog: MatDialog,
  ) {
    this.damageTypeOptions$ = this.damageTypeService.getList();
    this.legalBasisOptions$ = this.legalBasisService.getList();
    this.normOptions$ = this.normService.getList();
    this.accountOptions$ = this.accountService.getList();

    this.accountSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredAccountOptions(searchText);
      });
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    if (this.data.editMode === EditMode.EDIT) {
      this.riskService.getSingle(this.data.riskId).subscribe((risk) => {
        this.risk = risk;
        this.editFormGroup.patchValue(risk);

        risk.riskAccounts?.forEach((riskAccount) => {
          this.addRiskAccountFormGroup(riskAccount);
        });

        if (risk.riskMeasures) {
          this.activeMeasures = risk.riskMeasures.map(m => m.measure as Measure)
            .filter((m) => m.state === MeasureState.ACTIVE);
        }
      });
    }

    this.updateRiskAccountView();
    this.getFilteredAccountOptions();
  }

  public getFilteredAccountOptions(searchText?: string) {
    this.accountService.search({searchText}).subscribe((accountOptions) => {
      this.filteredAccountOptions$.next(accountOptions);
    });
  }

  public addRiskAccountFormGroup(
    riskAccount?: RiskAccount | RiskAccountDraft,
  ): void {
    const riskAccountFormGroup: FormGroup = this.fb.group({
      id: [riskAccount?.id || undefined, []],
      account: [riskAccount?.account, [Validators.required]],
      role: [riskAccount?.role || null, []],
    });

    (this.editFormGroup.get('riskAccounts') as FormArray).push(
      riskAccountFormGroup,
    );

    this.updateRiskAccountView();
  }

  public removeRiskAccountFormGroup(index: number) {
    (this.editFormGroup.get('riskAccounts') as FormArray).removeAt(index);
    this.updateRiskAccountView();
  }

  public setAccountSelectForRiskAccount(open: boolean, index: number): void {
    if (open) {
      this.openedAccountSelectForRiskAccount.select(index);
    } else {
      this.openedAccountSelectForRiskAccount.deselect(index);
    }
  }

  public isAccountSelectForRiskAccountOpen(index: number): boolean {
    return this.openedAccountSelectForRiskAccount.isSelected(index);
  }

  public updateRiskAccountView(): void {
    this.riskAccountsDataSource.next(
      (this.editFormGroup.get('riskAccounts') as FormArray).controls,
    );
  }

  public onSubmit(editFormGroup: FormGroup): void {
    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    const restartAudit = this.isRestartAudit;
    const confirmAudit = this.submitType === 'submitAudit';

    switch (this.data.editMode) {
      case EditMode.CREATE:
        const area: IdObject = {id: this.data.areaId};

        this.riskService.create({area, ...editData}).subscribe((riskId) => {
          let response: Risk | null = null;

          if (!!riskId) {
            response = {
              id: riskId,
              ...editData,
            };
          }

          this.pending = false;
          this.matDialogRef.close(response);
        });

        break;
      case EditMode.EDIT:
        const {riskId} = this.data;

        this.riskService.patch(riskId, {restartAudit, confirmAudit, ...editData})
          .subscribe((result) => {
              console.log('** Result:', result);
              let response: Risk | null = {
                id: riskId,
                ...editData,
              };

              this.pending = false;
              this.matDialogRef.close(response);
            }, (err) => {
              this.pending = false;
              console.debug('Error', err);
              alert('Das hat leider nicht geklappt.\n' + (err.errorMessage
                ? err.errorMessage
                : 'Die' +
                ' Daten konnten nicht gespeichert werden, bitte überprüfen Sie alle Felder.'));
            },
          );

        break;
    }
  }

  openMeasureDialog(measure: Measure) {
    const data: MeasureEditModalEditData = {
      measureId: measure.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(MeasureEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((measure?: Measure) => {
      if (measure) {
        this.ngOnInit();
      }
    });
  }

  tabClick(event: MatTabChangeEvent) {
    const tab = event.tab.textLabel;
    if (this.data && this.data.editMode === EditMode.EDIT) {
      if (tab === 'Revisionen') {
        const {riskId} = this.data;
        this.riskRevisionsLoaded = true;
        this.riskService.getRevisions(riskId).subscribe((revisions) => {
          this.riskRevisions = revisions;
        });
      }
    }
  }

  updateSubmitType(type: string) {
    this.submitType = type;
  }

  restartAudit() {
    this.isRestartAudit = true;
    if (this.risk) {
      this.risk.auditConfirmed = false;
    }
  }

  openRevisionModal(revision: RiskRevision) {
    if (!this.risk || !revision.id) {
      return;
    }

    const data: RiskRevisionModalData = {
      riskId: this.risk.id,
      revisionId: revision.id,
    };

    const dialogRef = this.matDialog.open(RiskRevisionModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    // dialogRef.afterClosed().subscribe((risk?: Risk) => {
    // });
  }

}
