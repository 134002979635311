import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { EditMode } from '../../../core/edit-mode.enum';
import { Area } from '../../area/area';
import { Deposit } from '../deposit';
import { DepositService } from '../deposit.service';
import { StateService } from '@uirouter/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaEditModalData } from '../../area/area-edit-modal/area-edit-modal-data';
import { AreaState } from '../../area/area-state.enum';
import { DepositState } from '../deposit-state.enum';
import {BarcodeFormat} from "@zxing/library";
import {DepositCreateComponent} from "../deposit-create/deposit-create.component";
import {ComplianceEditModalData} from "../../compliance/compliance-edit-modal/compliance-edit-modal-data";
import {DepositEditModalData, DepositEditModalEditData} from "../deposit-edit-modal/deposit-edit-modal-data";
import {DepositAgreementModalData} from "./deposit-agreement-modal-data";

@Component({
  selector: 'app-deposit-agreement-modal',
  templateUrl: './deposit-agreement-modal.component.html',
  styleUrls: ['./deposit-agreement-modal.component.scss'],
})
export class DepositAgreementModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  constructor(
    private matDialogRef: MatDialogRef<DepositAgreementModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DepositAgreementModalData
  ) {
  }

  public ngOnInit() {

  }

  closeDialog() {
    this.matDialogRef.close()
  }

}
