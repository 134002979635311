import { Component, Inject, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  NgxFileUploadStorage,
  NgxFileUploadFactory,
  NgxFileUploadOptions,
  NgxFileUploadState,
  INgxFileUploadRequest,
} from '@ngx-file-upload/core';
import { environment } from '../../../../environments/environment';
import { Asset } from '../../../common/asset/asset';
import { WorkspaceFilter } from '../../../common/workspace/workspace.filter';

@Component({
  selector: 'cp-upload',
  templateUrl: './cp-upload.component.html',
  styleUrls: ['./cp-upload.component.scss'],
})
export class CpUploadComponent implements OnDestroy, OnInit {
  @Input() multiple!: boolean;
  @Input() type: string = 'LOGO';
  @Output() uploadFinished = new EventEmitter<Asset>();

  private static uploadUrl = `${environment.apiUrl}asset`;

  uploadRunning: boolean = false;
  uploads: INgxFileUploadRequest[] = [];
  uploadStorage: NgxFileUploadStorage;
  // code = UploadEntry;
  states = NgxFileUploadState;

  assetUrl(assetId: string) {
    return `${environment.apiUrl}asset/${assetId}`;
  }

  /** upload options */
  private uploadOptions?: NgxFileUploadOptions

  private destroy$: Subject<boolean> = new Subject();

  constructor(@Inject(NgxFileUploadFactory) private uploadFactory: NgxFileUploadFactory) {
    this.uploadStorage = new NgxFileUploadStorage({concurrentUploads: 1, autoStart: true});
  }

  drop(files: NgxFileDropEntry[]) {
    for (const file of files) {
      if (file.fileEntry.isFile) {
        const dropped = file.fileEntry as FileSystemFileEntry;
        dropped.file((droppedFile: File) => {
          if (droppedFile instanceof DataTransferItem) {
            return;
          }

          const request = this.uploadFactory.createUploadRequest(
            droppedFile,
            this.uploadOptions!,
          );

          if (request) {
            this.uploadRunning = true;
            this.uploadStorage.add(request);
          }
        });
      }
    }
  }

  stateChanged(uploads: INgxFileUploadRequest[]) {
    this.uploads = uploads;

    let hasRunningUpload: boolean = false;
    for (const upload of uploads) {
      if (upload.state < NgxFileUploadState.COMPLETED) {
        hasRunningUpload = true;
      }

      if (upload.state === NgxFileUploadState.COMPLETED) {
        const asset: Asset = upload.data.response?.body;
        this.uploadFinished.emit(asset);
      }
    }

    this.uploads = this.uploads.filter(u => u.state < NgxFileUploadState.COMPLETED);
    this.uploadRunning = hasRunningUpload;
  }

  ngOnInit() {
    console.log('** onInit - uploadFinished:', this.uploadFinished);
    console.log('**THIS:', this);

    this.uploadStorage.change()
      .pipe(takeUntil(this.destroy$))
      .subscribe((uploads) => this.stateChanged(uploads));

    this.uploadOptions = {
        url: CpUploadComponent.uploadUrl,
          formData: {
        enabled: true,
          name: 'file',
          metadata: {
          type: this.type,
        },
      },
    };
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.uploadStorage.destroy();
  }

  isFinished(upload: INgxFileUploadRequest) {
    return upload.data.state === NgxFileUploadState.COMPLETED;
  }
}
