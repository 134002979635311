<header id="header" role="banner">
  <h1>
    Bereiche
    <small>Bereiche</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>
<main role="main" id="content" class="areas">
  <header class="overviewHeader isFixed">
      <form class="filter" novalidate [formGroup]="filterFormGroup" style="width: 100%; box-sizing: border-box; padding-right: 10px;">
        <mat-form-field appearance="outline">
          <mat-label>Compliance</mat-label>
          <mat-select [compareWith]="compareById" formControlName="compliance">
            <mat-option
              [value]="compliance"
              *ngFor="let compliance of complianceOptions$ | async"
            >
              {{ compliance.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    <div class="clearfix" *ngIf="filterFormGroup.get('compliance')?.value?.id !== null">
      <div class="header-item addItem width-100pz">
        <a
          uiSref=".create.general"
          [uiParams]="{
            complianceId: filterFormGroup.get('compliance')?.value?.id
          }"
          class="overviewHeader-add modal-open"
          *ngIf="filterFormGroup.get('compliance')?.value"
        >
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Neuer Bereich in "{{
            filterFormGroup.get('compliance')?.value?.displayName
          }}"
        </a>
      </div>
      <!--
      <div class="header-item searchItem">
        <input class="overviewHeader-search" type="text" />
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
      </div>
      <div class="header-item filterItem">
        <div>
          <a class="overviewHeader-filter">
            <span class="bigIcon">
              <fa-icon icon="filter"></fa-icon>
            </span>
            <span class="placeholder">Liste filtern</span>
          </a>
        </div>
      </div>
      -->
    </div>
  </header>

  <ul
    class="o-areaList"
    cdkDropList
    (cdkDropListDropped)="onAreaDropped($event)"
    *ngIf="filterFormGroup.get('compliance')?.value?.id !== null"
  >
    <li class="o-areaList__item"
      cdkDrag
      [class.-deleted]="area?.state === 'DELETED'"
      *ngFor="let area of areas">
      <div class="o-areaList__itemBlock">
        <span class="o-areaList__itemIcon">
          <fa-icon icon="sort"></fa-icon>
        </span>
        <span class="o-areaList__itemLabel">
          {{ area?.displayName }}
        </span>
        <span class="o-areaList__itemControls">
          <!-- ###HIDDEN FEATURE
          <button mat-icon-button (click)="expandedAreas.toggle(area)">
            <mat-icon
              [svgIcon]="
                expandedAreas.isSelected(area) ? 'expandLess' : 'expandMore'
              "
            ></mat-icon>
          </button>
          // ###HIDDEN FEATURE  -->
          <a
            mat-icon-button
            uiSref=".details.general"
            [uiParams]="{ areaId: area?.id }"
          >
            <mat-icon svgIcon="settings"></mat-icon>
          </a>
        </span>
      </div>
      <ul class="o-areaList__subItems" *ngIf="expandedAreas.isSelected(area)">
        <li
          class="o-areaList__item"
          cdkDrag
          cdkDropList
          (cdkDropListDropped)="onAreaDropped($event)"
          *ngFor="let subArea of area?.children"
        >
          <div class="o-areaList__itemBlock">
            <span class="o-areaList__itemIcon">
              <fa-icon icon="sort"></fa-icon>
            </span>
            <span class="o-areaList__itemLabel">
              {{ subArea?.displayName }}
            </span>
            <span class="o-areaList__itemControls">
              <a uiSref=".details.general" [uiParams]="{ areaId: subArea?.id }">
                <mat-icon svgIcon="settings"></mat-icon>
              </a>
            </span>
          </div>
        </li>
        <li class="o-areaList__item"
        *ngIf="area.children != null && area.children.length === 0"
        >
          <a
            uiSref=".create.general"
            [uiParams]="{ parentAreaId: area?.id }"
            class="o-areaList__itemBlock -placeholder"
          >
            <span class="o-areaList__itemIcon">
              <fa-icon icon="plus"></fa-icon>
            </span>
            <span class="o-areaList__itemLabel"> Neuer Unterbereich </span>
          </a>
        </li>
      </ul>
    </li>
    <li class="o-areaList__item"
      *ngIf="areas != null && areas.length === 0"
    >
      <a
        uiSref=".create.general"
        [uiParams]="{
          complianceId: filterFormGroup.get('compliance')?.value?.id
        }"
        class="o-areaList__itemBlock -placeholder"
        *ngIf="filterFormGroup.get('compliance')?.value"
      >
        <span class="o-areaList__itemIcon">
          <fa-icon icon="plus"></fa-icon>
        </span>
        <span class="o-areaList__itemLabel"> Neuer Bereich </span>
      </a>
    </li>
  </ul>

  <p *ngIf="filterFormGroup.get('compliance')?.value?.id === null" style="text-align: center; font-size: 18px; font-family: 'Roboto Slab', sans-serif;padding: 40px 0px">
    Bitte Compliance wählen.
  </p>

</main>
