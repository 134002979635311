import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationRiskComponent } from './evaluation-risk/evaluation-risk.component';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import { PROFILE_EVALUATION } from './evaluation.states';
import { EvaluationNavComponent } from './evaluation-nav/evaluation-nav.component';
import { EvaluationSidenavComponent } from './evaluation-sidenav/evaluation-sidenav.component';
import { DashboardModule } from '../dashboard/dashboard.module';

@NgModule({
  declarations: [
    EvaluationRiskComponent,
    EvaluationNavComponent,
    EvaluationSidenavComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: PROFILE_EVALUATION}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    DashboardModule,
  ],
})
export class EvaluationModule {
}
