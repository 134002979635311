import { Ng2StateDeclaration } from '@uirouter/angular';
import { MenuComponent } from './menu/menu.component';
import { AuthInfoService } from '../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../core/auth-info/auth-info.resolver';

export const UI_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.menu',
    component: MenuComponent,
    url: '/menu',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
];
