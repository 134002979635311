<header id="header" role="banner">
  <h1>
    Unternehmen
    <small>Benutzer</small>
  </h1>
</header>

<main role="main" id="content" class="companies">
  <app-company-nav></app-company-nav>

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a (click)="openAddUserModal($event)" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Benutzer hinzufügen
        </a>
      </div>
      <div class="header-item searchItem">
        <input [(ngModel)]="filter.searchText" (ngModelChange)="changed()" class="overviewHeader-search" type="text" />
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
      </div>
      <div class="header-item filterItem">
        <div>
          <a class="overviewHeader-filter">
            <span class="bigIcon">
              <fa-icon icon="filter"></fa-icon>
            </span>
            <span class="placeholder">Liste filtern</span>
          </a>
        </div>
      </div>
    </div>
  </header>

  <div class="o-companyUser" novalidate>
    <div class="userList">
      <div class="userList__entry" *ngFor="let companyAccount of this.companyAccounts">
        <div class="state">
          <span *ngIf="companyAccount.account.state === AccountState.NOT_ACTIVATED"><fa-icon icon="hourglass"></fa-icon></span>
          <span *ngIf="companyAccount.account.state === AccountState.INACTIVE"><fa-icon icon="pause"></fa-icon></span>
          <span *ngIf="companyAccount.account.state === AccountState.OK"><fa-icon icon="check"></fa-icon></span>
        </div>
        <div class="name">
          {{companyAccount.account.firstName}}&nbsp;{{companyAccount.account.lastName}}
        </div>
        <div class="info">
          {{companyAccount.account.lastConfirmMailSent}}
        </div>
        <div class="role">
          <span *ngIf="!companyAccount.role">keine Rolle</span>
          <span *ngIf="companyAccount.role">{{'ROLE.' + companyAccount.role | translate}}</span>
        </div>

        <div class="options">
          <button *ngIf="canResendConfirmation(companyAccount)" mat-icon-button (click)="resendConfirmation(companyAccount)"><fa-icon icon="sync"></fa-icon></button>

          <!--          <button mat-icon-button (click)="deleteAccount($event, companyAccount)"><fa-icon icon="trash"></fa-icon></button>-->
          <button mat-icon-button (click)="openEditUserModal($event, companyAccount)"><fa-icon icon="pencil-alt"></fa-icon></button>
        </div>
      </div>
    </div>
  </div>
</main>
