import { Component, EventEmitter, HostBinding, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { ProgressMeterType } from 'src/app/core/progress-meter/progress-meter-type.enum';
import { Measure } from '../../measure/measure';
import { MeasureEditModalCreateData } from '../../measure/measure-edit-modal/measure-edit-modal-data';
import { MeasureEditModalComponent } from '../../measure/measure-edit-modal/measure-edit-modal.component';
import { MeasureService } from '../../measure/measure.service';
import { Risk } from '../risk';
import { RiskEditModalEditData } from '../risk-edit-modal/risk-edit-modal-data';
import { RiskEditModalComponent } from '../risk-edit-modal/risk-edit-modal.component';
import { RiskService } from '../risk.service';
import { Role } from '../../account/role.enum';
import { ReminderType } from '../../reminder/reminder-type.enum';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { WorkspaceService } from '../../workspace/workspace.service';
import { RiskState } from '../risk-state.enum';
import { MeasureState } from '../../measure/measure-state.enum';

@Component({
  selector: 'app-risk-workspace-item',
  templateUrl: './risk-workspace-item.component.html',
  styleUrls: ['./risk-workspace-item.component.scss'],
})
export class RiskWorkspaceItemComponent implements OnInit {
  @Input() risk!: Risk;
  @Input() workspaceFilter!: WorkspaceFilter;
  @Output() riskChange = new EventEmitter<Risk>();
  @Output() updateParentCalculation = new EventEmitter();

  @HostBinding('class.-flaggedExpanded') get isFlaggedExpanded(): boolean {
    return this.expanded;
  }

  @HostBinding('class.-flaggedFilterMatched') get isFlaggedFilterMatched(): boolean {
    return this.risk.workspaceMatched === true;
  }

  @HostBinding('class.-flaggedDeleted') get isFlaggedDeleted(): boolean {
    return this.risk.state === RiskState.DELETED;
  }

  @HostBinding('class.-flaggedSecured') get isFlaggedSecured(): boolean {
    return this.risk.secured;
  }

  @HostBinding('class.-flaggedIgnored') get isFlaggedIgnored(): boolean {
    return this.risk.ignored;
  }

  measures: Measure[] | null = null;

  expanded: boolean = false;

  public progressMeterType = ProgressMeterType;

  constructor(
    private matDialog: MatDialog,
    private riskService: RiskService,
    private measureService: MeasureService,
    private workspaceService: WorkspaceService,
  ) {}

  public trackById = IdObjectService.trackById;
  private loaded: boolean = false;

  ngOnInit(): void {
    //this.measures = this.risk.mergedMeasures;
    if (this.workspaceService.isExpanded(this.risk.id)) {
      this.setExpanded();
    }
  }

  reloadCalculations() {
    this.riskService
      .getSingleCalculations(this.risk.id)
      .subscribe((riskCalculation) => {
        if (riskCalculation) {
          this.risk.calculatedDamage = riskCalculation.calculatedDamage;
          this.risk.calculatedDamageMax = riskCalculation.calculatedDamageMax;
          this.risk.calculatedProbability =
            riskCalculation.calculatedProbability;
          this.risk.calculatedProbabilityMax =
            riskCalculation.calculatedProbabilityMax;
          this.risk.calculatedRiskDamage = riskCalculation.calculatedRiskDamage;
          this.risk.calculatedRiskDamageMax =
            riskCalculation.calculatedRiskDamageMax;
        }
      });

    this.updateParentCalculation.emit();
  }

  updateMeasures(measure?: Measure) {
    const risk = IdObjectService.convertToIdObject(this.risk) as IdObject;

    const workspaceFilter = Object.assign(
      {workspaceAlreadyMatched: this.risk.workspaceMatched || this.risk.workspaceParentMatched},
      this.workspaceFilter,
    );

    this.measureService.search({ risk, workspaceFilter }).subscribe((measures) => {
      this.measures = measures;
      this.loaded = true;
    });

    if (measure) {
      this.reloadCalculations();
    }
  }

  public isResponsible(role: Role): boolean {
    return role === Role.RESPONSIBLE;
  }

  public isPrivate(reminderType: ReminderType): boolean {
    return reminderType === ReminderType.PRIVATE;
  }

  public toggleSecured(): void {
    const { id: riskId } = this.risk;
    const secured = !this.risk.secured;

    this.riskService.patch(riskId, { secured }).subscribe((success) => {
      if (success) {
        this.risk.secured = secured;
      }
    });
  }

  public openEditModal(event: MouseEvent): void {
    const data: RiskEditModalEditData = {
      riskId: this.risk.id,
      editMode: EditMode.EDIT,
      revisor: false,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.risk = risk;
        this.riskChange.emit(risk);
      }
    });
  }

  openCreateMeasureModal(event: MouseEvent) {
    const data: MeasureEditModalCreateData = {
      riskId: this.risk.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(MeasureEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe((measure: Measure) => {
      if (measure) {
        this.measures?.push(measure);
      }
      this.reloadCalculations();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.risk && changes.risk.currentValue) {
      if (this.expanded) {
        this.updateMeasures();
      }
    }
  }

  toggleExpand() {
    if (!this.expanded) {
      this.setExpanded();
    } else {
      this.setCollapsed();
    }
  }

  setCollapsed() {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
    this.workspaceService.setExpanded(this.risk.id, false);
  }

  setExpanded() {
    if (this.expanded) {
      return;
    }

    this.expanded = true;
    if (!this.loaded) {
      this.updateMeasures();
    }

    this.workspaceService.setExpanded(this.risk.id, true);
  }

  public get cssClass(): string[] {
    const classes: string[] = [];

    if (this.expanded) {
      classes.push('containerExpanded');
    }

    if (this.risk.workspaceMatched) {
      classes.push('filterMatched');
    }

    if (this.risk.secured) {
      classes.push('secured');
    }

    if (this.risk.ignored) {
      classes.push('ignored');
    }

    if (this.risk.state === RiskState.DELETED) {
      classes.push('deleted');
    }

    return classes;
  }
}
