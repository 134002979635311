import { Component, Input } from '@angular/core';
import { UIRouter, Transition, UIRouterGlobals } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { AuthInfo } from './core/auth-info/auth-info';
import { AuthInfoService } from './core/auth-info/auth-info.service';
import { DesignHelperService } from './layout/design/design-helper.service';
import { DesignParameter } from './layout/design/design-parameter';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @Input() authInfo!: AuthInfo;

  designParameter: DesignParameter = {
    feedback: false,
    hasMainMenu: true,
    hasSideNav: false,
    hasSubMenu: false,
  };

  uiRouterSuccessSubscription: Subscription;

  title = 'dsgvoo';

  constructor(
    private uiRouter: UIRouter,
    private designHelper: DesignHelperService,
  ) {
    this.uiRouterSuccessSubscription = (uiRouter.globals as MyUIRouterGlobals).success$.subscribe(
      (transition: Transition) => {
        this.designParameter.feedback = transition.params().style.feedback;
        this.designParameter.hasMainMenu = transition.params().style.hasMainMenu;
        this.designParameter.hasSideNav = transition.params().style.hasSideNav;
        this.designParameter.hasSubMenu = transition.params().style.hasSubMenu;

        designHelper.applyDesignParameter(this.designParameter);
      },
    );
  }
}

interface MyUIRouterGlobals extends UIRouterGlobals {
  start$: any;
  success$: any;
}
