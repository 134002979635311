import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AvvOverviewComponent } from './avv-overview/avv-overview.component';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import { TfaService } from 'src/app/core/tfa/tfa.service';
import { resolveTfaList } from 'src/app/core/tfa/tfa.resolver';
import {ClientService} from "../client/client.service";
import {resolveCurrentClient} from "../client/client.resolver";

export const DEPOSIT_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.avv',
    redirectTo: 'app.avv.overview',
    url: '/avv',
    params: {
      security: {
        enabled: false,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
  {
    name: 'app.avv.overview',
    views: {
      '$default@app': {
        component: AvvOverviewComponent,
      },
    },
    url: '/',
  },
];
