import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { ErrorStateMatcher } from '@angular/material/core';
import { PasswordValidator } from '../../../core/validation/password.validator';
import { MatDialog } from '@angular/material/dialog';
import { PasswordConfirmModalResponse } from '../../../core/validation/password-confirm-modal/password-confirm';
import { PasswordConfirmModalComponent } from '../../../core/validation/password-confirm-modal/password-confirm-modal.component';
import { UserService } from '../../../core/user/user.service';
import { UserPasswordUpdate } from '../../../core/user/user';
import { TfaCreateModalComponent } from 'src/app/core/tfa/tfa-create-modal/tfa-create-modal.component';
import { Tfa } from 'src/app/core/tfa/tfa';
import {
  TfaCreateModalConfirmData,
  TfaCreateModalCreateData,
} from 'src/app/core/tfa/tfa-create-modal/tfa-create-modal-data';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { TfaService } from 'src/app/core/tfa/tfa.service';
import {
  TfaAuthorizedRequestModalDeleteData,
  TfaAuthorizedRequestModalDisableData,
  TfaAuthorizedRequestModalEnableData,
} from 'src/app/core/tfa/tfa-authorized-request-modal/tfa-authorized-request-modal-data';
import { TfaAuthorizedRequestMode } from 'src/app/core/tfa/tfa-authorized-request-modal/tfa-authorized-request-mode';
import { TfaAuthorizedRequestModalComponent } from 'src/app/core/tfa/tfa-authorized-request-modal/tfa-authorized-request-modal.component';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent?.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  selector: 'app-profile-security',
  templateUrl: './profile-security.component.html',
  styleUrls: ['./profile-security.component.scss'],
})
export class ProfileSecurityComponent {
  @Input() authInfo!: AuthInfo;
  @Input() tfas!: Tfa[];

  passwordRepeatValidator: ValidatorFn = (
    group: AbstractControl,
  ): ValidationErrors | null => {
    const pass = group.get('newPassword')?.value;
    const confirmPass = group.get('newPasswordRepeat')?.value;

    if (pass !== confirmPass) {
      return { notSame: true };
    }

    return null;
  };

  passwordFormGroup: FormGroup = this.fb.group(
    {
      newPassword: ['', [PasswordValidator.strong]],
      newPasswordRepeat: ['', []],
    },
    { validators: this.passwordRepeatValidator },
  );

  matcher = new MyErrorStateMatcher();

  public pending: boolean = false;
  public changePasswordDone: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private logger: NGXLogger,
    private userService: UserService,
    private tfaService: TfaService,
  ) {}

  submitPasswordChange(passwordFormGroup: FormGroup) {
    this.changePasswordDone = false;

    if (this.pending) {
      return;
    }

    if (passwordFormGroup.invalid) {
      this.logger.log(passwordFormGroup.value, passwordFormGroup.errors);
      return;
    }

    this.pending = true;
    const { value: editData } = passwordFormGroup;
    const newPassword = passwordFormGroup.get('newPassword')?.value;
    const newPasswordRepeat = passwordFormGroup.get('newPasswordRepeat')?.value;

    const updatePassword = (currentPassword: string) => {
      return new Promise<boolean>((resolve, reject) => {
        const passwordUpdate: UserPasswordUpdate = {
          newPassword,
          newPasswordRepeat,
          currentPassword,
        };

        console.log('** run updatePassword with data:', passwordUpdate);
        this.userService
          .updatePassword(this.authInfo.account.id, passwordUpdate)
          .subscribe(
            (success) => {
              this.logger.log('pw change done.');
              resolve(true);
            },
            (err) => {
              reject(err);
            },
          );
      });
    };

    this.validateCurrentPassword(updatePassword);
  }

  validateCurrentPassword(
    updateFunction: (currentPassword: string) => Promise<boolean>,
  ) {
    const data = {
      updateFunction,
    };
    const dialogRef = this.matDialog.open(PasswordConfirmModalComponent, {
      // width: '750px',
      // maxWidth: '100%',
      // minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });

    dialogRef
      .afterClosed()
      .subscribe((result?: PasswordConfirmModalResponse) => {
        this.pending = false;
        if (result?.succeeded) {
          this.passwordFormGroup.get('newPassword')?.setValue('');
          this.passwordFormGroup.get('newPasswordRepeat')?.setValue('');
          this.changePasswordDone = true;
        }
      });
  }

  openCreateTFAModal(event: MouseEvent) {
    const data: TfaCreateModalCreateData = {
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(TfaCreateModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      disableClose: true,
      // position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tfaService.getList().subscribe((tfas) => {
        this.tfas = tfas;
      });
    });
  }

  openCompleteTFAModal(event: MouseEvent, tfaId: string) {
    const data: TfaCreateModalConfirmData = {
      tfaId,
      editMode: EditMode.CONFIRM,
    };

    const dialogRef = this.matDialog.open(TfaCreateModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tfaService.getList().subscribe((tfas) => {
        this.tfas = tfas;
      });
    });
  }

  deleteTFAModal(event: MouseEvent, tfaId: string) {
    const data: TfaAuthorizedRequestModalDeleteData = {
      tfaId,
      mode: TfaAuthorizedRequestMode.DELETE,
    };

    const dialogRef = this.matDialog.open(TfaAuthorizedRequestModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tfaService.getList().subscribe((tfas) => {
        this.tfas = tfas;
      });
    });
  }

  enableTFAModal(event: MouseEvent, tfaId: string) {
    const data: TfaAuthorizedRequestModalEnableData = {
      tfaId,
      mode: TfaAuthorizedRequestMode.ENABLE,
    };

    const dialogRef = this.matDialog.open(TfaAuthorizedRequestModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tfaService.getList().subscribe((tfas) => {
        this.tfas = tfas;
      });
    });
  }

  disableTFAModal(event: MouseEvent, tfaId: string) {
    const data: TfaAuthorizedRequestModalDisableData = {
      tfaId,
      mode: TfaAuthorizedRequestMode.DISABLE,
    };

    const dialogRef = this.matDialog.open(TfaAuthorizedRequestModalComponent, {
      disableClose: true,
      data,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.tfaService.getList().subscribe((tfas) => {
        this.tfas = tfas;
      });
    });
  }
}
