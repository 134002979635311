import { Component, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import {
  Reminder as ReminderDraft,
  ReminderDraft as IdObject,
} from '../reminder';
import { ReminderType } from '../reminder-type.enum';

@Component({
  selector: 'app-reminder-form-group',
  templateUrl: './reminder-form-group.component.html',
  styleUrls: ['./reminder-form-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReminderFormGroupComponent),
      multi: true,
    },
  ],
})
export class ReminderFormGroupComponent implements ControlValueAccessor {
  public globalRemindersDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public privateRemindersDataSource = new BehaviorSubject<AbstractControl[]>(
    [],
  );

  public reminderType = ReminderType;
  public columns = ['date', 'displayName', 'controls'];

  public editFormGroup: FormGroup = this.fb.group({
    globalReminders: this.fb.array([]),
    privateReminders: this.fb.array([]),
    deletedReminderIds: [[], []],
  });

  constructor(private fb: FormBuilder) {
    this.editFormGroup.valueChanges.subscribe((value) => {
      const globalReminders: ReminderDraft[] = value.globalReminders.map(
        (globalReminder: ReminderDraft) => ({
          ...globalReminder,
          reminderType: ReminderType.GLOBAL,
          deleted: false,
        }),
      );

      const privateReminders: ReminderDraft[] = value.privateReminders.map(
        (deletedReminder: ReminderDraft) => ({
          ...deletedReminder,
          reminderType: ReminderType.PRIVATE,
          deleted: false,
        }),
      );

      const deletedReminders: ReminderDraft[] = value.deletedReminderIds.map(
        (id: string) => ({
          id,
          deleted: true,
        }),
      );

      const reminders = [
        ...globalReminders,
        ...privateReminders,
        ...deletedReminders,
      ];

      this.propagateChange(reminders);
    });
  }

  public addReminderFormGroup(
    reminderType: ReminderType,
    reminder?: ReminderDraft | IdObject,
  ): void {
    const reminderFormGroup: FormGroup = this.fb.group({
      id: [reminder?.id || undefined, []],
      displayName: [reminder?.displayName || null, [Validators.required]],
      date: [reminder?.date || null, [Validators.required]],
    });

    switch (reminderType) {
      case ReminderType.GLOBAL:
        (this.editFormGroup.get('globalReminders') as FormArray).push(
          reminderFormGroup,
        );
        break;
      case ReminderType.PRIVATE:
        (this.editFormGroup.get('privateReminders') as FormArray).push(
          reminderFormGroup,
        );
        break;
    }

    this.updateGlobalRemindersView(reminderType);
  }

  public addDeletedReminderFormGroup(
    reminder?: ReminderDraft | IdObject,
  ): void {
    const deletedReminderFormGroup: FormGroup = this.fb.group({
      id: [reminder?.id || undefined, []],
    });

    (this.editFormGroup.get('deletedReminders') as FormArray).push(
      deletedReminderFormGroup,
    );
  }

  public removeReminderFormGroup(reminderType: ReminderType, index: number) {
    let reminderFormArray: FormArray;

    switch (reminderType) {
      case ReminderType.GLOBAL:
        reminderFormArray = this.editFormGroup.get(
          'globalReminders',
        ) as FormArray;

        break;
      case ReminderType.PRIVATE:
        reminderFormArray = this.editFormGroup.get(
          'privateReminders',
        ) as FormArray;

        break;
    }

    const reminderFormGroup = reminderFormArray.at(index) as FormGroup;
    const id = reminderFormGroup.get('id')?.value;

    if (id) {
      console.log('id', id);
      (this.editFormGroup.get('deletedReminderIds') as FormControl).value.push(
        id,
      );
    }

    reminderFormArray.removeAt(index);
    this.updateGlobalRemindersView(reminderType);
  }

  public updateGlobalRemindersView(reminderType: ReminderType): void {
    switch (reminderType) {
      case ReminderType.GLOBAL:
        this.globalRemindersDataSource.next(
          (this.editFormGroup.get('globalReminders') as FormArray).controls,
        );
        break;
      case ReminderType.PRIVATE:
        this.privateRemindersDataSource.next(
          (this.editFormGroup.get('privateReminders') as FormArray).controls,
        );
        break;
    }
  }

  writeValue(reminders: ReminderDraft[]): void {
    if (!reminders) {
      return;
    }

    reminders.forEach((reminder) => {
      this.addReminderFormGroup(reminder.reminderType, reminder);
    });
  }

  propagateChange!: (reminders: ReminderDraft[]) => {};

  registerOnChange(propagateChange: (reminders: ReminderDraft[]) => {}) {
    this.propagateChange = propagateChange;
  }

  registerOnTouched() {}
}
