<div mat-dialog-title>Wirklich löschen?</div>
<div mat-dialog-content>
  <div>
    <ng-content></ng-content>
  </div>
  <div>
    Soll das Attribut auch aus den Objekten entfernt werden?
  </div>
</div>
<footer mat-dialog-actions>
    <button type="button" (click)="cancel()" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="button" (click)="proceed()" mat-raised-button color="primary" [disabled]="pending">Normal löschen</button>
    <button type="button" (click)="proceedExtended()" mat-raised-button color="primary" [disabled]="pending">Auch aus Attributen löschen</button>
  </footer>
