import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UIRouterModule } from '@uirouter/angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { REVISOR_STATES } from './revisor.states';
import { RevisorRiskComponent } from './revisor-risk/revisor-risk.component';
import { RevisorMeasureComponent } from './revisor-measure/revisor-measure.component';
import { RevisorSidenavComponent } from './revisor-sidenav/revisor-sidenav.component';
import { RevisorNavComponent } from './revisor-nav/revisor-nav.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RevisorRiskComponent, RevisorMeasureComponent, RevisorSidenavComponent, RevisorNavComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: REVISOR_STATES}),
    FontAwesomeModule,
    MatTooltipModule,
    FormsModule,
    TranslateModule,
  ],
})
export class RevisorModule {
}
