<div *ngIf="category" class="container-box-style-v3">

  <div class="item noIcon"  [ngClass]="{'inactiveItem' : category.state === 'INACTIVE', 'deletedItem' : category.state === 'DELETED', 'statuslessItem' : !category.state}"><!--  | sort:'displayName' -->
    <div class="options-selection">

      <a (click)="openEditModal(category, $event)">
        <span class="options-icon"><fa-icon icon="pencil-alt"></fa-icon></span>
      </a>

      <a (click)="openCreateModal(category, $event)">
        <span class="options-icon"><fa-icon icon="plus"></fa-icon></span>
      </a>

    </div>

    <div class="details"  (click)="openEditModal(category, $event)">
      <span class="primary">
        <strong *ngIf="category.name">{{category.name}}</strong>
      </span>
      <span class="secondary">
        <span class="subItem">{{category.description}}</span>
        <span *ngFor="let template of category.templates"><span class="subItem"><fa-icon icon="file-alt"></fa-icon>&nbsp;<i>{{ template.name }}</i></span></span>
      </span>
    </div>
  </div>

  <div *ngIf="category.childCategories">
    <div *ngFor="let category of category.childCategories">
      <app-category-item [category]="category" [searchAction]="searchAction"></app-category-item>
    </div>
  </div>

</div>
