import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComplianceEditModalData } from '../../../common/compliance/compliance-edit-modal/compliance-edit-modal-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CurrentPassword,
  PasswordConfirmModalData,
  PasswordConfirmModalResponse,
} from './password-confirm';

@Component({
  selector: 'app-password-confirm-modal',
  templateUrl: './password-confirm-modal.component.html',
  styleUrls: ['./password-confirm-modal.component.scss'],
})
export class PasswordConfirmModalComponent implements OnInit {
  public pending: boolean = false;

  formGroup: FormGroup = this.fb.group({
    currentPassword: ['', [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: PasswordConfirmModalData,
    public matDialogRef: MatDialogRef<PasswordConfirmModalComponent>,
  ) {
  }

  submit(formGroup: FormGroup) {
    const currentPassword = this.formGroup.get('currentPassword')?.value;
    if (currentPassword) {
      console.log('set current password: ', currentPassword);

      if (this.data.updateFunction) {
        const promise: Promise<boolean> = this.data.updateFunction(currentPassword);
        promise.then((result) => {
          console.log('** UPDATE DONE!', result);
          const response: PasswordConfirmModalResponse = {succeeded: true};
          this.matDialogRef.close(response);

        }, (err) => {
          console.log('** UPDATE ERROR!', err);
          this.formGroup.get('currentPassword')?.setErrors({'invalid': true});
          // this.matDialogRef.close(false);
        });
      }
    }
  }

  ngOnInit(): void {
  }

  close() {
    const response: PasswordConfirmModalResponse = {succeeded: false};
    this.matDialogRef.close(response);
  }
}
