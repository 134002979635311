import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import {ERROR_PAGE_STATES} from './error-page.states';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import { ErrorPageOverviewComponent } from './error-page-overview/error-page-overview.component';
import {PagingModule} from "../../core/paging/paging.module";
import {UploadModule} from "../../core/upload/upload.module";

@NgModule({
  declarations: [
    ErrorPageOverviewComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: ERROR_PAGE_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
    PagingModule,
    UploadModule,
  ],
})
export class ErrorPageModule {
}
