<!-- Risk-Container -->
  <div class="risk-container">
    <!-- Risk -->
    <div class="risk-item-withoutCollapsable clickable status"
      [ngClass]="getProgressMeterClass(progressMeterType.MAIN, risk.calculatedRiskDamage)"
      (click)="editRisk(risk)" *ngFor="let risk of risks">
      <!-- style="background: #94c100" -->
      <div class="risk-shortInfo statusBG">
        <div class="mostImportant-info">
          {{risk.calculatedRiskDamage}}
        </div>
        <div class="lessImportant-info">
          <fa-icon icon="dice"></fa-icon>
          {{risk.calculatedProbability}} | <fa-icon icon="house-damage"></fa-icon>
          {{risk.calculatedDamage}}
        </div>
        <span class="risk-risky" *ngIf="risk.companyRisk"><fa-icon icon="bomb"></fa-icon></span>
      </div>

      <div class="risk-mainInfo">
        <span class="risk-titel" [matTooltip]="risk.riskName">{{risk.riskName}}</span>
        <div class="metaData">

          <div class="meta-item statusColor" [ngStyle]="{'background': risk.$$complianceColor}" *ngIf="!evaluationFilter.complianceName" [matTooltip]="risk.$$complianceName + ' » ' + risk.$$areaName">
            <span class="icon" [ngStyle]="{'color': risk.$$complianceColor, 'border-color': risk.$$complianceColor}" ><fa-icon icon="gavel"></fa-icon></span>
            <span class="value">{{risk.$$complianceName}} » {{risk.$$areaName}}</span>
          </div>
          <div class="meta-item" [matTooltip]="risk.$$companyName" *ngIf="!evaluationFilter.companyId">
            <span class="icon"><fa-icon icon="building"></fa-icon></span>
            <span class="value">{{risk.$$companyName}}</span>
          </div>
          <div *ngIf="risk.calculatedRiskDamage > risk.calculatedRiskDamageMax" class="meta-item statusColor" style="background: #94c100" matTooltip="Zu erreichende Punkte, wenn alle Maßnahmen abgeschlossen sind">
            <span class="icon" style="border-color: #94c100; color: #94c100;"><fa-icon icon="long-arrow-alt-down"></fa-icon></span>
            <span class="value">{{risk.calculatedRiskDamageMax}}</span>
          </div>
          <div *ngIf="risk.calculatedRiskDamage <= risk.calculatedRiskDamageMax" class="meta-item notImportant" matTooltip="Zu erreichende Punkte, wenn alle Maßnahmen abgeschlossen sind">
            <span class="icon"><fa-icon icon="long-arrow-alt-down"></fa-icon></span>
            <span class="value">{{risk.calculatedRiskDamageMax}}</span>
          </div>
          <div class="meta-item" [ngClass]="{'highlightMe': risk.openMeasuresCount > 0}" matTooltip="Offene Maßnahmen zu diesem Risiko">
            <span class="icon"><fa-icon icon="list-ul"></fa-icon></span>
            <span class="value">{{risk.openMeasuresCount}}</span>
          </div>
          <div class="meta-item" matTooltip="Eingestellte Reminder" *ngIf="risk.nextReminders?.length">
            <span class="icon"><fa-icon icon="bell"></fa-icon></span>
            <span class="value">
              <ng-container *ngFor="let reminder of risk.nextReminders; let last = last">
                <span [class.-italic]="isPrivate(reminder.reminderType)">{{ reminder.date | date: 'E, dd.MM.y HH:mm' }}</span><ng-container *ngIf="!last">, </ng-container>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
      <div class="progress" [ngStyle]="{'width': risk.percentageProportion + '%'}"></div>
    </div>
    <!-- //Risk -->
  </div>
  <!-- // Risk-Container -->
