import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReminderFormGroupComponent } from './reminder-form-group.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  NgxMatCalendar,
  NgxMatDatetimePickerModule,
} from '@angular-material-components/datetime-picker';

@NgModule({
  declarations: [ReminderFormGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatButtonModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
  ],
  exports: [ReminderFormGroupComponent],
})
export class ReminderFormGroupModule {}
