import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { DashboardComponent } from './dashboard.component';
import { DASHBOARD_STATES } from './dashboard.states';
import { ClientWidgetModule } from '../client/client-widget/client-widget.module';
import { CompanyWidgetModule } from '../company/company-widget/company-widget.module';
import { MeasuresWidgetModule } from '../measure/measures-widget/measures-widget.module';
import { RemindersWidgetModule } from '../reminder/reminders-widget/reminders-widget.module';
import { TasksWidgetModule } from '../task/tasks-widget/tasks-widget.module';
import { FormsModule } from '@angular/forms';
import { DashboardWidgetClientOverviewComponent } from './widgets/dashboard-widget-client-overview/dashboard-widget-client-overview.component';
import { DashboardWidgetClientCompliancesComponent } from './widgets/dashboard-widget-client-compliances/dashboard-widget-client-compliances.component';
import { DashboardWidgetCompanyOverviewComponent } from './widgets/dashboard-widget-company-overview/dashboard-widget-company-overview.component';
import { DashboardWidgetCompanyRisksComponent } from './widgets/dashboard-widget-company-risks/dashboard-widget-company-risks.component';
import { DashboardWidgetRisksComponent } from './widgets/dashboard-widget-risks/dashboard-widget-risks.component';

@NgModule({
  declarations: [DashboardComponent, DashboardWidgetCompanyOverviewComponent, DashboardWidgetClientCompliancesComponent, DashboardWidgetClientOverviewComponent, DashboardWidgetCompanyRisksComponent, DashboardWidgetRisksComponent],
  imports: [
    CommonModule,
    ClientWidgetModule,
    CompanyWidgetModule,
    MeasuresWidgetModule,
    RemindersWidgetModule,
    TasksWidgetModule,
    UIRouterModule.forChild({states: DASHBOARD_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
  ],
  exports: [
    DashboardWidgetCompanyOverviewComponent,
    DashboardWidgetClientCompliancesComponent,
    DashboardWidgetClientOverviewComponent,
    DashboardWidgetCompanyRisksComponent,
    DashboardWidgetRisksComponent,
  ],
})
export class DashboardModule {}
