import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { CompanyGeneralComponent } from './company-general/company-general.component';
import { CompanyDesignComponent } from './company-design/company-design.component';
import { CompanyUserComponent } from './company-user/company-user.component';
import { CompanyService } from './company.service';
import { resolveCurrentCompany } from './company.resolver';

export const COMPANY_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.company',
    redirectTo: 'app.company.general',
    url: '/company',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'currentCompany',
        deps: [Transition, CompanyService],
        resolveFn: resolveCurrentCompany,
      },
    ],
  },
  {
    name: 'app.company.general',
    views: {
      '$default@app': {
        component: CompanyGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.company.design',
    views: {
      '$default@app': {
        component: CompanyDesignComponent,
      },
    },
    url: '/design',
  },
  {
    name: 'app.company.user',
    views: {
      '$default@app': {
        component: CompanyUserComponent,
      },
    },
    url: '/user',
  },
];
