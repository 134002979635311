import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdObject } from 'src/app/core/id-object';
import { environment } from 'src/environments/environment';
import { Avv, AvvDraft, AvvSearchData } from './avv';
import { Item } from '../item/item';
import Compressor from 'compressorjs';
import { PagedResponse, Paging, PagingHeader } from '../../core/paging/paging';
import {RedirectUrl} from "./redirect-url";

@Injectable({
  providedIn: 'root',
})
export class AvvService {
  private static currentAvv = (apiUrl: string): string => `${apiUrl}avv/current`;
  private static redirectUrl = (apiUrl: string): string => `${apiUrl}avv/redirectUrl`;
  private static create = (apiUrl: string): string => `${apiUrl}avv`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getCurrentAvv(): Observable<Avv> {
    const {apiUrl} = environment;
    const url = AvvService.currentAvv(apiUrl);

    return this.httpClient
      .get<Avv>(url)
      .pipe(catchError(this.handleError<Avv>('AvvService->getSingle')));
  }

  public getRedirectUrl(): Observable<RedirectUrl> {
    const {apiUrl} = environment;
    const url = AvvService.redirectUrl(apiUrl);

    return this.httpClient
      .get<RedirectUrl>(url)
      .pipe(catchError(this.handleError<RedirectUrl>('AvvService->getSingle')));
  }

  public create(avvDraft: AvvDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = AvvService.create(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<IdObject>(url, avvDraft, {headers}).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('AvvService->create')),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
