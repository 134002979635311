import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { Category } from '../../category/category';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { CategoryService } from '../../category/category.service';
import { StateService } from '@uirouter/core';
import { EditMode } from '../../../core/edit-mode.enum';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../../area/area-state.enum';
import { CategoryState } from '../category-state.enum';
import {Role} from "../../account/role.enum";
import {Attribute} from "../../attribute/attribute";
import {Template} from "../../template/template";
import {startWith} from "rxjs/operators";
import {AttributeState} from "../../attribute/attribute-state.enum";
import {TemplateService} from "../../template/template.service";
import {TemplateState} from "../../template/template-state.enum";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {CategoryItemData} from "./category-item-data";
import {CategoryModalEditData} from "../category-modal/category-modal-data";
import {CategoryModalComponent} from "../category-modal/category-modal.component";
import {Paging} from "../../../core/paging/paging";
import {Delegate} from "../../../core/validation/cp-confirm/cp-confirm";

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss'],
})
export class CategoryItemComponent implements OnInit {
  @Input() authInfo!: AuthInfo;
  @Input() public category!: Category;

  @Input() searchAction!: Delegate<Promise<any>>;

  childCategories: Category[] = []

  constructor(
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private matDialog: MatDialog,
  ) {

  }

  public compareByValue = ValueObjectService.compareByValue;

  public ngOnInit() {
    if (this.category.childCategories && this.category.childCategories.length > 0) {
      this.childCategories = this.category.childCategories;
    }
  }

  openEditModal(category: Category, event: MouseEvent) {
    const data: CategoryModalEditData = {
      editMode: EditMode.EDIT,
      categoryId: category.id,
    };

    const dialogRef = this.matDialog.open(CategoryModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((category?: Category) => {
      this.searchAction();
    });
  }

  openCreateModal(parentCategory: Category, event: MouseEvent) {
    const data: CategoryModalEditData = {
      editMode: EditMode.CREATE,
      parentCategory: parentCategory,
    };

    const dialogRef = this.matDialog.open(CategoryModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((category?: Category) => {
      this.searchAction();
    });
  }
}
