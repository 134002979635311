import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { DepositOverviewComponent } from './deposit-overview/deposit-overview.component';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import { TfaService } from 'src/app/core/tfa/tfa.service';
import { resolveTfaList } from 'src/app/core/tfa/tfa.resolver';
import { DepositEditModalComponent } from './deposit-edit-modal/deposit-edit-modal.component';
import { DepositCreateComponent } from './deposit-create/deposit-create.component';
import { DepositReturnComponent } from './deposit-return/deposit-return.component';
import {ClientService} from "../client/client.service";
import {resolveCurrentClient} from "../client/client.resolver";

export const DEPOSIT_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.deposit',
    redirectTo: 'app.deposit.overview',
    url: '/deposit',
    params: {
      security: {
        enabled: false,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
  },
  {
    name: 'app.deposit.overview',
    views: {
      '$default@app': {
        component: DepositOverviewComponent,
      },
    },
    url: '/',
  },
  {
    name: 'app.deposit.create',
    views: {
      '$default@app': {
        component: DepositCreateComponent,
      },
    },
    url: '/create',
    params: {
      security: {
        enabled: false,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: false,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'currentClient',
        deps: [Transition, ClientService],
        resolveFn: resolveCurrentClient,
      },
    ],
  },
  {
    name: 'app.deposit.return',
    views: {
      '$default@app': {
        component: DepositReturnComponent,
      },
    },
    url: '/return',
    params: {
      security: {
        enabled: false,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: false,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
  },
];
