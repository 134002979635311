import { Component, Input } from '@angular/core';
import { Client } from '../client';
import { ValueObject } from '../../../core/value-object';
import { Role } from '../../account/role.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Account, AccountSearchData } from '../../account/account';
import { SelectionModel } from '@angular/cdk/collections';
import { IdObjectService } from '../../../core/id-object.service';
import { ValueObjectService } from '../../../core/value-object.service';
import { AreaAccount, AreaAccountDraft } from '../../area/area';
import { AccountService } from '../../account/account.service';
import { NGXLogger } from 'ngx-logger';
import { AreaService } from '../../area/area.service';
import { ClientService } from '../client.service';
import { TfaCreateModalConfirmData } from '../../../core/tfa/tfa-create-modal/tfa-create-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { TfaCreateModalComponent } from '../../../core/tfa/tfa-create-modal/tfa-create-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AccountTarget } from '../../account/accountTarget.enum';
import { CreateEditUserComponent } from '../../../core/user/create-edit-user/create-edit-user.component';
import { CreateUserModalMode } from '../../../core/user/create-edit-user/create-edit-user';
import { debounceTime } from 'rxjs/operators';
import { CompanyAccount } from '../../company/company';
import { CompanyUserFilter } from '../../company/company-user/company-user.filter';
import {AuthInfo} from "../../../core/auth-info/auth-info";

@Component({
  selector: 'app-client-user',
  templateUrl: './client-user.component.html',
  styleUrls: ['./client-user.component.scss'],
})
export class ClientUserComponent {
  @Input() currentClient!: Client;
  @Input() authInfo!: AuthInfo;

  modelChanged = new Subject<string>();
  accounts: Account[] = [];
  filter: AccountSearchData = {};

  constructor(
    private matDialog: MatDialog,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private clientService: ClientService,
    private accountService: AccountService,
  ) {
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.loadAccounts();
      });
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    this.loadAccounts();
  }

  loadAccounts() {
    this.accountService.search(this.filter).subscribe((accounts) => {
      this.accounts = accounts;
    });
  }

  openAddUserModal(event: MouseEvent) {
    const dialogRef = this.matDialog.open(CreateEditUserComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {
        target: AccountTarget.CLIENT,
        mode: CreateUserModalMode.CREATE,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAccounts();
    });
  }

  deleteAccount(event: MouseEvent, account: Account) {

  }

  openEditUserModal(event: MouseEvent, clientAccount: Account) {
    const dialogRef = this.matDialog.open(CreateEditUserComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {
        target: AccountTarget.CLIENT,
        mode: CreateUserModalMode.EDIT,
        id: clientAccount.id,
        userId: this.authInfo.account.id,
        amountClientAccounts: this.accounts.length,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAccounts();
    });
  }

  changed() {
    this.modelChanged.next();
  }
}
