import { Transition } from '@uirouter/angular';
import { IdObject } from 'src/app/core/id-object';
import { Compliance } from './compliance';
import { ComplianceService } from './compliance.service';

export function resolveComplianceList(
  transition: Transition,
  complianceService: ComplianceService
): Promise<Compliance[]> {
  const { areaId, searchText } = transition.params();
  const area: IdObject | undefined = areaId ? { id: areaId } : undefined;
  const searchData = { searchText, area };

  return new Promise((resolve) => {
    complianceService.search(searchData).subscribe(resolve);
  });
}

export function resolveComplianceSingle(
  transition: Transition,
  complianceService: ComplianceService,
): Promise<Compliance> {
  const { complianceId } = transition.params();

  return new Promise((resolve) => {
    complianceService.getSingle(complianceId).subscribe(resolve);
  });
}

export function resolveComplianceId(transition: Transition): string {
  const { complianceId } = transition.params();

  return complianceId;
}
