import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CLIENT_STATES } from './client.states';
import { UIRouterModule } from '@uirouter/angular';
import { ClientDesignComponent } from './client-design/client-design.component';
import { ClientNavComponent } from './client-nav/client-nav.component';
import { ClientUserComponent } from './client-user/client-user.component';
import { ClientGeneralComponent } from './client-general/client-general.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadModule } from '../../core/upload/upload.module';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    ClientDesignComponent,
    ClientNavComponent,
    ClientUserComponent,
    ClientGeneralComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: CLIENT_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UploadModule,
    FlexModule,
  ],
})
export class ClientModule {}
