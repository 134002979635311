<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Kategorie anlegen
  </ng-template>
  <ng-template #editModeText>
    Kategorie bearbeiten
  </ng-template>
</div>
<form
  novalidate
  (ngSubmit)=" onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>

  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="gear"></fa-icon>
        &nbsp;&nbsp;Stammdaten
      </ng-template>
      <div style="padding: 20px 10px;">
        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Bezeichnung</mat-label>
          <input matInput type="text" formControlName="name"/>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Beschreibung</mat-label>
          <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label>Interner Status</mat-label>
          <mat-select
            [compareWith]="compareByValue"
            formControlName="state">
            <mat-option
              [value]="state?.value"
              *ngFor="let state of stateOptions">
              {{ state?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="file-alt"></fa-icon>&nbsp;&nbsp;Zugeordnete Attribut-Gruppen
      </ng-template>

      <div mat-dialog-content>

        <section class="subView-steps">
          <!-- Liste Add / Remove -->
          <div class="cp-gui-item">
            <div class="cp-gui-list-v01" style="margin-top: -20px">
              <div class="add-items-header">
                <div class="search">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Attribut-Gruppe zuweisen</mat-label>
                    <input type="text"
                           placeholder="Bezeichnung"
                           aria-label="Number"
                           matInput
                           #templateInput
                           #trigger="matAutocompleteTrigger"
                           [formControl]="templateControl"
                           [matAutocomplete]="templateAutocomplete">
                    <mat-autocomplete
                      #templateAutocomplete="matAutocomplete"
                      (optionSelected)="selectTemplate($event)"
                    >
                      <mat-option *ngFor="let templates of searchTemplates" [value]="templates">
                        <span><fa-icon icon="file-alt"></fa-icon> | {{templates.name}}<small *ngIf="templates.description"> / {{templates.description}}</small></span>
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <a class="scan" (click)="listAllSearchItems($event, trigger)" matTooltip="Zeige alle Templates">
                  <fa-icon icon="list-ul"></fa-icon>
                </a>
              </div>
              <!-- Item List -->
              <div class="item-list">
                <div class="itemList__entry" *ngFor="let item of editFormGroup.get('templates')?.value; index as index">
                  <div class="item noIcon">
                    <div class="options-selection">
                      <a>
                        <span class="options-icon" (click)="removeTemplate(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                      </a>
                    </div>

                    <div class="details" *ngIf="!item?.description">

                        <span class="primary">
                          <strong>{{ item?.name }}</strong>
                        </span>

                    </div>

                    <div class="details" *ngIf="item?.description">

                        <span class="primary">
                          <strong>{{ item?.name }}</strong>
                        </span>
                      <span class="secondary">
                          <span class="subItem">{{ item?.description }}</span>
                        </span>

                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

      </div>

    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="file-alt"></fa-icon>&nbsp;&nbsp;Oberkategorie
      </ng-template>

      <div mat-dialog-content>

        <section class="subView-steps">
          <!-- Liste Add / Remove -->
          <div class="cp-gui-item">
            <div class="cp-gui-list-v01" style="margin-top: -20px">
              <div class="add-items-header">
                <div class="search">
                  <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Oberkategorie zuweisen</mat-label>
                    <input type="text"
                           placeholder="Bezeichnung"
                           aria-label="Number"
                           matInput
                           #parentCategoryInput
                           #parentCategoryTrigger="matAutocompleteTrigger"
                           [formControl]="parentCategoryControl"
                           [matAutocomplete]="parentCategoryAutocomplete">
                    <mat-autocomplete
                      #parentCategoryAutocomplete="matAutocomplete"
                      (optionSelected)="selectParentCategory($event)"
                    >
                      <mat-option *ngFor="let category of searchParentCategories" [value]="category">
                        <span><fa-icon icon="file-alt"></fa-icon> | {{category.name}}<small *ngIf="category.description"> / {{category.description}}</small></span>
                      </mat-option>

                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <a class="scan" (click)="listAllParentCategorySearchItems($event, parentCategoryTrigger)" matTooltip="Zeige alle Kategorien">
                  <fa-icon icon="list-ul"></fa-icon>
                </a>
              </div>
              <!-- Item List -->
              <div class="item-list">
                <div class="item noIcon" *ngIf="editFormGroup.get('parentCategory')?.value">
                  <div class="options-selection">

                    <a (click)="removeParentCategory()">
                      <span class="options-icon"><fa-icon icon="recycle"></fa-icon></span>
                    </a>

                  </div>

                  <div class="details">
                            <span class="primary">
                              <strong *ngIf="editFormGroup.get('parentCategory')?.value.name">{{editFormGroup.get('parentCategory')?.value.name}}</strong>
                            </span>
                    <span class="secondary">
                              <span class="subItem">{{editFormGroup.get('parentCategory')?.value.description}}</span>
                              <span *ngFor="let template of editFormGroup.get('parentCategory')?.value.templates"><span class="subItem"><fa-icon icon="file-alt"></fa-icon>&nbsp;<i>{{ template.name }}</i></span></span>
                            </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

      </div>

    </mat-tab>
  </mat-tab-group>

<footer mat-dialog-actions>
  <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
  <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
</footer>
</form>
