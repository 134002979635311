<div mat-dialog-title>Attribute hinzufügen?</div>
<div mat-dialog-content>
  <div>
    <ng-content></ng-content>
  </div>
  <div>
    Sollen die Attribute aus den Templates der Kategorie hinzugefügt werden?
  </div>
</div>
<footer mat-dialog-actions>
    <button type="button" (click)="cancel()" mat-raised-button mat-dialog-close>Nein</button>
    <button type="button" (click)="proceed()" mat-raised-button color="primary" [disabled]="pending">Ja</button>
  </footer>
