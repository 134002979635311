import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidator {
  public static strong(control: AbstractControl): ValidationErrors | null {
    const passwordPattern: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[,.\-_!@#$%^&+=§\/(){}\[\]?"´`])(?=\S+$).{8,}$/;
    const password = control.value;

    if (password && !passwordPattern.test(password)) {
      return {pattern: true};
    }
    return null;
  }
}
