<div mat-dialog-title>Daten nicht vollständig</div>

<mat-dialog-content class="mat-typography">
  <ul style="margin: 0px 0px 40px 0px;">
    <li *ngIf="data.identityDataIncomplete">
      Daten zur leihenden Person sind unvollständig
    </li>
    <li *ngIf="data.itemsIncomplete">
      Keine zu verleihenden Objekte zugeordnet
    </li>
    <li *ngIf="data.signatureIncomplete">
      Keine Unterschrift vorhanden
    </li>
  </ul>
  <div class="options-container">
    <a class="option notImportant" (click)="doSaveOrDecline(true)">Trotzdem anlegen</a>
    <a class="option" (click)="doSaveOrDecline(false)">Korrigieren</a>
  </div>
</mat-dialog-content>

