import { Injectable } from '@angular/core';
import { IdObject } from './id-object';

@Injectable({
  providedIn: 'root',
})
export class IdObjectService {
  public static trackById(index: number, idObject: IdObject): string {
    return idObject.id;
  }

  public static compareById(o1: IdObject, o2: IdObject): boolean {
    if (!o1 || !o1.hasOwnProperty('id') || !o2 || !o2.hasOwnProperty('id')) {
      return o1 === o2;
    }

    return o1.id === o2.id;
  }

  public static convertToIdObject(object: any): IdObject | undefined {
    if (!object || typeof object !== 'object' || !object.hasOwnProperty('id')) {
      return undefined;
    }

    const { id } = object;

    return { id };
  }

  public static convertToIdObjects(objects: any[]): IdObject[] {
    if (!Array.isArray(objects)) {
      return [];
    }

    return objects
      .filter(
        (object) =>
          object && typeof object === 'object' && object.hasOwnProperty('id'),
      )
      .map((object) => {
        const { id } = object;

        return { id };
      });
  }
}
