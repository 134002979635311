import { NumericValueObject } from '../value-object';

export const ProhabilityValues: NumericValueObject[] = [
  {displayName: '100%', value: 100},
  {displayName: 'mehrfach täglich und häufiger', value: 95},
  {displayName: 'täglich', value: 85},
  {displayName: 'mehrfach pro Woche', value: 75},
  {displayName: 'wöchentlich', value: 65},
  {displayName: 'mehrfach pro Monat', value: 55},
  {displayName: 'monatlich', value: 45},
  {displayName: 'mehrfach pro Quartal', value: 35},
  {displayName: 'quartalweise', value: 25},
  {displayName: 'jährlich', value: 15},
  {displayName: 'weniger als jährlich', value: 5},
  {displayName: 'gar nicht', value: 0},
];
