import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {Item} from '../../item/item';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ItemService} from '../../item/item.service';
import {ItemModalData} from './item-modal-data';
import {EditMode} from '../../../core/edit-mode.enum';
import {ValueObjectService} from '../../../core/value-object.service';
import {ValueObject} from '../../../core/value-object';
import {ItemState} from '../item-state.enum';
import {startWith} from "rxjs/operators";
import {AttributeService} from "../../attribute/attribute.service";
import {AttributeState} from "../../attribute/attribute-state.enum";
import {Attribute} from "../../attribute/attribute";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {Category} from "../../category/category";
import {CategoryState} from "../../category/category-state.enum";
import {CategoryService} from "../../category/category.service";
import {environment} from 'src/environments/environment';
import {Asset} from "../../asset/asset";
import {AttributeModalEditData} from "../../attribute/attribute-modal/attribute-modal-data";
import {AttributeModalComponent} from "../../attribute/attribute-modal/attribute-modal.component";
import {TemplateService} from "../../template/template.service";
import {TemplateState} from "../../template/template-state.enum";
import {Template} from "../../template/template";
import {CategoryAddAttributesModal} from "../category-add-attributes-modal/category-add-attributes-modal";
import {DepositDeleteModalData} from "../../deposit/deposit-delete-modal/deposit-delete-modal";
import {DepositDeleteModalComponent} from "../../deposit/deposit-delete-modal/deposit-delete-modal.component";
import {
  CategoryAddAttributesModalComponent
} from "../category-add-attributes-modal/category-add-attributes-modal.component";

@Component({
  selector: 'app-item-modal',
  templateUrl: './item-modal.component.html',
  styleUrls: ['./item-modal.component.scss'],
})
export class ItemModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  item: Item | null = null;
  pending: boolean = false;

  @ViewChild('attributeInput') attributeInput!: ElementRef<HTMLInputElement>;
  @ViewChild('categoryInput') categoryInput!: ElementRef<HTMLInputElement>;

  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: ItemState.ACTIVE},
    {displayName: 'inaktiv', value: ItemState.INACTIVE},
    {displayName: 'gelöscht ', value: ItemState.DELETED},
  ];

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    description: ['', []],
    state: [ItemState.ACTIVE, [Validators.required]],
    attributes: [[], []],
    categories: [[], []],
    defect: ['', []],
    lost: ['', []],
    sold: ['', []],
  });

  readonly attributesControl = new FormControl();
  searchAttributes: Attribute[] = [];
  searchTemplates: Template[] = [];
  searchAttributeCategories: Category[] = [];

  readonly categoryControl = new FormControl();
  searchCategories: Category[] = [];

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: ItemModalData,
    public matDialogRef: MatDialogRef<ItemModalComponent>,
    private attributeService: AttributeService,
    private templateService: TemplateService,
    private categoryService: CategoryService,
    private itemService: ItemService,
    private matDialog: MatDialog,
  ) {
    this.attributesControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.attributeService.search({searchText, state: AttributeState.ACTIVE}).then((pagesItems) => {
            let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
            this.searchAttributes = pagesItems.items.filter(x => !currentKeys.includes(x.id))
          });
          this.templateService.search({searchText, state: TemplateState.ACTIVE}).then((pagesItems) => {
            this.searchTemplates = pagesItems.items
          });
          this.categoryService.search({searchText, flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
            this.searchAttributeCategories = pagesItems.items
          });
        } else {
          this.searchAttributes = [];
          this.searchTemplates = [];
          this.searchAttributeCategories = [];
        }
      });

    this.categoryControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.categoryService.search({searchText, flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
            let currentKeys = (this.editFormGroup.get('categories')?.value as Category[]).map(x => x.id);
            this.searchCategories = pagesItems.items.filter(x => !currentKeys.includes(x.id));
          });
        } else {
          this.searchCategories = [];
        }
      });
  }

  public compareByValue = ValueObjectService.compareByValue;

  public ngOnInit() {
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (this.data.itemId) {
          this.itemService.getSingle(this.data.itemId).subscribe((item) => {
            this.item = item;
            this.editFormGroup.patchValue(item);
          });
        }
        break;
      case EditMode.CREATE:
      //TODO
    }
  }

  listAllSearchItems(event: Event, trigger: MatAutocompleteTrigger) {
    this.searchAttributes = [];
    this.searchCategories = [];
    this.searchTemplates = [];
    this.attributeService.search({searchText: '', state: AttributeState.ACTIVE}).then((pagesItems) => {
      let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
      this.searchAttributes = pagesItems.items.filter(x => !currentKeys.includes(x.id))
    });
    this.templateService.search({searchText: '', state: TemplateState.ACTIVE}).then((pagesItems) => {
      this.searchTemplates = pagesItems.items
    });
    this.categoryService.search({searchText: '', flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
      this.searchAttributeCategories = pagesItems.items
    });

    event.stopPropagation();
    trigger.openPanel();
  }

  listAllSearchCategoryItems(event: Event, trigger: MatAutocompleteTrigger) {
    this.searchCategories = [];

    this.categoryService.search({searchText: '', flat: true, state: CategoryState.ACTIVE}).then((pagesItems) => {
      let currentKeys = (this.editFormGroup.get('categories')?.value as Category[]).map(x => x.id);
      this.searchCategories = pagesItems.items.filter(x => !currentKeys.includes(x.id));
    });

    event.stopPropagation();
    trigger.openPanel();
  }

  selectAttribute(event: MatAutocompleteSelectedEvent) {
    const searchItem = event.option.value;
    if ('attributes' in searchItem) {
      // template
      this.templateService.getSingle(searchItem.id).subscribe((template) => {
        let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
        let attributesToAdd = template.attributes.filter(x => !currentKeys.includes(x.id));

        this.editFormGroup.get('attributes')?.setValue(this.editFormGroup.get('attributes')?.value.concat(attributesToAdd))
      });
    } else if ('templates' in searchItem) {
      // category
      this.categoryService.getAttributesList(searchItem.id).subscribe((attributes) => {
        let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
        let attributesToAdd = attributes.filter(x => !currentKeys.includes(x.id));

        this.editFormGroup.get('attributes')?.setValue(this.editFormGroup.get('attributes')?.value.concat(attributesToAdd))
      });
    } else {
      // attribute
      this.editFormGroup.get('attributes')?.value.push(searchItem as Attribute);
    }

    this.attributeInput.nativeElement.value = '';
  }

  removeAttribute(index: number) {
    this.editFormGroup.get('attributes')?.value.splice(index, 1);
  }

  selectCategory(event: MatAutocompleteSelectedEvent) {
    const category = event.option.value as Category;

    this.categoryInput.nativeElement.value = '';
    this.editFormGroup.get('categories')?.value.push(category);

    this.openAddAttributeModal(category.id)
  }

  removeCategory(index: number) {
    this.editFormGroup.get('categories')?.value.splice(index, 1);
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  public assetUploadFinished(attribute: Attribute, asset: Asset) {
    console.log('assetUploadFinished for attribute ', attribute, '=>', asset);
    attribute.fileValue = asset;
  }

  public standardImageAssetUploadFinished(asset: Asset) {
    console.log('assetUploadFinished for attribute ', this.item, '=>', asset);
    this.item!.standardImage = asset;
  }

  getAssetUrl(attribute: Attribute) {
    if (attribute?.fileValue) {
      return `${environment.apiUrl}/asset/${attribute?.fileValue.id}`;
    }
    return null;
  }

  getStandardImageAssetUrl() {
    if (this.item?.standardImage) {
      return `${environment.apiUrl}/asset/${this.item?.standardImage.id}`;
    }
    return null;
  }

  getOriginalStandardImageAssetUrl() {
    if (this.item?.standardImage) {
      return `${environment.apiUrl}/asset/${this.item?.standardImage.id}/ORIGINAL`;
    }
    return '';
  }

  openCreateModal(event: MouseEvent) {
    const data: AttributeModalEditData = {
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(AttributeModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((attributeId?: string) => {
      if (attributeId) {
        this.attributeService.getSingle(attributeId).subscribe((attribute) => {
          this.editFormGroup.get('attributes')?.value.push(attribute);
        });
      }
    });
  }

  removeStandardImage() {
    if (this.item && this.item.standardImage) {
      this.item.standardImage = undefined
    }
  }

  openAddAttributeModal(categoryId: string) {
    const data: CategoryAddAttributesModal = {
      cancelAction: () => {
        console.log('** cancel');
      },

      proceedAction: () => {
        console.log('** RUN proceedAction');
        return new Promise(async resolve => {
          this.categoryService.getAttributesList(categoryId).subscribe((attributes) => {
            let currentKeys = (this.editFormGroup.get('attributes')?.value as Attribute[]).map(x => x.id);
            let attributesToAdd = attributes.filter(x => !currentKeys.includes(x.id));
            this.editFormGroup.get('attributes')?.setValue(this.editFormGroup.get('attributes')?.value.concat(attributesToAdd));
            resolve(true);
          });
        })
      },
    };

    this.matDialog.open(CategoryAddAttributesModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: false,
      data,
    });
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    editData.standardImage = this.item?.standardImage ? this.item?.standardImage : null;
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (!this.item) {
          return;
        }
        const {id: itemId} = this.item;
        this.itemService.patch(itemId, editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;

      case EditMode.CREATE:
        this.itemService.create(editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;
    }
  }
}
