import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { Attribute } from '../../attribute/attribute';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { AttributeService } from '../../attribute/attribute.service';
import { StateService } from '@uirouter/core';
import { AttributeModalData } from './attribute-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../../area/area-state.enum';
import { AttributeState } from '../attribute-state.enum';
import {Role} from "../../account/role.enum";
import {AttributeType} from "../attribute-type.enum";
import {ItemState} from "../../item/item-state.enum";
import {AttributeDeleteModalData} from "../attribute-delete-modal/attribute-delete-modal";
import {AttributeDeleteModalComponent} from "../attribute-delete-modal/attribute-delete-modal.component";

@Component({
  selector: 'app-attribute-modal',
  templateUrl: './attribute-modal.component.html',
  styleUrls: ['./attribute-modal.component.scss'],
})
export class AttributeModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  attribute: Attribute | null = null;
  pending: boolean = false;

  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: AttributeState.ACTIVE},
    {displayName: 'inaktiv', value: AttributeState.INACTIVE},
    {displayName: 'gelöscht ', value: AttributeState.DELETED},
  ];

  public typeOptions: ValueObject[] = [
    {displayName: 'Nummer', value: AttributeType.NUMBER},
    {displayName: 'Preis', value: AttributeType.PRICE},
    {displayName: 'Datum', value: AttributeType.DATE},
    {displayName: 'Text (einzeilig)', value: AttributeType.TEXT_ONE_LINE},
    {displayName: 'Text (mehrzeilig)', value: AttributeType.TEXT_MULTI_LINE},
    {displayName: 'Datei', value: AttributeType.FILE},
  ];

  public editFormGroup: FormGroup = this.fb.group({
    key: ['', [Validators.required]],
    description: ['', []],
    state: [AttributeState.ACTIVE, [Validators.required]],
    type: [AttributeType.TEXT_ONE_LINE, [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: AttributeModalData,
    public matDialogRef: MatDialogRef<AttributeModalComponent>,
    private matDialog: MatDialog,
    private attributeService: AttributeService,
    private stateService: StateService,
  ) {
  }

  public compareByValue = ValueObjectService.compareByValue;

  public ngOnInit() {
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (this.data.attributeId) {
          this.attributeService.getSingle(this.data.attributeId).subscribe((attribute) => {
            this.attribute = attribute;
            this.editFormGroup.patchValue(attribute);
          });
        }
        break;
      case EditMode.CREATE:
      //TODO
    }
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.EDIT:
        if (!this.attribute) {
          return;
        }
        const {id: attributeId} = this.attribute;
        if (editData.state === ItemState.DELETED) {

          console.log("editData", editData)

          const data: AttributeDeleteModalData = {
            cancelAction: () => {
              console.log('** cancel');
              return new Promise(async resolve => {
                this.pending = false;
                resolve(true)
              })
            },

            proceedAction: () => {
              console.log('** RUN proceedAction');
              return new Promise(async resolve => {
                this.attributeService.patch(attributeId, editData).subscribe((response) => {
                  this.pending = false;
                  this.matDialogRef.close(response);
                  resolve(true)
                });
              })
            },

            proceedExtendedAction: () => {
              console.log('** RUN proceedExtendedAction');
              return new Promise(async resolve => {
                editData.deleteInItems = true
                this.attributeService.patch(attributeId, editData).subscribe((response) => {
                  this.pending = false;
                  this.matDialogRef.close(response);
                  resolve(true)
                });
              })
            }
          };

          const dialogRef = this.matDialog.open(AttributeDeleteModalComponent, {
            width: '750px',
            maxWidth: '100%',
            minHeight: 'calc(100vh - 0)',
            // position: { top: '0', right: '0' },
            disableClose: false,
            data,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.matDialogRef.close()
            }
          })

        } else {
          this.attributeService.patch(attributeId, editData).subscribe((response) => {
            this.pending = false;
            this.matDialogRef.close(response);
          });
        }




        break;

      case EditMode.CREATE:
        this.attributeService.create(editData).subscribe((response) => {
          this.pending = false;
          this.matDialogRef.close(response);
        });
        break;
    }
  }
}
