import { Component, Input } from '@angular/core';
import { Company } from '../company';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { CompanyService } from '../company.service';
import { Asset } from '../../asset/asset';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-company-design',
  templateUrl: './company-design.component.html',
  styleUrls: ['./company-design.component.scss'],
})
export class CompanyDesignComponent {
  @Input() currentCompany!: Company;

  public editFormGroup: FormGroup = this.fb.group({
    designLogoDashboard: ['', []],
    designLogoMail: ['', []],
    designPrimaryColor: ['', []],
    designAccentColor: ['', []],
    designTextColor: ['', []],
    designTextLightColor: ['', []],
    designDividerColor: ['', []],
    designBackgroundColor: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private companyService: CompanyService,
  ) {
  }

  public pending: boolean = false;

  public ngOnInit() {
    this.editFormGroup.patchValue(this.currentCompany);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    const {value: editData} = editFormGroup;
    if (!this.currentCompany) {
      return;
    }

    this.pending = true;
    const {id: companyId} = this.currentCompany;

    this.companyService.patch(companyId, editData).subscribe(() => {
      this.pending = false;
      this.stateService.go('.', undefined, {reload: true});
    });
  }


  public assetUploadFinished(field: string, asset: Asset) {
    console.log('assetUploadFinished for field ', field, '=>', asset);
    const formField = this.editFormGroup.get(field);
    if (formField) {
      formField.setValue(asset);
    }
  }

  getAssetUrl(field: string) {
    const formField = this.editFormGroup.get(field);
    if (formField?.value) {
      return `${environment.apiUrl}/asset/${formField?.value.id}`;
    }

    return null;
  }
}
