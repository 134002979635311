import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PasswordConfirmModalData } from '../password-confirm-modal/password-confirm';
import { CPConfirmModalData } from './cp-confirm-modal';

@Component({
  selector: 'app-cp-confirm-modal',
  templateUrl: './cp-confirm-modal.component.html',
  styleUrls: ['./cp-confirm-modal.component.scss'],
})
export class CpConfirmModalComponent implements OnInit {
  pending: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CPConfirmModalData,
    public matDialogRef: MatDialogRef<CpConfirmModalComponent>,
  ) {

  }

  ngOnInit(): void {

  }

  cancel() {
    if (this.data && this.data.cancelAction) {
      this.data.cancelAction.apply(false);
      this.matDialogRef.close();
    }
  }

  proceed() {
    if (this.data && this.data.proceedAction) {
      const promise: Promise<any> = this.data.proceedAction();
      console.log('** ACTION promise:', promise);

      promise.then((result) => {
        console.log('** ACTION result:', result);
        this.matDialogRef.close();

      }, (err) => {
        console.log('** ACTION error:', err);
      });

      // this.data.proceedAction.apply(true).subscribe((result: boolean) => {
      //   console.log('** ACTION result:', result);
      // }, (err: any) => {
      //   console.log('** ACTION error:', err);
      // });
    }
  }
}
