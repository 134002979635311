import { Component, Input, OnInit } from '@angular/core';
import { DashboardEvaluation } from '../../dashboard/dashboardEvaluation';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { DashboardFilter } from '../../dashboard/dashboard.filter';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard/dashboard.service';
import { EvaluationFilter } from '../evaluation.filter';

@Component({
  selector: 'app-evaluation-risk',
  templateUrl: './evaluation-risk.component.html',
  styleUrls: ['./evaluation-risk.component.scss'],
})
export class EvaluationRiskComponent implements OnInit {
  @Input() dashboardEvaluation!: DashboardEvaluation;
  @Input() authInfo?: AuthInfo | null = null;

  evaluationFilter: EvaluationFilter;

  constructor(
    private matDialog: MatDialog,
    private dashboardService: DashboardService,
  ) {
    const evaluationFilterStr = localStorage.getItem('evaluationFilter');
    if (evaluationFilterStr) {
      this.evaluationFilter = JSON.parse(evaluationFilterStr);
    } else {
      this.evaluationFilter = {};
    }
  }

  ngOnInit(): void {
  }

  reloadEvaluation() {
    this.dashboardService.getEvaluationData().subscribe((dashboard) => {
      this.dashboardEvaluation = dashboard;
      this.ngOnInit();

      this.evaluationFilter = {...this.evaluationFilter};
    });
  }

  updateFilter() {
    localStorage.setItem('evaluationFilter', JSON.stringify(this.evaluationFilter));
    this.evaluationFilter = {...this.evaluationFilter};
  }
}
