<div mat-dialog-title>History</div>
<div mat-dialog-content>
  <div>
    <table>
      <thead>
        <th>Wann</th>
        <th>Was</th>
        <th>Wer</th>
        <th>Alter Wert</th>
        <th>Neuer Wert</th>
      </thead>
      <tbody>
        <tr *ngFor="let entry of this.entries">
          <td>
            {{entry.created | date:'dd.MM.yyyy HH:mm'}}
          </td>
          <td>{{entry.key}}</td>
          <td>{{entry.userInfo.fullName}}</td>
          <td>{{entry.oldValue}}</td>
          <td>{{entry.newValue}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <footer mat-dialog-actions>
    <button type="button" (click)="closeModal()" mat-raised-button mat-dialog-close>Schließen</button>
  </footer>
</div>
