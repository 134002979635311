<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Aufgabe anlegen
  </ng-template>
  <ng-template #editModeText>Task bearbeiten</ng-template>
</div>
<form
  novalidate
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
  <mat-tab-group animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="info-circle"></fa-icon>
        &nbsp;&nbsp;Allgemeines
      </ng-template>

      <div mat-dialog-content>
        <mat-form-field appearance="fill">
          <mat-label>Bezeichnung*</mat-label>
          <textarea
            matInput
            formControlName="displayName"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Beschreibung</mat-label>
          <textarea
            matInput
            formControlName="description"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <h3 class="subHeading">Timing</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Start</mat-label>
              <input
                matInput
                type="datetime"
                [ngxMatDatetimePicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(startDatePicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #startDatePicker
                [showSpinners]="false"
              ></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Ende</mat-label>
              <input
                matInput
                type="datetime"
                [ngxMatDatetimePicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(endDatePicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #endDatePicker
                [showSpinners]="false"
              ></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <h3 class="subHeading">Kosten / Nutzen</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Aufwand</mat-label>
              <input matInput type="number" min="0" formControlName="effort" />
              <span matSuffix>Stunden</span>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Nutzen*</mat-label>
              <mat-select
                [compareWith]="compareByValue"
                formControlName="benefit"
              >
                <mat-option
                  [value]="benefit?.value"
                  *ngFor="let benefit of benefitOptions"
                >
                  {{ benefit?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <h3 class="subHeading">Status der Aufgabe</h3>

        <mat-form-field appearance="fill">
          <mat-label>Fortschritt</mat-label>
          <input
            matInput
            type="number"
            min="0"
            max="100"
            formControlName="progress"
          />
          <span matSuffix>%</span>
        </mat-form-field>
      </div>
    </mat-tab>

    <mat-tab label="Risiken">
      <ng-template mat-tab-label>
        <fa-icon icon="sliders-h"></fa-icon>
        &nbsp;&nbsp;Details
      </ng-template>

      <div mat-dialog-content>
        <h3 class="subHeading">Organisatorisches</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <app-cp-tag-field [editFormGroup]="editFormGroup">
            </app-cp-tag-field>
          </div>
        </div>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Interner Status</mat-label>
              <mat-select
                [compareWith]="compareByValue"
                formControlName="state"
              >
                <mat-option
                  [value]="state?.value"
                  *ngFor="let state of stateOptions"
                >
                  {{ state?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <div class="checkboxes">
              <mat-checkbox formControlName="ignored"
                >Diese Aufgabe ignorieren</mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="bell"></fa-icon>
        &nbsp;&nbsp;Reminder
      </ng-template>

      <app-reminder-form-group
        class="-containsTable"
        mat-dialog-content
        formControlName="taskReminders"
      ></app-reminder-form-group>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="users"></fa-icon>
        &nbsp;&nbsp;Benutzer
      </ng-template>

      <div class="-containsTable" mat-dialog-content>
        <table
          mat-table
          [dataSource]="taskAccountsDataSource"
          formArrayName="taskAccounts"
        >
          <tr mat-header-row *matHeaderRowDef="taskAccountColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; let index = index; columns: taskAccountColumns"
          ></tr>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Account</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select
                  placeholder="Account"
                  [compareWith]="compareById"
                  (openedChange)="setAccountSelectForTaskAccount($event, index)"
                  formControlName="account"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="accountSearchCtrl"
                      placeholderLabel="Accounts suchen …"
                      noEntriesFoundLabel="'keine Accounts gefunden'"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    [value]="account"
                    *ngFor="
                      let account of isAccountSelectForTaskAccountOpen(index)
                        ? (filteredAccountOptions$ | async)
                        : (accountOptions$ | async)
                    "
                  >
                    {{ account?.firstName }}
                    {{ account?.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="role" stickyEnd>
            <th mat-header-cell *matHeaderCellDef>Rolle</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select placeholder="Rolle" formControlName="role">
                  <mat-option
                    [value]="role.value"
                    *ngFor="let role of roleOptions"
                  >
                    {{ role.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let index = index">
              <a (click)="removeTaskAccountFormGroup(index)">
                <fa-icon icon="trash"></fa-icon>
              </a>
            </td>
          </ng-container>
        </table>

        <a (click)="addTaskAccountFormGroup()" class="addItem-button">
          <fa-icon icon="user-plus"></fa-icon>
          Benutzer zuordnen
        </a>
      </div>
    </mat-tab>
  </mat-tab-group>
  <footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
  </footer>
</form>
