import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { AppStyle } from './app-style';
import { DesignParameter } from './design-parameter';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DesignHelperService {
  private renderer: Renderer2;
  validBodyClasses: string[] = ['hasNewMenue', 'noMainMenu', 'hasSubNav', 'hasBackLink', 'noMenue'];
  appStyle: AppStyle = {
    feedback: false,
    hasMainMenu: true,
    hasSubMenu: false,
    hasSideNav: false,
    isSubPage: false,
    bodyClass: [],
    bodyStyle: [],

    additionalHtmlClasses: [],
    additionalBodyClasses: [],
    htmlClass: [],
    htmlStyle: [],
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  applyDesignParameter(designParameter: DesignParameter): void {
    this.appStyle.feedback = designParameter.feedback;
    this.appStyle.hasMainMenu = designParameter.hasMainMenu;
    this.appStyle.hasSubMenu = designParameter.hasSubMenu;
    this.appStyle.hasSideNav = designParameter.hasSideNav;

    const indexMainMenu = this.appStyle.bodyClass.indexOf('hasNewMenue');
    if (this.appStyle.hasMainMenu) {
      if (indexMainMenu === -1) {
        this.appStyle.bodyClass.push('hasNewMenue');
      }

    } else {
      if (indexMainMenu > -1) {
        this.appStyle.bodyClass.splice(indexMainMenu, 1);
      }
    }

    const indexNoMainMenu = this.appStyle.bodyClass.indexOf('noMainMenu');
    if (!this.appStyle.hasMainMenu) {
      if (indexNoMainMenu === -1) {
        this.appStyle.bodyClass.push('noMainMenu');
      }

    } else {
      if (indexNoMainMenu > -1) {
        this.appStyle.bodyClass.splice(indexNoMainMenu, 1);
      }
    }


    const indexNoMenue = this.appStyle.bodyClass.indexOf('noMenue');
    if (!this.appStyle.hasSideNav) {
      if (indexNoMenue === -1) {
        this.appStyle.bodyClass.push('noMenue');
      }

    } else {
      if (indexNoMenue > -1) {
        this.appStyle.bodyClass.splice(indexNoMenue, 1);
      }
    }

    const indexSubMenu = this.appStyle.bodyClass.indexOf('hasSubNav');
    if (this.appStyle.hasSubMenu) {
      if (indexSubMenu === -1) {
        this.appStyle.bodyClass.push('hasSubNav');
      }
    } else {
      if (indexSubMenu > -1) {
        this.appStyle.bodyClass.splice(indexSubMenu, 1);
      }
    }

    const indexSubPage = this.appStyle.bodyClass.indexOf('hasBackLink');
    if (this.appStyle.isSubPage) {
      if (indexSubPage === -1) {
        this.appStyle.bodyClass.push('hasBackLink');
      }
    } else {
      if (indexSubPage > -1) {
        this.appStyle.bodyClass.splice(indexSubPage, 1);
      }
    }

    this.updateStyles();
  }

  updateStyles(): void {
    this.validBodyClasses.filter(c => this.appStyle.bodyClass.indexOf(c) === -1)
      .forEach(c => this.renderer.removeClass(this.document.body, c));

    this.appStyle.bodyClass
      .forEach(c => this.renderer.addClass(this.document.body, c));
  }
}
