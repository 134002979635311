import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { AuthInfo } from '../../core/auth-info/auth-info';
import {
  ChangeRoleModalComponent,
} from '../../common/account/change-role-modal/change-role-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  @Input() authInfo!: AuthInfo;

  constructor(
    private location: Location,
    private matDialog: MatDialog,
  ) {
  }

  closeMenuPage(): void {
    this.location.back();
  }

  openChangeRoleModal() {
    const dialogRef = this.matDialog.open(ChangeRoleModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {authInfo: this.authInfo},
    });
  }
}
