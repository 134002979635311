import { Pipe, PipeTransform } from '@angular/core';
import { IdObject } from 'src/app/core/id-object';
import { Risk } from './risk';

@Pipe({
  name: 'risksCommaSeparated',
})
export class RisksCommaSeparatedPipe implements PipeTransform {
  transform(risks: Risk[] | IdObject[] | null): unknown {
    if (!risks) {
      return null;
    }

    return (
      risks.filter((damageType) =>
        damageType && damageType.hasOwnProperty('displayName'),
      ) as Risk[]
    )
      .map((damageType) => damageType.displayName)
      .join(', ');
  }
}
