import {Component, ElementRef, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {Deposit, DepositPatch} from '../deposit';
import {DepositService} from '../deposit.service';
import {StateService} from '@uirouter/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DepositEditModalData} from './deposit-edit-modal-data';
import {environment} from '../../../../environments/environment';
import {Asset} from '../../asset/asset';
import {ItemService} from "../../item/item.service";
import {ItemState} from "../../item/item-state.enum";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Item, ItemSearchData} from "../../item/item";
import {startWith} from "rxjs/operators";
import {DepositQrScanModalComponent} from "../deposit-qr-scan-modal/deposit-qr-scan-modal.component";
import {DepositState} from "../deposit-state.enum";
import {DepositAgreementModalData} from "../deposit-agreement-modal/deposit-agreement-modal-data";
import {DepositAgreementModalComponent} from "../deposit-agreement-modal/deposit-agreement-modal.component";
import {Client} from "../../client/client";
import {ClientService} from "../../client/client.service";
import {resolveCurrentClient} from "../../client/client.resolver";
import {AuthInfoService} from "../../../core/auth-info/auth-info.service";
import {DepositDeleteModalData} from "../deposit-delete-modal/deposit-delete-modal";
import {DepositDeleteModalComponent} from "../deposit-delete-modal/deposit-delete-modal.component";

@Component({
  selector: 'app-deposit-edit-modal',
  templateUrl: './deposit-edit-modal.component.html',
  styleUrls: ['./deposit-edit-modal.component.scss'],
})
export class DepositEditModalComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  deposit: Deposit | null = null;
  pending: boolean = false;

  depositReturned: boolean = false;

  public depositPatch: DepositPatch = {};

  readonly itemsControl = new FormControl();
  @ViewChild('itemInput') itemInput!: ElementRef<HTMLInputElement>;
  searchItems: Item[] = [];

  public editFormGroup: FormGroup = this.fb.group({
    comment: ['', []],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    birthday: [null, []],
    idNumber: ['', []],
    state: ['', []],
    street: ['', [Validators.required]],
    plz: ['', [Validators.required]],
    location: ['', [Validators.required]],
    country: ['', []],
    items: [[], []],
    borrowedDate: [null, []],
    returnedDate: [null, []],
    onHoldDate: [null, []]
  });

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DepositEditModalData,
    private matDialogRef: MatDialogRef<DepositEditModalComponent>,
    private depositService: DepositService,
    private itemService: ItemService,
    private stateService: StateService,
    private authInfoService: AuthInfoService,
    private matDialog: MatDialog,
  ) {
    this.itemsControl.valueChanges
      .pipe(startWith(null))
      .subscribe((searchText: string | null) => {
        if (searchText && searchText.length > 2) {
          this.itemService.search({searchText, state: ItemState.ACTIVE}).then((pagesItems) => {
            let currentCodes = (this.editFormGroup.get('items')?.value as Item[]).map(x => x.code);
            this.searchItems = pagesItems.items.filter(x => !currentCodes.includes(x.code));
          });
        } else {
          this.searchItems = [];
        }
      });
  }

  public ngOnInit() {
    this.initDeposit()
  }

  initDeposit() {
    this.depositService.getSingle(this.data.depositId).subscribe((deposit) => {
      this.deposit = deposit;
      if (this.deposit.state !== DepositState.BORROWED) this.depositReturned = true;
      this.editFormGroup.patchValue(deposit);
    });
  }

  setDepositState(isOk: boolean) {
    if (this.deposit) {
      this.depositPatch.state = isOk ? DepositState.RETURNED : DepositState.ON_HOLD;
    }
  }

  selectItem(event: MatAutocompleteSelectedEvent) {
    const item = event.option.value as Item;

    this.itemInput.nativeElement.value = '';
    this.editFormGroup.get('items')?.value.push(item);
  }

  showAgreementModal() {

    if (this.deposit) {
      const data: DepositAgreementModalData = {
        letterOfAgreement: this.deposit.letterOfAgreement
      };

      this.matDialog.open(DepositAgreementModalComponent, {
        width: '750px',
        maxWidth: '100%',
        minHeight: 'calc(100vh - 0)',
        // position: {top: '0', right: '0'},
        data
      });
    }
  }

  deleteDeposit() {
    const data: DepositDeleteModalData = {
      cancelAction: () => {
        console.log('** cancel');
      },

      proceedAction: () => {
        console.log('** RUN proceedAction');
        return new Promise(async resolve => {
          if (this.deposit) {
            this.depositService.remove(this.deposit?.id).subscribe(() => {
              resolve(true)
            })
          }
        })
      },
    };

    const dialogRef = this.matDialog.open(DepositDeleteModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: false,
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.matDialogRef.close()
      }
    })
  }

  removeItem(index: number) {
    this.editFormGroup.get('items')?.value.splice(index, 1);
  }

  openQrScanModal(event: MouseEvent) {

    const dialogRef = this.matDialog.open(DepositQrScanModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
    });
    dialogRef.afterClosed().subscribe((result?: string) => {
      if (result) {
        let searchFilter: ItemSearchData = {
          searchText: result
        }
        this.itemService.search(searchFilter).then((pagesItems) => {
          if (pagesItems.items) {
            let currentCodes = (this.editFormGroup.get('items')?.value as Item[]).map(x => x.code)
            let searchItems = pagesItems.items.filter(x => !currentCodes.includes(x.code))
            if (searchItems.length > 0) {
              this.editFormGroup.get('items')?.value.push(searchItems[0])
            }
          }
        });
      }

    });
  }

  public onSubmit(editFormGroup: FormGroup) {
    console.log("on submit 01");
    if (!this.deposit) {
      return;
    }
    console.log("on submit 02");

    if (this.pending) {
      return;
    }

    console.log("on submit 03", this.depositPatch);
    this.pending = true;
    const {value: editData} = editFormGroup;
    const {id: depositId} = this.deposit;

    const patchData = {...editData, ...this.depositPatch};
    console.log("on submit 04", patchData);
    this.depositService.patch(depositId, patchData).subscribe(() => {
      this.pending = false;
      this.matDialogRef.close();
      this.stateService.go('app.deposit', undefined, {reload: true});
    });
  }

  updateSignature(data: any) {
    this.depositPatch.newSignature = data;
  }

  getAssetUrl(asset: Asset) {
    console.log("** get AssetUrl", asset);
    if (asset?.id) {
      return `${environment.apiUrl}/asset/${asset.id}`;
    }
    return null;
  }

  removeSignature() {
    if (!this.deposit) {
      return;
    }

    this.depositPatch.newSignature = null;
    this.deposit.signature = undefined;
  }
}
