<button class="closeDialog" aria-label="Abbrechen" mat-dialog-close>
  <fa-icon icon="times"></fa-icon>
</button>
<div mat-dialog-title *ngIf="isDisable">Authentifizierung deaktivieren</div>
<div mat-dialog-title *ngIf="isEnable">Authentifizierung aktivieren</div>
<div mat-dialog-title *ngIf="isDelete">Authentifizierung löschen</div>
<form
  novalidate
  (ngSubmit)="onSubmit(requestFormGroup)"
  [formGroup]="requestFormGroup"
>
  <div mat-dialog-content>
    <p *ngIf="isDisable">
      Wollen Sie die Authentifizierungsmethode wirklich deaktivieren?
    </p>
    <p *ngIf="isEnable">
      Möchten Sie die Authentifizierungsmethode wirklich aktivieren?
    </p>
    <p *ngIf="isDelete">
      Möchten Sie die Authentifizierungsmethode wirklich löschen?
    </p>
    <mat-form-field appearance="fill">
      <mat-label>Account-Passwort</mat-label>
      <input
        type="password"
        autocomplete="current-password"
        matInput
        formControlName="accountPassword"
        required
      />
      <mat-error *ngIf="requestFormGroup.hasError('required', 'accountPassword')">
        Bitte aktuelles Passwort angeben.
      </mat-error>
      <mat-error *ngIf="requestFormGroup.hasError('pattern', 'accountPassword')">
        Das eingegebene Passwort stimmt nicht überein.
      </mat-error>
    </mat-form-field>
  </div>
  <footer mat-dialog-actions>
    <button type="button" mat-raised-button mat-dialog-close>Nein</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="!requestFormGroup.get('accountPassword')?.value || pending || requestFormGroup.invalid">Ja</button>
  </footer>
</form>
