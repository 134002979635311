<header id="header" role="banner">
	<h1>Profil
    <small>Settings</small>
	</h1>
	<a href="" class="header-logo">
		<img src="/img/logos/logo-100x100-inverted.png" alt="guestoo - Gästemanagement einfach!" />
	</a>
</header>

<main role="main" id="content" class="areas">
  <app-profile-nav></app-profile-nav>
  <p>Settings works!</p>
</main>
