import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { AccountService } from '../../../common/account/account.service';
import { Account, AccountDraft } from '../../../common/account/account';
import { ValueObject } from '../../value-object';
import { Role } from '../../../common/account/role.enum';
import { CreateUserModalData, CreateUserModalMode } from './create-edit-user';
import { CompanyAccountService } from '../../../common/company/company-account.service';
import { AccountTarget } from '../../../common/account/accountTarget.enum';
import { CompanyAccount } from '../../../common/company/company';
import {DepositDeleteModalData} from "../../../common/deposit/deposit-delete-modal/deposit-delete-modal";
import {DepositDeleteModalComponent} from "../../../common/deposit/deposit-delete-modal/deposit-delete-modal.component";

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss'],
})
export class CreateEditUserComponent implements OnInit {
  public requestFormGroup: FormGroup = this.fb.group({
    email: ['', [Validators.required]],
    id: ['', []],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    role: ['', []],
  });

  pending: boolean = false;

  public roleOptions: ValueObject[] = [
    {displayName: 'Administrator', value: Role.ADMIN},
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateUserModalData,
    public matDialogRef: MatDialogRef<CreateEditUserComponent>,
    private accountService: AccountService,
    private companyAccountService: CompanyAccountService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.requestFormGroup.get('role')?.setValue(Role.ADMIN);

    const service: CompanyAccountService | AccountService = this.data.target === AccountTarget.COMPANY
      ? this.companyAccountService
      : this.accountService;

    if (this.data.id && this.data.mode === CreateUserModalMode.EDIT) {
      if (this.data.target === AccountTarget.COMPANY) {
        this.companyAccountService.getSingle(this.data.id)
          .subscribe((companyAccount) => {
            this.requestFormGroup.get('firstName')?.setValue(companyAccount.account.firstName);
            this.requestFormGroup.get('lastName')?.setValue(companyAccount.account.lastName);
            this.requestFormGroup.get('email')?.setValue(companyAccount.account.user.email);
            this.requestFormGroup.get('id')?.setValue(companyAccount.account.user.id);
            this.requestFormGroup.get('role')?.setValue(companyAccount.role);
          }, this.handleError);

      } else {
        this.accountService.getSingle(this.data.id)
          .subscribe((account) => {
            this.requestFormGroup.get('firstName')?.setValue(account.firstName);
            this.requestFormGroup.get('lastName')?.setValue(account.lastName);
            this.requestFormGroup.get('email')?.setValue(account.user.email);
            this.requestFormGroup.get('id')?.setValue(account.user.id);
            this.requestFormGroup.get('role')?.setValue(account.role);
          }, this.handleError);
      }
    }
  }

  deleteUser() {
    const data: DepositDeleteModalData = {
      cancelAction: () => {
        console.log('** cancel');
      },

      proceedAction: () => {
        console.log('** RUN proceedAction');
        return new Promise(async resolve => {
          if (this.data.id) {
            if (this.data.target === AccountTarget.COMPANY) {
              this.companyAccountService.remove(this.data.id)
                .subscribe((companyAccount) => {
                  this.matDialogRef.close()
                  resolve(true)
                }, this.handleError);

            } else {
              this.accountService.remove(this.data.id)
                .subscribe((account) => {
                  this.matDialogRef.close()
                  resolve(true)
                }, this.handleError);
            }
          }
        })
      },
    };

    const dialogRef = this.matDialog.open(DepositDeleteModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: false,
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.matDialogRef.close()
      }
    })
  }

  public onSubmit(requestFormGroup: FormGroup) {
    if (requestFormGroup.invalid) {
      return;
    }

    if (this.pending) {
      return;
    }

    const data: AccountDraft = {
      email: requestFormGroup.get('email')?.value,
      firstName: requestFormGroup.get('firstName')?.value,
      lastName: requestFormGroup.get('lastName')?.value,
      role: requestFormGroup.get('role')?.value,
      target: this.data.target,
    };

    this.pending = true;

    const service: CompanyAccountService | AccountService = this.data.target === AccountTarget.COMPANY
      ? this.companyAccountService
      : this.accountService;

    if (this.data.mode === CreateUserModalMode.CREATE) {
      service
        .create(data)
        .subscribe((success) => {
          this.pending = false;
          if (success) {
            console.log('** Account created: ', success);
            this.matDialogRef.close();
          }
        }, (err) => {
          this.handleError(err);
        });

    } else if (this.data.mode === CreateUserModalMode.EDIT && this.data.id) {
      service
        .patch(this.data.id, data)
        .subscribe((success) => {
          this.pending = false;
          if (success) {
            console.log('** Account updated: ', success);
            this.matDialogRef.close();
          }
        }, (err) => {
          this.handleError(err);
        });
    }
  }

  handleError(err: any) {
    this.pending = false;
    if (err.errorFields) {
      for (let errorField of err.errorFields) {
        switch (errorField.field) {
          case'email':
            this.requestFormGroup.get('email')?.setErrors({'pattern': true});
            break;
          case'firstName':
            this.requestFormGroup.get('firstName')?.setErrors({'pattern': true});
            break;
          case'lastName':
            this.requestFormGroup.get('lastName')?.setErrors({'pattern': true});
            break;
        }
      }
    }
  }

  isEditMode() {
    return this.data.mode === CreateUserModalMode.EDIT;
  }
}
