import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { StateService } from '@uirouter/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Compliance } from '../../compliance/compliance';
import { ComplianceService } from '../../compliance/compliance.service';
import { Tag } from '../../tag/tag';
import { Area } from '../area';
import { AreaService } from '../area.service';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../area-state.enum';

@Component({
  selector: 'app-area-edit-general',
  templateUrl: './area-edit-general.component.html',
  styleUrls: ['./area-edit-general.component.scss'],
})
export class AreaEditGeneralComponent implements OnInit {
  @Input() area: Area | null = null;
  @Input() parentAreaId: string | null = null;
  @Input() complianceId: string | null = null;
  @Input() editMode!: EditMode;
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  public stateOptions: ValueObject[] = [
    { displayName: 'aktiv', value: AreaState.ACTIVE },
    { displayName: 'gelöscht ', value: AreaState.DELETED },
  ];

  public pending: boolean = false;

  compliances$!: Observable<Compliance[]>;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    tags: [[], []],
    state: [AreaState.ACTIVE, [Validators.required]],
  });

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public navigationService: NavigationService,
    private areaService: AreaService,
    public complianceService: ComplianceService,
    private fb: FormBuilder,
    private stateService: StateService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-cancel.svg',
      ),
    );
    this.compliances$ = this.complianceService.getList();
  }

  public compareByValue = ValueObjectService.compareByValue;
  public compareWithId = IdObjectService.compareById;

  public ngOnInit() {
    this.editFormGroup.patchValue(this.area as Area);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    this.pending = true;
    const { value: editData } = editFormGroup;
    switch (this.editMode) {
      case EditMode.CREATE:
        if (this.parentAreaId) {
          editData.parentArea = {
            id: this.parentAreaId,
          };
        }

        if (this.complianceId) {
          editData.compliance = {
            id: this.complianceId,
          };
        }

        this.areaService.create(editData).subscribe(() => {
          this.pending = false;

          const params = { complianceId: this.complianceId };
          this.stateService.go('app.area', params, { reload: true });
        });

        break;
      case EditMode.EDIT:
        if (!this.area) {
          return;
        }

        const { id: areaId } = this.area;

        this.areaService.patch(areaId, editData).subscribe(() => {
          this.pending = false;
          this.stateService.go('app.area', undefined, { reload: true });
        });

        break;
    }
  }
}
