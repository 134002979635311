import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdObject } from 'src/app/core/id-object';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { UserPasswordUpdate } from './user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private static updatePasswordUrl = (apiUrl: string): string => `${apiUrl}user/password`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public updatePassword(id: string, passwordUpdate: UserPasswordUpdate): Observable<boolean> {
    const {apiUrl} = environment;
    const url = UserService.updatePasswordUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<void>(url, passwordUpdate, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('patch', false)),
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${UserService.name}->${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
