import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { Risk } from '../../risk/risk';
import { RiskEditModalCreateData } from '../../risk/risk-edit-modal/risk-edit-modal-data';
import { RiskEditModalComponent } from '../../risk/risk-edit-modal/risk-edit-modal.component';
import { RiskService } from '../../risk/risk.service';
import { Area, AreaCalculation } from '../area';
import { AreaEditModalEditData } from '../area-edit-modal/area-edit-modal-data';
import { AreaEditModalComponent } from '../area-edit-modal/area-edit-modal.component';
import { WorkspaceFocus } from '../../workspace/workspace.focus';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { WorkspaceService } from '../../workspace/workspace.service';
import { AreaService } from '../area.service';
import { MeasureState } from '../../measure/measure-state.enum';
import { AreaState } from '../area-state.enum';

@Component({
  selector: 'app-area-workspace-item',
  templateUrl: './area-workspace-item.component.html',
  styleUrls: ['./area-workspace-item.component.scss'],
})
export class AreaWorkspaceItemComponent implements OnInit {
  @Input() area!: Area;
  @Input() focus!: WorkspaceFocus;
  @Input() workspaceFilter!: WorkspaceFilter;
  @Output() areaChange = new EventEmitter<Area>();
  @Output() updateParentCalculation = new EventEmitter();

  @HostBinding('class.-flaggedExpanded') get isFlaggedExpanded(): boolean {
    return this.expanded;
  }

  @HostBinding('class.-flaggedFilterMatched') get isFlaggedFilterMatched(): boolean {
    return this.area.workspaceMatched === true
  }

  @HostBinding('class.-flaggedDeleted') get isFlaggedDeleted(): boolean {
    return this.area.state === AreaState.DELETED;
  }

  risks: Risk[] | null = null;

  expanded: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private riskService: RiskService,
    private workspaceService: WorkspaceService,
    private areaService: AreaService,
  ) {
  }

  public trackById = IdObjectService.trackById;
  private loaded: boolean = false;

  ngOnInit(): void {
    console.log('*** focus: ', this.focus);
    if (this.focus.area && this.focus.area.id === this.area.id) {
      this.setExpanded();
    }

    if (this.workspaceService.isExpanded(this.area.id)) {
      this.setExpanded();
    }
  }

  updateRisks() {
    const area = IdObjectService.convertToIdObject(this.area) as IdObject;

    const workspaceFilter = Object.assign(
      {workspaceAlreadyMatched: this.area.workspaceMatched || this.area.workspaceParentMatched},
      this.workspaceFilter,
    );

    this.riskService.search({area, workspaceFilter}).subscribe((risks) => {
      this.loaded = true;
      this.risks = risks;
    });
  }

  updateCalculation() {
    this.areaService.getSingleCalculation(this.area.id)
      .subscribe((calculation: AreaCalculation) => {
        this.area.calculation = calculation;
      });

    this.updateParentCalculation.emit();
  }

  openEditModal(event: MouseEvent) {
    const data: AreaEditModalEditData = {
      areaId: this.area.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(AreaEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((area?: Area) => {
      if (area) {
        this.area = area;
        this.areaChange.emit(area);
      }
    });
  }

  openCreateRiskModal(event: MouseEvent) {
    const data: RiskEditModalCreateData = {
      areaId: this.area.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.risks?.push(risk);
        this.updateRisks();
      }
      this.updateCalculation();
    });
  }

  isVisible() {
    return !this.focus?.area || this.focus?.area?.id === this.area.id;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus && changes.focus.currentValue) {
      if (this.focus?.area && this.focus?.area?.id === this.area.id) {
        this.setExpanded();
      }
    }

    if (changes.area && changes.area.currentValue) {
      if (this.expanded) {
        console.log('** change on area');
        this.updateRisks();
      }
    }
  }

  toggleExpand() {
    if (!this.expanded) {
      this.setExpanded();
    } else {
      this.setCollapsed();
    }
  }

  setCollapsed() {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
    this.workspaceService.setExpanded(this.area.id, false);
  }

  setExpanded() {
    if (this.expanded) {
      return;
    }

    this.expanded = true;
    if (!this.loaded) {
      this.updateRisks();
    }

    this.workspaceService.setExpanded(this.area.id, true);
  }

  getTooltipTotalHigh() {
    return `${this.area.calculation?.riskCountTotalHigh} von ${this.area.calculation?.riskCount} Risiken > 7000 Punkte'`;
  }

  getTooltipTotalDamageValue() {
    return `${this.area.calculation?.riskCountHighDamageValue} von ${this.area.calculation?.riskCount} Risiken mit Schadenshöhe > 80'`;
  }

  getTooltipTotalProbabilityValue() {
    return `${this.area.calculation?.riskCountHighProbabilityValue} von ${this.area.calculation?.riskCount} Risiken mit Eintrittswahrscheinlichkeit > 80'`;
  }

  getTooltipTotalNoMeasure() {
    return `${this.area.calculation?.riskCountNoMeasure} von ${this.area.calculation?.riskCount} Risiken ohne Maßnahmen'`;
  }

  public get cssClass(): string[] {
    const classes: string[] = [];

    if (this.expanded) {
      classes.push('containerExpanded');
    }

    if (this.area.workspaceMatched) {
      classes.push('filterMatched');
    }

    return classes;
  }
}
