import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasureWorkspaceItemComponent } from './measure-workspace-item.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatButtonModule } from '@angular/material/button';
import { TaskWorkspaceItemModule } from '../../task/task-workspace-item/task-workspace-item.module';
import { ProgressModule } from 'src/app/core/progress/progress.module';
import { RiskModule } from '../../risk/risk.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [MeasureWorkspaceItemComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    ProgressModule,
    TaskWorkspaceItemModule,
    RiskModule,
    MatTooltipModule,
  ],
  exports: [MeasureWorkspaceItemComponent],
})
export class MeasureWorkspaceItemModule {}
