<!--<header id="header" role="banner">
  <h1>
    Ausweis fotografieren
    <small>Schritt {{ currentStep + 1 }} von 5</small>
  </h1>
</header>-->


<main role="main" class="subView-main" id="content">
  <form [formGroup]="editFormGroup">
    <div [hidden]="currentStep === 4">
      <mat-horizontal-stepper #stepper (selectionChange)="onStepChange($event)">
        <mat-step state="picture" label="Ausweis fotografieren">
          <!-- ### Step 1 • Ausweis abfotografieren ### -->
          <section class="subView-steps">

            <mat-checkbox formControlName="isIdCardFromNetherlands">
              Holländischer Personalausweis
            </mat-checkbox>
            <!-- Seite 1 -->
            <label for="fileUploadPage1">
              <div class="cp-gui-item">
                <div class="cp-gui-upload status-empty" *ngIf="uploadStatePage1 === 0">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="camera"></fa-icon></div>
                    <div class="label">Bitte abfotografieren</div>
                  </div>
                  <div class="label style-big">Seite 1</div>
                </div>
                <div class="cp-gui-upload status-inProgess" *ngIf="uploadStatePage1 === 1">
                  <div class="status style-big">
                    <div class="icon"><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></div>
                    <div class="label">Upload … Bitte warten</div>
                  </div>
                  <div class="label style-big">Seite 1</div>
                </div>
                <div class="cp-gui-upload status-done" *ngIf="uploadStatePage1 === 2">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="check-circle"></fa-icon></div>
                    <div class="label">Fertig <small>(Hier klicken für neuen Upload)</small></div>
                  </div>
                  <div class="label style-big">Seite 1</div>
                </div>
                <div class="cp-gui-upload status-error" *ngIf="uploadStatePage1 === 3">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="exclamation-circle"></fa-icon></div>
                    <div class="label">Es gab einen Fehler … bitte neu hochladen</div>
                  </div>
                  <div class="label style-big">Seite 1</div>
                </div>
              </div>
            </label>

            <input type="file" id="fileUploadPage1" ref="fileUpload" (change)="uploadPage(1, $event)"
                   multiple accept="image/png,image/jpeg" capture="environment" hidden>

            <!-- Seite 1 -->
            <label for="fileUploadPage2">
              <div class="cp-gui-item">
                <div class="cp-gui-upload status-empty" *ngIf="uploadStatePage2 === 0">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="camera"></fa-icon></div>
                    <div class="label">Bitte abfotografieren</div>
                  </div>
                  <div class="label style-big">Seite 2</div>
                </div>
                <div class="cp-gui-upload status-inProgess" *ngIf="uploadStatePage2 === 1">
                  <div class="status style-big">
                    <div class="icon"><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></div>
                    <div class="label">Upload … Bitte warten</div>
                  </div>
                  <div class="label style-big">Seite 2</div>
                </div>
                <div class="cp-gui-upload status-done" *ngIf="uploadStatePage2 === 2">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="check-circle"></fa-icon></div>
                    <div class="label">Fertig <small>(Hier klicken für neuen Upload)</small></div>
                  </div>
                  <div class="label style-big">Seite 2</div>
                </div>
                <div class="cp-gui-upload status-error" *ngIf="uploadStatePage2 === 3">
                  <div class="status style-big">
                    <div class="icon"><fa-icon icon="exclamation-circle"></fa-icon></div>
                    <div class="label">Es gab einen Fehler … bitte neu hochladen</div>
                  </div>
                  <div class="label style-big">Seite 2</div>
                </div>
              </div>
            </label>

            <input type="file" id="fileUploadPage2" ref="fileUpload" (change)="uploadPage(2, $event)"
                   multiple accept="image/png,image/jpeg" capture="environment" hidden>
          </section>
        </mat-step>
        <mat-step state="items" label="Gegenstände zuordnen">
          <!-- ### Step 3 • Geliehene Objekte ### -->
          <section class="subView-steps">
            <!-- Liste Add / Remove -->
            <div class="cp-gui-item">
              <div class="cp-gui-list-v01" style="margin-top: -20px">
                <div class="add-items-header">
                  <div class="search">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Objekt suchen</mat-label>
                      <input type="text"
                             placeholder="Code, Bezeichnung"
                             aria-label="Number"
                             matInput
                             #itemInput
                             [formControl]="itemsControl"
                             [matAutocomplete]="itemAutocomplete">
                      <mat-autocomplete
                        #itemAutocomplete="matAutocomplete"
                        (optionSelected)="selectItem($event)"
                      >
                        <mat-option *ngFor="let item of searchItems" [value]="item">
                          <span *ngIf="item.displayName">{{item.displayName}}<small > / {{item.code}}</small></span>
                          <span *ngIf="!item.displayName">{{item.code}}</span>
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <a class="scan" (click)="openQrScanModal($event)">
                    <fa-icon icon="qrcode"></fa-icon>
                  </a>
                </div>
                <!-- Item List -->
                <div class="item-list">
                  <div class="itemList__entry" *ngFor="let item of editFormGroup.get('items')?.value; index as index">
                    <div class="item noIcon">
                      <div class="options-selection">
                        <a>
                          <span class="options-icon" (click)="removeItem(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                        </a>
                      </div>

                      <div class="details" *ngIf="!item?.displayName">

                        <span class="primary">
                          <strong>{{ item?.code }}</strong>
                        </span>

                      </div>

                      <div class="details" *ngIf="item?.displayName">

                        <span class="primary">
                          <strong>{{ item?.displayName }}</strong>
                        </span>
                        <span class="secondary" *ngIf="item?.code">
                          <span class="subItem">{{ item?.code }}</span>
                        </span>

                      </div>



                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
        </mat-step>
        <mat-step state="check" label="Ausweisdaten prüfen">
          <form [formGroup]="editFormGroup">
            <!-- ### Step 2 • Ausweisdaten prüfen ### -->

            <div class="cp-gui-upload status-inProgess" *ngIf="uploadStatePage1 === 1 || uploadStatePage2 === 1">
              <div class="status">
                <div class="icon"><fa-icon [icon]="['fas', 'spinner']" [spin]="true"></fa-icon></div>
                <div class="label">Upload … Bitte warten</div>
              </div>
            </div>

            <section class="subView-steps">

              <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                <mat-label>Vorname</mat-label>
                <input matInput type="text" formControlName="firstName" value="Stefan" />
              </mat-form-field>
              <mat-form-field style="display: block" appearance="fill">
                <mat-label>Nachname</mat-label>
                <input matInput type="text" formControlName="lastName" value="Wirtz" />
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>Geburtsdatum</mat-label>

                <input matInput [matDatepicker]="birthdayDatePicker" formControlName="birthday">
                <mat-datepicker-toggle matSuffix [for]="$any(birthdayDatePicker)"></mat-datepicker-toggle>
                <mat-datepicker #birthdayDatePicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>Ausweisnummer</mat-label>
                <input matInput type="text" formControlName="idNumber" value="1234567894" />
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>Straße + Hausnr.</mat-label>
                <input matInput type="text" formControlName="street" value="Musterstr. 2" />
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>PLZ</mat-label>
                <input matInput type="text" formControlName="plz" value="12345" />
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>Ort</mat-label>
                <input matInput type="text" formControlName="location" value="Musterstadt" />
              </mat-form-field>
              <mat-form-field style="display: block; " appearance="fill">
                <mat-label>Land</mat-label>
                <input matInput type="text" formControlName="country" value="Deutschland" />
              </mat-form-field>

            </section>
          </form>
        </mat-step>
        <mat-step state="sign" label="Unterschreiben & anlegen">
          <!-- ### Step 4 • Prüfen und Anlegen ### -->
          <section class="subView-steps">
            <div class="cp-gui-item">

              <!-- Ausleihende Person -->
              <div class="cp-gui-textinfo">
                <div class="options">
                  <a (click)="goToStep( 2)"><fa-icon icon="pencil-alt"></fa-icon></a>
                </div>
                <div class="width-100 subItem">
                  <div class="headline">Ausleihende Person</div>
                </div>

                <div class="width-50 subItem">
                  <div class="key">Name</div>
                  <div class="value">{{ editFormGroup.get('firstName')?.value }} {{ editFormGroup.get('lastName')?.value }}</div>
                </div>
                <div class="width-50 subItem">
                  <div class="key">Geburtstag</div>
                  <div class="value">{{ editFormGroup.get('birthday')?.value  | date:'dd.MM.yyyy' }}</div>
                </div>
                <div class="width-100 subItem">
                  <div class="key">Anschrift</div>
                  <div class="value">{{ editFormGroup.get('street')?.value }}, {{ editFormGroup.get('plz')?.value }} {{ editFormGroup.get('location')?.value }}</div>
                </div>
                <div class="width-100 subItem">
                  <div class="key">Ausweisnummer</div>
                  <div class="value">{{ editFormGroup.get('idNumber')?.value }}</div>
                </div>

              </div>

              <!-- Geliehene Objekte -->
              <div class="cp-gui-textinfo">
                <div class="options">
                  <a (click)="goToStep( 1)"><fa-icon icon="pencil-alt"></fa-icon></a>
                </div>

                <div class="width-100 subItem">
                  <div class="headline">Geliehene Objekte</div>
                </div>

                <div class="width-100 subItem" *ngFor="let item of editFormGroup.get('items')?.value">
                  <div class="width-100 subItem">
                    <div class="key">Code</div>
                    <div class="value">{{ item?.code }}<small *ngIf="item.displayName"> / {{item.displayName}}</small></div>
                  </div>
                </div>

              </div>

              <!-- Unterschreiben -->
              <div class="cp-gui-textinfo">

                <div class="width-100 subItem">
                  <div class="text" style="white-space: pre-wrap;">
                    {{ currentClient.letterOfAgreementShort }}<span *ngIf="currentClient.letterOfAgreement">…</span>
                  </div>
                  <a (click)="showAgreementModal()" *ngIf="currentClient.letterOfAgreement" class="readMore">Bedingungen vollständig anzeigen</a>
                </div>
                <div class="width-100 subItem">
                  <app-cp-signature [signature]="editFormGroup.get('signatureData')?.value" (signatureChanged)="updateSignature($event)">
                    {{ editFormGroup.get('firstName')?.value }} {{ editFormGroup.get('lastName')?.value }}, {{today | date:'dd.MM.yyyy'}}
                  </app-cp-signature>
                </div>
              </div>
            </div>

            <a class="footer-action-inside" (click)="onSubmit(editFormGroup)">Jetzt anlegen</a>

          </section>
        </mat-step>
        <!-- Icon overrides. -->
        <ng-template matStepperIcon="number" let-index="index">
          <fa-icon [icon]="['fas', 'camera']" *ngIf="index === 0"></fa-icon>
          <fa-icon [icon]="['fas', 'heart']" *ngIf="index === 1"></fa-icon>
          <fa-icon [icon]="['fas', 'id-card']" *ngIf="index === 2"></fa-icon>
          <fa-icon [icon]="['fas', 'file-signature']" *ngIf="index === 3"></fa-icon>
        </ng-template>

        <ng-template matStepperIcon="edit">
          <fa-icon [icon]="['fas', 'check']"></fa-icon>
        </ng-template>

      </mat-horizontal-stepper>
    </div>

    <div [hidden]="currentStep < 4">
      <!-- ### Step 5 • Bestätigung ### -->
      <section class="subView-steps">
        <div class="cp-gui-item">
          <div class="cp-gui-message-big status-ok">
            <div class="icon"><fa-icon icon="check-circle"></fa-icon></div>
            <div class="label">Vorgang erfolgreich angelegt</div>
          </div>
        </div>

      </section>
    </div>
  </form>
</main>

<!-- Step 1 -->
<footer class="subView-footer actions-2" *ngIf="currentStep === 0">
  <a class="footer-action notImportant" (click)="goToStep( 1)">Überspringen</a>
  <a class="footer-action" (click)="goToStep( 1)">Weiter</a>
</footer>
<!-- Step 2 -->
<footer class="subView-footer actions-2" *ngIf="currentStep === 1">
  <a class="footer-action notImportant" (click)="goToStep( 0)">Neu fotografieren</a>
  <a class="footer-action" (click)="goToStep( 2)">Speichern &amp; weiter</a>
</footer>
<!-- Step 3 -->
<footer class="subView-footer actions-2" *ngIf="currentStep === 2">
  <a class="footer-action" (click)="goToStep( 3)">Speichern &amp; weiter</a>
</footer>
<!-- Step 4
<footer class="subView-footer actions-2" *ngIf="currentStep === 3">
  <a class="footer-action" (click)="onSubmit(editFormGroup)">Sichern</a>
</footer> -->
<!-- Step 5 -->
<footer class="subView-footer actions-2" *ngIf="currentStep === 4">
  <a class="footer-action notImportant" (click)="editDeposit()">Vorgang bearbeiten</a>
  <a class="footer-action" (click)="resetAll()">Mehr verleihen</a>
</footer>



