<!-- Widget -->
<div *ngIf="selectedCompany">
  <header class="widget-header">
    <span class="icon"><fa-icon icon="building"></fa-icon></span>
    <span class="text">Unternehmens-Status</span>
  </header>
  <div class="content">
    <div class="item-type-intro status">
      <span class="key">
        {{selectedCompany.companyName}}
        <small>
          <fa-icon icon="money-bill" matTooltip="Kritische Größe des Mandanten"></fa-icon>&nbsp;<b matTooltip="Kritische Größe des Unternehmens">€ {{selectedCompany.criticalSize | number}}</b>
          &nbsp;|&nbsp;&nbsp;&nbsp;<fa-icon icon="gavel"></fa-icon>&nbsp;<b matTooltip="Gefüllte Compliance-Themen / Notwendige Complance-Themen"> {{selectedCompany.complianceCount}}/10</b>
        </small>
      </span>
      <span class="value statusText" matTooltip="Anteil Unternehmensrisiko an kritischem Maximalwert des Unternehmens"><!--{{getTotalRiskCompany() | number:'1.0-0' }}-->
        {{selectedCompany.percentageTotalCriticalSize | number:'1.0-1'}}%*
      </span>
    </div>
    <div class="item-type-graph status" [ngClass]="getProgressMeterClass(progressMeterType.SUB, compliance.percentageSum)" *ngFor="let compliance of selectedCompany.compliances">
      <span class="key">
        <span matTooltip="Anteil Risiko Compliance-Thema an Unternehmensrisiko">{{compliance.complianceName}} <span style="font-size: 0.8em; font-weight: normal;"> // {{compliance.percentageSum | number:'1.0-1'}}%</span></span>
        <small>
          <fa-icon icon="bomb" matTooltip="Unternehmenskritische Risiken"></fa-icon>&nbsp;<b matTooltip="Unternehmenskritische Risiken">{{compliance.criticalRiskCount}}</b>
          &nbsp;|&nbsp;<fa-icon icon="balance-scale" matTooltip="Anteil Risiko eines Unternehmens an anteiligem kritischen Maximalwert pro Unternehmen"></fa-icon>&nbsp;<b>{{compliance.percentageSumCriticalMax | number}}%</b>
        </small>
      </span>
      <span class="value" matTooltip="">{{compliance.capped | number:'1.0-0'}}</span>
      <span class="percentage statusBG" [ngStyle]="{width: compliance.percentageSum+'%'}"></span>
    </div>
  </div>
  <footer class="footer">
    <br>
    *Anteil Unternehmensrisiko an kritischem Maximalwert des Unternehmens
    <br><br>
    Zeigt den aktuellen Stand des Gesamtrisikos (Eintrittswahrsch. * Schadenshöhe) unter Berücksichtigung der getroffenen Maßnahmen und der angedachten zu befüllenden Compliance-Themen.
  </footer>
</div>
<!-- // Widget -->
