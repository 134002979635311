import { Account } from '../../account/account';
import { AccountService } from '../../account/account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { DamageType } from '../../damage-type/damage-type';
import { DamageTypeService } from '../../damage-type/damage-type.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { LegalArea } from '../../legal-area/legal-area.enum';
import { LegalBasis } from '../../legal-basis/legal-basis';
import { LegalBasisService } from '../../legal-basis/legal-basis.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Norm } from '../../norm/norm';
import { NormService } from '../../norm/norm.service';
import { Risk, RiskAccount, RiskAccountDraft, RiskRevision } from '../risk';
import { RiskRevisionModalData } from './risk-revision-modal-data';
import { RiskService } from '../risk.service';
import { ValueObjectService } from 'src/app/core/value-object.service';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProgressMeterType } from '../../../core/progress-meter/progress-meter-type.enum';
import { ValueObject } from '../../../core/value-object';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-risk-revision-modal',
  templateUrl: './risk-revision-modal.component.html',
  styleUrls: ['./risk-revision-modal.component.scss'],
})
export class RiskRevisionModalComponent implements OnInit {
  public riskRevision: RiskRevision | null = null;
  public progressMeterType = ProgressMeterType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RiskRevisionModalData,
    public matDialogRef: MatDialogRef<RiskRevisionModalComponent>,
    private fb: FormBuilder,
    private riskService: RiskService,
    private translateService: TranslateService,
  ) {
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  private probabilityValueOptions: ValueObject[] = [
    {displayName: '100%', value: 100},
    {displayName: 'mehrfach täglich und häufiger', value: 95},
    {displayName: 'täglich', value: 85},
    {displayName: 'mehrfach pro Woche', value: 75},
    {displayName: 'wöchentlich', value: 65},
    {displayName: 'mehrfach pro Monat', value: 55},
    {displayName: 'monatlich', value: 45},
    {displayName: 'mehrfach pro Quartal', value: 35},
    {displayName: 'quartalweise', value: 25},
    {displayName: 'jährlich', value: 15},
    {displayName: 'weniger als jährlich', value: 5},
    {displayName: 'gar nicht', value: 0},
  ];

  public get probabilityValue(): string {
    const value = this.probabilityValueOptions.find(o => o.value === this.riskRevision?.probabilityValue);
    return value ? value.displayName : '-';
  }

  ngOnInit(): void {
    this.riskService
      .getSingleRevision(this.data.riskId, this.data.revisionId)
      .subscribe((riskRevision) => {
        this.riskRevision = riskRevision;

        if (this.riskRevision.legalAreas) {
          const translatedLegalAreas: string[] = [];
          for (const legalArea of this.riskRevision.legalAreas) {
            this.translateService.get('LEGAL_AREA.' + legalArea)
              .subscribe((translation) => translatedLegalAreas.push(translation));
          }
          this.riskRevision.$$translatedLegalAreas = translatedLegalAreas;
        }
      });
  }

  public closeModal(): void {
    this.matDialogRef.close({});
  }
}
