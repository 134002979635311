import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Dashboard } from './dashboard';
import { environment } from '../../../environments/environment';
import { DashboardEvaluation } from './dashboardEvaluation';
import { DashboardRevisor, DashboardRevisorFilter } from '../revisor/dashboardRevisor';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private static listUrl = (apiUrl: string) => `${apiUrl}dashboard`;
  private static evaluationListUrl = (apiUrl: string) => `${apiUrl}dashboard/evaluation`;
  private static revisorListUrl = (apiUrl: string) => `${apiUrl}dashboard/revisor`;

  constructor(private http: HttpClient) {
  }

  getSingle(): Observable<Dashboard> {
    const {apiUrl} = environment;
    const url = DashboardService.listUrl(apiUrl);
    const headers = new HttpHeaders({});

    return this.http
      .get<Dashboard>(url, {headers})
      .pipe(
        catchError(this.handleError<Dashboard>('DashboardService->getSingle')),
      );
  }

  getEvaluationData(): Observable<DashboardEvaluation> {
    const {apiUrl} = environment;
    const url = DashboardService.evaluationListUrl(apiUrl);
    const headers = new HttpHeaders({});

    return this.http
      .post<DashboardEvaluation>(url, {headers}, {})
      .pipe(
        catchError(this.handleError<DashboardEvaluation>('DashboardService->getEvaluationData')),
      );
  }

  getRevisorData(filter: DashboardRevisorFilter): Observable<DashboardRevisor> {
    const {apiUrl} = environment;
    const url = DashboardService.revisorListUrl(apiUrl);
    const headers = new HttpHeaders({});

    return this.http
      .post<DashboardRevisor>(url, filter, {headers})
      .pipe(
        catchError(this.handleError<DashboardRevisor>('DashboardService->getRevisorData')),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
