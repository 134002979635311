import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyWidgetComponent } from './company-widget.component';



@NgModule({
  declarations: [
    CompanyWidgetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CompanyWidgetComponent,
  ],
})
export class CompanyWidgetModule { }
