<header id="header" role="banner">
  <h1>
    Bereiche
    <small>User</small>
  </h1>
  <a
    (click)="navigationService.goBack('app.area')"
    rel="back"
    class="header-icon header-icon-left"
    ng-if="!vm.event.parentEvent"
  >
    <fa-icon icon="chevron-left"></fa-icon>
  </a>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-area-edit-nav></app-area-edit-nav>
  <form class="o-areaEditUser" novalidate (ngSubmit)="onSubmit(editFormGroup)" [formGroup]="editFormGroup">
    <h3 class="subHeading">Benutzer</h3>
    <div class="-containsTable" mat-dialog-content>
      <table
        mat-table
        [dataSource]="areaAccountsDataSource"
        formArrayName="areaAccounts"
      >
        <tr mat-header-row *matHeaderRowDef="areaAccountColumns"></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            let index = index;
            columns: areaAccountColumns
          "
        ></tr>

        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef>Account</th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field appearance="standard">
              <mat-select
                placeholder="Account"
                [compareWith]="compareById"
                (openedChange)="
                  setAccountSelectForAreaAccount($event, index)
                "
                formControlName="account"
              >
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="accountSearchCtrl"
                    placeholderLabel="Accounts suchen …"
                    noEntriesFoundLabel="'keine Accounts gefunden'"
                  ></ngx-mat-select-search>
                </mat-option>
                <mat-option
                  [value]="account"
                  *ngFor="
                    let account of isAccountSelectForAreaAccountOpen(index)
                      ? (filteredAccountOptions$ | async)
                      : (accountOptions$ | async)
                  "
                >
                  {{ account?.firstName }}
                  {{ account?.lastName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef>Rolle</th>
          <td
            mat-cell
            *matCellDef="let row; let index = index"
            [formGroupName]="index"
          >
            <mat-form-field appearance="standard">
              <mat-select placeholder="Rolle" formControlName="role">
                <mat-option
                  [value]="role.value"
                  *ngFor="let role of roleOptions"
                >
                  {{ role.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="controls" stickyEnd>
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let index = index">
            <a (click)="removeAreaAccountFormGroup(index)">
              <fa-icon icon="trash"></fa-icon>
            </a>
          </td>
        </ng-container>
      </table>

      <a (click)="addAreaAccountFormGroup()" class="addItem-button">
        <fa-icon icon="user-plus"></fa-icon>
        Benutzer zuordnen
      </a>
    </div>
    <footer class="o-areaEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>
