import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdObject } from 'src/app/core/id-object';
import { environment } from 'src/environments/environment';
import { Category, CategoryDraft, CategorySearchData } from './category';
import {Deposit, DepositSearchData} from "../deposit/deposit";
import {PagedResponse, Paging, PagingHeader} from "../../core/paging/paging";
import {Item} from "../item/item";
import {Attribute} from "../attribute/attribute";

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}category`;
  private static attributesListUrl = (apiUrl: string, id: string): string => `${apiUrl}category/${id}/attributes`;
  private static singleUrl = (apiUrl: string, id: string): string => `${apiUrl}category/${id}`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}category/search`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {
  }

  public getList(): Observable<Category[]> {
    const {apiUrl} = environment;
    const url = CategoryService.listUrl(apiUrl);

    return this.httpClient
      .get<Category[]>(url)
      .pipe(catchError(this.handleError<Category[]>('CategoryService->getList', [])));
  }

  public getAttributesList(id: string): Observable<Attribute[]> {
    const {apiUrl} = environment;
    const url = CategoryService.attributesListUrl(apiUrl, id);

    return this.httpClient
      .get<Attribute[]>(url)
      .pipe(catchError(this.handleError<Attribute[]>('ItemService->getAttributesList', [])));
  }

  public getSingle(id: string): Observable<Category> {
    const {apiUrl} = environment;
    const url = CategoryService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Category>(url)
      .pipe(catchError(this.handleError<Category>('CategoryService->getSingle')));
  }

  public create(categoryDraft: CategoryDraft): Observable<string> {
    const {apiUrl} = environment;
    const url = CategoryService.listUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.post<IdObject>(url, categoryDraft, {headers}).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('CategoryService->create')),
    );
  }

  public patch(id: string, categoryDraft: CategoryDraft): Observable<boolean> {
    const {apiUrl} = environment;
    const url = CategoryService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});

    return this.httpClient.patch<void>(url, categoryDraft, {headers}).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('CategoryService->patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const {apiUrl} = environment;
    const url = CategoryService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('CategoryService->remove', false)),
    );
  }

  public search(
    categorySearchData: CategorySearchData,
    paging?: Paging,
  ): Promise<PagedResponse<Category[]>> {
    const {apiUrl} = environment;
    let url = CategoryService.searchUrl(apiUrl);
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = paging ? new HttpParams()
      .set('page', paging.currentPage)
      .set('sort', paging.sort)
      .set('sortDirection', paging.sortDirection)
      .set('perPage', paging.perPage) : undefined;

    return new Promise(async resolve => {
      this.httpClient
        .post<Category[]>(
          url,
          categorySearchData,
          {params, headers, observe: 'response'},
        )
        .pipe(catchError(this.handleError<HttpResponse<Category[]>>(
          'CategoryService->search',
          undefined,
        )))
        .subscribe((r) => {
          console.log('DONE - Response:', r);
          if (r.body) {
            const pagingHeader: PagingHeader = this.getPaging(r.headers);
            resolve({pagingHeader, items: r.body});
          }
        });
    });
  }

  getPaging(headers: HttpHeaders): PagingHeader {
    if (headers && headers.has('pagination-countavailable')) {
      const totalCategorys = headers.get('pagination-count');
      const perPage = headers.get('pagination-limit');
      const currentPage = headers.get('pagination-page');
      const hasNextPage = headers.get('pagination-nextpage');

      if (totalCategorys && perPage && currentPage && hasNextPage) {
        return {
          totalItems: parseInt(totalCategorys),
          perPage: parseInt(perPage),
          currentPage: parseInt(currentPage),
          hasNextPage: hasNextPage === 'true',
        };
      }
    }

    return {
      totalItems: 0, perPage: -1, currentPage: 0, hasNextPage: true,
    };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
