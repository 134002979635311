import { Component, Input, OnInit } from '@angular/core';
import { Measure, MeasureRevision } from '../../measure/measure';
import { MeasureRevisionModalData } from '../../measure/measure-revision-modal/measure-revision-modal-data';
import { MeasureRevisionModalComponent } from '../../measure/measure-revision-modal/measure-revision-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HistoryModalComponent } from '../history-modal/history-modal.component';
import { HistoryModalData } from '../history-modal/history-modal-data';

@Component({
  selector: 'app-cp-history',
  templateUrl: './cp-history.component.html',
  styleUrls: ['./cp-history.component.scss'],
})
export class CpHistoryComponent implements OnInit {
  @Input() id!: string;
  @Input() field?: string;
  @Input() module!: string;

  constructor(private matDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openHistoryModal() {
    const data: HistoryModalData = {
      entityId: this.id,
      field: this.field,
      module: this.module,
    };

    const dialogRef = this.matDialog.open(HistoryModalComponent, {
      width: '750px',
      maxWidth: '100%',
      position: {top: '0', right: '0'},
      data,
    });
  }
}
