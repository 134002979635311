<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Maßnahme anlegen
  </ng-template>
  <ng-template #editModeText>Maßnahme bearbeiten</ng-template>
</div>
<form
  novalidate
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
  <mat-tab-group animationDuration="0ms" (selectedTabChange)="tabClick($event)">
    <mat-tab *ngIf="data.revisor">
      <ng-template mat-tab-label>
        <fa-icon icon="user-check"></fa-icon>
        &nbsp;&nbsp;Revision
      </ng-template>

      <div mat-dialog-content *ngIf="measure">
        <h3 class="subHeading">Details</h3>

        <mat-form-field appearance="fill">
          <mat-label>Beschreibung der Prüfungshandlung*</mat-label>
          <textarea
            [readonly]="measure.auditConfirmed"
            matInput
            formControlName="auditPlotDescription"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Dokumentation der Prüfung*</mat-label>
          <textarea
            [readonly]="measure.auditConfirmed"
            matInput
            formControlName="auditResultDescription"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>
        <app-cp-history [id]="measure.id" field="auditResultDescription" module="DEPOSITOO_MEASURE"></app-cp-history>

        <mat-form-field appearance="fill">
          <mat-label>Stichprobengröße*</mat-label>
          <textarea
            [readonly]="measure.auditConfirmed"
            matInput
            formControlName="auditSampleSize"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <h3 class="subHeading">Zusammenfassung</h3>

        <mat-form-field appearance="fill">
          <mat-label>Art der Prüfung*</mat-label>
          <mat-select
            [disabled]="measure.auditConfirmed"
            [compareWith]="compareByValue"
            formControlName="auditType">
            <mat-option
              [value]="auditType.value"
              *ngFor="let auditType of auditTypeOptions">
              {{ auditType.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Ergebnis der Prüfung*</mat-label>
          <mat-select
            [disabled]="measure.auditConfirmed"
            [compareWith]="compareByValue"
            formControlName="auditResult">
            <mat-option
              [value]="auditResult.value"
              *ngFor="let auditResult of auditResultOptions">
              {{ auditResult.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Nächste Prüfung*</mat-label>
          <mat-select
            [disabled]="measure.auditConfirmed"
            [compareWith]="compareByValue"
            formControlName="auditNextDateType">
            <mat-option
              [value]="dateType.value"
              *ngFor="let dateType of auditNextDateOptions">
              {{ dateType.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="editFormGroup.get('auditNextDateType')?.value === 'CUSTOM' && !measure.auditConfirmed">
          <mat-label>Nächste Prüfung*</mat-label>
          <input
            matInput
            placeholder="Nächste Prüfung"
            [matDatepicker]="datePicker"
            formControlName="auditNextDate" />
          <mat-datepicker-toggle matSuffix [for]="$any(datePicker)"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>

        <button
          *ngIf="!measure.auditConfirmed"
          type="submit"
          (click)="updateSubmitType('submitAudit')"
          mat-raised-button
          color="primary"
          class="cta-button"
          [disabled]="pending">
          Prüfung abschließen
        </button>

        <div *ngIf="measure.auditConfirmed">
          <div>
            <label>Nächste Prüfung*</label><br />
            <span>{{measure.auditNextDate | date:'dd.MM.yyyy'}}</span>
          </div>

          <div>
            <h3 class="subHeading">Prüfung</h3>
            Durchgeführt am {{measure.auditLastDate | date:'dd.MM.yyyy'}} von {{measure.auditLastUser.firstName}}&nbsp;{{measure.auditLastUser.lastName}}
          </div>
        </div>
        <button
          *ngIf="measure.auditConfirmed"
          type="button"
          (click)="restartAudit()"
          mat-raised-button
          color="primary"
          class="cta-button"
          [disabled]="pending">
          Neue Revision starten
        </button>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="info-circle"></fa-icon>
        &nbsp;&nbsp;Allgemeines
      </ng-template>

      <div mat-dialog-content>
        <mat-form-field appearance="fill">
          <mat-label>Bezeichnung*</mat-label>
          <textarea
            matInput
            formControlName="displayName"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Beschreibung</mat-label>
          <textarea
            matInput
            formControlName="description"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Häufigkeit der Maßnahme</mat-label>
          <textarea
            matInput
            formControlName="frequency"
            cdkTextareaAutosize
          ></textarea>
        </mat-form-field>

        <h3 class="subHeading">Timing</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Start</mat-label>
              <input
                matInput
                type="datetime"
                [ngxMatDatetimePicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(startDatePicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #startDatePicker
                [showSpinners]="false"
              ></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Ende</mat-label>
              <input
                matInput
                type="datetime"
                [ngxMatDatetimePicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="$any(endDatePicker)"
              ></mat-datepicker-toggle>
              <ngx-mat-datetime-picker
                #endDatePicker
                [showSpinners]="false"
              ></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>
        </div>

        <h3 class="subHeading">Einordnung</h3>

        <div class="gridContainer" style="padding-top: 8px">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Art der Maßnahme 1*</mat-label>
              <mat-select
                [compareWith]="compareById"
                formControlName="firstType"
              >
                <mat-option
                  [value]="type"
                  *ngFor="
                    let type of measureTypeOptions$
                      | async
                      | measureType: SECURITY_TYPE
                  "
                >
                  {{ type?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Art der Maßnahme 2*</mat-label>
              <mat-select
                [compareWith]="compareById"
                formControlName="secondType"
              >
                <mat-option
                  [value]="type"
                  *ngFor="
                    let type of measureTypeOptions$
                      | async
                      | measureType: CONTROL_TYPE
                  "
                >
                  {{ type?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <h3 class="subHeading">Status der Maßnahme</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Reduzierung der Schadenshöhe<sup>1</sup></mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="100"
                formControlName="reachedEfficiencyDamageValue"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>
                Reduzierung der Eintrittswahrscheinlichkeit<sup>1</sup>
              </mat-label>
              <input
                matInput
                type="number"
                min="0"
                max="100"
                formControlName="reachedEfficiencyProbabilityValue"
              />
              <span matSuffix>%</span>
            </mat-form-field>
          </div>
        </div>
        <p class="infoText">
          <sup>1</sup> Der Status der Maßnahme berechnet automatisch
          entsprechend dem Status der zugeordneten Aufgaben. Hier können Sie
          aber einen individuellen bereits erreichten Wirkungsgrad hinterlegen.
        </p>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="sliders-h"></fa-icon>
        &nbsp;&nbsp;Details
      </ng-template>

      <div class="mat-dialog-content" mat-dialog-content>

        <!-- Kategorie ausblenden
        <mat-form-field appearance="fill">
          <mat-label>Maßnahmen-Kategorie</mat-label>
          <mat-select [compareWith]="compareById" formControlName="category">
            <mat-option [value]="null">
              <i>keine Kategorie</i>
            </mat-option>
            <mat-option
              [value]="category"
              *ngFor="let category of measureCategoryOptions$ | async"
            >
              {{ category?.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      -->

        <h3 class="subHeading">Organisatorisches</h3>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <app-cp-tag-field [editFormGroup]="editFormGroup">
            </app-cp-tag-field>
          </div>
        </div>

        <div class="gridContainer">
          <div class="gridItem giw-50">
            <mat-form-field appearance="fill">
              <mat-label>Interner Status</mat-label>
              <mat-select
                [compareWith]="compareByValue"
                formControlName="state"
              >
                <mat-option
                  [value]="state?.value"
                  *ngFor="let state of stateOptions"
                >
                  {{ state?.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="gridItem giw-50">
            <div class="checkboxes" style="padding-top: 8px">
              <mat-checkbox formControlName="ignored"
              >Diese Maßnahme ignorieren</mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Risiken">
      <ng-template mat-tab-label>
        <fa-icon icon="bolt"></fa-icon>
        &nbsp;&nbsp;Risiken
      </ng-template>

      <div class="-containsTable" mat-dialog-content>
        <table
          mat-table
          [dataSource]="riskMeasuresDataSource"
          formArrayName="riskMeasures"
        >
          <tr mat-header-row *matHeaderRowDef="riskMeasureColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; let index = index; columns: riskMeasureColumns"
          ></tr>

          <ng-container matColumnDef="risk">
            <th mat-header-cell *matHeaderCellDef>Risiko</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select
                  placeholder="Risiko"
                  [compareWith]="compareById"
                  (openedChange)="setRiskSelectForRiskMeasure($event, index)"
                  formControlName="risk"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="riskSearchCtrl"
                      placeholderLabel="Risiko suchen …"
                      noEntriesFoundLabel="'keine Risiken gefunden'"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    [value]="risk"
                    *ngFor="
                      let risk of isRiskSelectForRiskMeasureOpen(index)
                        ? (filteredRiskOptions$ | async)
                        : (riskOptions$ | async)
                    "
                  >
                    {{ risk?.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="damageValue">
            <th mat-header-cell *matHeaderCellDef>Schadenshöhe</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <input
                  type="number"
                  placeholder="Schadenshöhe"
                  matInput
                  formControlName="damageValue"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="probabilityValue">
            <th mat-header-cell *matHeaderCellDef>
              Eintrittswahrscheinlichkeit
            </th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <input
                  type="number"
                  placeholder="Eintrittswahrscheinlichkeit"
                  matInput
                  formControlName="probabilityValue"
                />
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="controls">
            <th mat-header-cell *matHeaderCellDef>
              <button
                type="button"
                mat-icon-button
                (click)="addRiskMeasureFormGroup()"
              >
                <fa-icon icon="plus"></fa-icon>
              </button>
            </th>
            <td mat-cell *matCellDef="let index = index">
              <button
                type="button"
                mat-icon-button
                (click)="removeRiskMeasureFormGroup(index)"
              >
                <fa-icon icon="minus"></fa-icon>
              </button>
            </td>
          </ng-container>
        </table>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="bell"></fa-icon>
        &nbsp;&nbsp;Reminder
      </ng-template>

      <app-reminder-form-group
        class="-containsTable"
        mat-dialog-content
        formControlName="measureReminders"
      ></app-reminder-form-group>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="users"></fa-icon>
        &nbsp;&nbsp;Benutzer
      </ng-template>

      <div class="-containsTable" mat-dialog-content>
        <table
          mat-table
          [dataSource]="measureAccountsDataSource"
          formArrayName="measureAccounts"
        >
          <tr mat-header-row *matHeaderRowDef="measureAccountColumns"></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              let index = index;
              columns: measureAccountColumns
            "
          ></tr>

          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef>Account</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select
                  placeholder="Account"
                  [compareWith]="compareById"
                  (openedChange)="
                    setAccountSelectForMeasureAccount($event, index)
                  "
                  formControlName="account"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="accountSearchCtrl"
                      placeholderLabel="Accounts suchen …"
                      noEntriesFoundLabel="'keine Accounts gefunden'"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    [value]="account"
                    *ngFor="
                      let account of isAccountSelectForMeasureAccountOpen(index)
                        ? (filteredAccountOptions$ | async)
                        : (accountOptions$ | async)
                    "
                  >
                    {{ account?.firstName }}
                    {{ account?.lastName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Rolle</th>
            <td
              mat-cell
              *matCellDef="let row; let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="standard">
                <mat-select placeholder="Rolle" formControlName="role">
                  <mat-option
                    [value]="role.value"
                    *ngFor="let role of roleOptions"
                  >
                    {{ role.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="controls" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let index = index">
              <a (click)="removeMeasureAccountFormGroup(index)">
                <fa-icon icon="trash"></fa-icon>
              </a>
            </td>
          </ng-container>
        </table>

        <a (click)="addMeasureAccountFormGroup()" class="addItem-button">
          <fa-icon icon="user-plus"></fa-icon>
          Benutzer zuordnen
        </a>
      </div>
    </mat-tab>

    <mat-tab label="Revisionen" *ngIf="!isCreate">
      <ng-template mat-tab-label>
        <fa-icon icon="user-clock"></fa-icon>
      &nbsp;&nbsp;Revisionen
      </ng-template>

      <div class="infobox" *ngIf="!measureRevisionsLoaded">Lädt...</div>
      <div class="infobox" *ngIf="measureRevisionsLoaded && measureRevisions?.length === 0">Keine Revisionen gefunden</div>
      <ul class="measureList" >
        <li *ngFor="let revision of measureRevisions"><a (click)="openRevisionModal(revision)">
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'EffectiveWithoutComplaint'"><fa-icon [icon]="['far', 'check-circle']"></fa-icon></span>
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'EffectiveWithComplaint'" ><fa-icon [icon]="['far', 'check-circle']" style="color: orange !important;"></fa-icon></span>
          <span class="icon" matTooltip="{{'AUDIT_RESULT.' + revision?.auditResult | translate}}" *ngIf="revision?.auditResult === 'Ineffective'"><fa-icon [icon]="['fas', 'ban']" style="color: red !important;"></fa-icon></span>
          Revision vom {{revision.revisionDate | date:'dd.MM.yyyy, HH:mm'}} erstellt durch {{revision.revisionUser.firstName}}&nbsp;{{revision.revisionUser.lastName}}
        </a></li>
      </ul>
    </mat-tab>
  </mat-tab-group>
  <footer mat-dialog-actions>
    <app-cp-history *ngIf="measure" [id]="measure.id" module="DEPOSITOO_MEASURE"></app-cp-history>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
  </footer>
</form>
