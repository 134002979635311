<div class="o-upload" >
  <ng-content></ng-content>

  <div class="dragAndDrop">
  <ngx-file-drop (onFileDrop)="drop($event)" [dropZoneLabel]="'Hier hin ziehen oder &nbsp;&nbsp;&nbsp;'"
    [multiple]="multiple"
    [dropZoneClassName]="'ngx-fileupload__ngx-file-drop'" [showBrowseBtn]="true" [browseBtnLabel]="'Rechner durchsuchen'"
     >
  </ngx-file-drop>
  </div>

  <div class="files" *ngIf="uploadRunning">
    <div *ngFor="let upload of uploads" class="upload">
        <div class="data">
          <span class="name">{{upload.data.name}}</span>
            <span class="uploaded">
              {{upload.data.uploaded | fileSize}} | {{upload.data.size | fileSize}} | {{upload.data.progress}}%
            </span>
            <span class="state">{{upload.data.state | stateToString}}</span>
        </div>

        <ngx-file-upload-ui--progressbar [progress]="upload.data.progress" [parts]="5" [gap]="1" [duration]="100">
        </ngx-file-upload-ui--progressbar>
    </div>
  </div>
</div>
