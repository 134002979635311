import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaWorkspaceItemComponent } from './area-workspace-item.component';
import { MatButtonModule } from '@angular/material/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RiskWorkspaceItemModule } from '../../risk/risk-workspace-item/risk-workspace-item.module';
import { AreaEditModalModule } from '../area-edit-modal/area-edit-modal.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [AreaWorkspaceItemComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    MatButtonModule,
    RiskWorkspaceItemModule,
    MatTooltipModule,
  ],
  exports: [AreaWorkspaceItemComponent],
})
export class AreaWorkspaceItemModule {}
