<button class="closeDialog" aria-label="Abbrechen" mat-dialog-close>
  <fa-icon icon="times"></fa-icon>
</button>
<div mat-dialog-title>Rolle wechseln</div>
<div mat-dialog-content>
  <div class="change-role">
    <ul>
      <li *ngFor="let account of accounts">
        <a target="_self" (click)="useAccount(account)" [ngClass]="{active: authInfo?.client?.id === account.clientId}">
          <span class="icon">
            <fa-icon icon="crown" *ngIf="account.role===Role.ADMIN"></fa-icon>
          </span>
          <span class="text">
            <span class="user-company">{{account.companyName}}</span>
            <span class="user-client">{{account.clientName}}</span>
            <span class="user-role">{{'ROLE.' + account.role | translate}}</span>
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>
