import {Component, Inject, Input, NgZone, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { EditMode } from '../../../core/edit-mode.enum';
import { Area } from '../../area/area';
import { Avv } from '../avv';
import { AvvService } from '../avv.service';
import { StateService } from '@uirouter/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaEditModalData } from '../../area/area-edit-modal/area-edit-modal-data';
import { AreaState } from '../../area/area-state.enum';
import { AvvState } from '../avv-state.enum';
import {BarcodeFormat} from "@zxing/library";
import {ComplianceEditModalData} from "../../compliance/compliance-edit-modal/compliance-edit-modal-data";
import {AvvConfirmationModalData} from "./avv-confirmation-modal-data";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-avv-confirmation-modal',
  templateUrl: './avv-confirmation-modal.component.html',
  styleUrls: ['./avv-confirmation-modal.component.scss'],
})
export class AvvConfirmationModalComponent implements OnInit {
  state?: string | null = null
  appName?: string | null = null
  appDisplayName?: string | null = null
  redirectUrl?: string | null = null
  fileId?: string | null = null

  constructor(
    private matDialogRef: MatDialogRef<AvvConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AvvConfirmationModalData,
    private avvService: AvvService,
  ) {
  }

  public ngOnInit() {
    this.state = this.data.state
    this.appName = this.data.appName
    this.redirectUrl = this.data.redirectUrl
    this.fileId = this.data.fileId
  }

  backToApp() {
    this.avvService.getRedirectUrl().subscribe((redirectUrl) => {
      window.location.href = redirectUrl.result;
    })
  }

  closeDialog() {
    this.matDialogRef.close()
  }

  revokeAvv() {
    if (this.data && this.data.revokeAction) {
      const promise: Promise<any> = this.data.revokeAction();
      promise.then((result) => {
        this.matDialogRef.close(false);
      });
    }
  }

  getAvvDownloadUrl(){
    if (this.fileId) {
      return `${environment.apiUrl}/asset/${this.fileId}/ORIGINAL`;
    }
    return '';
  }

}
