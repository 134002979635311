import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryModalComponent } from './category-modal/category-modal.component';
import { UIRouterModule } from '@uirouter/angular';
import { CATEGORY_STATES} from './category.states';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';
import { CategoryOverviewComponent } from './category-overview/category-overview.component';
import {PagingModule} from "../../core/paging/paging.module";
import {SettingsModule} from "../settings/settings.module";
import {CategoryItemComponent} from "./category-item/category-item.component";

@NgModule({
  declarations: [
    CategoryOverviewComponent,
    CategoryModalComponent,
    CategoryItemComponent
  ],
    imports: [
        CommonModule,
        UIRouterModule.forChild({states: CATEGORY_STATES}),
        TranslateModule.forChild({}),
        FontAwesomeModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ValidationModule,
        PagingModule,
        SettingsModule,
    ],
})
export class CategoryModule {
}
