import { Component, Input } from '@angular/core';
import { Area, AreaDraft, AreaSearchData } from '../area';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AreaService } from '../area.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Compliance } from '../../compliance/compliance';
import { Observable } from 'rxjs';
import { ComplianceService } from '../../compliance/compliance.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IdObjectService } from 'src/app/core/id-object.service';
import { StateParams, StateService } from '@uirouter/core';
import { IdObject } from '../../../core/id-object';

@Component({
  selector: 'app-area-overview',
  templateUrl: './area-overview.component.html',
  styleUrls: ['./area-overview.component.scss'],
})
export class AreaOverviewComponent {
  @Input() complianceId: string | null = null;
  @Input() areas: Area[] = [];

  public filterFormGroup: FormGroup = this.fb.group({
    compliance: [undefined, []],
  });

  expandedAreas = new SelectionModel<Area>(true);

  public complianceOptions$!: Observable<Compliance[]>;

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private fb: FormBuilder,
    private areaService: AreaService,
    private complianceService: ComplianceService,
    private stateService: StateService,
  ) {
    this.matIconRegistry.addSvgIcon(
      'expandLess',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-expand_less.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'expandMore',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-expand_more.svg',
      ),
    );
    this.matIconRegistry.addSvgIcon(
      'settings',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-settings.svg',
      ),
    );

    this.complianceOptions$ = this.complianceService.getList();

    this.filterFormGroup.valueChanges.subscribe(
      (areaSearchData: AreaSearchData) => {
        this.areaService.search(areaSearchData).subscribe((areas) => {
          this.areas = areas;
        });
      },
    );
  }

  public compareById = IdObjectService.compareById;

  onAreaDropped(event: CdkDragDrop<string[]>) {
    const { previousIndex, currentIndex } = event;
    const { id } = this.areas[previousIndex];
    const areaDraft: AreaDraft = { sortOrder: currentIndex };

    moveItemInArray(this.areas, previousIndex, currentIndex);
    this.areaService.patch(id, areaDraft).subscribe();
  }

  ngOnInit() {
    if (this.complianceId) {
      this.complianceOptions$.subscribe((compliances: Compliance[]) => {
        const selectedCompliance = compliances.find(c => c.id === this.complianceId);
        if (selectedCompliance) {
          this.filterFormGroup.setValue({'compliance': selectedCompliance});
        }
      });
    }
  }
}
