<!--<header id="header" role="banner">-->
<!--  <h1>-->
<!--    Rückgabe anlegen-->
<!--  </h1>-->
<!--</header>-->


<main role="main" class="subView-main">

  <!-- ### Step 1 • Rückgabe scannen ### -->
  <section class="subView-steps"*ngIf="!deposit">

    <zxing-scanner [formats]="allowedQrFormats"
                   (scanSuccess)="scanSuccessHandler($event)"></zxing-scanner>

    <div *ngIf="searchingDeposit">
      Suche läuft...
    </div>

  </section>
  <!-- ### // Step 1 • Rückgabe scannen ### -->

  <!-- ### Step 2 • Rückgabe prüfen ### -->
  <section class="subView-steps" *ngIf="deposit">
<!--    • Status: (Rückgabe OK / Fehlerhaft - nicht löschen)-->
<!--    • Kommentar-->
    <div class="cp-gui-item">

      <!-- Details Leihen -->
      <div class="cp-gui-textinfo">

        <div class="width-100 subItem">

          <div class="selectItem">
            <div class="label">Status der Rückgabe</div>
            <ul>
              <li class="positive" [ngClass]="{'active': deposit.state === 'RETURNED'}" (click)="saveDeposit(true)">
                <span class="icon"><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon></span>
                <span class="label">Rückgabe OK <small>Vorgang wird nach <b>X</b> Tagen gelöscht</small></span>
              </li>
              <li class="negative" [ngClass]="{'active': deposit.state === 'ON_HOLD'}" (click)="saveDeposit(false)">
                <span class="icon"><fa-icon [icon]="['fas', 'thumbs-down']"></fa-icon></span>
                <span class="label">Rückgabe mangelhaft <small>Vorgang bleibt erhalten</small></span>
              </li>
            </ul>
          </div>

          <div class="inputItem margin-top-medium">
            <label class="label">Kommentar</label>
            <textarea class="textarea" [(ngModel)]="deposit.comment"></textarea>
          </div>
        </div>

        <div class="divider"></div>

        <div class="width-100 subItem">
          <div class="headline">Ausleihende Person</div>
        </div>

        <div class="width-50 subItem">
          <div class="key">Name</div>
          <div class="value">{{ deposit.firstName }} {{ deposit.lastName }}</div>
        </div>
        <div class="width-50 subItem">
          <div class="key">Geburtstag</div>
          <div class="value">{{ deposit.birthday  | date:'dd.MM.yyyy' }}</div>
        </div>

        <div class="width-100 subItem" style="padding-top: 20px">
          <div class="headline">Geliehene Objekte: <b>{{ deposit.items.length }}</b></div>
        </div>

        <div class="width-100 subItem" *ngFor="let item of deposit.items">
          <div class="width-50 subItem">
            <div class="key">Code</div>
            <div class="value">{{ item?.code }}<small *ngIf="item.displayName"> / {{item.displayName}}</small></div>
          </div>
        </div>

      </div>
    </div>

  </section>
  <!-- ### // Step 2 • Rückgabe prüfen ### -->

  <!-- ### Step 3 • Bestätigung ### -->
  <section class="subView-steps" *ngIf="depositSuccessfullySaved">

    <div class="cp-gui-item">
      <div class="cp-gui-message-big status-ok">
        <div class="icon"><fa-icon icon="check-circle"></fa-icon></div>
        <div class="label">Rückgabe erfolgreich gespeichert</div>
      </div>
    </div>

  </section>
  <!-- ### // Step 3 • Bestätigung ### -->


</main>

<footer class="subView-footer actions-2">
  <!-- Step 1 -->
  <a class="footer-action" uiSref="app.deposit" *ngIf="!deposit">Manuell suchen</a>
  <!-- Step 2 -->
  <a class="footer-action notImportant" href="/deposit/return" *ngIf="deposit">Abbrechen</a>
  <a class="footer-action" *ngIf="deposit" (click)="onSubmit()">Rücknahme abschlie&szlig;en</a>
  <!-- Step 3 -->
  <a class="footer-action notImportant" *ngIf="depositSuccessfullySaved">Vorgang bearbeiten</a>
  <a class="footer-action" href="/deposit/return" *ngIf="depositSuccessfullySaved">Mehr zurücknehmen</a>

</footer>
