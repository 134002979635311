<div [ngClass]="cssClass">
  <header class="header">
    <button type="button" class="headerCheckbox" (click)="isDone = !isDone" matTooltip="Aufgabe als erledigt markieren">
      <fa-icon icon="check" *ngIf="isDone"></fa-icon>
    </button>
    <span class="itemBadge" *ngIf="isDeleted">DELETED</span>
    <span class="itemBadge" *ngIf="task.ignored">IGNORED</span>
    <h6 class="heading" (click)="openEditModal($event)">
      <s *ngIf="isDeleted; else notStrikethroughText">{{ task.displayName }}</s>
      <ng-template #notStrikethroughText><span>{{ task.displayName }}</span></ng-template>
    </h6>
  </header>
  <div class="body" *ngIf="showBody">
    <app-progress [progress]="task.progress"></app-progress>
    <div class="tags">
      <span
        class="a-tag"
        *ngIf="task.taskAccounts && task.taskAccounts.length > 0"
      >
        <fa-icon icon="users" class="a-tag__icon"></fa-icon>
        <span
          *ngFor="
            let taskAccount of task.taskAccounts;
            let last = last;
            trackBy: trackById
          "
        >
          <span
            class="a-tag__userName"
            [ngClass]="{ responsible: isResponsible(taskAccount.role) }"
            >{{ taskAccount.account.firstName }}
            {{ taskAccount.account.lastName }}</span
          ><span *ngIf="!last">, </span>
        </span>
      </span>
      <span class="a-tag" *ngIf="task.startDate || task.endDate">
        <fa-icon icon="calendar" class="a-tag__icon"></fa-icon>
        <span class="a-tag__partial" *ngIf="task.startDate">
          Start: {{ task.startDate | date: 'EE, dd.MM.yyyy' }} &nbsp;
        </span>
        <span
          class="a-tag__partial"
          [class.-warn]="warnAboutEndDate"
          *ngIf="task.endDate"
        >
          Ende: {{ task.endDate | date: 'EE, dd.MM.yyyy' }}
        </span>
      </span>
      <span class="a-tag" *ngIf="task.taskReminders?.length">
        <fa-icon icon="bell" class="a-tag__icon"></fa-icon>
        <ng-container
          *ngFor="let reminder of task.taskReminders; let last = last"
        >
          <span
            class="a-tag__partial"
            [class.-italic]="isPrivate(reminder.reminderType)"
            >{{ reminder.date | date: 'E, dd.MM.y HH:mm' }}</span
          ><ng-container *ngIf="!last">, </ng-container>
        </ng-container>
      </span>
      <span class="a-tag" *ngIf="task.effort">
        <fa-icon icon="stopwatch" class="a-tag__icon"></fa-icon>
        {{ task.effort }} Stunden
      </span>
    </div>
  </div>
</div>
