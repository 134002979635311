import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordValidationComponent } from './password-validation/password-validation.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { CpConfirmComponent } from './cp-confirm/cp-confirm.component';
import { CpConfirmModalComponent } from './cp-confirm-modal/cp-confirm-modal.component';

@NgModule({
  declarations: [PasswordValidationComponent, CpConfirmComponent, CpConfirmModalComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatChipsModule,
  ],
  exports: [
    PasswordValidationComponent,
    CpConfirmComponent,
  ],
})
export class ValidationModule {
}
