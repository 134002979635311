import { Transition } from '@uirouter/angular';
import { Area } from './area';
import { AreaService } from './area.service';

export function resolveAreaList(
  transition: Transition,
  areaService: AreaService,
): Promise<Area[]> {
  const { searchText } = transition.params();
  const searchData = { searchText };

  return new Promise((resolve) => {
    areaService.search(searchData).subscribe(resolve);
  });
}

export function resolveAreaSingle(
  transition: Transition,
  areaService: AreaService,
): Promise<Area> {
  const { areaId } = transition.params();

  return new Promise((resolve) => {
    areaService.getSingle(areaId).subscribe(resolve);
  });
}

export function resolveParentAreaId(transition: Transition): string {
  const { parentAreaId } = transition.params();

  return parentAreaId;
}
