import { Transition } from '@uirouter/angular';
import { Company } from './company';
import { CompanyService } from './company.service';

export function resolveCurrentCompany(
  transition: Transition,
  companyService: CompanyService,
): Promise<Company> {
  return new Promise((resolve, reject) => {
    transition
      .injector()
      .getAsync('authInfo')
      .then((authInfo) => {
        if (!authInfo?.company?.id) {
          reject('current company not found in AuthInfo');
        }

        const { id: companyId } = authInfo.company;

        companyService.getSingle(companyId).subscribe(resolve);
      });
  });
}
