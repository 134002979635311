import { Component, Input } from '@angular/core';
import { AuthInfo } from 'src/app/core/auth-info/auth-info';
import { CpConfirmModalComponent } from '../../core/validation/cp-confirm-modal/cp-confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ChangeRoleModalComponent } from '../../common/account/change-role-modal/change-role-modal.component';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  @Input() authInfo!: AuthInfo;

  constructor(private matDialog: MatDialog) {
  }

  openChangeRoleModal() {
    const dialogRef = this.matDialog.open(ChangeRoleModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {authInfo: this.authInfo},
    });
  }
}
