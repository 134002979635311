import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Measure } from '../measure';
import { MeasureService } from '../measure.service';

@Component({
  selector: 'app-unassigned-measures-workspace-item',
  templateUrl: './unassigned-measures-workspace-item.component.html',
  styleUrls: ['./unassigned-measures-workspace-item.component.scss'],
})
export class UnassignedMeasuresWorkspaceItemComponent {
  unassignedMeassures$!: Observable<Measure[]>;

  expanded: boolean = false;

  constructor(private measureService: MeasureService) {
    this.unassignedMeassures$ = this.measureService.search({
      onlyUnassigned: true,
    });
  }
}
