import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {MatDialog} from '@angular/material/dialog';
import {Avv} from '../avv';
import {AvvService} from '../avv.service';
import {AvvState} from "../avv-state.enum";
import {AvvConfirmationModalData} from "../avv-confirmation-modal/avv-confirmation-modal-data";
import {AvvConfirmationModalComponent} from "../avv-confirmation-modal/avv-confirmation-modal.component";
import {environment} from "../../../../environments/environment";
import {AvvField} from "../avv-field";

@Component({
  selector: 'app-avv-overview',
  templateUrl: './avv-overview.component.html',
  styleUrls: ['./avv-overview.component.scss'],
})
export class AvvOverviewComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  avv: Avv | null = null;
  avvExtraFields: AvvField[] = [];
  public pending: boolean = false;
  public canBeRevoked: boolean = false;
  public canAvvBeDownloaded: boolean = false;

  public initialState: AvvState | null = null

  public editFormGroup: FormGroup = this.fb.group({
    clientName: ['', []],
    clientStreet: ['', []],
    clientLocation: ['', []],
    clientPlz: ['', []],
    clientCountry: ['', []],
    state: [AvvState.UNDEFINED, [Validators.required]],
    categories: ['', []],
    typeOfPersonalData: ['', []],
    personsAuthorized: ['', []],
    reportingType: ['', []],
    avvFields: this.fb.array([])
  });

  constructor(
    private fb: FormBuilder,
    private avvService: AvvService,
    private matDialog: MatDialog
  ) {

  }

  public ngOnInit() {
    this.avvService.getCurrentAvv().subscribe((avv) => {
      this.avv = avv
      this.avvExtraFields = this.avv.avvFields
      if (this.avv.state === AvvState.UNDEFINED) {
        this.editFormGroup.get('state')?.setValue(AvvState.UNDEFINED)
        this.editFormGroup.get('clientName')?.setValue(this.authInfo.appData.clientName)
        this.editFormGroup.get('clientStreet')?.setValue(this.authInfo.appData.clientStreet)
        this.editFormGroup.get('clientLocation')?.setValue(this.authInfo.appData.clientLocation)
        this.editFormGroup.get('clientPlz')?.setValue(this.authInfo.appData.clientPlz)
        this.editFormGroup.get('clientCountry')?.setValue(this.authInfo.appData.clientCountry)
        this.editFormGroup.get('categories')?.setValue(this.authInfo.appData.categories)
        this.editFormGroup.get('typeOfPersonalData')?.setValue(this.authInfo.appData.typeOfPersonalData)
        this.editFormGroup.get('personsAuthorized')?.setValue(this.authInfo.appData.personsAuthorized)
        this.editFormGroup.get('reportingType')?.setValue(this.authInfo.appData.reportingType)

        this.initialState = AvvState.UNDEFINED
      } else {
        this.editFormGroup.patchValue(this.avv);

        this.canBeRevoked = this.avv.state !== AvvState.AVV_REVOKED;
        this.canAvvBeDownloaded = this.avv.state === AvvState.AVV_USAGE;
        this.initialState = avv.state
      }

      if (this.avv.avvFields) {
        this.avv.avvFields.forEach(t => {
          this.avvFields.push(this.fb.group({
            fieldValue: t.fieldValue ? t.fieldValue : '',
            pdfKey: t.pdfKey
          }))
        })
      }
    });
  }

  get avvFields() {
    return this.editFormGroup.controls["avvFields"] as FormArray;
  }

  backToApp() {
    this.avvService.getRedirectUrl().subscribe((redirectUrl) => {
      window.location.href = redirectUrl.result;
    })
  }

  revokeAvv() {
    this.onSubmit(this.editFormGroup, true)
  }

  getAvvDownloadUrl(){
    if (this.avv?.file) {
      return `${environment.apiUrl}/asset/${this.avv?.file.id}/ORIGINAL`;
    }
    return '';
  }

  public onSubmit(editFormGroup: FormGroup, revokeRequest: boolean = false) {
    if (this.pending) {
      return;
    }

    if (revokeRequest) {
      const data: AvvConfirmationModalData = {
        state: editFormGroup.get('state')?.value,
        appName: this.authInfo.appData.appName,
        redirectUrl: this.authInfo.appData.appUrl,
        isRevokeMode: true,
        revokeAction: () => {
          return new Promise(async resolve => {
            this.editFormGroup.get('state')?.setValue(AvvState.AVV_REVOKED)
            this.createAvv(editFormGroup)
            resolve(true)
          })
        },
      };

      this.initialState = this.editFormGroup.get('state')?.value

      this.matDialog.open(AvvConfirmationModalComponent, {
        width: '750px',
        maxWidth: '100%',
        minHeight: 'calc(100vh - 0)',
        // position: {top: '0', right: '0'},
        data
      });
    } else {
      this.createAvv(editFormGroup)
    }
  }

  public createAvv(editFormGroup: FormGroup) {
    const showSaveOnly = this.editFormGroup.get('state')?.value === 'AVV_REVOKED';

    const { value: editData } = editFormGroup;
    if (!this.avv) {
      return;
    }

    this.pending = true;

    this.avvService.create(editData).subscribe((avvId) => {
      this.pending = false;

      this.canBeRevoked = this.editFormGroup.get('state')?.value !== 'AVV_REVOKED';
      this.canAvvBeDownloaded = this.editFormGroup.get('state')?.value === 'AVV_USAGE';
      this.editFormGroup.markAsPristine();

      this.avvService.getCurrentAvv().subscribe((avvCreated) => {
        this.avv = avvCreated;
        const data: AvvConfirmationModalData = {
          state: editFormGroup.get('state')?.value,
          appName: this.authInfo.appData.appName,
          redirectUrl: this.authInfo.appData.appUrl,
          fileId: avvCreated.file?.id,
          showSaveOnly: showSaveOnly
        };

        this.initialState = this.editFormGroup.get('state')?.value

        this.matDialog.open(AvvConfirmationModalComponent, {
          width: '750px',
          maxWidth: '100%',
          minHeight: 'calc(100vh - 0)',
          // position: {top: '0', right: '0'},
          data
        });
        // dialogRef.afterClosed().subscribe((saveData?: boolean) => {
        //
        // });
      });
    });
  }

  protected readonly JSON = JSON;
}
