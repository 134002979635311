import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output, SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { Risk } from '../../risk/risk';
import { Task } from '../../task/task';
import { TaskEditModalData } from '../../task/task-edit-modal/task-edit-modal-data';
import { TaskEditModalComponent } from '../../task/task-edit-modal/task-edit-modal.component';
import { TaskService } from '../../task/task.service';
import { Measure } from '../measure';
import { MeasureEditModalEditData } from '../measure-edit-modal/measure-edit-modal-data';
import { MeasureEditModalComponent } from '../measure-edit-modal/measure-edit-modal.component';
import { MeasureService } from '../measure.service';
import { Role } from '../../account/role.enum';
import { ReminderType } from '../../reminder/reminder-type.enum';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { RiskState } from '../../risk/risk-state.enum';
import { MeasureState } from '../measure-state.enum';

@Component({
  selector: 'app-measure-workspace-item',
  templateUrl: './measure-workspace-item.component.html',
  styleUrls: ['./measure-workspace-item.component.scss'],
})
export class MeasureWorkspaceItemComponent implements OnInit {
  @Input() riskId!: string;
  @Input() measure!: Measure;
  @Input() workspaceFilter!: WorkspaceFilter;
  @Output() measureChange = new EventEmitter<Measure>();

  @HostBinding('class.-flaggedImportant') get isFlaggedImportant(): boolean {
    return false; //this.measure?.flaggedImportant;
  }

  @HostBinding('class.-flaggedExpanded') get isFlaggedExpanded(): boolean {
    return this.expanded;
  }

  @HostBinding('class.-flaggedFilterMatched') get isFlaggedFilterMatched(): boolean {
    return this.measure.workspaceMatched === true;
  }

  @HostBinding('class.-flaggedDeleted') get isFlaggedDeleted(): boolean {
    return this.measure.state === MeasureState.DELETED;
  }

  @HostBinding('class.-flaggedCompleted') get isFlaggedCompleted(): boolean {
    return this.measure.calculatedTotalProgress === 100;
  }

  @HostBinding('class.-flaggedIgnored') get isFlaggedIgnored(): boolean {
    return this.measure.ignored;
  }

  tasks: Task[] | null = null;

  expanded: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private measureService: MeasureService,
    private taskService: TaskService,
  ) {}

  public trackById = IdObjectService.trackById;
  private loaded: boolean = false;

  ngOnInit(): void {
    //this.updateTasks(false);
  }

  updateTasks(updated: boolean) {
    const measure = IdObjectService.convertToIdObject(this.measure) as IdObject;

    const workspaceFilter = Object.assign(
      {workspaceAlreadyMatched: this.measure.workspaceMatched || this.measure.workspaceParentMatched},
      this.workspaceFilter,
    );

    this.taskService.search({ measure, workspaceFilter }).subscribe((tasks) => {
      this.tasks = tasks;
      this.loaded = true;
    });

    if (updated) {
      this.measureChange.emit(this.measure);
    }
  }

  public get isCompleted(): boolean {
    return this.measure.calculatedTotalProgress === 100;
  }

  public get damageValue(): number | null {
    return (
      this.measure.riskMeasures?.find(
        (riskMeasure) => riskMeasure.risk?.id === this.riskId,
      )?.damageValue || null
    );
  }

  public get reachedDamageValue(): number | null {
    return (
      ((this.damageValue || 0) / 100) *
      this.measure.reachedEfficiencyDamageValue
    );
  }

  public get reachedProbabilityValue(): number | null {
    return (
      ((this.probabilityValue || 0) / 100) *
      this.measure.reachedEfficiencyProbabilityValue
    );
  }

  public get probabilityValue(): number | null {
    return (
      this.measure.riskMeasures?.find(
        (riskMeasure) => riskMeasure.risk?.id === this.riskId,
      )?.probabilityValue || null
    );
  }

  public get hasOtherAssignedRisks(): boolean {
    if (!this.measure.riskMeasures) {
      return false;
    }

    return !!this.measure.riskMeasures.filter(
      (riskMeasure) => riskMeasure.risk?.id !== this.riskId,
    ).length;
  }

  public get otherAssignedRisks(): Risk[] | IdObject[] | null {
    if (!this.measure.riskMeasures) {
      return null;
    }

    return this.measure.riskMeasures
      .filter((riskMeasure) => riskMeasure?.risk?.id !== this.riskId)
      .map((riskMeasure) => riskMeasure.risk);
  }

  public get warnAboutEndDate(): boolean {
    if (this.isCompleted) {
      return false;
    }

    return new Date() >= new Date(this.measure.endDate);
  }

  public setCompleted(): void {
    this.measureService.setComplete(this.measure.id).subscribe((success) => {
      if (success) {
        this.measure.calculatedTotalProgress = 100;
        this.measureChange.emit(this.measure);
      }
    });
  }

  public isResponsible(role: Role) {
    return role === Role.RESPONSIBLE;
  }

  public isPrivate(reminderType: ReminderType): boolean {
    return reminderType === ReminderType.PRIVATE;
  }

  public openEditModal(event: MouseEvent): void {
    const data: MeasureEditModalEditData = {
      measureId: this.measure.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(MeasureEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe((measure?: Measure) => {
      if (measure) {
        Object.assign(this.measure, measure);
        this.measureChange.emit(measure);
      }
    });
  }

  public openCreateTaskModal(event: MouseEvent): void {
    const data: TaskEditModalData = {
      measureId: this.measure.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(TaskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: { top: '0', right: '0' },
      data,
    });

    dialogRef.afterClosed().subscribe((task?: Task) => {
      if (task) {
        this.tasks?.push(task);
        this.measureChange.emit(this.measure);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.measure && changes.measure.currentValue) {
      if (this.expanded) {
        this.updateTasks(false);
      }
    }
  }

  toggleExpand() {
    this.expanded = !this.expanded;
    if (this.expanded && !this.loaded) {
      this.updateTasks(false);
    }
  }

  public get cssClass(): string[] {
    const classes: string[] = [];

    if (this.expanded) {
      classes.push('containerExpanded');
    }

    if (this.measure.workspaceMatched) {
      classes.push('filterMatched');
    }

    if (this.measure.state === MeasureState.DELETED) {
      classes.push('deleted');
    }

    if (this.measure.calculatedTotalProgress === 100) {
      classes.push('completed');
    }
    if (this.measure.ignored) {
      classes.push('ignored');
    }

    return classes;
  }
}
