import { Component, Input } from '@angular/core';
import { Tag } from '../tag';

@Component({
  selector: 'app-tags-overview',
  templateUrl: './tags-overview.component.html',
  styleUrls: ['./tags-overview.component.scss'],
})
export class TagsOverviewComponent {
  @Input() tags!: Tag[];
  constructor() {}
}
