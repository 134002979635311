import { HostBinding } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'app-reminders-widget',
  templateUrl: './reminders-widget.component.html',
  styleUrls: ['./reminders-widget.component.scss'],
})
export class RemindersWidgetComponent {
  @HostBinding('class') string = 'o-widget';

  constructor() { }
}
