import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AreaOverviewComponent } from './area-overview/area-overview.component';
import { UIRouterModule } from '@uirouter/angular';
import { AREA_STATES } from './area.states';
import { AreaEditGeneralComponent } from './area-edit-general/area-edit-general.component';
import { AreaEditUserComponent } from './area-edit-user/area-edit-user.component';
import { AreaEditNavComponent } from './area-edit-nav/area-edit-nav.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { UiModule } from '../../layout/ui.module';

@NgModule({
  declarations: [
    AreaOverviewComponent,
    AreaEditGeneralComponent,
    AreaEditUserComponent,
    AreaEditNavComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: AREA_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    UiModule,
  ],
})
export class AreaModule {}
