import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluation-nav',
  templateUrl: './evaluation-nav.component.html',
  styleUrls: ['./evaluation-nav.component.scss']
})
export class EvaluationNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
