import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';
import { EvaluationRiskComponent } from './evaluation-risk/evaluation-risk.component';
import { DashboardService } from '../dashboard/dashboard.service';
import { resolveDashboardEvaluation } from '../dashboard/dashboard.resolver';

export const PROFILE_EVALUATION: Ng2StateDeclaration[] = [
  {
    name: 'app.evaluation',
    redirectTo: 'app.evaluation.risk',
    url: '/evaluation',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'dashboardEvaluation',
        deps: [DashboardService],
        resolveFn: resolveDashboardEvaluation,
      },
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
  {
    name: 'app.evaluation.risk',
    views: {
      '$default@app': {
        component: EvaluationRiskComponent,
      },
    },
    url: '/risk',
  },
];
