<nav id="subNav" class="hasIcons">
  <ul>
    <li uiSrefActive="active">
      <a uiSref="^.general">
        <fa-icon icon="font"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.AREA.MENU.GENERAL' | translate }}</small>
      </a>
    </li>
    <li uiSrefActive="active">
      <a uiSref="^.user">
        <fa-icon icon="user-tie"></fa-icon>
        <i class="fas fa-font"></i>
        <small>{{ 'PAGES.AREA.MENU.USER' | translate }}</small>
      </a>
    </li>
  </ul>
</nav>
