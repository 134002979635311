import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObjectService } from 'src/app/core/id-object.service';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Compliance } from '../../compliance/compliance';
import { ComplianceService } from '../../compliance/compliance.service';
import { Tag } from '../../tag/tag';
import { Area } from '../area';
import { AreaService } from '../area.service';
import { AreaEditModalData } from './area-edit-modal-data';
import { ValueObject } from '../../../core/value-object';
import { AreaState } from '../area-state.enum';
import { MeasureState } from '../../measure/measure-state.enum';
import { ValueObjectService } from '../../../core/value-object.service';

@Component({
  selector: 'app-area-edit-modal',
  templateUrl: './area-edit-modal.component.html',
  styleUrls: ['./area-edit-modal.component.scss'],
})
export class AreaEditModalComponent implements OnInit {
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  public stateOptions: ValueObject[] = [
    {displayName: 'aktiv', value: AreaState.ACTIVE},
    {displayName: 'gelöscht ', value: AreaState.DELETED},
  ];

  compliances$!: Observable<Compliance[]>;

  public pending: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    compliance: [undefined, [Validators.required]],
    state: [AreaState.ACTIVE, [Validators.required]],
    tags: [[], []],
  });

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public navigationService: NavigationService,
    @Inject(MAT_DIALOG_DATA) public data: AreaEditModalData,
    public matDialogRef: MatDialogRef<AreaEditModalComponent>,
    private areaService: AreaService,
    public complianceService: ComplianceService,
    private fb: FormBuilder,
  ) {
    this.matIconRegistry.addSvgIcon(
      'cancel',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        'assets/icons/mat-cancel.svg',
      ),
    );
    this.compliances$ = this.complianceService.getList();
  }

  public compareWithId = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    if (this.data.editMode === EditMode.EDIT) {
      this.areaService.getSingle(this.data.areaId).subscribe((area) => {
        this.editFormGroup.patchValue(area);
      });
    }
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      return;
    }

    this.pending = true;

    const {value: editData} = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.EDIT:
        const {areaId} = this.data;

        this.areaService.patch(areaId, editData).subscribe((success) => {
          let response: Area | null = null;

          if (success) {
            response = {
              id: areaId,
              ...editData,
            };
          }

          this.pending = false;
          this.matDialogRef.close(response);
        });

        break;
    }
  }
}
