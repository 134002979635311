import {Component, Input, OnInit} from '@angular/core';
@Component({
  selector: 'app-error-page-overview',
  templateUrl: './error-page-overview.component.html',
  styleUrls: ['./error-page-overview.component.scss'],
})
export class ErrorPageOverviewComponent implements OnInit {

  constructor(

  ) {
    
  }

  public ngOnInit() {
  }

}
