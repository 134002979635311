import { Component, Input } from '@angular/core';
import { Client } from '../client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from '@uirouter/core';
import { ClientService } from '../client.service';
import { Asset } from '../../asset/asset';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-client-design',
  templateUrl: './client-design.component.html',
  styleUrls: ['./client-design.component.scss'],
})
export class ClientDesignComponent {
  @Input() currentClient!: Client;

  public editFormGroup: FormGroup = this.fb.group({
    designLogo: ['', []],
  });

  constructor(
    private fb: FormBuilder,
    private stateService: StateService,
    private clientService: ClientService,
  ) {
  }

  public pending: boolean = false;

  public ngOnInit() {
    this.editFormGroup.patchValue(this.currentClient);
  }

  public onSubmit(editFormGroup: FormGroup) {
    if (this.pending) {
      return;
    }

    const {value: editData} = editFormGroup;
    if (!this.currentClient) {
      return;
    }

    this.pending = true;
    const {id: clientId} = this.currentClient;

    this.clientService.patch(clientId, editData).subscribe(() => {
      this.pending = false;
      this.stateService.go('.', undefined, {reload: true});
    });
  }

  public assetUploadFinished(field: string, asset: Asset) {
    console.log('assetUploadFinished for field ', field, '=>', asset);
    const formField = this.editFormGroup.get(field);
    if (formField) {
      formField.setValue(asset);
    }
  }

  getAssetUrl(field: string) {
    const formField = this.editFormGroup.get(field);
    if (formField?.value) {
      return `${environment.apiUrl}/asset/${formField?.value.id}`;
    }

    return null;
  }
}
