import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Compliance, ComplianceAccount, ComplianceAccountDraft } from '../compliance';
import { EditMode } from '../../../core/edit-mode.enum';
import { ComplianceService } from '../compliance.service';
import { ValueObject } from '../../../core/value-object';
import { Role } from '../../account/role.enum';
import { AbstractControl, FormControl } from '@angular/forms';
import { IdObjectService } from '../../../core/id-object.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../../account/account';
import { SelectionModel } from '@angular/cdk/collections';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AccountService } from '../../account/account.service';
import { Delegate } from '../../../core/validation/cp-confirm/cp-confirm';

@Component({
  selector: 'app-compliance-edit-user',
  templateUrl: './compliance-edit-user.component.html',
  styleUrls: ['./compliance-edit-user.component.scss'],
})
export class ComplianceEditUserComponent implements OnInit {
  @Input() compliance: Compliance | null = null;

  complianceAccounts: ComplianceAccount[] = [];
  complianceAccountDraft: ComplianceAccountDraft = {};

  public roleOptions: ValueObject[] = [
    {displayName: 'informativer Benutzer', value: Role.INFORMATIVE},
    {displayName: 'Sachbearbeiter', value: Role.OFFICIAL},
    {displayName: 'Verantwortlicher', value: Role.RESPONSIBLE},
    {displayName: 'Administrator', value: Role.ADMIN},
    {displayName: 'Revisor', value: Role.REVISOR},
  ];

  public accountSearchCtrl = new FormControl();
  public compareById = IdObjectService.compareById;

  public filteredAccountOptions$ = new BehaviorSubject<Account[]>([]);

  constructor(
    public accountService: AccountService,
    public navigationService: NavigationService,
    public complianceService: ComplianceService,
  ) {
    this.accountSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredAccountOptions(searchText);
      });
  }

  public ngOnInit() {
    this.getFilteredAccountOptions();
    this.loadAccounts();
  }

  loadAccounts() {
    if (this.compliance) {
      this.complianceService.getSingleAccounts(this.compliance.id).subscribe((accounts) => {
        this.complianceAccounts = accounts;
      });
    }
  }

  public getFilteredAccountOptions(searchText?: string) {
    this.accountService.search({searchText}).subscribe((accountOptions) => {
      console.log('** found accounts', accountOptions);
      this.filteredAccountOptions$.next(accountOptions);
    });
  }

  deleteAccount(complianceAccount: ComplianceAccount): Delegate<Promise<boolean>> {
    return () => new Promise<boolean>((resolve, reject) => {
      if (this.compliance && this.complianceAccountDraft) {
        this.complianceService.deleteAccount(this.compliance.id, complianceAccount)
          .subscribe(() => {
            resolve(true);
            console.log('** DONE');
            this.loadAccounts();
          });
      } else {
        reject();
      }
    });
  }

  addAccount() {
    this.createOrUpdateAccount(this.complianceAccountDraft);
  }

  roleChanged(account: ComplianceAccount) {
    this.createOrUpdateAccount(account);
  }

  private createOrUpdateAccount(account: ComplianceAccount | ComplianceAccountDraft) {
    if (this.compliance && account) {
      this.complianceService.createOrUpdateAccount(this.compliance.id, account)
        .subscribe(() => {
          this.complianceAccountDraft = {};
          this.loadAccounts();
        });
    }
  }
}
