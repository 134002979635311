<header id="header" role="banner">
  <h1>
    Profil
    <small>
      {{ authInfo?.account?.firstName }}
      {{ authInfo?.account?.lastName }}
    </small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content">
  <app-profile-nav></app-profile-nav>

  <div class="profileSecurity">
    <!-- Passwort ändern -->
    <form
      novalidate
      (ngSubmit)="submitPasswordChange(passwordFormGroup)"
      [formGroup]="passwordFormGroup">

      <div class="passwordContainer" fxLayout="column">
        <h3 class="subHeading">Passwort</h3>
        <div fxLayout="row" fxLayoutGap="5px">
          <mat-form-field appearance="fill" style="width: 50%">
            <mat-label>Neues Passwort*</mat-label>
            <input matInput type="password" formControlName="newPassword" />

            <mat-error *ngIf="passwordFormGroup.hasError('pattern', 'newPassword')">
              Passwort zu unsicher.
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 50%">
            <mat-label>Neues Passwort wiederholung*</mat-label>
            <input
              matInput
              type="password"
              formControlName="newPasswordRepeat"
              [errorStateMatcher]="matcher" />
            <mat-error *ngIf="passwordFormGroup.hasError('notSame')">
              Passwort-Wiederholung nicht korrekt.
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          Passwortstärke:<br />
          <app-password-validation [password]="passwordFormGroup.get('newPassword')"></app-password-validation>
        </div>
      </div>

      <footer mat-dialog-actions>
        <button
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!passwordFormGroup.get('newPassword')?.value || pending || passwordFormGroup.invalid"
        >
          Neues Passwort speichern
        </button>
      </footer>
    </form>
    <div *ngIf="changePasswordDone">Passwort wurde geändert.</div>
    <!-- /Passwort ändern -->

    <h3 class="subHeading">2-Faktor-Auth</h3>
    <p>
      To better secure your account, you can enable two-factor authentication
      here. With this, you still have to enter a token generated by an OTP app
      after logging in via password.
    </p>
    <mat-list role="list" *ngIf="tfas.length">
      <mat-list-item role="listitem" *ngFor="let tfa of tfas">
        <p matLine>{{ tfa.displayName }}</p>
        <p matLine>
          {{
          tfa.linked
            ? tfa.enabled
            ? 'aktiviert'
            : 'deaktiviert'
            : 'noch nicht abgeschlossen'
          }}
        </p>
        <button
          mat-icon-button
          matTooltip="Authentifizierung abschließen"
          (click)="openCompleteTFAModal($event, tfa.id)"
          *ngIf="!tfa.linked"
        >
          <fa-icon icon="link"></fa-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Authentifizierung deaktivieren"
          (click)="disableTFAModal($event, tfa.id)"
          *ngIf="tfa.linked && tfa.enabled"
        >
          <fa-icon icon="times-circle"></fa-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Authentifizierung aktivieren"
          (click)="enableTFAModal($event, tfa.id)"
          *ngIf="tfa.linked && !tfa.enabled"
        >
          <fa-icon icon="check-circle"></fa-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Authentifizierung löschen"
          (click)="deleteTFAModal($event, tfa.id)"
        >
          <fa-icon icon="trash"></fa-icon>
        </button>
      </mat-list-item>
    </mat-list>
    <button
      type="button"
      mat-raised-button
      color="primary"
      (click)="openCreateTFAModal($event)"
    >
      Neues Gerät hinzufügen
    </button>
  </div>
</main>
