<mat-form-field [fxFlex]="fxFlex" appearance="fill" [formGroup]="editFormGroup" style="width: 100%;box-sizing: border-box">
  <mat-label>Schlagwörter</mat-label>
  <mat-chip-list formControlName="tags" #tagList>
    <mat-chip
      [selectable]="true"
      [removable]="true"
      (removed)="removeTag(index)"
      *ngFor="let tag of editFormGroup.get('tags')?.value; index as index">
      {{ tag?.displayName }}
      <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
    </mat-chip>
    <input
      placeholder="neues Schlagwort"
      #tagInput
      [formControl]="tagCtrl"
      [matAutocomplete]="tagAutocomplete"
      [matChipInputFor]="tagList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="addTag($event)"
    />
  </mat-chip-list>
  <mat-autocomplete
    #tagAutocomplete="matAutocomplete"
    (optionSelected)="selectTag($event)">
    <mat-option *ngFor="let tag of tagOptions$ | async" [value]="tag">
      {{ tag?.displayName }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
