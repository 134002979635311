<header id="header" role="banner">
  <h1>
    Einstellungen
    <small>Attribute</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-settings-nav></app-settings-nav>

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a (click)="openCreateModal($event)" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Attribut hinzufügen
        </a>
      </div>
      <div class="header-item searchItem size-2-3">
        <input class="overviewHeader-search" type="text" [(ngModel)]="filter.searchText" (ngModelChange)="searchChanged()" placeholder="Bezeichnung"/>
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
        <a class="icon-deleteSearch" *ngIf="filter.searchText" (click)="resetFilter()">
          <fa-icon icon="broom"></fa-icon>
        </a>
      </div>
     </div>
   </header>

  <!-- ### FILTER ### -->
  <div class="filterBar-v01">
		<div class="filter">
			<ul>
				<li [ngClass]="{'active': !filter.state}" (click)="changeFilterState(null)"><small>Alle</small></li>
        <li [ngClass]="{'active': filter.state === 'ACTIVE'}" (click)="changeFilterState('ACTIVE')"><small>Aktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'INACTIVE'}" (click)="changeFilterState('INACTIVE')"><small>Inaktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'DELETED'}" (click)="changeFilterState('DELETED')"><small>Gelöscht</small></li>
			</ul>
		</div>
		<div class="filter-icon"><a class="header-item refresh size-square"  (click)="search()"><fa-icon icon="sync-alt"></fa-icon></a></div>
	</div>
  <!-- ### FILTER ### -->

  <!-- ATTRIBUTE LISTE -->
  <div class="container-box-style-v3">

    <div class="item" *ngFor="let attribute of attributes" [ngClass]="{'inactiveItem' : attribute.state === 'INACTIVE', 'deletedItem' : attribute.state === 'DELETED', 'statuslessItem' : !attribute.state}"><!--  | sort:'displayName' -->


			<div class="options-selection">

        <a (click)="openEditModal(attribute, $event)">
					<span class="options-icon"><fa-icon icon="pencil-alt"></fa-icon></span>
				</a>

			</div>
      <div class="icon"  (click)="openEditModal(attribute, $event)">
        <span *ngIf="attribute?.type === 'TEXT_ONE_LINE'" matTooltip="Einzeiliger Text"><fa-icon icon="font"></fa-icon></span>
        <span *ngIf="attribute?.type === 'TEXT_MULTI_LINE'" matTooltip="Mehrzeiliger Text"><fa-icon icon="align-left"></fa-icon></span>
        <span *ngIf="attribute?.type === 'NUMBER'" matTooltip="Nummer/Zahl">123</span>
        <span *ngIf="attribute?.type === 'PRICE'" matTooltip="Preis"><fa-icon icon="euro-sign"></fa-icon></span>
        <span *ngIf="attribute?.type === 'DATE'" matTooltip="Datum"><fa-icon icon="calendar-day"></fa-icon></span>
        <span *ngIf="attribute?.type === 'FILE'" matTooltip="Datei"><fa-icon icon="upload"></fa-icon></span>
      </div>
			<div class="details" (click)="openEditModal(attribute, $event)">
        <span class="primary">
					<strong *ngIf="attribute.key">{{attribute.key}}</strong>
				</span>
				<span class="secondary" *ngIf="attribute.description">
          <span class="subItem">{{attribute.description}}</span>
				</span>
			</div>
		</div>

    <app-paging [paging]="paging" [pagingSorts]="pagingSorts" (pagingChanged)="search()"></app-paging>
	</div>
  <!-- // ATTRIBUTE LISTE -->
</main>
