import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Compliance } from '../../compliance/compliance';
import { WorkspaceFocus } from '../../workspace/workspace.focus';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { WorkspaceFilterType } from '../../workspace/workspace.filter-type.enum';
import { IdObjectService } from '../../../core/id-object.service';
import { Area } from '../../area/area';
import { RiskEditModalCreateData } from '../../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../../risk/risk-edit-modal/risk-edit-modal.component';
import { Risk } from '../../risk/risk';
import { ComplianceService } from '../../compliance/compliance.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { RevisorFilter } from '../revisor.filter';
import { DashboardFilter } from '../../dashboard/dashboard.filter';
import {
  DashboardRevisor,
  DashboardRevisorCompany, DashboardRevisorCompliance,
} from '../dashboardRevisor';
import { RevisorFilterType } from '../revisor.filter-type.enum';

@Component({
  selector: 'app-revisor-sidenav',
  templateUrl: './revisor-sidenav.component.html',
  styleUrls: ['./revisor-sidenav.component.scss'],
})
export class RevisorSidenavComponent implements OnInit {
  @Input() revisorFilter!: RevisorFilter;
  @Input() dashboardRevisor!: DashboardRevisor;
  @Input() companies?: DashboardRevisorCompany[] | null = null;
  @Output() revisorFilterChanged = new EventEmitter<boolean>();

  expandedCompliance: string = '';
  focus: WorkspaceFocus = {compliance: undefined, area: undefined};
  revisorFilterType = RevisorFilterType;
  compliances: DashboardRevisorCompliance[] | undefined = [];

  public trackById = IdObjectService.trackById;

  constructor(
    private complianceService: ComplianceService,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.updateCompliances();
  }

  updateCompliances() {
    const complianceNames = [];
    const newCompliances = [];

    console.log('** updateCompliances by filter:', this.revisorFilter);

    for (const company of this.dashboardRevisor.companies) {
      if (this.revisorFilter.companyId && company.id !== this.revisorFilter.companyId) {
        continue;
      }

      for (const compliance of company.compliances) {
        if (complianceNames.indexOf(compliance.complianceName) === -1) {
          complianceNames.push(compliance.complianceName);
          newCompliances.push(compliance);
        }
      }
    }

    this.compliances = newCompliances;
    //
    // if (this.revisorFilter.companyId) {
    //   this.compliances = this.dashboardRevisor.companies.find(c => c.id === this.revisorFilter.companyId)?.compliances;
    //
    // } else {
    //   this.compliances = [];
    // }
  }

  setCompanyFilter(companyId?: string) {
    this.revisorFilter.companyId = companyId;
    this.revisorFilterChanged.emit();
    this.updateCompliances();
  }

  selectCompliance(compliance: DashboardRevisorCompliance) {
    if (compliance) {
      if (this.revisorFilter.complianceName === compliance.complianceName) {
        this.revisorFilter.complianceName = undefined;
      } else {
        this.revisorFilter.complianceName = compliance.complianceName;
      }

    } else {
      this.revisorFilter.complianceName = undefined;
    }

    this.revisorFilterChanged.emit();
  }

  setFilterType(filterType: RevisorFilterType) {
    if (this.revisorFilter.filterType === filterType) {
      this.revisorFilter.filterType = undefined;

    } else {
      this.revisorFilter.filterType = filterType;
    }
    this.revisorFilterChanged.emit(true);
  }

  openCreateRiskModal(area: Area, event: MouseEvent) {
    const data: RiskEditModalCreateData = {
      areaId: area.id,
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        //this.updateCompliances();
      }
    });
  }

  clearFocus() {
    this.focus = {compliance: undefined, area: undefined};
  }

  filterChanged() {
    // localStorage.setItem('workspaceFilter', JSON.stringify(this.workspaceFilter));
    //this.updateCompliances();
  }
}
