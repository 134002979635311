export enum AuditResult {
  EffectiveWithoutComplaint = 'EffectiveWithoutComplaint',
  EffectiveWithComplaint = 'EffectiveWithComplaint',
  Ineffective = 'Ineffective',
}

export enum AuditNextDateType {
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
  CUSTOM = 'CUSTOM',
}

export enum AuditType {
  StructureTest= 'StructureTest',
  FunctionTest= 'FunctionTest',
  Other = 'Other',
}

