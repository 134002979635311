<!--<div mat-dialog-title>Verleihvorgang bearbeiten</div>-->
<form
  novalidate
  *ngIf="deposit"
  (ngSubmit)="onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>





    <mat-tab-group animationDuration="0ms">
      <mat-tab>
      <ng-template mat-tab-label>
        <fa-icon icon="inbox"></fa-icon>
        &nbsp;&nbsp;Rückgabe
      </ng-template>

      <div mat-dialog-content>



      <section class="subView-steps">
       <div class="cp-gui-item">

      <!-- Details Leihen -->
      <div class="cp-gui-textinfo">

        <div class="width-100 subItem">

          <div class="selectItem">
            <div class="label">Aktueller Status:
              <strong *ngIf="editFormGroup.get('state')?.value === 'RETURNED'">Zurückgegeben</strong>
              <strong *ngIf="editFormGroup.get('state')?.value === 'ON_HOLD'">Rückgabe mangelhaft</strong>
              <strong *ngIf="editFormGroup.get('state')?.value === 'BORROWED'">Verliehen</strong>
            </div>
            <p>
              Neuer Status nach dem Speichern:
              <i *ngIf="!depositPatch.state || depositPatch.state === editFormGroup.get('state')?.value">kein neuer Status</i>
              <b *ngIf="depositPatch.state === 'RETURNED' && depositPatch.state !== editFormGroup.get('state')?.value">Zurückgegeben</b>
              <b *ngIf="depositPatch.state === 'ON_HOLD' && depositPatch.state !== editFormGroup.get('state')?.value">Rückgabe mangelhaft</b>
            </p>
            <ul><!-- *ngIf="!depositReturned" -->
              <li class="positive" [ngClass]="{'active': (!depositPatch.state && editFormGroup.get('state')?.value === 'RETURNED') || depositPatch.state === 'RETURNED'}" (click)="setDepositState(true)">
                <span class="icon"><fa-icon [icon]="['fas', 'thumbs-up']"></fa-icon></span>
                <span class="label">Rückgabe OK <small>Vorgang wird nach <b>X</b> Tagen gelöscht</small></span>
              </li>
              <li class="negative" [ngClass]="{'active': (!depositPatch.state && editFormGroup.get('state')?.value === 'ON_HOLD') || depositPatch.state === 'ON_HOLD'}" (click)="setDepositState(false)">
                <span class="icon"><fa-icon [icon]="['fas', 'thumbs-down']"></fa-icon></span>
                <span class="label">Rückgabe mangelhaft <small>Vorgang bleibt erhalten</small></span>
              </li>
            </ul>
            <!--<div *ngIf="depositReturned">
              <div *ngIf="editFormGroup.get('state')?.value === 'RETURNED'">
                Rückgabe ok
              </div>
              <div *ngIf="editFormGroup.get('state')?.value === 'ON_HOLD'">
                Rückgabe fehlerhaft
              </div>
            </div>-->
          </div>

          <div class="inputItem margin-top-medium">
            <label class="label">Kommentar</label>
            <textarea class="textarea" formControlName="comment"></textarea>
          </div>
        </div>

        <div class="divider"></div>

        <div class="width-100 subItem">
          <div class="headline">Ausleihende Person</div>
        </div>

        <div class="width-50 subItem">
          <div class="key">Name</div>
          <div class="value">{{ editFormGroup.get('firstName')?.value }} {{ editFormGroup.get('lastName')?.value }}</div>
        </div>
        <div class="width-50 subItem">
          <div class="key">Geburtstag</div>
          <div class="value">{{ editFormGroup.get('birthday')?.value  | date:'dd.MM.yyyy' }}</div>
        </div>

        <div class="width-100 subItem" style="padding-top: 20px">
          <div class="headline">Geliehene Objekte: <b>{{ editFormGroup.get('items')?.value.length }}</b></div>
        </div>

        <div class="width-100 subItem" *ngFor="let item of editFormGroup.get('items')?.value">
          <div class="width-50 subItem">
            <div class="key">Code</div>
            <div class="value">{{ item?.code }}<small *ngIf="item.displayName"> / {{item.displayName}}</small></div>
          </div>
        </div>

      </div>
    </div>

      </section>

      </div>
    </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <fa-icon icon="file-signature"></fa-icon>
          &nbsp;&nbsp;Leihvorgang
        </ng-template>
        <ng-template matTabContent style="padding-bottom: 40px">
          <div mat-dialog-content>
            <!--<h3 class="subHeading">Urlsprünglich unterschriebene Daten</h3>-->
            <section class="subView-steps"  style="margin-bottom: 60px">
                <div class="cp-gui-item">

                  <!-- Ausleihen Datum -->
                  <div class="cp-gui-textinfo">
                   <!-- <div class="options">
                      <a><fa-icon icon="pencil-alt"></fa-icon></a>
                    </div>-->
                    <div class="width-100 subItem">
                      <div class="headline">Zeiten</div>
                    </div>

                    <div class="width-100 subItem" *ngIf="deposit.borrowedDate">
                      <div class="key">Ausgeliehen am</div>
                      <div class="value">{{ deposit.borrowedDate | date:'dd.MM.yyyy, HH:mm' : '-' }} Uhr</div>
                    </div>

                    <div class="width-100 subItem" *ngIf="deposit.onHoldDate">
                      <div class="key">Rückgabe mangelhaft</div>
                      <div class="value">{{ deposit.onHoldDate | date:'dd.MM.yyyy, HH:mm' : '-' }} Uhr</div>
                    </div>

                    <div class="width-100 subItem" *ngIf="deposit.returnedDate">
                      <div class="key">Rückgabe (fehlerfrei)</div>
                      <div class="value">{{ deposit.returnedDate | date:'dd.MM.yyyy, HH:mm': '-' }} Uhr</div>
                    </div>




                  </div>

                  <!-- Ausleihende Person -->
                  <div class="cp-gui-textinfo">
                   <!-- <div class="options">
                      <a><fa-icon icon="pencil-alt"></fa-icon></a>
                    </div>-->
                    <div class="width-100 subItem">
                      <div class="headline">Ausgeliehen von</div>
                    </div>

                    <div class="width-50 subItem">
                      <div class="key">Name</div>
                      <div class="value">{{ editFormGroup.get('firstName')?.value }} {{ editFormGroup.get('lastName')?.value }}</div>
                    </div>
                    <div class="width-50 subItem">
                      <div class="key">Geburtstag</div>
                      <div class="value">{{ editFormGroup.get('birthday')?.value  | date:'dd.MM.yyyy' }}</div>
                    </div>
                    <div class="width-100 subItem">
                      <div class="key">Anschrift</div>
                      <div class="value">{{ editFormGroup.get('street')?.value }}, {{ editFormGroup.get('plz')?.value }} {{ editFormGroup.get('location')?.value }}</div>
                    </div>
                    <div class="width-100 subItem">
                      <div class="key">Ausweisnummer</div>
                      <div class="value">{{ editFormGroup.get('idNumber')?.value }}</div>
                    </div>

                  </div>

                  <!-- Geliehene Objekte -->
                  <div class="cp-gui-textinfo">
                    <!--<div class="options">
                      <a><fa-icon icon="pencil-alt"></fa-icon></a>
                    </div>-->

                    <div class="width-100 subItem">
                      <div class="headline">Geliehene Objekte</div>
                    </div>

                    <div class="width-100 subItem" *ngFor="let item of editFormGroup.get('items')?.value">
                      <div class="width-100 subItem">
                        <div class="key">Code</div>
                        <div class="value">{{ item?.code }}<small *ngIf="item.displayName"> / {{item.displayName}}</small></div>
                      </div>
                    </div>

                  </div>

                  <!-- Unterschreiben -->
                  <div class="cp-gui-textinfo">

                    <div class="width-100 subItem">
                      <div class="text" style="white-space: pre-wrap;">{{ deposit.letterOfAgreementShort }}<span *ngIf="deposit.letterOfAgreement">…</span></div>
                      <a (click)="showAgreementModal()" *ngIf="deposit.letterOfAgreement" class="readMore">Bedingungen vollständig anzeigen</a>
                    </div>

                    <div class="width-100 subItem" *ngIf="deposit.signature">
                      <div class="signUp">
                       <img [src]="getAssetUrl(deposit.signature)" style="max-width: 100%; height: auto"/>
                        <!--<a (click)="removeSignature()">Unterschrift löschen</a>-->
                        <div class="label">
                        {{ editFormGroup.get('firstName')?.value }} {{ editFormGroup.get('lastName')?.value }}, {{ editFormGroup.get('borrowedDate')?.value | date:'dd.MM.yyyy, HH:mm' + ' Uhr' : '-' }}
                      </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="false">
        <ng-template mat-tab-label>
          <fa-icon icon="id-card"></fa-icon>
          &nbsp;&nbsp;Person
        </ng-template>
        <ng-template matTabContent>
          <div mat-dialog-content>
            <!--
            firstName: string;
            lastName: string;
            street: string;
            streetNr: string;
            plz: string;
            location: string;
            idNumber: string;
            letterOfAgreement: string;
            birthday: Date;
            deletable: boolean;
            state: DepositState;
            -->
            <h3 class="subHeading">Person</h3>
            <div class="gridContainer">

              <div class="gridItem giw-50">
                <mat-form-field appearance="fill" style="width: 100%;" >
                  <mat-label>Vorname</mat-label>
                  <input matInput type="text" formControlName="firstName" [disabled]="true" />
                </mat-form-field>
              </div>

              <div class="gridItem giw-50">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Nachname</mat-label>
                  <input matInput type="text" formControlName="lastName" [disabled]="true" />
                </mat-form-field>
              </div>

              <div class="gridItem giw-50">
                <mat-form-field  style="width: 100%;" appearance="fill">
                  <mat-label>Geburtsdatum</mat-label>
                  <input matInput [matDatepicker]="birthdayDatePicker" formControlName="birthday"  [disabled]="true"  />
                  <mat-datepicker-toggle matSuffix [for]="$any(birthdayDatePicker)"></mat-datepicker-toggle>
                  <mat-datepicker #birthdayDatePicker></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="gridItem giw-50">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Ausweisnummer</mat-label>
                  <input matInput type="text" formControlName="idNumber"  [disabled]="true"  />
                </mat-form-field>
              </div>
            </div>

            <h3 class="subHeading">Anschrift</h3>
            <div class="gridContainer">
              <div class="gridItem giw-100">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Strasse Nr.</mat-label>
                  <input matInput type="text" formControlName="street"  [disabled]="true" />
                </mat-form-field>
              </div>

              <div class="gridItem giw-50">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>PLZ</mat-label>
                  <input matInput type="text" formControlName="plz" [disabled]="true" />
                </mat-form-field>
              </div>

              <div class="gridItem giw-50">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Ort</mat-label>
                  <input matInput type="text" formControlName="location" [disabled]="true" />
                </mat-form-field>
              </div>

              <div class="gridItem giw-100">
                <mat-form-field appearance="fill" style="width: 100%;">
                  <mat-label>Land</mat-label>
                  <input matInput type="text" formControlName="country"  [disabled]="true"  />
                </mat-form-field>
              </div>

                </div>
        </div>
      </ng-template>
      </mat-tab>
      <mat-tab *ngIf="false">
      <ng-template mat-tab-label>
        <fa-icon icon="heart"></fa-icon>
        &nbsp;&nbsp;Objekte
      </ng-template>

      <ng-template matTabContent>
        <div mat-dialog-content>
          <!--
          firstName: string;
          lastName: string;
          street: string;
          streetNr: string;
          plz: string;
          location: string;
          idNumber: string;
          letterOfAgreement: string;
          birthday: Date;
          deletable: boolean;
          state: DepositState;
          -->

          <section class="subView-steps" style="margin-top: 20px">
            <!-- Liste Add / Remove -->
            <div class="cp-gui-item">
              <div class="cp-gui-list-v01" style="margin-top: -20px">
                <!-- hidden
                <div class="add-items-header" *ngIf="false">
                  <div class="search">
                    <mat-form-field class="example-full-width" appearance="fill">
                      <mat-label>Objekt suchen</mat-label>
                      <input type="text"
                             placeholder="Code, Bezeichnung"
                             aria-label="Number"
                             matInput
                             #itemInput
                             [formControl]="itemsControl"
                             [matAutocomplete]="itemAutocomplete">
                      <mat-autocomplete
                        #itemAutocomplete="matAutocomplete"
                        (optionSelected)="selectItem($event)"
                      >
                        <mat-option *ngFor="let item of searchItems" [value]="item">
                          {{item.code}}<small *ngIf="item.displayName"> / {{item.displayName}}</small>

                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <a class="scan" (click)="openQrScanModal($event)">
                    <fa-icon icon="qrcode"></fa-icon>
                  </a>
                </div>
                // hidden -->
                <!-- Item List -->
                <div class="item-list">
                  <div class="itemList__entry" *ngFor="let item of editFormGroup.get('items')?.value; index as index">
                    <div class="item noIcon">
                      <!-- hidden
                      <div class="options-selection" *ngIf="false">
                        <a>
                          <span class="options-icon" (click)="removeItem(index)"><fa-icon icon="trash-alt"></fa-icon></span>
                        </a>
                      </div>
                      // hidden -->

                      <div class="details">
                    <span class="primary">
                      <strong>{{ item?.code }}</strong>
                    </span>
                        <span class="secondary">
                      <span class="subItem">{{ item?.displayName }}</span>
                    </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>

        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>


  <footer mat-dialog-actions>
    <button type="button" mat-raised-button [disabled]="pending" (click)="deleteDeposit()" style="margin-right: 20px; box-shadow: none !important">Löschen</button>
    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>

  </footer>
</form>
