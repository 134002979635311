import { Ng2StateDeclaration } from '@uirouter/angular';
import { TagsOverviewComponent } from './tag-overview/tags-overview.component';
import { resolveTagList } from './tag.resolver';
import { TagService } from './tag.service';

export const TAG_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.tag',
    component: TagsOverviewComponent,
    url: '/tag',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'tags',
        deps: [TagService],
        resolveFn: resolveTagList,
      },
    ],
  },
];
