import { Injectable } from '@angular/core';
import { ValueObject } from './value-object';

@Injectable({
  providedIn: 'root',
})
export class ValueObjectService {
  public static compareByValue(o1: ValueObject, o2: ValueObject): boolean {
    if (
      !o1 ||
      !o1.hasOwnProperty('value') ||
      !o2 ||
      !o2.hasOwnProperty('value')
    ) {
      return o1 === o2;
    }

    return o1.value === o2.value;
  }
}
