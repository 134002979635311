<header id="header" role="banner">
  <h1>
    Mandant
    <small>Design</small>
  </h1>
</header>

<main role="main" id="content" class="areas">
  <app-client-nav></app-client-nav>
  <form
    class="o-clientDesign"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup">

    <h3 class="subHeading">Logos &amp; Bilder </h3>
    <div fxLayout="row" fxLayoutGap="5px">
      <div fxFlex="33%" appearance="fill">
        <label>Logo</label>
        <cp-upload (uploadFinished)="assetUploadFinished('designLogo', $event)" [multiple]="false">
          <div class="data upload--finished">
            <img [src]="getAssetUrl('designLogo')" />
          </div>
        </cp-upload>
      </div>
    </div>

    <footer class="o-complianceEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>

