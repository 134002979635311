import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler, HttpHeaders,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { AuthInfoService } from '../auth-info/auth-info.service';
import {environment} from "../../../environments/environment";
import {AuthUrlData} from "../auth-info/auth-url-data";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private httpClient: HttpClient) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    let headers: HttpHeaders = request.headers;
    // let authInfo = this.authInfoService.getCurrentAuthInfo();
    // if (authInfo !== null) {
    //   headers = request.headers
    //     .set('Account', authInfo.account.id);
    // }

    const authReq = request.clone({
      headers: headers,
    });

    return next.handle(authReq)
      .pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              // do stuff with response if you want
            }
          },
          (err) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 403 || err.status === 401 ) {
                  console.error('not logged in')
                  window.location.href = '/error-page'
              }
            }
          },
        ),
      );
  }

    private handleError<T>(
        operation = 'operation',
        result?: T,
    ): (error: any) => Observable<T> {
        return (error: any): Observable<T> => {
            console.error(`${operation} failed: `, error);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
