<header id="header" role="banner">
  <h1>
    Schlagwörter
    <small>Schlagwörter</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content" class="tags">
  <pre>{{ tags | json }}</pre>
</main>
