import {
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PasswordConfirmModalData } from '../password-confirm-modal/password-confirm';
import { CreateEditUserComponent } from '../../user/create-edit-user/create-edit-user.component';
import { AccountTarget } from '../../../common/account/accountTarget.enum';
import { CreateUserModalMode } from '../../user/create-edit-user/create-edit-user';
import { CpConfirmModalComponent } from '../cp-confirm-modal/cp-confirm-modal.component';
import { CPConfirmModalData } from '../cp-confirm-modal/cp-confirm-modal';
import { Delegate } from './cp-confirm';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cp-confirm',
  templateUrl: './cp-confirm.component.html',
  styleUrls: ['./cp-confirm.component.scss'],
})
export class CpConfirmComponent implements OnInit {
  @Input() linkText!: string;
  @Input() proceedAction!: Delegate<Promise<any>>;

  constructor(
    private matDialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
  }

  click() {
    console.log('** OPEN MODAL');

    const data: CPConfirmModalData = {
      cancelAction: () => {
        console.log('** cancel');
      },

      proceedAction: () => {
        console.log('** RUN proceedAction');
        return this.proceedAction();
      },
    };

    const dialogRef = this.matDialog.open(CpConfirmModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data,
    });
    // dialogRef.afterClosed().subscribe(() => {
    //
    // });
  }
}
