import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UIRouterModule } from '@uirouter/angular';
import { WorkspaceComponent } from './workspace.component';
import { WORKSPACE_STATES } from './workspace.states';
import { ComplianceWorkspaceItemModule } from '../compliance/compliance-workspace-item/compliance-workspace-item.module';
import { UnassignedMeasuresWorkspaceItemModule } from '../measure/unassigned-measures-workspace-item/unassigned-measures-workspace-item.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [WorkspaceComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: WORKSPACE_STATES}),
    FontAwesomeModule,
    ComplianceWorkspaceItemModule,
    UnassignedMeasuresWorkspaceItemModule,
    MatTooltipModule,
    FormsModule,
  ],
})
export class WorkspaceModule {}
