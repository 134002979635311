<header id="header" role="banner">
  <h1>
    Revisor
    <small>Maßnahmen</small>
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>
<app-revisor-nav></app-revisor-nav>

<main role="main" id="content" class="revisor dashboard-widgets">
<!-- Risk-Container -->
  <div class="risk-container">
    <!-- Measure -->
    <div class="risk-item-withoutCollapsable clickable status" (click)="editMeasure(measure)" *ngFor="let measure of filteredMeasures">
      <div class="risk-shortInfo">
        <div class="checkbox">
          <fa-icon *ngIf="!measure.auditConfirmed" [icon]="['far', 'circle']"></fa-icon>
          <fa-icon *ngIf="measure.auditConfirmed" [icon]="['far', 'check-circle']"></fa-icon>
        </div>
      </div>

      <div class="risk-mainInfo">
        <span class="risk-titel">{{measure.measureName}}</span>
        <div class="metaData">
          <div class="meta-item statusColor" [ngStyle]="{'background': measure.compliance.designColor}">
            <span class="icon"><fa-icon icon="gavel"></fa-icon></span>
            <span class="value">{{measure.compliance.complianceName}}</span>
          </div>
          <div class="meta-item">
            <span class="icon"><fa-icon icon="building"></fa-icon></span>
            <span class="value">{{measure.company.companyName}}</span>
          </div>
          <div class="meta-item statusColor" style="background: #94c100" matTooltip="{{'AUDIT_RESULT.' + measure.auditResult | translate}}" *ngIf="measure.auditResult">
            <span class="icon" style="border-color: #94c100; color: #94c100;"><fa-icon icon="user-check"></fa-icon></span>
            <span class="value">Letzte Prüfung: {{measure.auditLastDate | date: 'dd.MM.yyyy'}}, {{'AUDIT_RESULT.' + measure.auditResult | translate}}</span>
          </div>
          <div class="meta-item" *ngIf="measure.auditNextDate">
            <span class="icon"><fa-icon icon="bell"></fa-icon></span>
            <span class="value">
              <ng-container>
                <span>Nächste Prüfung: {{measure.auditNextDate | date: 'dd.MM.yyyy'}}</span>
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<app-revisor-sidenav
  [companies]="dashboardRevisor?.companies"
  [dashboardRevisor]="dashboardRevisor"
  [revisorFilter]="revisorFilter"
  (revisorFilterChanged)="updateFilter($event)">
</app-revisor-sidenav>
