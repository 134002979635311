import {Component, Input, OnInit} from '@angular/core';
import {TemplateModalEditData} from '../template-modal/template-modal-data';
import {EditMode} from '../../../core/edit-mode.enum';
import {MatDialog} from '@angular/material/dialog';
import {TemplateService} from '../template.service';
import {TemplateModalComponent} from '../template-modal/template-modal.component';
import {Template, TemplateSearchData} from '../template';
import {AuthInfo} from '../../../core/auth-info/auth-info';
import {TemplateState} from "../template-state.enum";
import {Paging, PagingHeader, PagingSort} from "../../../core/paging/paging";
import {debounceTime} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'app-template-overview',
  templateUrl: './template-overview.component.html',
  styleUrls: ['./template-overview.component.scss'],
})
export class TemplateOverviewComponent implements OnInit {
  @Input() authInfo!: AuthInfo;

  categories: Template[] = [];
  paging: Paging = {
    currentPage: 0,
    pages: [],
    perPage: 20,
    sort: 'name',
    sortDirection: 'desc',
    totalItems: 0,
    totalPages: 0,
  };

  pagingSorts: PagingSort[] = [
    {key: 'name', direction: 'asc', displayName: 'Name (absteigend)'},
    {key: 'name', direction: 'desc', displayName: 'Name (aufsteigend)'},
  ];

  modelChanged = new Subject<string>();

  public filter: TemplateSearchData = {
    state: TemplateState.ACTIVE
  };

  constructor(
    private templateService: TemplateService,
    private matDialog: MatDialog,
  ) {
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.search();
      });
  }

  public ngOnInit() {
    this.search();
  }

  searchChanged() {
    this.modelChanged.next();
  }

  search() {
    this.templateService.search(this.filter, this.paging).then((pagesTemplates) => {
      this.updatePaging(pagesTemplates.pagingHeader);
      this.categories = pagesTemplates.items;
    });
  }

  openCreateModal(event: MouseEvent) {
    const data: TemplateModalEditData = {
      editMode: EditMode.CREATE,
    };

    const dialogRef = this.matDialog.open(TemplateModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((template?: Template) => {
      this.search();
    });
  }

  changeFilterState(filterState: string | null) {
    if (!filterState) {
      this.filter.state = null
    } else {
      this.filter.state = TemplateState[filterState as keyof typeof TemplateState];
    }

    this.search();
  }
  resetFilter() {
    this.filter.searchText = undefined;
    this.search();
  }
  openEditModal(template: Template, event: MouseEvent) {
    const data: TemplateModalEditData = {
      editMode: EditMode.EDIT,
      templateId: template.id,
    };

    const dialogRef = this.matDialog.open(TemplateModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: {top: '0', right: '0'},
      data,
    });
    dialogRef.afterClosed().subscribe((template?: Template) => {
      this.search();
    });
  }

  private updatePaging(pagingHeader: PagingHeader | undefined) {
    if (pagingHeader) {
      this.paging.perPage = pagingHeader.perPage;
      this.paging.totalItems = pagingHeader.totalItems;
      this.paging.currentPage = pagingHeader.currentPage;
      this.paging.totalPages = Math.ceil(pagingHeader.totalItems / pagingHeader.perPage);

      console.log('paging updated', this.paging);
      const pages: number[] = [];
      for (let i = 0; i < this.paging.totalPages; i++) {
        pages.push(i);
      }
      this.paging.pages = pages;
    }
  }
}
