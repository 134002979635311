import { Component, Input, OnInit } from '@angular/core';
import { DashboardRevisor, DashboardRevisorRisk } from '../dashboardRevisor';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { RevisorFilter } from '../revisor.filter';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard/dashboard.service';
import { DashboardEvaluationRisk } from '../../dashboard/dashboardEvaluation';
import { RiskEditModalEditData } from '../../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../../risk/risk-edit-modal/risk-edit-modal.component';
import { Risk } from '../../dashboard/dashboard';
import { AuthInfoService } from '../../../core/auth-info/auth-info.service';
import { RevisorFilterType } from '../revisor.filter-type.enum';

@Component({
  selector: 'app-revisor-risk',
  templateUrl: './revisor-risk.component.html',
  styleUrls: ['./revisor-risk.component.scss'],
})
export class RevisorRiskComponent implements OnInit {
  @Input() dashboardRevisor!: DashboardRevisor;
  @Input() authInfo?: AuthInfo | null = null;

  revisorFilter: RevisorFilter;
  filteredRisks: DashboardRevisorRisk[] = [];

  constructor(
    private matDialog: MatDialog,
    private authInfoService: AuthInfoService,
    private dashboardService: DashboardService,
  ) {
    const revisorFilterStr = localStorage.getItem('revisorFilter');
    if (revisorFilterStr) {
      this.revisorFilter = JSON.parse(revisorFilterStr);
    } else {
      this.revisorFilter = {};
    }
    if (!this.revisorFilter.filterType) {
      this.revisorFilter.filterType = RevisorFilterType.ALL_ENTRIES;
    }
  }

  ngOnInit(): void {
    this.filterRisks();
  }

  filterRisks() {
    const risks = this.dashboardRevisor.risks
      .filter((r) => {
        if (this.revisorFilter.companyId) {
          if (this.revisorFilter.companyId !== r.company.id) {
            return false;
          }
        }

        if (this.revisorFilter.complianceName) {
          if (this.revisorFilter.complianceName !== r.compliance.complianceName) {
            return false;
          }
        }
        return true;
      });

    this.filteredRisks = risks;
  }

  editRisk(risk: DashboardRevisorRisk) {
    this.changeAccountIfNecessary(risk.company.id);

    const data: RiskEditModalEditData = {
      riskId: risk.id,
      editMode: EditMode.EDIT,
      revisor: true,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.reloadRevisor();
      }
    });
  }

  reloadRevisor() {
    const filter = {
      filterType: this.revisorFilter.filterType,
    };
    this.dashboardService.getRevisorData(filter).subscribe((dashboard) => {
      this.dashboardRevisor = dashboard;
      this.ngOnInit();

      this.revisorFilter = {...this.revisorFilter};
    });
  }

  updateFilter(reload?: boolean) {
    localStorage.setItem('revisorFilter', JSON.stringify(this.revisorFilter));
    this.revisorFilter = {...this.revisorFilter};

    if (reload) {
      this.reloadRevisor();
    } else {
      this.filterRisks();
    }
  }

  changeAccountIfNecessary(companyId: string) {
    if (!this.authInfo) {
      return;
    }

    // if (companyId !== this.authInfo.company.id) {
    //   this.authInfoService.setCurrentAccount({
    //     clientId: this.authInfo.client.id,
    //   });
    // }
  }
}
