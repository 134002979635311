<header id="header" role="banner">
  <h1>
    Objekt
  </h1>
  <a href="" class="header-logo">
    <img src="/img/logos/logo-100x100-inverted.png" alt="dsgvoo - Ausleihen sicher und einfach" />
  </a>
</header>

<main role="main" id="content" [ngClass]="{'categoryExtended': filter.categoryOpen}">

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a (click)="openCreateModal($event)" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Objekt hinzufügen
        </a>
      </div>
      <div class="header-item searchItem size-2-3">
        <input class="overviewHeader-search" type="text" [(ngModel)]="filter.searchText" (ngModelChange)="searchChanged()" placeholder="Name, Code, Kategorie"/> <!-- [(ngModel)]="filter.searchText" (ngModelChange)="changed()" -->
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
        <a class="icon-deleteSearch" *ngIf="filter.searchText" (click)="resetFilter()">
          <fa-icon icon="broom"></fa-icon>
        </a>
        <a class="icon-deleteSearch" *ngIf="!filter.searchText" (click)="openQrScanModal($event)">
          <fa-icon icon="qrcode"></fa-icon>
        </a>
      </div>
      <!-- <div class="header-item filterItem">
         <div>
           <a class="overviewHeader-filter">
             <span class="bigIcon">
               <fa-icon icon="filter"></fa-icon>
             </span>
             <span class="placeholder">Liste filtern</span>
           </a>
         </div>
       </div>-->
     </div>
   </header>

  <!-- ### FILTER ### -->
  <div class="filterBar-v01">
    <div class="filter-multiple" [ngClass]="{'categoryExtended': filter.categoryOpen}">
      <div class="filter-status" (click)="toggleCategoryFilter()">
        <small *ngIf="!filter.category">Alle Kategorien</small>
        <small *ngIf="filter.category">{{ filter.category.name }}</small>
      </div>
			<div class="allOptions">

        <ul>
          <li class="filter-reset"><a (click)="removeCategory()">Filter zurücksetzen</a></li>
          <li *ngFor="let category of categories">
            <a (click)="selectCategory(category)" [ngClass]="{'categorySelected': filter.category && filter.category.id === category.id}">
              <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>
              <span class="name">{{ category.name }}</span>
            </a>
          </li>
          <!--          <li>-->
          <!--            <a>-->
          <!--              <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>-->
          <!--              <span class="name">Hardware</span>-->
          <!--            </a>-->
          <!--            &lt;!&ndash; # SUBCATEGORY # &ndash;&gt;-->
          <!--            <ul>-->
          <!--              <li>-->
          <!--                <a>-->
          <!--                  <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>-->
          <!--                  <span class="name">Hardware</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a>-->
          <!--                  <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>-->
          <!--                  <span class="name">Hardware</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li>-->
          <!--                <a>-->
          <!--                  <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>-->
          <!--                  <span class="name">Hardware</span>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--            &lt;!&ndash; // # SUBCATEGORY # &ndash;&gt;-->
          <!--          </li>-->
          <!--          <li>-->
          <!--            <a>-->
          <!--              <span class="icon"><fa-icon icon="calendar-day"></fa-icon></span>-->
          <!--              <span class="name">Hardware</span>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </div>
		</div>
		<div class="filter">
			<ul>
				<li [ngClass]="{'active': !filter.state}" (click)="changeFilterState(null)"><small>Alle</small></li>
        <li [ngClass]="{'active': filter.state === 'ACTIVE'}" (click)="changeFilterState('ACTIVE')"><small>Aktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'INACTIVE'}" (click)="changeFilterState('INACTIVE')"><small>Inaktiv</small></li>
        <li [ngClass]="{'active': filter.state === 'DELETED'}" (click)="changeFilterState('DELETED')"><small>Gelöscht</small></li>
			</ul>
		</div>
    <div class="filter-icon"><a class="header-item refresh size-square"  (click)="search()"><fa-icon icon="sync-alt"></fa-icon></a></div>
    <div class="filter-icon"><a class="header-item refresh size-square" href="/proxy/api/item/labels" target="_blank"><fa-icon icon="print"></fa-icon></a></div>
	</div>
  <!-- ### FILTER ### -->

  <!-- ITEM LISTE -->
  <div class="container-box-style-v3">

    <div class="item noIcon" *ngFor="let item of items" [ngClass]="{'inactiveItem' : item.state === 'INACTIVE', 'deletedItem' : item.state === 'DELETED', 'statuslessItem' : !item.state}" style="padding-right: 90px"><!--  | sort:'displayName' -->

      <div class="preview" *ngIf="item.standardImage"><img [src]="getStandardImageAssetUrl(item)"/></div>

      <div class="options-selection">

        <a [href]="'/proxy/api/item/' + item.id + '/label'" target="_blank">
					<span class="options-icon"><fa-icon icon="qrcode"></fa-icon></span>
				</a>

        <a (click)="openEditModal(item, $event)">
					<span class="options-icon"><fa-icon icon="pencil-alt"></fa-icon></span>
				</a>

			</div>

			<div class="details" (click)="openEditModal(item, $event)">
				<span class="primary">
					<strong *ngIf="!item.displayName">{{item.code}}</strong>
          <strong *ngIf="item.displayName">{{item.displayName}}</strong>
				</span>
				<span class="secondary" (click)="openEditModal(item, $event)">
          <span class="subItem" *ngIf="item.displayName"><fa-icon icon="qrcode"></fa-icon>&nbsp;{{item.code}}</span>
          <span *ngFor="let itemCategory of item.categories"><span class="subItem"><fa-icon icon="file-alt"></fa-icon>&nbsp;<i>{{ itemCategory.name }}</i></span></span>
				</span>
			</div>
		</div>

    <app-paging [paging]="paging" [pagingSorts]="pagingSorts" (pagingChanged)="search()"></app-paging>
	</div>
  <!-- // ITEM LISTE -->
</main>
