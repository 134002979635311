import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as tinycolor from 'tinycolor2';
import { Contrast } from '@smockle/contrast';
import { Observable } from 'rxjs';
import { IdObjectService } from 'src/app/core/id-object.service';
import { Area, AreaCalculation } from '../../area/area';
import { AreaService } from '../../area/area.service';
import { Compliance, ComplianceCalculation } from '../compliance';
import { MatDialog } from '@angular/material/dialog';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { ComplianceEditModalComponent } from '../compliance-edit-modal/compliance-edit-modal.component';
import { ComplianceEditModalEditData } from '../compliance-edit-modal/compliance-edit-modal-data';
import { ComplianceService } from '../compliance.service';
import { IdObject } from 'src/app/core/id-object';
import { WorkspaceFocus } from '../../workspace/workspace.focus';
import { WorkspaceFilter } from '../../workspace/workspace.filter';
import { WorkspaceService } from '../../workspace/workspace.service';

@Component({
  selector: 'app-compliance-workspace-item',
  templateUrl: './compliance-workspace-item.component.html',
  styleUrls: ['./compliance-workspace-item.component.scss'],
})
export class ComplianceWorkspaceItemComponent implements OnInit {
  @Input() compliance!: Compliance;
  @Input() workspaceFilter!: WorkspaceFilter;
  @Input() focus!: WorkspaceFocus;
  @Output() complianceChange = new EventEmitter<Compliance>();

  @HostBinding('style.--primary-color') get primaryColor(): string {
    return this.compliance?.designColor;
  }

  @HostBinding('style.--primary-color-sub') get backgroundColor(): string {
    return tinycolor.mix(this.primaryColor, 'white', 25).toString();
  }

  @HostBinding('style.--text-color') get textColor(): string | null {
    if (!this.compliance?.designColor) {
      return null;
    }

    const {value: c1} = new Contrast(this.compliance.designColor, 'white');
    const {value: c2} = new Contrast(this.compliance.designColor, '#646f79');

    return c1 > c2 ? 'white' : '#646f79';
  }

  @HostBinding('class.-flaggedExpanded') get isFlaggedExpanded(): boolean {
    return this.expanded;
  }

  @HostBinding('class.-flaggedFilterMatched') get isFlaggedFilterMatched(): boolean {
    return this.compliance.workspaceMatched === true;
  }

  areas: Area[] | null = null;

  expanded: boolean = false;

  constructor(
    private matDialog: MatDialog,
    private areaService: AreaService,
    private workspaceService: WorkspaceService,
    private complianceService: ComplianceService,
  ) {
  }

  public trackById = IdObjectService.trackById;
  private loaded: boolean = false;

  ngOnInit(): void {
    //this.updateAreas();
    if (this.workspaceService.isExpanded(this.compliance.id)) {
      this.setExpanded();
    }
  }

  updateAreas() {
    const compliance = IdObjectService.convertToIdObject(
      this.compliance,
    ) as IdObject;

    const workspaceFilter = Object.assign(
      {workspaceAlreadyMatched: this.compliance.workspaceMatched || this.compliance.workspaceParentMatched},
      this.workspaceFilter,
    );

    this.areaService.search({compliance, workspaceFilter}).subscribe((areas) => {
      this.loaded = true;
      this.areas = areas;
      this.compliance.areas = areas;
    });
  }

  updateCalculation() {
    this.complianceService.getSingleCalculation(this.compliance.id)
      .subscribe((calculation: ComplianceCalculation) => {
        this.compliance.calculation = calculation;
      });
  }

  openEditModal(event: MouseEvent) {
    const data: ComplianceEditModalEditData = {
      complianceId: this.compliance.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(ComplianceEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((compliance?: Compliance) => {
      if (compliance) {
        this.compliance = compliance;
        this.complianceChange.emit(compliance);
      }
    });
  }

  isVisible() {
    return (
      !this.focus?.compliance ||
      this.focus?.compliance?.id === this.compliance.id
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.focus && changes.focus.currentValue) {
      if (
        this.focus?.compliance &&
        this.focus?.compliance?.id === this.compliance.id
      ) {
        this.setExpanded();
      }
    }

    if (changes.compliance && changes.compliance.currentValue) {
      if (this.expanded) {
        this.updateAreas();
      }
    }
  }

  toggleExpand() {
    if (!this.expanded) {
      this.setExpanded();
    } else {
      this.setCollapsed();
    }
  }

  setCollapsed() {
    if (!this.expanded) {
      return;
    }

    this.expanded = false;
    this.workspaceService.setExpanded(this.compliance.id, false);
  }

  setExpanded() {
    if (this.expanded) {
      return;
    }

    this.expanded = true;
    if (!this.loaded) {
      this.updateAreas();
    }

    this.workspaceService.setExpanded(this.compliance.id, true);
  }

  getTooltipTotalHigh() {
    return `${this.compliance.calculation?.riskCountTotalHigh} von ${this.compliance.calculation?.riskCount} Risiken > 7000 Punkte'`;
  }

  getTooltipTotalDamageValue() {
    return `${this.compliance.calculation?.riskCountHighDamageValue} von ${this.compliance.calculation?.riskCount} Risiken mit Schadenshöhe > 80'`;
  }

  getTooltipTotalProbabilityValue() {
    return `${this.compliance.calculation?.riskCountHighProbabilityValue} von ${this.compliance.calculation?.riskCount} Risiken mit Eintrittswahrscheinlichkeit > 80'`;
  }

  getTooltipTotalNoMeasure() {
    return `${this.compliance.calculation?.riskCountNoMeasure} von ${this.compliance.calculation?.riskCount} Risiken ohne Maßnahmen'`;
  }

  public get cssClass(): string[] {
    const classes: string[] = [];

    if (this.expanded) {
      classes.push('containerExpanded');
    }

    if (this.compliance.workspaceMatched) {
      classes.push('filterMatched');
    }

    return classes;
  }
}

