import { Component, Input } from '@angular/core';
import { Company, CompanyAccount, CompanyAccountSearchData } from '../company';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { AccountState } from '../../account/account';
import { NGXLogger } from 'ngx-logger';
import { CompanyService } from '../company.service';
import { MatDialog } from '@angular/material/dialog';
import { AccountTarget } from '../../account/accountTarget.enum';
import { CreateEditUserComponent } from '../../../core/user/create-edit-user/create-edit-user.component';
import { CreateUserModalMode } from '../../../core/user/create-edit-user/create-edit-user';
import { CompanyAccountService } from '../company-account.service';
import { debounceTime } from 'rxjs/operators';
import { AccountService } from '../../account/account.service';

@Component({
  selector: 'app-company-user',
  templateUrl: './company-user.component.html',
  styleUrls: ['./company-user.component.scss'],
})
export class CompanyUserComponent {
  @Input() currentCompany!: Company;

  modelChanged = new Subject<string>();
  companyAccounts: CompanyAccount[] = [];
  filter: CompanyAccountSearchData = {};
  AccountState = AccountState;

  constructor(
    private matDialog: MatDialog,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private accountService: AccountService,
    private companyAccountService: CompanyAccountService,
  ) {
    this.modelChanged
      .pipe(debounceTime(300))
      .subscribe(() => {
        this.loadAccounts();
      });
  }

  ngOnInit(): void {
    this.loadAccounts();
  }

  loadAccounts() {
    this.companyAccountService.search(this.filter).subscribe((companyAccounts) => {
      this.companyAccounts = companyAccounts;
    });
  }

  openAddUserModal(event: MouseEvent) {
    const dialogRef = this.matDialog.open(CreateEditUserComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {
        target: AccountTarget.COMPANY,
        mode: CreateUserModalMode.CREATE,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAccounts();
    });
  }

  deleteAccount(event: MouseEvent, companyAccount: CompanyAccount) {

  }

  resendConfirmation(companyAccount: CompanyAccount) {
    console.log('** resend for ', companyAccount);
    if (companyAccount.account.state === AccountState.NOT_ACTIVATED) {
      this.accountService.resendConfirmationUrl(companyAccount.account.id, {}).subscribe(() => {
        console.log('DONE');
      });
    }
  }

  openEditUserModal(event: MouseEvent, companyAccount: CompanyAccount) {
    const dialogRef = this.matDialog.open(CreateEditUserComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      // position: { top: '0', right: '0' },
      disableClose: true,
      data: {
        target: AccountTarget.COMPANY,
        mode: CreateUserModalMode.EDIT,
        id: companyAccount.id,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.loadAccounts();
    });
  }

  changed() {
    this.modelChanged.next();
  }

  canResendConfirmation(companyAccount: CompanyAccount) {
    return companyAccount.account.state === AccountState.NOT_ACTIVATED;
  }
}
