import { Account } from '../../account/account';
import { AccountService } from '../../account/account.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { IdObject } from 'src/app/core/id-object';
import { IdObjectService } from 'src/app/core/id-object.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Role } from '../../account/role.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { Task, TaskAccount, TaskAccountDraft } from '../task';
import { TaskBenefit } from '../task-benefit.enum';
import { TaskEditModalData } from './task-edit-modal-data';
import { TaskService } from '../task.service';
import { TaskState } from '../task-state.enum';
import { ValueObject } from 'src/app/core/value-object';
import { ValueObjectService } from 'src/app/core/value-object.service';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-task-edit-modal',
  templateUrl: './task-edit-modal.component.html',
  styleUrls: ['./task-edit-modal.component.scss'],
})
export class TaskEditModalComponent implements OnInit {
  public benefitOptions: ValueObject[] = [
    { displayName: 'gering', value: TaskBenefit.LOW },
    { displayName: 'mittel', value: TaskBenefit.MEDIUM },
    { displayName: 'hoch', value: TaskBenefit.HIGH },
  ];
  public stateOptions: ValueObject[] = [
    { displayName: 'aktiv', value: TaskState.ACTIVE },
    { displayName: 'gelöscht', value: TaskState.DELETED },
  ];
  public roleOptions: ValueObject[] = [
    { displayName: 'informativer Benutzer', value: Role.INFORMATIVE },
    { displayName: 'Sachbearbeiter', value: Role.OFFICIAL },
    { displayName: 'Verantwortlicher', value: Role.RESPONSIBLE },
    { displayName: 'Administrator', value: Role.ADMIN },
    { displayName: 'Revisor', value: Role.REVISOR },
  ];
  public accountOptions$!: Observable<Account[]>;
  public filteredAccountOptions$ = new BehaviorSubject<Account[]>([]);
  public openedAccountSelectForTaskAccount = new SelectionModel<number>(false);

  public pending: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    description: ['', []],
    effort: [null, [Validators.min(0)]],
    benefit: [null, [Validators.required]],
    progress: [null, [Validators.min(0), Validators.max(100)]],
    startDate: [null, []],
    endDate: [null, []],
    ignored: [false, []],
    state: [TaskState.ACTIVE, [Validators.required]],
    taskAccounts: this.fb.array([]),
    taskReminders: [[], []],
    tags: [[], []],
  });

  public accountSearchCtrl = new FormControl();

  public taskAccountsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public taskAccountColumns = ['account', 'role', 'controls'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaskEditModalData,
    public matDialogRef: MatDialogRef<TaskEditModalComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
    private accountService: AccountService,
  ) {
    this.accountOptions$ = this.accountService.getList();

    this.accountSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredAccountOptions(searchText);
      });
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    if (this.data.editMode === EditMode.EDIT) {
      this.taskService.getSingle(this.data.taskId).subscribe((task) => {
        this.editFormGroup.patchValue(task);

        task.taskAccounts?.forEach((taskAccount) => {
          this.addTaskAccountFormGroup(taskAccount);
        });
      });
    }

    this.updateTaskAccountView();
    this.getFilteredAccountOptions();
  }

  public getFilteredAccountOptions(searchText?: string) {
    this.accountService.search({ searchText }).subscribe((accountOptions) => {
      this.filteredAccountOptions$.next(accountOptions);
    });
  }

  public addTaskAccountFormGroup(
    taskAccount?: TaskAccount | TaskAccountDraft,
  ): void {
    const taskAccountFormGroup: FormGroup = this.fb.group({
      id: [taskAccount?.id || undefined, []],
      account: [taskAccount?.account, [Validators.required]],
      role: [taskAccount?.role || null, []],
    });

    (this.editFormGroup.get('taskAccounts') as FormArray).push(
      taskAccountFormGroup,
    );

    this.updateTaskAccountView();
  }

  public removeTaskAccountFormGroup(index: number) {
    (this.editFormGroup.get('taskAccounts') as FormArray).removeAt(index);
    this.updateTaskAccountView();
  }

  public setAccountSelectForTaskAccount(open: boolean, index: number): void {
    if (open) {
      this.openedAccountSelectForTaskAccount.select(index);
    } else {
      this.openedAccountSelectForTaskAccount.deselect(index);
    }
  }

  public isAccountSelectForTaskAccountOpen(index: number): boolean {
    return this.openedAccountSelectForTaskAccount.isSelected(index);
  }

  public updateTaskAccountView(): void {
    this.taskAccountsDataSource.next(
      (this.editFormGroup.get('taskAccounts') as FormArray).controls,
    );
  }

  public onSubmit(editFormGroup: FormGroup): void {
    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      return;
    }

    this.pending = true;
    const { value: editData } = editFormGroup;
    switch (this.data.editMode) {
      case EditMode.CREATE:
        const measure: IdObject = { id: this.data.measureId };

        this.taskService
          .create({ measure, ...editData })
          .subscribe((taskId) => {
            let response: Task | null = null;

            if (!!taskId) {
              response = {
                id: taskId,
                ...editData,
              };
            }

            this.pending = false;
            this.matDialogRef.close(response);
          });

        break;
      case EditMode.EDIT:
        const { taskId } = this.data;

        this.taskService.patch(taskId, editData).subscribe((success) => {
          let response: Task | null = null;

          if (success) {
            response = {
              id: taskId,
              ...editData,
            };
          }

          this.pending = false;
          this.matDialogRef.close(response);
        });

        break;
    }
  }
}
