import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { AreaOverviewComponent } from './area-overview/area-overview.component';
import { AreaEditGeneralComponent } from './area-edit-general/area-edit-general.component';
import { AreaEditUserComponent } from './area-edit-user/area-edit-user.component';
import { AreaService } from './area.service';
import {
  resolveParentAreaId,
  resolveAreaList,
  resolveAreaSingle,
} from './area.resolver';
import { resolveEditMode } from 'src/app/core/edit-mode.resolver';
import { EditMode } from 'src/app/core/edit-mode.enum';
import { resolveComplianceId } from '../compliance/compliance.resolver';

export const AREA_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.area',
    url: '/area',
    views: {
      '$default@app': {
        component: AreaOverviewComponent,
      },
    },
    params: {
      complianceId: null,
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'areas',
        deps: [Transition, AreaService],
        resolveFn: resolveAreaList,
      },
      {
        token: 'complianceId',
        deps: [Transition],
        resolveFn: resolveComplianceId,
      },
    ],
  },
  {
    name: 'app.area.details',
    abstract: true,
    url: '/{areaId}',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
      editMode: EditMode.EDIT,
    },
    resolve: [
      {
        token: 'area',
        deps: [Transition, AreaService],
        resolveFn: resolveAreaSingle,
      },
      {
        token: 'editMode',
        deps: [Transition],
        resolveFn: resolveEditMode,
      },
    ],
  },
  {
    name: 'app.area.details.general',
    views: {
      '$default@app': {
        component: AreaEditGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.area.details.user',
    views: {
      '$default@app': {
        component: AreaEditUserComponent,
      },
    },
    url: '/user',
  },
  {
    name: 'app.area.create',
    abstract: true,
    url: '/create?complianceId&parentAreaId',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
      editMode: EditMode.CREATE,
    },
    resolve: [
      {
        token: 'editMode',
        deps: [Transition],
        resolveFn: resolveEditMode,
      },
      {
        token: 'parentAreaId',
        deps: [Transition],
        resolveFn: resolveParentAreaId,
      },
      {
        token: 'complianceId',
        deps: [Transition],
        resolveFn: resolveComplianceId,
      },
    ],
  },
  {
    name: 'app.area.create.general',
    views: {
      '$default@app': {
        component: AreaEditGeneralComponent,
      },
    },
    url: '/general',
  },
  {
    name: 'app.area.create.user',
    views: {
      '$default@app': {
        component: AreaEditUserComponent,
      },
    },
    url: '/user',
  },
];
