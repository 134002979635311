<span class="legend -start">{{ start }}</span>
<span class="legend -end">{{ end }}</span>
<span
  class="progress"
  [style.width.%]="progressPercentage"
  [ngClass]="progressClassName"
>
  <span class="legend -value" *ngIf="value < start" [ngClass]="progressClassName"><b>{{ value }}</b></span>
</span>
<span
  class="milestone"
  [ngClass]="milestone.className"
  [style.left.%]="getMilestonePercentage(milestone)"
  *ngFor="
    let milestone of milestones | progressMeterMilestoneInRange: start:end:type
  "
>
  <span class="milestoneLegend">{{ getMilestoneValue(milestone) }}</span>
</span>
