<header id="header" role="banner">
  <h1>
    Mandant
    <small>Benutzer</small>
  </h1>
</header>

<main role="main" id="content" class="companies">
  <app-client-nav></app-client-nav>

  <header class="overviewHeader isFixed">
    <div class="clearfix">
      <div class="header-item addItem">
        <a (click)="openAddUserModal($event)" class="overviewHeader-add modal-open">
          <span class="bigIcon">
            <fa-icon icon="plus"></fa-icon>
          </span>
          Benutzer hinzufügen
        </a>
      </div>
      <div class="header-item searchItem size-2-3">
        <input [(ngModel)]="filter.searchText" (ngModelChange)="changed()" class="overviewHeader-search" type="text" />
        <span class="icon-search">
          <fa-icon icon="search"></fa-icon>
        </span>
      </div>
      <!-- <div class="header-item filterItem">
         <div>
           <a class="overviewHeader-filter">
             <span class="bigIcon">
               <fa-icon icon="filter"></fa-icon>
             </span>
             <span class="placeholder">Liste filtern</span>
           </a>
         </div>
       </div>-->
     </div>
   </header>

  <!-- BENUTZER LISTE -->
  <div class="container-box-style-v3" novalidate>
    <!-- ng-class="{'confirmed': manager.accountStatus === 'OK', 'invitationPending': manager.accountStatus === 'PENDING_ACTIVATION', 'mailBounced': manager.emailInfoErrorText}"  -->
		<div class="item" *ngFor="let account of this.accounts">

			<div class="icon">
				<span class="graphic">
					<span class="status singleStatus" >
						<fa-icon icon="chess-queen"></fa-icon>
					</span>
          <!--
          <span class="status singleStatus">
						<i class="fas fa-user-tie"></i>
					</span>
          <span class="status singleStatus">
            <i class="fas fa-building"></i>
          </span>
          <span class="status singleStatus">
            <i class="fas fa-barcode"></i>
          </span>
          <span class="status singleStatus">
            <i class="fas fa-language"></i>
          </span>-->
				</span>
				<!--<span class="description"></span>-->
			</div>

			<div class="options-selection">
				<a (click)="openEditUserModal($event, account)">
					<span class="options-icon"><fa-icon icon="pencil-alt"></fa-icon></span>
				</a>
			</div>

			<div class="details">
				<span class="primary">
					<strong>{{account.firstName}}&nbsp;{{account.lastName}}</strong>
					<!--<strong ng-if="manager.accountStatus === 'PENDING_ACTIVATION' && !manager.emailInfoErrorText" class="color-system-warning-important"><i class="far fa-pause-circle"></i>&nbsp;{{manager.user.email}}</strong>
					<strong ng-if="manager.emailInfoErrorText" class="error"><i class="fas fa-exclamation-triangle"></i>&nbsp;{{manager.user.email}}</strong>-->
				</span>
				<span class="secondary">
          <span *ngIf="!account.role" class="subItem"><i>keine Rolle</i></span>
          <span *ngIf="account.role" class="subItem">{{'ROLE.' + account.role | translate}}</span>
					<!--<span class="subItem error" ng-if="manager.emailInfoErrorText">
						{{manager.emailInfoErrorText}}
					</span>
					<span class="subItem" ng-if="manager.accountStatus === 'PENDING_ACTIVATION'">
						{{ 'PHRASE_INVITATION_SEND' | translate}} ({{manager.lastInvitationSent | tzDate:"DD.MM.YYYY HH:mm"}}) <a ng-click="vm.resendInvitation(manager)" ng-if="!manager.emailInfoErrorText">» Neu senden</a>
					</span>
					<span class="subItem" ng-if="manager.accountStatus === 'OK'">
						{{manager.user.email}}
					</span>-->
				</span>

				<!--<div class="special" ng-if="manager.userRole === 'CP_PERSOO_VISITOR_EVENT_MANAGER'">
					<strong style="padding-bottom: 0px">Zusatzrechte</strong>
					<div style="display: flex;gap: 20px; flex-wrap: wrap">
						<cp-toggle-option callback="vm.changePermissions(manager)" option="permission.code" list="manager.permissions" link-class="checkbox" ng-repeat="permission in vm.permissions" ng-if="vm.permissionIsVisible(permission)">
							<span translate="{{'PERMISSION.' + permission.code}}"></span>
							<md-tooltip md-direction="top"><span class="tooltip-inside"><translate>{{'PERMISSION.' + permission.code + ".INFO"}}</translate></span></md-tooltip>
						</cp-toggle-option>
					</div>


				</div>-->

			</div>

      <!-- <md-tooltip md-direction="top" ><span class="tooltip-inside">
      </span></md-tooltip> -->

		</div>


	</div>

  <!-- // BENUTZER LISTE -->

</main>
