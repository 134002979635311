import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TfaService } from '../tfa.service';
import { TfaAuthorizedRequestModalData } from './tfa-authorized-request-modal-data';
import { TfaAuthorizedRequestMode } from './tfa-authorized-request-mode';

@Component({
  selector: 'app-tfa-authorized-request-modal',
  templateUrl: './tfa-authorized-request-modal.component.html',
  styleUrls: ['./tfa-authorized-request-modal.component.scss'],
})
export class TfaAuthorizedRequestModalComponent {
  public pending: boolean = false;

  public requestFormGroup: FormGroup = this.fb.group({
    accountPassword: ['', [Validators.required]],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TfaAuthorizedRequestModalData,
    public matDialogRef: MatDialogRef<TfaAuthorizedRequestModalComponent>,
    private fb: FormBuilder,
    private tfaService: TfaService,
  ) {
  }

  get isDisable(): boolean {
    return this.data.mode === TfaAuthorizedRequestMode.DISABLE;
  }

  get isEnable(): boolean {
    return this.data.mode === TfaAuthorizedRequestMode.ENABLE;
  }

  get isDelete(): boolean {
    return this.data.mode === TfaAuthorizedRequestMode.DELETE;
  }

  handleError(err: any) {
    this.pending = false;
    if (err.errorFields) {
      for (let errorField of err.errorFields) {
        if (errorField.field === 'accountPassword') {
          this.requestFormGroup.get('accountPassword')?.setErrors({'pattern': true});
        }
      }
    }
  }

  public onSubmit(requestFormGroup: FormGroup) {
    if (requestFormGroup.invalid) {
      return;
    }

    if (this.pending) {
      return;
    }

    this.pending = true;

    const {value: tfaAuthorizedRequestData} = requestFormGroup;
    switch (this.data.mode) {
      case TfaAuthorizedRequestMode.DISABLE:
        this.tfaService
          .disable(this.data.tfaId, tfaAuthorizedRequestData)
          .subscribe((success) => {
            this.pending = false;
            if (success) {
              this.matDialogRef.close();
            }
          }, (err) => {
            this.handleError(err);
          });
        break;
      case TfaAuthorizedRequestMode.ENABLE:
        this.tfaService
          .enable(this.data.tfaId, tfaAuthorizedRequestData)
          .subscribe((success) => {
            this.pending = false;
            if (success) {
              this.matDialogRef.close();
            }
          }, (err) => {
            this.handleError(err);
          });
        break;
      case TfaAuthorizedRequestMode.DELETE:
        this.tfaService
          .delete(this.data.tfaId, tfaAuthorizedRequestData)
          .subscribe((success) => {
            this.pending = false;
            if (success) {
              this.matDialogRef.close();
            }
          }, (err) => {
            this.handleError(err);
          });
        break;
    }
  }
}
