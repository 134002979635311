<div mat-dialog-title>Aktion ausführen</div>
<div mat-dialog-content>
  <div>
    Soll die Aktion wirklich ausgeführt werden?
    <ng-content></ng-content>
  </div>
  <footer mat-dialog-actions>
    <button type="button" (click)="cancel()" mat-raised-button mat-dialog-close>Nein</button>
    <button type="button" (click)="proceed()" mat-raised-button color="primary" [disabled]="pending">Ja</button>
  </footer>
</div>
