<form
  novalidate
  (ngSubmit)="onSubmit(requestFormGroup)"
  [formGroup]="requestFormGroup"
>
  <div fxLayout="row">
    <mat-form-field fxFlexFill appearance="fill">
      <mat-label>E-Mail-Adresse</mat-label>
      <input
        type="email"
        matInput
        formControlName="email"
        [readonly]="isEditMode()"
        required
      />
      <mat-error *ngIf="requestFormGroup.hasError('required', 'email')">
        Bitte eine E-Mail-Adresse angeben
      </mat-error>
      <mat-error *ngIf="requestFormGroup.hasError('pattern', 'email')">
        Bitte eine gültige E-Mail-Adresse angeben
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row">
    <mat-form-field fxFlex="50%" appearance="fill">
      <mat-label>Vorname</mat-label>
      <input
        type="text"
        matInput
        formControlName="firstName"
        required
      />
      <mat-error *ngIf="requestFormGroup.hasError('pattern', 'firstName')">
        Bitte einen Vornamen angeben
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="50%" appearance="fill">
      <mat-label>Nachname</mat-label>
      <input
        type="text"
        matInput
        formControlName="lastName"
        required
      />
      <mat-error *ngIf="requestFormGroup.hasError('pattern', 'lastName')">
        Bitte einen Nachnamen angeben
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row">
      <mat-form-field fxFlex="50%" appearance="fill">
        <mat-label>Rolle</mat-label>
        <mat-select placeholder="Rolle" formControlName="role">
          <mat-option [value]="role.value" *ngFor="let role of roleOptions">
            {{ role.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
  </div>

  <footer mat-dialog-actions>
    <button type="button" (click)="deleteUser()" mat-raised-button *ngIf="isEditMode()" [disabled]="pending || data.userId === data.id" style="margin-right: 20px; box-shadow: none !important;">
      <span>Löschen</span>
    </button>

    <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
    <button type="submit" mat-raised-button color="primary" [disabled]="!requestFormGroup.get('email')?.value || pending || requestFormGroup.invalid">
      <span *ngIf="isEditMode()">Speichern</span>
      <span *ngIf="!isEditMode()">Benutzer hinzufügen</span>
    </button>

  </footer>
</form>
