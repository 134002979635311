import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasuresWidgetComponent } from './measures-widget.component';



@NgModule({
  declarations: [
    MeasuresWidgetComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MeasuresWidgetComponent,
  ],
})
export class MeasuresWidgetModule { }
