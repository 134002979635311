import { Component, Input, OnInit } from '@angular/core';
import { AuthInfo } from '../../../core/auth-info/auth-info';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard/dashboard.service';
import { DashboardRevisor, DashboardRevisorMeasure } from '../dashboardRevisor';
import { RevisorFilter } from '../revisor.filter';
import { AuthInfoService } from '../../../core/auth-info/auth-info.service';
import { MeasureEditModalEditData } from '../../measure/measure-edit-modal/measure-edit-modal-data';
import { EditMode } from '../../../core/edit-mode.enum';
import { MeasureEditModalComponent } from '../../measure/measure-edit-modal/measure-edit-modal.component';
import { Measure } from '../../measure/measure';
import { RevisorFilterType } from '../revisor.filter-type.enum';

@Component({
  selector: 'app-revisor-measure',
  templateUrl: './revisor-measure.component.html',
  styleUrls: ['./revisor-measure.component.scss'],
})
export class RevisorMeasureComponent implements OnInit {
  @Input() dashboardRevisor!: DashboardRevisor;
  @Input() authInfo?: AuthInfo | null = null;

  revisorFilter: RevisorFilter;
  filteredMeasures: DashboardRevisorMeasure[] = [];

  constructor(
    private matDialog: MatDialog,
    private authInfoService: AuthInfoService,
    private dashboardService: DashboardService,
  ) {
    const revisorFilterStr = localStorage.getItem('revisorFilter');
    if (revisorFilterStr) {
      this.revisorFilter = JSON.parse(revisorFilterStr);
    } else {
      this.revisorFilter = {};
    }
    if (!this.revisorFilter.filterType) {
      this.revisorFilter.filterType = RevisorFilterType.ALL_ENTRIES;
    }
  }

  ngOnInit(): void {
    this.filterMeasures();
  }

  filterMeasures() {
    const measures = this.dashboardRevisor.measures
      .filter((r) => {
        if (this.revisorFilter.companyId) {
          if (this.revisorFilter.companyId !== r.company.id) {
            return false;
          }
        }

        if (this.revisorFilter.complianceName) {
          if (this.revisorFilter.complianceName !== r.compliance.complianceName) {
            return false;
          }
        }
        return true;
      });

    this.filteredMeasures = measures;
  }

  editMeasure(measure: DashboardRevisorMeasure) {
    this.changeAccountIfNecessary(measure.company.id);

    const data: MeasureEditModalEditData = {
      measureId: measure.id,
      editMode: EditMode.EDIT,
      revisor: true,
    };

    const dialogRef = this.matDialog.open(MeasureEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((measure?: Measure) => {
      if (measure) {
        this.reloadRevisor();
      }
    });
  }

  reloadRevisor() {
    const filter = {
      filterType: this.revisorFilter.filterType,
    };
    this.dashboardService.getRevisorData(filter).subscribe((dashboard) => {
      this.dashboardRevisor = dashboard;
      this.ngOnInit();

      this.revisorFilter = {...this.revisorFilter};
    });
  }

  updateFilter(reload?: boolean) {
    localStorage.setItem('revisorFilter', JSON.stringify(this.revisorFilter));
    this.revisorFilter = {...this.revisorFilter};

    console.log('update filter. Reload?', reload);
    if (reload) {
      this.reloadRevisor();
    } else {
      this.filterMeasures();
    }
  }

  changeAccountIfNecessary(companyId: string) {
    if (!this.authInfo) {
      return;
    }

    // if (companyId !== this.authInfo.company.id) {
    //   this.authInfoService.setCurrentAccount({
    //     clientId: this.authInfo.client.id,
    //   });
    // }
  }
}
