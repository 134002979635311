import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthInfo, AuthInfoReduced, CurrentAccount } from './auth-info';
import {Role} from "../../common/account/role.enum";
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from "@angular/router";
import {AuthUrlData} from "./auth-url-data";

@Injectable({
  providedIn: 'root',
})
export class AuthInfoService {
  private static authInfoUrl = (authApiUrl: string) => `${authApiUrl}authInfo`;
  private static accountListUrl = (authApiUrl: string) =>
    `${authApiUrl}accounts`;

  // currentAccount: CurrentAccount | null = null;
  currentAuthInfo: AuthInfo | null = null;

  constructor(private httpClient: HttpClient) {
  }

  // public getCurrentAccount(): CurrentAccount | null {
  //   return this.currentAccount;
  // }

  public getCurrentAuthInfo(): AuthInfo | null {
    return this.currentAuthInfo;
  }

  public setCurrentAccount(account: CurrentAccount) {
    console.log('** set account:', account);
    if (account.clientId) {
      if (this.currentAuthInfo) {
        this.currentAuthInfo.client.id = account.clientId;
        this.currentAuthInfo.account.id = account.id;
      }
    }
  }

  public setCurrentAuthInfo(account: AuthInfo) {
    this.currentAuthInfo = account;
  }

  public getAuthInfo(reload?: boolean): Promise<AuthInfo> {
    const {authApiUrl} = environment;

    return new Promise((resolve) => {
      if (this.currentAuthInfo && !reload) {
        resolve(this.currentAuthInfo);
      } else {
        const {authApiUrl} = environment;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let dataParameter = urlParams.get('data')

        const url = AuthInfoService.authInfoUrl(authApiUrl);
        const headers = new HttpHeaders({'Content-Type': 'application/json'});

        const dataBody : AuthUrlData = {data: dataParameter}

        this.httpClient.post<AuthInfo>(url, dataBody, {headers}).pipe(
            catchError(this.goToErrorPage<AuthInfo>(undefined)),
        ).subscribe((authInfo) => {
          this.currentAuthInfo = authInfo;
          resolve(this.currentAuthInfo);
          if (dataParameter) {
            window.location.href = '/'
          }

        });
      }
      // resolve({
      //   account: {
      //     id: '',
      //     user: {id: '', email: ''},
      //   },
      //   client: {id: ''},
      //   company: {id: '', displayName: '', role: Role.NO_ROLE},
      // })
    });
  }

  public getAccounts(): Observable<AuthInfoReduced[]> {
    const {authApiUrl} = environment;
    const url = AuthInfoService.accountListUrl(authApiUrl);

    return this.httpClient
      .get<AuthInfoReduced[]>(url)
      .pipe(
        catchError(
          this.handleError<AuthInfoReduced[]>('AuthInfoService->getAccounts', undefined),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private goToErrorPage<T>(result?: T): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      window.location.href = '/error-page'

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
