<header id="header" role="banner">
  <h1>
    Mandant
    <small>Allgemeines</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="dsgvoo - Ausleihen sicher und einfach"
    />
  </a>
</header>

<main role="main" id="content" class="areas">

  <form
    class="o-clientGeneral"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup">
    <div class="field-edit-container">
      <button type="submit" [disabled]="pending" class="edit-button"><fa-icon icon="save"></fa-icon>&nbsp;&nbsp;Sichern</button>
    </div>
    <div class="field-edit-container-spacerBig"></div>

  <app-client-nav></app-client-nav>


    <h3 class="subHeading">Allgemeine Informationen</h3>

    <div>
      <mat-form-field appearance="fill" style="display: block; width: 100%; box-sizing: border-box;">
        <mat-label>Name Mandant</mat-label>
        <input matInput type="text" formControlName="displayName" />
      </mat-form-field>
    </div>

    <div>
      Anzahl Ausweis-Scans: {{ currentClient.scanCounter ? currentClient.scanCounter : '0' }}
    </div>

    <h3 class="subHeading">Rechtstexte</h3>

    <div style="display: block;">
      <mat-form-field style="display: block; margin-bottom: 20px;" appearance="fill">
        <mat-label>Ausleihbedingungen (wird unterschrieben)</mat-label>
        <textarea matInput formControlName="letterOfAgreement"></textarea>
      </mat-form-field>
    </div>

    <div style="display: block;">
      <mat-form-field style="display: block; margin-bottom: 20px;" appearance="fill">
        <mat-label>Ausleihbedingungen (Kurzform, wird unterschrieben)</mat-label>
        <textarea matInput formControlName="letterOfAgreementShort"></textarea>
      </mat-form-field>
    </div>

    <h3 class="subHeading">Settings</h3>

    <div style="display: block;">
      <mat-form-field style="display: block; margin-bottom: 20px;" appearance="fill">
        <mat-label>Autom. Löschung nach Rückgabe</mat-label>
        <input matInput type="text" formControlName="deleteAfterReturnDays" />
      </mat-form-field>
      <small class="legende">
        <b>Hinweis</b>: X Tage nach der Rückgabe werden die Daten gelöscht.<br>Als "nicht löschen" markierte Vorgänge bleiben immer erhalten.
      </small>
    </div>

    <div style="display: block;">
      <mat-form-field style="display: block; margin-bottom: 20px;" appearance="fill">
        <mat-label>Autom. Löschung nach Verleih</mat-label>
        <input matInput type="text" formControlName="deleteAfterBorrowedDays" />
      </mat-form-field>
      <small class="legende">
        <b>Hinweis</b>: X Tage nach dem Verleihvorgang werden die Daten gelöscht (unabhängig davon ob sie zurückgegeben wurden oder nicht). Wenn Du das nicht willst, lasse dieses Feld leer.<br>
                      Als "nicht löschen" markierte Vorgänge bleiben immer erhalten.
      </small>
    </div>


  </form>
</main>
