<header id="header" role="banner">
  <h1>
    {{ authInfo.appData.appName }}
    <small>Details zur Auftragsdatenverarbeitung</small>
  </h1>
</header>

<main role="main" id="content" class="areas">

  <div class="container-box-style-v3">
    <div>

    </div>

    <form
            novalidate
            [formGroup]="editFormGroup"
    >

      <div class="field-edit-container">
        <a class="edit-button download" target="_blank" *ngIf="canAvvBeDownloaded" [href]="getAvvDownloadUrl()" download><fa-icon icon="file-pdf"></fa-icon>&nbsp;&nbsp;Download AVV</a>
        <a class="edit-button" (click)="backToApp()">Zurück zu {{ authInfo.appData.appName }}</a>
      </div>
      <div class="field-edit-container-spacerBig"></div>
      <div class="selection">
        <h3>Nutzung von personenbezogenen Daten</h3>
        <mat-radio-group
              aria-labelledby="example-radio-group-label"
              class="example-radio-group"
              formControlName="state">
        <mat-radio-button class="example-radio-button" [value]="'TEST_ONLY'">Ich teste das Tool des Anbieters nur und verwende keine personenbezogenen Daten
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" [value]="'PRIVATE'">Ich nutze das Tool des Anbieters ausschließlich zu privaten Zwecken
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" [value]="'NO_THIRD_PARTY_DATA_USAGE'">Ich speichere keine personenbezogenen Daten Dritter im Tool des Anbieters
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" [value]="'AVV_ALREADY_PRESENT'">Ich versichere hiermit bereits einen AVV mit der Code Piraten GmbH geschlossen zu haben
        </mat-radio-button>
        <mat-radio-button class="example-radio-button" [value]="'AVV_USAGE'">Ich speichere personenbezogene Daten Dritter im Tool des Anbieters und akzeptiere den AVV (<a href="http://www.codepiraten.com/downloads/CodePiraten-Adv-Vertrag-v20220805-dsgvoo-muster.pdf" target="_blank" style="color:#ff0084;">Muster downloaden</a>)
        </mat-radio-button>
      </mat-radio-group>
      </div>
      <div *ngIf="editFormGroup.get('state')?.value === 'AVV_USAGE'" class="selection">
          <h3>Auftraggeber-Daten</h3>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Name</mat-label>
            <input matInput type="text" formControlName="clientName"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Straße + Hausnummer</mat-label>
            <input matInput type="text" formControlName="clientStreet"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Postleitzahl</mat-label>
            <input matInput type="text" formControlName="clientPlz"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Ort</mat-label>
            <input matInput type="text" formControlName="clientLocation"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Land</mat-label>
            <input matInput type="text" formControlName="clientCountry"/>
          </mat-form-field>
        <br/>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Weisungsberechtigte Personen</mat-label>
            <input matInput type="text" formControlName="personsAuthorized"/>
          </mat-form-field>
        </div>
      <div *ngIf="editFormGroup.get('state')?.value === 'AVV_USAGE'" class="selection">
          <h3>Erhobenen Daten</h3>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Kategorien von betroffenen Personen</mat-label>
            <input matInput type="text" formControlName="categories"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Art der personenbezogenen Daten</mat-label>
            <input matInput type="text" formControlName="typeOfPersonalData"/>
          </mat-form-field>
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Kommunikationskanal zur Meldung von Verstößen</mat-label>
            <input matInput type="text" formControlName="reportingType"/>
          </mat-form-field>

          <div formArrayName="avvFields" *ngIf="avvExtraFields && avvExtraFields.length > 0">
              <div class="itemList__entry" *ngFor="let avvField of avvFields.controls; let i = index" [formGroupName]="i">
                  <mat-form-field style="display: block; margin-top: 20px" appearance="fill">
                      <mat-label>{{ avvExtraFields[i].displayName }}</mat-label>
                      <input matInput type="text" formControlName="fieldValue"/>
                  </mat-form-field>
              </div>
          </div>

        </div>
      <button type="button" (click)="onSubmit(editFormGroup)" [disabled]="pending" class="edit-button-bottom" [ngClass]="{'disabled': (editFormGroup.get('state')?.value === 'UNDEFINED' || editFormGroup.get('state')?.value === 'AVV_REVOKED') || (editFormGroup.get('state')?.value === 'AVV_USAGE' && !editFormGroup.dirty) || pending}">
        <fa-icon icon="save"></fa-icon>&nbsp;&nbsp;
          <span *ngIf="editFormGroup.get('state')?.value === 'AVV_USAGE'">
             <span *ngIf="initialState !== 'AVV_USAGE'">
                 AVV abschließen
             </span>
             <span *ngIf="initialState === 'AVV_USAGE'">
                 AVV ändern
             </span>
          </span>
          <span *ngIf="editFormGroup.get('state')?.value !== 'AVV_USAGE'">
              Speichern
          </span>
      </button>
      <button type='button' *ngIf="canBeRevoked" (click)="revokeAvv()" class="edit-button-bottom secondary">Widerrufen</button>
    </form>
  </div>
</main>
<footer>powered by <a href="https://www.dsgvoo.de" target="_blank">dsgvoo.de</a></footer>
