import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { AuthInfoService } from '../../core/auth-info/auth-info.service';
import { resolveAuthInfo } from '../../core/auth-info/auth-info.resolver';

export const CATEGORY_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.settings.template',
    redirectTo: 'app.settings.template.overview',
    url: '/template',
    params: {
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: true,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'authInfo',
        deps: [AuthInfoService],
        resolveFn: resolveAuthInfo,
      },
    ],
  },
  {
    name: 'app.settings.template.overview',
    views: {
      '$default@app': {
        component: TemplateOverviewComponent,
      },
    },
    url: '/',
  },
];
