import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DashboardEvaluation,
  DashboardEvaluationCompany,
  DashboardEvaluationRisk,
} from '../../dashboardEvaluation';
import { IdObjectService } from '../../../../core/id-object.service';
import { ProgressMeterType } from '../../../../core/progress-meter/progress-meter-type.enum';
import { ProgressMeterMilestone } from '../../../../core/progress-meter/progress-meter-milestone';
import { ProgressMeterStates } from '../../../../core/calculation/ProgressMeterStates';
import { Risk } from '../../dashboard';
import { RiskEditModalEditData } from '../../../risk/risk-edit-modal/risk-edit-modal-data';
import { EditMode } from '../../../../core/edit-mode.enum';
import { RiskEditModalComponent } from '../../../risk/risk-edit-modal/risk-edit-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';
import { StateService } from '@uirouter/angular';
import { AuthInfoService } from '../../../../core/auth-info/auth-info.service';
import { AuthInfo } from '../../../../core/auth-info/auth-info';
import { DashboardFilter } from '../../dashboard.filter';
import { NumericValueObject } from '../../../../core/value-object';
import { ProhabilityValues } from '../../../../core/calculation/ProhabilityValue';
import { EvaluationFilter } from '../../../evaluation/evaluation.filter';

@Component({
  selector: 'app-dashboard-widget-company-risks',
  templateUrl: './dashboard-widget-company-risks.component.html',
  styleUrls: ['./dashboard-widget-company-risks.component.scss'],
})
export class DashboardWidgetCompanyRisksComponent implements OnInit {
  @Input() evaluationFilter!: EvaluationFilter;
  @Input() authInfo?: AuthInfo | null = null;
  @Input() dashboardEvaluation?: DashboardEvaluation | null = null;
  @Output() filterChanged = new EventEmitter<EvaluationFilter>();
  @Output() dashboardReload = new EventEmitter<void>();

  selectedCompliance?: string;
  selectedCompany?: DashboardEvaluationCompany;

  private prohabilityValues: NumericValueObject[] = ProhabilityValues;

  public trackById = IdObjectService.trackById;

  constructor(
    private matDialog: MatDialog,
    private dashboardService: DashboardService,
    private stateService: StateService,
    private authInfoService: AuthInfoService,
  ) {
  }

  public progressMeterType = ProgressMeterType;

  ngOnInit(): void {
    this.updateSelectedCompanyRisks();
  }

  complianceChanged() {
    this.evaluationFilter.complianceName = this.selectedCompliance;
    this.filterChanged.emit(this.evaluationFilter);
  }

  editRisk(risk: Risk | DashboardEvaluationRisk) {
    this.changeAccountIfNecessary(risk.company.id);

    const data: RiskEditModalEditData = {
      riskId: risk.id,
      editMode: EditMode.EDIT,
    };

    const dialogRef = this.matDialog.open(RiskEditModalComponent, {
      width: '750px',
      maxWidth: '100%',
      minHeight: 'calc(100vh - 0)',
      position: {top: '0', right: '0'},
      data,
    });

    dialogRef.afterClosed().subscribe((risk?: Risk) => {
      if (risk) {
        this.dashboardReload.emit();
      }
    });
  }

  changeAccountIfNecessary(companyId: string) {
    if (!this.authInfo) {
      return;
    }

    // if (companyId !== this.authInfo.company.id) {
    //   this.authInfoService.setCurrentAccount({
    //     clientId: this.authInfo.client.id,
    //   });
    // }
  }

  getProhabilityValues(value: number): string {
    const values = this.prohabilityValues.sort((a, b) => a.value - b.value);
    let lastValue: NumericValueObject | null = null;
    for (let entry of values) {
      if (value >= entry.value) {
        lastValue = entry;
      } else {
        break;
      }
    }

    return lastValue ? lastValue.displayName : '';
  }

  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  getProgressMeterClass(type: ProgressMeterType, value: number) {
    const classNames: string[] = [];
    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }

    return classNames;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.evaluationFilter && changes.evaluationFilter.currentValue) {
      this.updateSelectedCompanyRisks();
    }
  }

  updateSelectedCompanyRisks() {
    if (this.dashboardEvaluation) {
      if (this.evaluationFilter.companyId) {
        this.selectedCompany = this.dashboardEvaluation.companies.find(c => c.id === this.evaluationFilter.companyId);

      } else if (this.dashboardEvaluation.companies.length > 0) {
        this.selectedCompany = this.dashboardEvaluation.companies[0];
      }

      if (this.selectedCompany) {
        const risks: DashboardEvaluationRisk[] = [];
        this.selectedCompany.compliances
          .filter(c => !this.evaluationFilter.complianceName || this.evaluationFilter.complianceName === c.complianceName)
          .forEach(c => {
            c.risks.forEach(r => {
              r.$$complianceName = c.complianceName;
              r.$$complianceColor = c.designColor;
              r.$$prohabilityValue = this.getProhabilityValues(r.calculatedProbability);
              risks.push(r);
            });
          });
        this.selectedCompany.$risks = risks.sort((
          a,
          b,
        ) => b.calculatedRiskDamage - a.calculatedRiskDamage);

        this.selectedCompliance = undefined;
        setTimeout(() => {
          this.selectedCompliance = this.evaluationFilter.complianceName;
        });
      }
    }
  }
}
