import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  DashboardEvaluation,
  DashboardEvaluationComplianceByClient, DashboardEvaluationRisk,
} from '../../dashboardEvaluation';
import { IdObjectService } from '../../../../core/id-object.service';
import { ProgressMeterType } from '../../../../core/progress-meter/progress-meter-type.enum';
import { ProgressMeterMilestone } from '../../../../core/progress-meter/progress-meter-milestone';
import { ProgressMeterStates } from '../../../../core/calculation/ProgressMeterStates';
import { RiskComplianceCompliance } from '../../dashboard';
import { DashboardFilter } from '../../dashboard.filter';
import { IdObject } from '../../../../core/id-object';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from '../../dashboard.service';
import { StateService } from '@uirouter/angular';
import { AuthInfoService } from '../../../../core/auth-info/auth-info.service';
import { AuthInfo } from '../../../../core/auth-info/auth-info';
import { Compliance } from '../../../compliance/compliance';
import { EvaluationFilter } from '../../../evaluation/evaluation.filter';

@Component({
  selector: 'app-dashboard-widget-client-compliances',
  templateUrl: './dashboard-widget-client-compliances.component.html',
  styleUrls: ['./dashboard-widget-client-compliances.component.scss'],
})
export class DashboardWidgetClientCompliancesComponent implements OnInit {
  @Input() dashboardEvaluation?: DashboardEvaluation | null = null;
  @Input() evaluationFilter!: EvaluationFilter;
  @Input() authInfo?: AuthInfo | null = null;
  @Output() filterChanged = new EventEmitter<EvaluationFilter>();

  compliances: DashboardEvaluationComplianceByClient[] = [];

  public trackById = IdObjectService.trackById;
  public progressMeterType = ProgressMeterType;

  constructor(
    private matDialog: MatDialog,
    private dashboardService: DashboardService,
    private stateService: StateService,
    private authInfoService: AuthInfoService,
  ) {

  }

  ngOnInit(): void {
    this.updateCompliances();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.evaluationFilter && changes.evaluationFilter.currentValue) {
      this.updateCompliances();
    }
  }

  updateCompliances() {
    const newCompliances = [];
    if (this.dashboardEvaluation) {
      for (const compliance of this.dashboardEvaluation.compliances) {
        if (this.evaluationFilter.complianceName && this.evaluationFilter.complianceName !== compliance.complianceName) {
          continue;
        }
        newCompliances.push(compliance);
      }
    }
    this.compliances = newCompliances;
  }

  gotoCompliance(
    compliance: RiskComplianceCompliance | DashboardEvaluationComplianceByClient,
    company: IdObject,
  ) {
    this.changeAccountIfNecessary(company.id);
    this.stateService.go('app.workspace', {complianceId: compliance.id}, {reload: true});
  }

  changeAccountIfNecessary(companyId: string) {
    if (!this.authInfo) {
      return;
    }

    // if (companyId !== this.authInfo.company.id) {
    //   this.authInfoService.setCurrentAccount({
    //     clientId: this.authInfo.client.id,
    //   });
    // }
  }

  totalRiskDetailsComplianceIsVisible(compliance: RiskComplianceCompliance | DashboardEvaluationComplianceByClient) {
    if (!this.evaluationFilter.collapsedCompliances) {
      return true;
    }

    return this.evaluationFilter.collapsedCompliances.indexOf(compliance.id) === -1;
  }

  toggleTotalRiskDetailsCompliance(compliance: RiskComplianceCompliance | DashboardEvaluationComplianceByClient) {
    if (!this.evaluationFilter.collapsedCompliances) {
      this.evaluationFilter.collapsedCompliances = [];
    }

    const index = this.evaluationFilter.collapsedCompliances.indexOf(compliance.id);
    if (index === -1) {
      this.evaluationFilter.collapsedCompliances.push(compliance.id);
    } else {
      this.evaluationFilter.collapsedCompliances.splice(index, 1);
    }

    this.updateDashboardFilter();
  }

  toggleTotalRiskDetails() {
    this.evaluationFilter.showTotalRiskDetails = !this.evaluationFilter.showTotalRiskDetails;
    this.updateDashboardFilter();
  }

  updateDashboardFilter() {
    this.filterChanged?.emit(this.evaluationFilter);
  }

  public readonly milestones: ProgressMeterMilestone[] = ProgressMeterStates;

  getProgressMeterClass(type: ProgressMeterType, value: number) {
    const classNames: string[] = [];
    const reachedMilestione = this.milestones
      .sort((milestoneA, milestoneB) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return milestoneB.valueMain - milestoneA.valueMain;
          case ProgressMeterType.SUB:
            return milestoneB.valueSub - milestoneA.valueSub;
        }
      }).find((milestone) => {
        switch (type) {
          case ProgressMeterType.MAIN:
            return value >= milestone.valueMain;
          case ProgressMeterType.SUB:
            return value >= milestone.valueSub;
        }
      });

    if (reachedMilestione) {
      classNames.push(reachedMilestione.className);
    }

    return classNames;
  }
}
