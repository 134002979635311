import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplianceOverviewComponent } from './compliance-overview/compliance-overview.component';
import { UIRouterModule } from '@uirouter/angular';
import { COMPLIANCE_STATES } from './compliance.states';
import { ComplianceEditNavComponent } from './compliance-edit-nav/compliance-edit-nav.component';
import { ComplianceEditUserComponent } from './compliance-edit-user/compliance-edit-user.component';
import { ComplianceEditGeneralComponent } from './compliance-edit-general/compliance-edit-general.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from '../../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ValidationModule } from '../../core/validation/validation.module';

@NgModule({
  declarations: [
    ComplianceOverviewComponent,
    ComplianceEditNavComponent,
    ComplianceEditUserComponent,
    ComplianceEditGeneralComponent,
  ],
  imports: [
    CommonModule,
    UIRouterModule.forChild({states: COMPLIANCE_STATES}),
    TranslateModule.forChild({}),
    FontAwesomeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    ValidationModule,
  ],
})
export class ComplianceModule {}
