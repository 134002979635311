<header class="header">
  <h2 class="heading">Maßnahmen ohne Zuordnung</h2>
  <div class="headerControls">
    <button mat-icon-button (click)="expanded = !expanded">
      <fa-icon [icon]="expanded ? 'angle-up' : 'angle-down'"></fa-icon>
    </button>
  </div>
</header>
<div class="unassignedMeassures" *ngIf="expanded">
  <app-measure-workspace-item
    [measure]="measure"
    *ngFor="let measure of unassignedMeassures$ | async"
  ></app-measure-workspace-item>
</div>
