import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Task, TaskDraft, TaskSearchData } from './task';
import { IdObject } from 'src/app/core/id-object';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private static listUrl = (apiUrl: string): string => `${apiUrl}task`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}task/${id}`;
  private static searchUrl = (apiUrl: string): string => `${apiUrl}task/search`;

  constructor(private httpClient: HttpClient, private logger: NGXLogger) {}

  public getList(): Observable<Task[]> {
    const { apiUrl } = environment;
    const url = TaskService.listUrl(apiUrl);

    return this.httpClient
      .get<Task[]>(url)
      .pipe(catchError(this.handleError<Task[]>('TaskService->getList', [])));
  }

  public getSingle(id: string): Observable<Task> {
    const { apiUrl } = environment;
    const url = TaskService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<Task>(url)
      .pipe(catchError(this.handleError<Task>('TaskService->getSingle')));
  }

  public create(taskDraft: TaskDraft): Observable<string> {
    const { apiUrl } = environment;
    const url = TaskService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.post<IdObject>(url, taskDraft, { headers }).pipe(
      map((idObject: IdObject) => idObject.id),
      catchError(this.handleError<string>('TaskService->create')),
    );
  }

  public patch(id: string, taskDraft: TaskDraft): Observable<boolean> {
    const { apiUrl } = environment;
    const url = TaskService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient.patch<void>(url, taskDraft, { headers }).pipe(
      map(() => true),
      catchError(this.handleError<boolean>('TaskService->patch', false)),
    );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = TaskService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('TaskService->remove', false)),
    );
  }

  public search(taskSearchData: TaskSearchData): Observable<Task[]> {
    const { apiUrl } = environment;
    const url = TaskService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<Task[]>(url, taskSearchData, { headers })
      .pipe(catchError(this.handleError<Task[]>('TaskService->search', [])));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      this.logger.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
