<header id="header" role="banner">
  <h1>
    Company
    <small>Design</small>
  </h1>
  <a href="" class="header-logo">
    <img
      src="/img/logos/logo-100x100-inverted.png"
      alt="guestoo - Gästemanagement einfach!"
    />
  </a>
</header>

<main role="main" id="content" class="areas">
  <app-company-nav></app-company-nav>
  <form
    class="o-companyDesign"
    novalidate
    (ngSubmit)="onSubmit(editFormGroup)"
    [formGroup]="editFormGroup">

    <h3 class="subHeading">Farben</h3>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Primärfarbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Primärfarbe"
          formControlName="designPrimaryColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>

      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Akzentfarbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Akzentfarbe"
          formControlName="designAccentColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Textfarbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Textfarbe"
          formControlName="designTextColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>

      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Textfarbe leicht</mat-label>
        <input
          matInput
          type="color"
          placeholder="Textfarbe leicht"
          formControlName="designTextLightColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="5px">
      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Trennfarbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Trennfarbe"
          formControlName="designDividerColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>

      <mat-form-field fxFlex="33%" appearance="fill">
        <mat-label>Hintergrundfarbe</mat-label>
        <input
          matInput
          type="color"
          placeholder="Hintergrundfarbe"
          formControlName="designBackgroundColor"
          list="colorList"
        />
        <datalist id="colorList">
          <option value="#7A6FF0"></option>
          <option value="#008CE3"></option>
        </datalist>
      </mat-form-field>
    </div>

    <h3 class="subHeading">Logos &amp; Bilder </h3>
    <div fxLayout="row" fxLayoutGap="5px">

      <div fxFlex="33%" appearance="fill">
        <label>Logo Dashboard</label>
        <cp-upload (uploadFinished)="assetUploadFinished('designLogoDashboard', $event)" [multiple]="false">
          <div class="data upload--finished">
            <img [src]="getAssetUrl('designLogoDashboard')" />
          </div>
        </cp-upload>
      </div>

      <div fxFlex="33%" appearance="fill">
        <label>Logo E-Mail</label>
        <cp-upload (uploadFinished)="assetUploadFinished('designLogoMail', $event)" [multiple]="false">
          <div class="data upload--finished">
            <img [src]="getAssetUrl('designLogoMail')" />
          </div>
        </cp-upload>
      </div>
    </div>
    <footer class="o-complianceEdit__footer">
      <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
    </footer>
  </form>
</main>

