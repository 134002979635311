import { Component, Input } from '@angular/core';
import { NavigationService } from '../../../layout/navigation/navigation.service';
import { Area } from '../area';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AreaAccount, AreaAccountDraft } from '../area';
import { Account } from '../../account/account';
import { SelectionModel } from '@angular/cdk/collections';
import { AccountService } from '../../account/account.service';
import { MeasureState } from '../../measure/measure-state.enum';
import { IdObjectService } from '../../../core/id-object.service';
import { ValueObjectService } from '../../../core/value-object.service';
import { ValueObject } from '../../../core/value-object';
import { Role } from '../../account/role.enum';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EditMode } from '../../../core/edit-mode.enum';
import { IdObject } from '../../../core/id-object';
import { Measure } from '../../measure/measure';
import { NGXLogger } from 'ngx-logger';
import { AreaService } from '../area.service';
import { RiskMeasureDraft } from '../../risk-measure/risk-measure';

@Component({
  selector: 'app-area-edit-user',
  templateUrl: './area-edit-user.component.html',
  styleUrls: ['./area-edit-user.component.scss'],
})
export class AreaEditUserComponent {
  @Input() area: Area | null = null;
  public pending: boolean = false;

  public editFormGroup: FormGroup = this.fb.group({
    areaAccounts: this.fb.array([]),
  });

  public roleOptions: ValueObject[] = [
    {displayName: 'informativer Benutzer', value: Role.INFORMATIVE},
    {displayName: 'Sachbearbeiter', value: Role.OFFICIAL},
    {displayName: 'Verantwortlicher', value: Role.RESPONSIBLE},
    {displayName: 'Administrator', value: Role.ADMIN},
    {displayName: 'Revisor', value: Role.REVISOR},
  ];

  public areaAccountsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  public areaAccountColumns = ['account', 'role', 'controls'];
  public accountOptions$!: Observable<Account[]>;
  public filteredAccountOptions$ = new BehaviorSubject<Account[]>([]);
  public openedAccountSelectForAreaAccount = new SelectionModel<number>(
    false,
  );

  public accountSearchCtrl = new FormControl();

  public compareById = IdObjectService.compareById;
  public compareByValue = ValueObjectService.compareByValue;

  ngOnInit(): void {
    this.getFilteredAccountOptions();

    if (this.area) {
      this.area.areaAccounts?.forEach((areaAccount) => {
        this.addAreaAccountFormGroup(areaAccount);
      });
    }
  }

  constructor(
    public navigationService: NavigationService,
    private logger: NGXLogger,
    private fb: FormBuilder,
    private areaService: AreaService,
    private accountService: AccountService,
  ) {
    this.accountOptions$ = this.accountService.getList();
    this.accountSearchCtrl.valueChanges
      .pipe(debounceTime(120), distinctUntilChanged())
      .subscribe((searchText) => {
        this.getFilteredAccountOptions(searchText);
      });
  }

  public onSubmit(editFormGroup: FormGroup): void {

    if (this.pending) {
      return;
    }

    if (editFormGroup.invalid) {
      this.logger.log(editFormGroup.value, editFormGroup.errors);
      return;
    }

    if (!this.area) {
      return;
    }

    this.pending = true;
    const {value: editData} = editFormGroup;
    this.areaService.patch(this.area.id, editData).subscribe((success) => {
      this.pending = false;
    });
  }

  public getFilteredAccountOptions(searchText?: string) {
    this.accountService.search({searchText}).subscribe((accountOptions) => {
      this.filteredAccountOptions$.next(accountOptions);
    });
  }

  public addAreaAccountFormGroup(
    areaAccount?: AreaAccount | AreaAccountDraft,
  ): void {
    const riskAccountFormGroup: FormGroup = this.fb.group({
      id: [areaAccount?.id || undefined, []],
      account: [areaAccount?.account, [Validators.required]],
      role: [areaAccount?.role || null, []],
    });

    (this.editFormGroup.get('areaAccounts') as FormArray).push(
      riskAccountFormGroup,
    );

    this.updateAreaAccountView();
  }

  public removeAreaAccountFormGroup(index: number) {
    (this.editFormGroup.get('areaAccounts') as FormArray).removeAt(index);
    this.updateAreaAccountView();
  }

  public setAccountSelectForAreaAccount(open: boolean, index: number): void {
    if (open) {
      this.openedAccountSelectForAreaAccount.select(index);
    } else {
      this.openedAccountSelectForAreaAccount.deselect(index);
    }
  }

  public isAccountSelectForAreaAccountOpen(index: number): boolean {
    return this.openedAccountSelectForAreaAccount.isSelected(index);
  }

  public updateAreaAccountView(): void {
    this.areaAccountsDataSource.next(
      (this.editFormGroup.get('areaAccounts') as FormArray).controls,
    );
  }
}
