import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MeasureCategory, MeasureCategoryDraft } from './measure-category';
import { IdObject } from 'src/app/core/id-object';

@Injectable({
  providedIn: 'root',
})
export class MeasureCategoryService {
  private static listUrl = (apiUrl: string): string =>
    `${apiUrl}measureCategory`;
  private static singleUrl = (apiUrl: string, id: string): string =>
    `${apiUrl}measureCategory/${id}`;
  private static searchUrl = (apiUrl: string): string =>
    `${apiUrl}measureCategory/search`;

  constructor(private httpClient: HttpClient) {}

  public getList(): Observable<MeasureCategory[]> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.listUrl(apiUrl);

    return this.httpClient
      .get<MeasureCategory[]>(url)
      .pipe(
        catchError(
          this.handleError<MeasureCategory[]>(
            'MeasureCategoryService->getList',
            [],
          ),
        ),
      );
  }

  public getSingle(id: string): Observable<MeasureCategory> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.singleUrl(apiUrl, id);

    return this.httpClient
      .get<MeasureCategory>(url)
      .pipe(
        catchError(
          this.handleError<MeasureCategory>(
            'MeasureCategoryService->getSingle',
          ),
        ),
      );
  }

  public create(
    measureCategoryDraft: MeasureCategoryDraft,
  ): Observable<string> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.listUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<IdObject>(url, measureCategoryDraft, { headers })
      .pipe(
        map((idObject: IdObject) => idObject.id),
        catchError(this.handleError<string>('MeasureCategoryService->create')),
      );
  }

  public patch(
    id: string,
    measureCategoryDraft: MeasureCategoryDraft,
  ): Observable<boolean> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.singleUrl(apiUrl, id);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .patch<void>(url, measureCategoryDraft, { headers })
      .pipe(
        map(() => true),
        catchError(
          this.handleError<boolean>('MeasureCategoryService->patch', false),
        ),
      );
  }

  public remove(id: string): Observable<boolean> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.singleUrl(apiUrl, id);

    return this.httpClient.delete<void>(url).pipe(
      map(() => true),
      catchError(this.handleError('MeasureCategoryService->remove', false)),
    );
  }

  public search(searchData: any): Observable<MeasureCategory[]> {
    const { apiUrl } = environment;
    const url = MeasureCategoryService.searchUrl(apiUrl);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.httpClient
      .post<MeasureCategory[]>(url, searchData, { headers })
      .pipe(
        catchError(
          this.handleError<MeasureCategory[]>(
            'MeasureCategoryService->search',
            [],
          ),
        ),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(
    operation = 'operation',
    result?: T,
  ): (error: any) => Observable<T> {
    return (error: any): Observable<T> => {
      console.error(`${operation} failed: `, error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
