import { Ng2StateDeclaration, Transition } from '@uirouter/angular';
import { resolveComplianceId, resolveComplianceList } from '../compliance/compliance.resolver';
import { ComplianceService } from '../compliance/compliance.service';
import { WorkspaceComponent } from './workspace.component';
import { IdObject } from '../../core/id-object';

export const WORKSPACE_STATES: Ng2StateDeclaration[] = [
  {
    name: 'app.workspace',
    component: WorkspaceComponent,
    url: '/workspace',
    params: {
      complianceId: null,
      areaId: null,
      security: {
        enabled: true,
        roles: [],
      },
      style: {
        feedback: true,
        hasMainMenu: true,
        hasSubMenu: false,
        hasSideNav: false,
      },
    },
    resolve: [
      {
        token: 'complianceId',
        deps: [Transition],
        resolveFn: resolveComplianceId,
      },
    ],
  },
];
