import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { EditMode } from '../../edit-mode.enum';
import { TfaForLinkData } from '../tfa';
import { TfaType } from '../tfa-type.enum';
import { TfaService } from '../tfa.service';
import { TfaCreateModalData } from './tfa-create-modal-data';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

@Component({
  selector: 'app-tfa-create-modal',
  templateUrl: './tfa-create-modal.component.html',
  styleUrls: ['./tfa-create-modal.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: true}
  }]
})
export class TfaCreateModalComponent implements OnInit {
  @ViewChild(MatStepper) stepper!: MatStepper;
  public pending: boolean = false;

  public createFormGroup: FormGroup = this.fb.group({
    displayName: ['', [Validators.required]],
    accountPassword: ['', [Validators.required]],
    twoFactorType: [TfaType.OTP, []],
  });

  public confirmFormGroup: FormGroup = this.fb.group({
    token: ['', [Validators.required]],
  });

  public tfaForLinkData: TfaForLinkData | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TfaCreateModalData,
    public matDialogRef: MatDialogRef<TfaCreateModalComponent>,
    private fb: FormBuilder,
    private tfaService: TfaService,
  ) {
  }

  public get isCreate(): boolean {
    return this.data.editMode === EditMode.CREATE;
  }

  ngOnInit(): void {
    if (this.data.editMode === EditMode.CONFIRM) {
      this.tfaService
        .getForLinkData(this.data.tfaId)
        .subscribe((tfaForLinkData) => {
          if (tfaForLinkData) {
            this.processTfaForLinkData(tfaForLinkData);
          }
        });
    }
  }

  public processTfaForLinkData(tfaForLinkData: TfaForLinkData): void {
    this.tfaForLinkData = tfaForLinkData;

    if (this.stepper.selected) {
      this.stepper.selected.completed = true;
      this.stepper.selected.editable = false;
    }

    this.stepper.next();
  }

  public onCreateFormSubmit(createFormGroup: FormGroup): void {
    if (createFormGroup.invalid) {
      return;
    }

    const {value: tfaCreateData} = createFormGroup;

    this.tfaService.create(tfaCreateData).subscribe((tfaForLinkData) => {
      if (tfaForLinkData) {
        this.processTfaForLinkData(tfaForLinkData);
      }
    }, (err) => {
      this.handleError(err);
    });
  }

  public onConfirmFormSubmit(confirmFormGroup: FormGroup): void {
    if (confirmFormGroup.invalid || !this.tfaForLinkData) {
      return;
    }

    const {value: tfaLinkData} = confirmFormGroup;
    const {id} = this.tfaForLinkData;

    this.tfaService.confirm(id, tfaLinkData).subscribe((success) => {
      if (success) {
        this.matDialogRef.close();
      }
    });
  }

  handleError(err: any) {
    this.pending = false;
    if (err.errorFields) {
      for (let errorField of err.errorFields) {
        if (errorField.field === 'accountPassword') {
          this.createFormGroup.get('accountPassword')?.setErrors({'pattern': true});
        }
      }
    }
  }
}
