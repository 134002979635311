<nav id="subNav" class="hasIcons">
	<ul>
		<li uiSrefActive="active">
      <a uiSref="app.settings.attribute.overview">
        <fa-icon icon="text-height"></fa-icon>
        <small>Attribute</small>
		  </a>
    </li>
    <li uiSrefActive="active">
      <a uiSref="app.settings.template">
        <fa-icon icon="file-alt"></fa-icon>
        <small>Attribut-Gruppen</small>
		  </a>
    </li>
    <li uiSrefActive="active">
      <a uiSref="app.settings.category">
        <fa-icon icon="sitemap"></fa-icon>
        <small>Objekt-Kategorien</small>
      </a>
    </li>
	</ul>
</nav>
