import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasureTypePipe } from './measure-type.pipe';

@NgModule({
  declarations: [MeasureTypePipe],
  imports: [CommonModule],
  exports: [MeasureTypePipe],
})
export class MeasureTypeModule {}
