<div mat-dialog-title>
  <ng-template [ngIf]="isCreate" [ngIfElse]="editModeText">
    Attribut anlegen
  </ng-template>
  <ng-template #editModeText>
    Attribut bearbeiten
  </ng-template>
</div>
<form
  novalidate
  (ngSubmit)=" onSubmit(editFormGroup)"
  [formGroup]="editFormGroup"
>
    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Bezeichnung</mat-label>
      <input matInput type="text" formControlName="key"/>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Beschreibung</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Typ</mat-label>
      <mat-select placeholder="Typ" formControlName="type">
        <mat-option [value]="type.value" *ngFor="let type of typeOptions">
          {{ type.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Interner Status</mat-label>
      <mat-select
        [compareWith]="compareByValue"
        formControlName="state">
        <mat-option
          [value]="state?.value"
          *ngFor="let state of stateOptions">
          {{ state?.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

<footer mat-dialog-actions>
  <button type="button" mat-raised-button mat-dialog-close>Abbrechen</button>
  <button type="submit" mat-raised-button color="primary" [disabled]="pending">Sichern</button>
</footer>
</form>
