import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DamageTypesCommaSeparatedPipe } from './damage-types-comma-separated.pipe';

@NgModule({
  declarations: [DamageTypesCommaSeparatedPipe],
  imports: [CommonModule],
  exports: [DamageTypesCommaSeparatedPipe],
})
export class DamageTypeModule {}
